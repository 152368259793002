import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DropDownAddValue from 'src/components/drop-down-add-value/drop-down-add-value';
import FormProvider, {
  RHFTextField,
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { MultiFilePreview, Upload } from 'src/components/upload';
import { endpoints } from 'src/utils/axios';
import * as Yup from 'yup';
import { formatDate, getDropdownsId, handleErrorMessages } from './feching';

import Iconify from 'src/components/iconify';
import RiskprojectUserAdd from 'src/components/risk-project-user-drop/project-user-drop';
import { postRequest } from 'src/services';
const RiskRegisterCreate = ({
  open,
  onClose,
  riskOwnersOptions,
  riskCategoryOptions,
  projectStageOptions,
  reloadRiskOwnersOptions,
  reloadRiskCategoryOptions,
  reloadProjectStageOptions,
  currentItem,
  reload,
  closeform,
}) => {
  console.log("🚀 ~ currentItem:", currentItem)
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [loading, setLoading] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [selectedRiskOwnerId, setSelectedRiskOwnerId] = useState(null);
  const [isFileDeleted, setIsFileDeleted] = useState(false); // State to track file deletion
  console.log("🚀 ~ isFileDeleted:", isFileDeleted)

  const { enqueueSnackbar } = useSnackbar();
  const NewUserSchema = Yup.object().shape({
    risk_id: Yup.string().required('Risk ID is required'),
    risk_title: Yup.string().required('Risk Title is required'),
    project_stage: Yup.string().required('Project Stage is required'),
    risk_category: Yup.string().required('Risk Category are required'),
    risk_description: Yup.string().required('Risk Description is required'),
    date_identified: Yup.mixed().required('Date Identified are required'),
  });
  console.log(currentItem, 'here we go');
  const defaultValues = useMemo(
    () => ({
      risk_id: currentItem?.risk_id || '',
      risk_title: currentItem?.title || '',
      project_stage: currentItem?.project_stage?.name || '',
      risk_category: currentItem?.risk_category?.name || '',
      risk_description: currentItem?.description || '',
      base_case: currentItem?.base_case || '',
      exposure: currentItem?.exposure || '',
      date_identified: currentItem?.date_identified ? new Date(currentItem.date_identified) : new Date(),
      identified_by: currentItem?.identified_by || '',
      risk_owner: currentItem?.risk_owner?.name || 'None',
      risk_owner_id: currentItem?.risk_owner_id || 'None',
      file: currentItem?.file || 'None',

    }),
    [currentItem]
  );
  useEffect(() => {
    console.log("🚀 ~ defaultValues------:", defaultValues)
    if (currentItem) {
      if (currentItem.file != null) {
        setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
      } else {
        setFilePreview([]);
      }
    }
  }, [defaultValues]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });
  const {
    reset,
    control,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [reset, defaultValues, open]);

  const handleOwnerChange = useCallback((riskOwnerId) => {
    setSelectedRiskOwnerId(riskOwnerId);
  }, []);

  const checkRiskIdExists = async (riskId, currentItemId) => {
    const existingRiskIds = [
      { id: 1, riskId: 'RISK123' },
      { id: 2, riskId: 'RISK456' }
    ]; 
    const riskExists = existingRiskIds.find(
      (item) => item.riskId === riskId && item.id !== currentItemId
    );
    return riskExists !== undefined;
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const formData = new FormData();
      const riskIdExists = await checkRiskIdExists(data.risk_id, currentItem?.id);
      if (riskIdExists) {
        setError('risk_id', {
          type: 'manual',
          message: 'Risk ID already exists',
        });
        setRiskIdError(true);
        enqueueSnackbar('Risk ID already exists', { variant: 'error' });
        window.scrollTo(0, 0);
        throw new Error('Risk ID already exists'); // Throw error to be caught later
      } else {
        setRiskIdError(false);
      }
      formData.append('project_stage_id', getDropdownsId(projectStageOptions, data.project_stage));
      formData.append('risk_category_id', getDropdownsId(riskCategoryOptions, data.risk_category));
      if (getDropdownsId(riskOwnersOptions, data.risk_owner_id) !== '') {
        formData.append('r_m_risk_owner', getDropdownsId(riskOwnersOptions, data.risk_owner_id));
      }
      formData.append('risk_owner_id', selectedRiskOwnerId);
      formData.append('risk_id', data.risk_id);
      formData.append('title', data.risk_title);
      formData.append('description', data.risk_description);
      formData.append('base_case', data.base_case);
      formData.append('exposure', data.exposure);
      formData.append('date_identified', formatDate(data.date_identified));
      formData.append('identified_by', data.identified_by);
      if (isFileDeleted) {
        formData.append('is_file_deleted', true);
      }
      if (files.length > 0) {
        formData.append('file', files[0]);
      }
      setLoading(true);
      const result = await postRequest(
        currentItem?.id
          ? `${endpoints.risk_management.risk_register.identification.identification}/${currentItem.id}?_method=PUT`
          : endpoints.risk_management.risk_register.identification.identification,
        formData,
        true
      );
      setLoading(false);
      if (result.metadata?.success) {
        setIsFileDeleted(false);
        reset();
        setFiles([]);
        setFilePreview([]);
        onClose();
        closeform();
        reload();
        enqueueSnackbar('Risk Management Identification Created Successfully', { variant: 'success' });
      } else {
        handleErrorMessages(result.metadata.message);
      }
    } catch (err) {
      setLoading(false);
      if (err.message === 'Risk ID already exists') {
      } else {
        enqueueSnackbar('Risk ID already exists', { variant: 'error' });
      }
      console.error('Error in catch:', err);
    }
  });
  useEffect(() => {
    if (Object.keys(methods.formState.errors).length > 0) {
      window.scrollTo(0, 0);
      Object.values(methods.formState.errors).forEach((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    }
  }, [methods.formState.errors, enqueueSnackbar]);
  console.log("🚀 ~ onSubmit ~ selectedRiskOwnerId:", selectedRiskOwnerId)
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }));
      setFiles([...newFiles]);
    },
    [files]
  );

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleClose = () => {
    console.log("close clicked----");
    setIsFileDeleted(false);
    onClose(); // Close the dialog
  };

  const handleRemoveFile = (inputFile) => {
    setIsFileDeleted(true); 
    setFiles([]); 
    setFilePreview([]);
  };
 
  useEffect(() => {
    console.log("🚀 ~ defaultValues------:", defaultValues)
    if (currentItem) {
      if (currentItem.file != null) {
        setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
      } else {
        setFilePreview([]);
      }
    }
  }, [defaultValues]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(); 
          }
        }}
        fullWidth
        maxWidth="md"
        sx={{
          borderRadius: 'none',
          padding: '60px',
          fontSize: '13px',
          fontFamily: 'arial',
        }}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
            <span
              className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
              &nbsp;&nbsp;  Create / Identify Risk  &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <IconButton onClick={handleClose}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </DialogTitle>
          <>
            <DialogContent
              sx={{
                borderRadius: '1px',
              }}
            >
                <>
              <Box
                mt={2}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField
                  name="risk_id"
                  label={`Risk ID ${methods.formState.errors.risk_id ? '*' : ''}`}
                  error={!!methods.formState.errors.risk_id} 
                  helperText={methods.formState.errors.risk_id?.message}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      '&.Mui-focused fieldset': {
                        borderColor: methods.formState.errors.risk_id ? 'red' : '',
                      },
                      '& fieldset': {
                        borderColor: methods.formState.errors.risk_id ? 'red' : '', 
                      },
                    },
                  }}
                />
                <RHFTextField name="risk_title" label={`Risk Title ${methods.formState.errors.risk_title ? '*' : ''}`} />
                <DropDownAddValue
                  name="project_stage"
                  label={`Project Stage ${methods.formState.errors.project_stage ? '*' : ''}`}
                  options={projectStageOptions}
                  reload={() => reloadProjectStageOptions()}
                  url={endpoints.risk_management.risk_register.identification.project_stages}
                />
                <DropDownAddValue
                  name="risk_category"
                  label={`Risk Category ${methods.formState.errors.risk_category ? '*' : ''}`}
                  options={riskCategoryOptions}
                  reload={() => reloadRiskCategoryOptions()}
                  url={endpoints.risk_management.risk_register.identification.risk_categories}
                />
                <RHFTextField name="risk_description" label={`Risk Description ${methods.formState.errors.risk_description ? '*' : ''}`} />
                <RHFTextField name="base_case" label="Base Case" />
                <RHFTextField name="exposure" label="Exposure" type="number" />
                <Controller
                  name="date_identified"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label={`Date Identified ${error ? '*' : ''}`}
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
                <RHFTextField name="identified_by" label="Identified By" />
                <RiskprojectUserAdd
                label="Risk Owner"
                  options={riskOwnersOptions}
                  reload={() => reloadRiskOwnersOptions()}
                  url={endpoints.risk_management.risk_register.identification.risk_owners}
                  onRiskOwnerChange={handleOwnerChange}
                  onChange={handleOwnerChange}
                  value={currentItem?.risk_owner?.id}
                />

              </Box>
              <Box
                mt={4}
                rowGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                }}
              >
                <Typography variant="subtitle2">Related Documents</Typography>
                <Upload onDrop={handleDrop} onRemove={handleRemoveFile} />
              </Box>
              <Box sx={{ my: 3 }}>
                <MultiFilePreview
                  files={files?.length > 0 ? files : filePreview}
                  onRemove={handleRemoveFile}
                  setFileDeleted={setIsFileDeleted} 
                />
              </Box>
                  </>
            </DialogContent>
          </>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" className='dark-button'  loading={isSubmitting}>
              {!currentItem ? 'Create Risk' : 'Save Changes'}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
export default RiskRegisterCreate;