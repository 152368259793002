import isEqual from 'lodash/isEqual';
import { useState, useCallback, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows, TableSelectedAction,
  TablePaginationCustom
} from 'src/components/table';
import { tablerowData } from './table-row-data';
import SettingModal from './settings-jobcosting-modal';
import { Box, MenuItem, Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useNavigate } from 'react-router';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import ShareByEmailpopup from './share-by-email';
import { CSVLink } from 'react-csv';
import { getRequest, postRequest } from 'src/services';
import { apiHeader, endpoints, simpleHeader } from 'src/utils/axios';
import JobCostingSummaryPage from './Subtotoal';
import { TABLE_HEAD } from './table-head-report';
import JobcostingGenerateTable from './job-costing-genreate-report-table';
import JobcostingChangeorderGenerateRerportTableRow from './job-costing-change-order-genreate-report-table';
import { getCsvData } from './getCsvData';
import JobCostingChangeOrderSubTotal from './subtotoal-change-order';
import { enqueueSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';
import PdfTable, { generatePdfBlob } from './PDFExportFile';
import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetUpper, calculateVariance } from './Calculations/formulas';
import { baseURL } from 'src/config-global';
import axios from 'axios';
import OrderTableFiltersResult from 'src/sections/order/order-table-filters-result';
import OrderTableToolbar from './generate-report-filter-head';
// ----------------------------------------------------------------------

export default function ReportTable() {
  const table = useTable({ defaultOrderBy: 'orderNumber' });
  const table2 = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const add = useBoolean();
  const check = useBoolean();
  const navigate = useNavigate();
  const popover = usePopover();
  const csvLinkRef = useRef(null);

  const [pdfData, setPdfData] = useState(tablerowData);
  const [pdfFileUrl, setPdfFileUrl] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [loading, setLoading] = useState();
  const [jobCostinglabels, setjobCostinglabels] = useState([]);
  const [projectID, setsetprojectID] = useState('');
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [defaultStartDate, setDefaultStartDate] = useState(null);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [profile, SetProfile] = useState();
  const [projectname, setprojectname] = useState();
  const [subtotals, setSubtotals] = useState({});
  const defaultFilters = {
    startDate: startDate,
    endDate: endDate,
  };

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    getProfile();
    getChatDetails();
  }, []);
  const getProfile = async () => {
    try {
      const data = await getRequest(endpoints.profile_info, {
        headers: {
          'Cache-Control': 'no-cache',
          apiHeader,
        },
      });
      SetProfile(data);
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };
  const getChatDetails = () => {
    axios
      .get(`${baseURL}${endpoints.chat_details}`, {
        headers: simpleHeader,
      })
      .then((res) => {
        //  console.log('🚀 ~ getChatDetails============= ~ res:', res);
        localStorage.getItem('chatDetails', JSON.stringify(res.data));
        if (res.status === 200) {
          setprojectname(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleSubtotalsCalculated = (calculatedSubtotals) => {
    setSubtotals(calculatedSubtotals);
  };

  useEffect(() => {
    if (defaultStartDate) {
      const parsedDefaultStartDate = new Date(defaultStartDate);
      if (!isNaN(parsedDefaultStartDate.getTime())) {
        setStartDate(parsedDefaultStartDate);
      } else {
        console.error('Invalid defaultStartDate format:', defaultStartDate);
      }
    }
    setEndDate(new Date());
  }, [defaultStartDate]);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);


  const dataFiltered2 = applyFilter({
    inputData: tableData2,
    comparator: getComparator(table2.order, table2.orderBy),
    filters,
  });
  const dataInPage2 = dataFiltered2.slice(
    table2.page * table2.rowsPerPage,
    table2.page * table2.rowsPerPage + table2.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual(defaultFilters, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  useEffect(() => {
    getJobCostigList()
  }, []);
  const getJobCostigList = async () => {
    try {
      const data = await getRequest(endpoints.job_costing.get_list, {
        headers: {
          simpleHeader,
        },
      });
      const firstStartDate = data[0]?.project?.start_date || null;
      if (firstStartDate) {
        // Format date to 'MM dd, yyyy'
        const formattedStartDate = new Date(firstStartDate).toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });

        // Store it in state
        setDefaultStartDate(formattedStartDate);
      }

      const changeOrderTable = data.filter(
        (item) => item.is_change_order === 1 && item.is_order_attached === 0
      );
      const baseChangetable = data
        .filter(
          (item) =>
            !(item.is_change_order === 1 && item.is_order_attached === 1) &&
            !changeOrderTable.some((changeItem) => changeItem.id === item.id)
        )
        .map((item) => ({ ...item, hidden: false }));

      setTableData(baseChangetable);
      setTableData2(changeOrderTable);

      if (data.length > 0) {
        setsetprojectID(data[0]?.project_id || '');
      }
      // console.log(
      //   '🚀 ~ baseChangetable, changeOrderTable ><><><><><><>:',
      //   baseChangetable,
      //   changeOrderTable
      // );
    } catch (error) {
      console.error("Error fetching job costing list:", error);
    }
  };

  useEffect(() => {
    const fetchAndRenderLabels = async () => {
      await getSettingsLabels();
      renderLabels();
    };
    fetchAndRenderLabels();
  }, []);

  const getSettingsLabels = async () => {
    try {
      setLoading(true)

      const response = await getRequest(endpoints.job_costing.setting);
      if (response) {
        const defaultCheckedKeys = [
          "facility",
          "level",
          "duration",
          "trade",
          "responsible_party",
          "milestone",
          "payment_terms",
          "billing_schedule",
          "risk_level",
          "quality_checks",
          "compliance",
          "payment_status",
          "contingency_used",
          "notes",
        ];
        const excludedKeys = ["id", "creator_id", "project_name", "contract_number", "project_id", "job_cost_graph", "schedule_performance", "cost_performance"];

        const labels = Object.keys(response)
          .filter((key) => !excludedKeys.includes(key))
          .map((key) => ({
            name: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
            value: defaultCheckedKeys.includes(key) || response[key] === 1,
            key: key,
            visible: !defaultCheckedKeys.includes(key),
          }));

        setjobCostinglabels(labels);

        const visibleColumns = labels.map((label) => ({
          key: label.key,
          value: label.value ? 1 : 0,
        }));
        const payload = visibleColumns.reduce((acc, column) => {
          acc[column.key] = column.value;
          return acc;
        }, {});

        const renderResponse = await postRequest(endpoints.job_costing.setting_post, payload);
        if (renderResponse.metadata && renderResponse.metadata.success) {
          enqueueSnackbar("Settings updated successfully!", { variant: "success" });
          setVisibleColumns(labels.filter((label) => !label.value).map((label) => label.key));
        } else {
          throw new Error(renderResponse.metadata.message || "Error updating settings.");
        }
        setLoading(false)
      }
    } catch (error) {
      console.error("Error fetching and rendering settings labels:", error);
      enqueueSnackbar("Error fetching settings labels.", { variant: "error" });
    }
  };
  const renderLabels = async () => {
    try {
      const visibleColumns = jobCostinglabels.map((label) => ({
        key: label.key,
        value: label.value ? 1 : 0,
      }));
      // console.log("🚀 ~ visibleColumns ~ visibleColumns:", visibleColumns)
      const payload = visibleColumns.reduce((acc, column) => {
        acc[column.key] = column.value;
        return acc;
      }, {});
      const response = await postRequest(endpoints.job_costing.setting_post, payload);
      if (response.metadata && response.metadata.success) {
        enqueueSnackbar({
          variant: 'success',
          message: 'Settings updated successfully!',
        });
        // onSave(JobCostingLabels);
      } else {
        enqueueSnackbar({
          variant: 'error',
          message: response.metadata.message || 'Error updating settings.',
        });
      }
    } catch (error) {
      enqueueSnackbar({
        variant: 'error',
        message: 'Error submitting form.',
      });
      console.error('Error submitting form:', error);
    } finally {
    }
  };

  const handleSaveSettings = (labels) => {
    setjobCostinglabels(labels);
    setVisibleColumns(labels.filter((label) => !label.value).map((label) => label.key));
  };

  useEffect(() => {
    const defaultVisibleColumns = TABLE_HEAD.map((head) => head.id);
    setVisibleColumns(defaultVisibleColumns);
  }, []);

  const isColumnVisible = (columnKey) => {
    if (['action', 'change_order_id'].includes(columnKey)) {
      return true;
    }
    return !jobCostinglabels.some((label) => label.key === columnKey && label.value);
  };

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData.filter((row) => row.id !== id);
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );
  const handleDeleteRows = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
    setTableData(deleteRows);
    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);
  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );
  const getCsvHeaders = () => {
    return TABLE_HEAD
      .filter(({ label, id }) =>
        id !== 'action' && id !== 'project_id' &&
        isColumnVisible(id)
      )
      .map(({ id, label }) => ({
        label,
        key: id
      }));
  };
  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData, jobCostinglabels, isColumnVisible);
      csvLinkRef.current.link.click(csvData);
    }
  };

  // pdf generate corected
  const handleGeneratePdf = async () => {
    try {
      const blob = await generatePdfBlob({
        tableData,         
        jobCostinglabels,  
        subtotals,        
        projectname,      
        profile,          
        // startDate,        
        // endDate          
      });
      // console.log("🚀 ~ handleGeneratePdf ~ blob:", blob);
      const fileName = `job_costing_report.pdf`;
      const binaryFile = new File([blob], fileName, { type: 'application/pdf' });
      // console.log("🚀 Generated Binary File:", binaryFile);
      setPdfFileUrl({ blob, name: fileName, file: binaryFile });
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleOpenShareModal = () => {
    handleGeneratePdf();
    check.onTrue();
  };

  const enrichTableDataWithCalculatedValues = (data) => {
    return data.map((row) => {
      const approvedChangeOrders = parseFloat(row?.change_orders?.[0]?.budgeted_amount) || 0;
      const plannedSubcontractorCost = parseFloat(row?.change_orders?.[0]?.planned_subcontractor_cost) || 0;

      const revisedBudget = calculateRevisedBudgetUpper(row.original_budgeted_amount, approvedChangeOrders);
      const committedSubcontractorCost = calculateCommittedSubcontractorCost(row, plannedSubcontractorCost);
      const nonCommittedCostToDate = calculateNonCommittedCostToDate(revisedBudget, committedSubcontractorCost);
      const estimatedFinalCost = calculateEstimatedFinalCost(
        committedSubcontractorCost,
        nonCommittedCostToDate,
        parseFloat(row.non_committed_estimate_cost_to_date) || 0
      );
      const variance = calculateVariance(estimatedFinalCost, revisedBudget);
      const performedPercentage = calculatePerformedPercentage(row.performed_amount, revisedBudget);
      const actualCost = calculateActualCost(row.resources_used, row.expenses);
      const grossProfit = calculateGrossProfit(row.performed_amount, actualCost);
      const grossProfitPercentage = calculateGrossProfitPercentage(grossProfit, revisedBudget);
      const remainingBudget = calculateRemainingBudget(revisedBudget, actualCost);
      const retainage = calculateRetainage(row.performed_amount);

      return {
        ...row,
        revised_budget_amount: revisedBudget,
        committed_revised_subcontractor_cost: committedSubcontractorCost,
        non_committed_cost_to_date: nonCommittedCostToDate,
        estimated_final_cost: estimatedFinalCost,
        variance_project_budgeted: variance,
        performed_percentage: performedPercentage,
        actual_cost: actualCost,
        gross_profit: grossProfit,
        gross_profit_percentage: grossProfitPercentage,
        remaining_budget: remainingBudget,
        retainage: retainage,
      };
    });
  };
  const enrichedTableData = enrichTableDataWithCalculatedValues(dataFiltered);


  return (
    <Box sx={{ width: '100%', padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          borderRadius: 1,
        }}
      >
        <OrderTableToolbar
          filters={filters}
          onFilters={handleFilters}
          //
          defaultStartDate={defaultStartDate}
          canReset={canReset}
          onResetFilters={handleResetFilters}
        />
        {/* Action Buttons */}
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            className="dark-button"
            variant="contained"
            startIcon={<Iconify icon="ic:baseline-email" />}
            onClick={handleOpenShareModal} // Updated to open modal and generate PDF
          >
            Share By mail
          </Button>
          <Button
            onClick={popover.onOpen}
            className="dark-button"
            variant="contained"
            startIcon={<Iconify icon="solar:export-bold" />}
          >
            Export
          </Button>
          <Button
            onClick={add.onTrue}
            className="dark-button"
            variant="contained"
            startIcon={<Iconify icon="material-symbols:settings" />}
          >
            Setting
          </Button>
          <CustomPopover
            open={popover.open}
            onClose={popover.onClose}
            arrow="right-top"
            sx={{ width: 140 }}
          >
            <MenuItem
              onClick={() => {
                handleExport(); // Call the export function when the menu item is clicked
                popover.onClose();
              }}
            >
              Download as CSV
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPdfData(dataFiltered); // Set the filtered data for export
                popover.onClose();
              }}
            >
              {/* //pdfhere */}
              <PdfTable
                // startDate={startDate}
                // endDate={endDate}
                projectname={projectname}
                profile={profile}
                tableData={enrichedTableData}
                jobCostinglabels={jobCostinglabels}
                subtotals={subtotals}
              />
            </MenuItem>
          </CustomPopover>
          <Button
            className="dark-button"
            variant="contained"
            startIcon={<Iconify icon="mdi:arrow-back-circle" />}
            onClick={() => navigate('/job-costing')}
          >
            Back
          </Button>
        </Box>
      </Box>
      {/* <Card sx={{ marginTop: '30px' }}> */}


      <SettingModal
        open={add.value}
        onClose={add.onFalse}
        onSave={handleSaveSettings}
        labels={jobCostinglabels}
        setjobCostinglabels={setjobCostinglabels}
      />
      <Card>

    
        {canReset && (
          <OrderTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={dataFiltered.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}
        <Box>
          <h3 style={{ pading: '20px', marginRight: '10px' }}>BASE CONTRACT</h3>
        </Box>
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={table.dense}
            numSelected={table.selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) =>
              table.onSelectAllRows(
                checked,
                tableData.map((row) => row.id)
              )
            }
            action={
              <Tooltip title="Delete">
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            {loading ? (
              <Box height={700}>
                <LoadingScreen />
              </Box>
            ) : (
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHead>

                  <TableRow>
                    {TABLE_HEAD.filter(
                      (headCell) => isColumnVisible(headCell.id) // Check if column is visible
                    ).map((headCell) => (
                      <TableCell key={headCell.id} sx={{ minWidth: headCell.minWidth }}>
                        {headCell.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <JobcostingGenerateTable
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        refreshTableData={() => getJobCostigList()}
                        visibleColumns={visibleColumns}
                      />
                      // <OrderTableRow
                      //   key={row.id}
                      //   row={row}
                      //   selected={table.selected.includes(row.id)}
                      //   onSelectRow={() => table.onSelectRow(row.id)}
                      //   onDeleteRow={() => handleDeleteRow(row.id)}
                      //   // onViewRow={() => handleViewRow(row.id)}
                      // />
                    ))}

                  {dataFiltered?.length > 0 && (
                    <JobCostingSummaryPage
                      tableData={dataFiltered} // Pass filtered data to calculate subtotals
                      visibleColumns={visibleColumns}
                      onSubtotalsCalculated={handleSubtotalsCalculated}
                    />
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            )}
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={dataFiltered.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          //
          dense={table.dense}
          onChangeDense={table.onChangeDense}
        />
      </Card>



      {/* //my tabes previous */}


      <Box>
        <h3 style={{ pading: '20px', marginRight: '10px' }}>CHANGE ORDER</h3>
      </Box>

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <TableSelectedAction
          dense={table.dense}
          numSelected={table.selected.length}
          rowCount={tableData.length}
          onSelectAllRows={(checked) =>
            table.onSelectAllRows(
              checked,
              tableData?.map((row) => row.id)
            )
          }
          action={
            <Tooltip title="Delete">
              <IconButton color="primary" onClick={confirm.onTrue}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          }
        />

        <Scrollbar>
          <Table
            size={table.dense ? 'small' : 'medium'}
            sx={{ minWidth: '100%', marginTop: '20px' }}
          >
            <TableHead>
              <TableRow>
                {TABLE_HEAD.filter(
                  (headCell) => isColumnVisible(headCell.id) // Check if column is visible
                ).map((headCell) => (
                  <TableCell key={headCell.id} sx={{ minWidth: headCell.minWidth }}>
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData2?.map((row) => (
                <JobcostingChangeorderGenerateRerportTableRow
                  key={row.id}
                  row={row} // Pass each row data here
                  selected={table.selected.includes(row.id)}
                  onSelectRow={() => table.onSelectRow(row.id)}
                  onDeleteRow={() => handleDeleteRow(row.id)}
                  onEditRow={() => handleEditRow(row.id)}
                  refreshTableData={getJobCostigList}
                  visibleColumns={visibleColumns} // Pass visible columns
                />
              ))}
              <JobCostingChangeOrderSubTotal
                tableData={tableData2}
                visibleColumns={visibleColumns}
              />

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
            <TableRow style={{ width: '100%' }}>
              <TableCell align="left">
                <strong>Total:</strong>
              </TableCell>

              <TableCell />
            </TableRow>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={dataFiltered2.length}
        page={table2.page}
        rowsPerPage={table2.rowsPerPage}
        onPageChange={table2.onChangePage}
        onRowsPerPageChange={table2.onChangeRowsPerPage}
        dense={table2.dense}
        onChangeDense={table2.onChangeDense}
      />
      {/* </Card> */}
      {
        <CSVLink
          ref={csvLinkRef}
          filename="Job_costing.csv"
          headers={getCsvHeaders()}
          data={getCsvData(tableData, jobCostinglabels, isColumnVisible)}
          style={{ display: 'none' }}
        />
      }

      <ShareByEmailpopup
        open={check.value}
        onClose={check.onFalse}
        pdfFileUrl={pdfFileUrl} // Pass the generated PDF URL to the modal
      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </Box>
  );
}

// ----------------------------------------------------------------------
function applyFilter({ inputData, comparator, filters, dateError }) {
  const { startDate, endDate } = filters;

  if (!dateError && startDate && endDate) {
    const filterStartDate = new Date(startDate);
    const filterEndDate = new Date(endDate);

    inputData = inputData.filter((row) => {
      const rowStartDate = new Date(row.start_date);
      const rowEndDate = new Date(row.end_date);

      return (
        rowStartDate >= filterStartDate && rowEndDate <= filterEndDate
      );
    });
  }

  return inputData;
}
