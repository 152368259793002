import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import * as Yup from 'yup';
import { handleErrorMessages } from '../../risk-register/feching';
import TeamMembersAdd from './team-menembers-create';

const RiskResponseTeamCreate = ({
  open,
  onClose,
  reload,
  currentItem,
  handleCloseForm,
}) => {
  console.log("🚀 ~ currentItem:", currentItem)
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);
  const [isFetchingRisks, setIsFetchingRisks] = useState(true);
  const [riskOwnersOptions, setRiskOwnersOptions] = useState([]);

  const [selectedMemberIds, setSelectedMemberIds] = useState({}); // Track selected member IDs per assignment

  const onOwnerChange = useCallback((assignmentIndex, memberIds) => {
    setSelectedMemberIds((prev) => ({ ...prev, [assignmentIndex]: memberIds }));
  }, []);
  useEffect(() => {
    const fetchIdentification = async () => {
      try {
        console.log('Fetching risk IDs...');
        const data = await getRequest(
          endpoints.risk_management.risk_register.identification.identification
        );
        console.log('Fetched risk IDs:', data);
        setRiskIds(data || []);
      } catch (err) {
        console.error('Error fetching risk IDs:', err);
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to fetch risk IDs.',
        });
      } finally {
        setIsFetchingRisks(false);
      }
    };
    fetchIdentification();
  }, [enqueueSnackbar]);


  const matchedMemberIds = useMemo(() => {
    if (currentItem?.members) {
      return currentItem.members.map(member => member.id) || [];
    }
    return [];
  }, [currentItem]);

  const matchedRisk = useMemo(() => {
    if (currentItem && riskIds.length > 0) {
      return (
        riskIds.find(
          (risk) => risk.id === currentItem?.identification_and_assessment?.id
        ) || { id: '', risk_id: 'None' }
      );
    }
    return { id: '', risk_id: 'None' };
  }, [currentItem, riskIds]);
  // New constant to match member IDs




  const NewUserSchema = Yup.object().shape({
    risk_assignments: Yup.array().of(
      Yup.object().shape({
        assignment_id: Yup.string().required('Assignment ID is required'),
        member_ids: Yup.array().of(Yup.string()).required('Team Members are required'),
        assigned_tasks: Yup.string().required('Assigned Tasks are required'),
        assigned_date: Yup.date().required('Assignment Date is required').nullable(),
      })
    )
  });
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };
  const defaultValues = useMemo(() => {
    const riskAssignment = {
    
      risk_id: matchedRisk && matchedRisk.id ? matchedRisk : null,
      member_ids: matchedMemberIds, 
      assigned_tasks: currentItem?.assigned_task,
      assigned_date: isValidDate(new Date(currentItem?.assigned_date))
        ? new Date(currentItem.assigned_date)
        : null, 
    };

    return {
      risk_assignments: [riskAssignment], // Wrap it in an array
    };
  }, [currentItem, matchedRisk, matchedMemberIds]); 
  

  
  console.log("🚀 ~ defaultValues:", defaultValues)
  console.log("🚀 ~ defaultValues:", currentItem?.identification_and_assessment?.risk_id)

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'risk_assignments',
  });


 


  useEffect(() => {
    fields.forEach((field, index) => {
      const desiredAssignmentId = (index + 1).toString().padStart(2, '0');
      if (field.assignment_id !== desiredAssignmentId) {
        console.log(`Setting assignment ID for index ${index} to ${desiredAssignmentId}`);
        setValue(`risk_assignments[${index}].assignment_id`, desiredAssignmentId);
      }
    });
  }, [fields, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    console.log("Form data before submission:", data);
    setLoading(true);
    const formData = new FormData();
    data.risk_assignments.forEach((assignment, index) => {
      console.log("🚀 ~ ============== ~ assignment:", assignment);
      formData.append(`risk_assignments[${index}][risk_id]`, assignment.risk_id?.id || '');
      formData.append(`risk_assignments[${index}][assigned_task]`, assignment.assigned_tasks);
      formData.append(`risk_assignments[${index}][assigned_date]`,
        assignment.assigned_date ? assignment.assigned_date.toISOString().split('T')[0] : '');
      if (assignment.member_ids && assignment.member_ids.length > 0) {
        assignment.member_ids.forEach((member_id) => {
          formData.append(`risk_assignments[${index}][member_ids][]`, member_id);
        });
      } else {
        formData.append(`risk_assignments[${index}][member_ids]`, ''); 
      }
      console.log("🚀 ~ data.risk_assignments.forEach ~ memberIdsArray:", assignment.member_ids);
    });
    try {
      const endpoint = currentItem?.id
        ? `${endpoints.risk_management.risk_response_team.crud}/${currentItem.id}?_method=PUT`
        : endpoints.risk_management.risk_response_team.crud;
      const response = await postRequest(endpoint, formData, false, true);
      console.log("API response:", response);
      if (response.metadata?.success) {
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
        handleClose();
        reload();
      } else {
        handleErrorMessages(response.message);
      }
    } catch (err) {
      console.error('Submission error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });



  const handleClose = () => {
    console.log("Closing form, resetting to default values:", defaultValues);
    reset(defaultValues);
    if (handleCloseForm) handleCloseForm();
    onClose();
  };

  useEffect(() => {
    if (!isFetchingRisks) {
      console.log('Resetting form with default values:', defaultValues);
      reset(defaultValues);
    }
  }, [currentItem, defaultValues, reset, isFetchingRisks]);

  const getRiskOwnersOptions = async () => {
    console.log("Fetching risk owner options...");
    const data = await getRequest(endpoints.risk_management.risk_register.identification.risk_owners);
    console.log("Risk owner options fetched:", data);
    setRiskOwnersOptions([{ id: '', name: 'None' }, ...(Array.isArray(data) ? data : [])]);
  };

  const onMemberIdsChange = useCallback((assignmentIndex, memberIds) => {
    setValue(`risk_assignments[${assignmentIndex}].member_ids`, memberIds);
  }, [setValue]);



  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'Arial, sans-serif',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <span
            className="cardColor"
            style={{
              padding: '5px',
              marginLeft: '-25px',
              color: 'white',
              fontWeight: '700',
            }}
          >
            &nbsp;&nbsp; Risk Response Team Assignment &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            borderRadius: '1px',
          }}
        >
          {fields.map((item, index) => (
            <Box
              key={item.id}
              mt={2}
              mb={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
              }}
              alignItems="center"
              columnGap={2}
              rowGap={3}
            >
              <RHFTextField
                name={`risk_assignments[${index}].assignment_id`}
                label="Assignment ID"
                disabled
                value={(index + 1).toString().padStart(2, '0')}
              />
              <RHFAutocomplete
                name={`risk_assignments[${index}].risk_id`}
                label="Risk ID"
                options={[{ id: '', risk_id: 'None' }, ...riskIds]}
                getOptionLabel={(option) => option.risk_id || 'None'}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id || value === ''
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.id || 'none'}>
                    {option.risk_id}
                  </li>
                )}p
                disabled={isFetchingRisks}
              />
              <TeamMembersAdd
                label="Team Members"
                name={`risk_assignments[${index}].member_ids`}
                options={riskOwnersOptions}
                selectedIds={defaultValues.matchedMemberIds || []}
              />
              <RHFTextField name={`risk_assignments[${index}].assigned_tasks`} label="Assigned Tasks" />
              <Controller
                name={`risk_assignments[${index}].assigned_date`}
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="Assignment Date"
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    renderInput={(params) => (
                      <RHFTextField
                        {...params}
                        fullWidth
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                )}
              />
              <Box
                sx={{
                  paddingTop: '5px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}
              >

                {currentItem && (
                  <LoadingButton
                    className='dark-button'
                    variant="contained"
                    onClick={() =>
                      append({
                        assignment_id: '',
                        risk_id: '',
                        member_ids: [],
                        assigned_tasks: '',
                        assigned_date: null,
                      })
                    }
                    sx={{
                      padding: '8px 16px',
                    }}
                  >
                    Add More
                  </LoadingButton>
                )}


                {fields.length > 1 && (
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      padding: '9px 16px',
                    }}
                    onClick={() => remove(index)}
                  >
                    Remove
                  </Button>
                )}
              </Box>
            </Box>
          ))}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" className='dark-button' loading={isSubmitting || loading}>
            {!currentItem?.id ? 'Create Risk Response Team Assignment' : 'Update Risk Response Team Assignment'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default RiskResponseTeamCreate;
