import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------
const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const icon1 = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.png`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    people: icon1('people'),
    global: icon1('global'),
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    clipboard: icon('ic_clipboard'),
    incident : icon('ic-incident'),
    arrows: icon('ic-change-req'),
    uppdownArrows: icon('ic-priority'),
    team: icon('ic-Response'),
    calendar2: icon('ic-Quarterly'),
    Survey: icon('ic-Survey'),
    Analysis: icon('ic-Analysis'),
    Inventory: icon('ix-Inventory'),
    Budget: icon('ic-Budget'),
    Learning: icon('ic-Learning'),
};
// ----------------------------------------------------------------------
export function useRiskNavData() {
    const { t } = useLocales();
    const data = useMemo(
        () => [
            // ----------------------------------------------------------------------
            {
                subheader: t('Accounting'),
                items: [
            
                    {
                        title: t('Customers'),
                        path: '/accounts-management/risk-register',
                        icon: ICONS.people,
                    },
                    {
                        title: t('Vendors'),
                        path: '/accounts-management/incident-reporting',
                        icon: ICONS.global,
                    },
                    {
                        title: t('Accounts Receivable'),
                        path: '/accounts-management/risk-change-request',
                        icon: ICONS.arrows,
                        children: [
                            {
                                title: t('Invoice'),
                                path:'/accounts-management/risk-change-request',
                                //icon: ICONS.file, 
                            },
                
                        ],

                    },
                    {
                        title: t('Accounts Payable'),
                        path: '/accounts-management/accounts-payable',
                        icon: ICONS.chat,
                        children: [
                            {
                                title: t('Purchase Order'),
                                path:'/accounts-management/accounts-payable',
                                //icon: ICONS.arrows, 
                            },
                
                        ],
                    },
                    {
                        title: t('Expenses'),
                        path: '/accounts-management/expenses',
                        icon: ICONS.uppdownArrows,
                    },
                    {
                        title: t('Payroll'),
                        path: '/accounts-management/payroll',
                        icon: ICONS.analytics,
                        children: [
                            {
                                title: t('Weekly Payroll Time Sheets'),
                                path:'/accounts-management/payroll',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Run Payroll'),
                                path:'/accounts-management/payroll',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Payroll taxes'),
                                path:'/accounts-management/payroll',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Certified Payroll'),
                                path:'/accounts-management/payroll',
                                //icon: ICONS.arrows, 
                            },
                
                
                
                        ],
                    },
                    {
                        title: t('Taxes'),
                        path: '/accounts-management/taxes',
                        icon: ICONS.team,
                        children: [
                            {
                                title: t('Sales Taxes'),
                                path:'/accounts-management/taxes',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Payroll Taxes'),
                                path:'/accounts-management/taxes',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Business Taxes'),
                                path:'/accounts-management/taxes',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Misc. Taxes'),
                                path:'/accounts-management/taxes',
                                //icon: ICONS.arrows, 
                            },
                
                        ],
                    },
                    {
                        title: t('Inventory'),
                        path: '/accounts-management/inventory',
                        icon: ICONS.calendar2,
                        children: [
                            {
                                title: t('Materials'),
                                path:'/accounts-management/inventory',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Equipments'),
                                path:'/accounts-management/inventory',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Tools'),
                                path:'/accounts-management/inventory',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Assets'),
                                path:'/accounts-management/inventory',
                                //icon: ICONS.arrows, 
                            },
                
                        ],
                    },
                    {
                        title: t('General Ledger'),
                        path: '/risk-management/risk-mitigation-effectiveness-survey',
                        icon: ICONS.Survey,
                        children: [
                            {
                                title: t('General Ledger'),
                                path:'/accounts-management/general-ledger',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Cash flow Management'),
                                path:'/accounts-management/general-ledger',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Bank Reconcilition'),
                                path:'/accounts-management/general-ledger',
                                //icon: ICONS.arrows, 
                            },
                
                        ],
                    },
                    {
                        title: t('Balance Sheet'),
                        path: '/accounts-management/balance-sheet',
                        icon: ICONS.Analysis,
                        children: [
                            {
                                title: t('Balance Sheet'),
                                path:'/accounts-management/balance-sheet',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Income Statement'),
                                path:'/accounts-management/balance-sheet',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('profit & loss statement'),
                                path:'/accounts-management/balance-sheet',
                                //icon: ICONS.arrows, 
                            },
                
                        ],
                    },
                    {
                        title: t('Bank Information'),
                        path: '/accounts-management/bank-information',
                        icon: ICONS.Inventory,
                    },
                    {
                        title: t('Finance Statement'),
                        path: '/accounts-management/finance-statement',
                        icon: ICONS.Budget,
                    },
                    {
                        title: t('Employees'),
                        path: '/accounts-management/employees',
                        icon: ICONS.file,
                    },
                    {
                        title: t('Reports'),
                        path: '/accounts-management/reports',
                        icon: ICONS.Learning,
                        children: [
                            {
                                title: t('Tax handling & Reporting'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Overview'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },
                            {
                                title: t('Transactions'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Financial Reporting'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Reports'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Budgeting'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },

                            {
                                title: t('Assets & Liabilities'),
                                path:'/accounts-management/reports',
                                //icon: ICONS.arrows, 
                            },
                
                
                
                        ],



                    },
                    {
                        title: t('Documents'),
                        path: '/accounts-management/documents',
                        icon: ICONS.Survey,
                    },
                    {
                        title: t('Calendar'),
                        path: '/accounts-management/calendar',
                        icon: ICONS.Budget,
                    },
                     {
                        title: t('Audit Logs'),
                        path: '/accounts-management/audit-logs',
                        icon: ICONS.team,
                    },
                    {
                        title: t('Settings'),
                        path: '/accounts-management/settings',
                        icon: ICONS.Analysis,
                    }
                ]
            }
        ],
        [t]
    );
    return data;
}