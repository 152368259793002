import { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetLower, calculateVariance } from './Calculations/formulas';

// ----------------------------------------------------------------------

export default function JobcostingChangeorderGenerateRerportTableRow({
    row,
    selected,
    onEditRow,
    onSelectRow,
    onDeleteRow,
    visibleColumns,
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [committedSubcontractorCost, setCommittedSubcontractorCost] = useState(0);
    const [nonCommittedCostToDate, setNonCommittedCostToDate] = useState(0);
    const [estimatedFinalCost, setEstimatedFinalCost] = useState(0);
    const [variance, setVariance] = useState(0);
    const [performedPercentage, setPerformedPercentage] = useState(0);
    const [actualCost, setActualCost] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [grossProfitPercentage, setGrossProfitPercentage] = useState(0);
    const [remainingBudget, setRemainingBudget] = useState(0);
    const [retainage, setRetainage] = useState(0);
    const [revisedBudget, setRevisedBudget] = useState(0);
    const [original_budgeted_amount, setoriginal_budgeted_amount] = useState(0);
    const [rowData, setRowData] = useState(row);


    useEffect(() => {
        //14
        const calculatedRevisedBudget = calculateRevisedBudgetLower(
            original_budgeted_amount,
            row.approved_change_orders
        );
        setRevisedBudget(calculatedRevisedBudget);
        //19
        //19
        const calculatedValue = calculateCommittedSubcontractorCost(row);
        setCommittedSubcontractorCost(calculatedValue);
        //20
        const calculatedNonCommittedCost = calculateNonCommittedCostToDate(row, committedSubcontractorCost);
        setNonCommittedCostToDate(calculatedNonCommittedCost);
        //22
        const calculatedEstimatedFinalCost = calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            parseFloat(row.non_committed_estimated_cost) || 0
        );
        setEstimatedFinalCost(calculatedEstimatedFinalCost);
        //23
        const calculatedVariance = calculateVariance(estimatedFinalCost, parseFloat(row.revised_budget) || 0);
        setVariance(calculatedVariance);
        //25
        const calculatedPerformedPercentage = calculatePerformedPercentage(
            row.performed_amount,
            parseFloat(row.revised_budget) || 0
        );
        setPerformedPercentage(calculatedPerformedPercentage);
        //28
        const calculatedActualCost = calculateActualCost(
            parseFloat(row.resources_used) || 0,
            parseFloat(row.expenses) || 0
        );
        setActualCost(calculatedActualCost);
        //29
        const calculatedGrossProfit = calculateGrossProfit(
            parseFloat(row.performed_amount) || 0,
            actualCost
        );
        setGrossProfit(calculatedGrossProfit);
        //30
        const calculatedGrossProfitPercentage = calculateGrossProfitPercentage(
            grossProfit,
            parseFloat(row.revised_budget) || 0
        );
        setGrossProfitPercentage(calculatedGrossProfitPercentage);
        //31
        const calculatedRemainingBudget = calculateRemainingBudget(
            parseFloat(row.revised_budget) || 0,
            actualCost
        );
        setRemainingBudget(calculatedRemainingBudget);
        //32
        const calculatedRetainage = calculateRetainage(row.performed_amount);
        setRetainage(calculatedRetainage);
    }, [
        row.original_subcontractor_cost,
        row.approved_change_order_subcontractor,
        row.purchase_order,
        row.material_requisition,
        //20
        row.revised_budget,
        committedSubcontractorCost,
        //22
        row.non_committed_estimated_cost,
        //23
        estimatedFinalCost,
        //25
        row.performed_amount,
        //28
        row.resources_used,
        row.expenses,
        //29
        actualCost,
        //30
        grossProfit,
    ]);
    return (
        <>
            <TableRow hover selected={selected} key={rowData.id}>
             
                <TableCell
                    sx={{ minWidth: '100px' }}
                >
                    {rowData.project_id}
                </TableCell>
                <TableCell
                    sx={{ minWidth: '100px' }}
                >
                    {rowData.change_order_id ?? ""}
                </TableCell>
                {visibleColumns.includes('facility') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.facility ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('level') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.level ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('milestone') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.milestone ?? ""}
                    </TableCell>
                )}
          
                {visibleColumns.includes('category') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {typeof rowData?.change_order_category === "object" && rowData.change_order_category !== null
                            ? `${rowData.change_order_category?.code ?? ""} - ${rowData.change_order_category?.description ?? ""}`
                            : ""}
                    </TableCell>
                )}
                {visibleColumns.includes('activity') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {typeof rowData?.change_order_activity === "object" && rowData.change_order_activity !== null
                            ? `${rowData.change_order_activity?.code ?? ""} - ${rowData.change_order_activity?.description ?? ""}`
                            : ""}
                    </TableCell>
                )}
                {visibleColumns.includes('start_date') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.start_date
                            ? new Date(rowData.start_date).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                            })
                            : ""}
                    </TableCell>
                )}
                {visibleColumns.includes('end_date') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.end_date
                            ? new Date(rowData.end_date).toLocaleDateString("en-US", {
                                month: "2-digit",
                                day: "2-digit",
                                year: "numeric",
                            })
                            : ""}
                    </TableCell>
                )}
                {visibleColumns.includes('duration') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.cost_codes?.duration ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('trade') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.trade ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('responsible_party') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.responsible_party ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('original_budgeted_amount') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {original_budgeted_amount}
                    </TableCell>
                )}
                {visibleColumns.includes('approved_change_orders') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.approved_change_orders ? `$${rowData.approved_change_orders}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('revised_budget_amount') && (
                    <TableCell>
                        {revisedBudget && !isNaN(revisedBudget) ? `$${revisedBudget.toFixed(2)}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('original_subcontractor_cost') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.original_subcontractor_cost ? `$${rowData.original_subcontractor_cost}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('approved_change_order_subcontractor') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.approved_change_order_subcontractor ? `$${rowData.approved_change_order_subcontractor}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('purchase_order') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.purchase_order ? `$${rowData.purchase_order}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('material_requisition') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.material_requisition ? `$${rowData.material_requisition}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('committed_revised_subcontractor_cost') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {committedSubcontractorCost && !isNaN(committedSubcontractorCost)
                            ? `$${committedSubcontractorCost}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('non_committed_cost_to_date') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {nonCommittedCostToDate && !isNaN(nonCommittedCostToDate)
                            ? `$${nonCommittedCostToDate.toFixed(2)}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('non_committed_estimate_cost_to_date') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.non_committed_estimated_cost
                            ? `$${rowData.non_committed_estimated_cost}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('estimated_final_cost') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {estimatedFinalCost && !isNaN(estimatedFinalCost)
                            ? `$${estimatedFinalCost.toFixed(2)}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('variance_project_budgeted') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {variance && !isNaN(variance)
                            ? `$${variance.toFixed(2)}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('performed_amount') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {typeof rowData.performed_amount === 'number' && !isNaN(rowData.performed_amount)
                            ? `$${rowData.performed_amount}`
                            : '$0'}
                    </TableCell>
                )}
                {visibleColumns.includes('performed_percentage') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {typeof rowData.performed_percentage === 'number' && !isNaN(rowData.performed_percentage)
                            ? `${rowData.performed_percentage}%`
                            : '0%'}
                    </TableCell>
                )}
                {visibleColumns.includes('resources_used') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.resources_used ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('expenses') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.expenses != null ? `$${rowData.expenses}` : ''}
                    </TableCell>
                )}
                {visibleColumns.includes('actual_cost') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {actualCost && !isNaN(actualCost)
                            ? `$${actualCost.toFixed(2)}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('gross_profit') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {grossProfit && !isNaN(grossProfit)
                            ? `$${grossProfit.toFixed(2)}`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('gross_profit_percentage') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {grossProfitPercentage && !isNaN(grossProfitPercentage)
                            ? `${grossProfitPercentage.toFixed(2)}%`
                            : ''}                    </TableCell>
                )}
                {visibleColumns.includes('remaining_budget') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {remainingBudget && !isNaN(remainingBudget)
                            ? `$${remainingBudget.toFixed(2)}`
                            : ''}                 
                               </TableCell>
                )}

                {visibleColumns.includes('retainage_percentage') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {retainage && !isNaN(retainage)
                            ? `$${retainage.toFixed(2)}`
                            : ''}                    </TableCell>
                )}

                {visibleColumns.includes('requisition_number') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.requisition_number ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('invoice_number') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.invoice_number ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('payment_status') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.payment_status ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('risk_level') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.risk_level ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('quality_checks') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.quality_checks ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('compliance') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.compliance ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('contingency_used') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.contingency_used ?? ""}
                    </TableCell>
                )}
                {visibleColumns.includes('notes') && (
                    <TableCell
                        sx={{ minWidth: '100px' }}
                    >
                        {rowData.notes ?? ""}
                    </TableCell>
                )}
            </TableRow>
        </>
    );}