import Iconify from 'src/components/iconify';
import { Box, Button, Card, IconButton, Table, TableBody, TableContainer, Tooltip, Typography } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import {
    TableHeadCustom,
    TableSelectedAction,
} from 'src/components/table';
import { TableCell, TableRow } from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { table } from 'src/theme/overrides/components/table';
import RenderField from '../../risk-register/render-field';
import { useNavigate } from 'react-router';


const RiskMitigationRequestTable = ({ data }) => {
    console.log("🚀 ~ RiskMitigationRequestTable ~ data:", data)

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };


   
    const mitigationResources = data?.mitigation_budget_resources || [];
    const TABLE_HEAD = [
        { id: 'Sr NO', label: 'Sr NO' },
        { id: 'Code', label: 'Code' },
        { id: 'Description', label: 'Description' },
        { id: 'QTY', label: data?.type === 'hours' ? 'Hours' : 'Qty' },
        { id: 'Unit', label: 'Unit' },
        { id: 'Unit price', label: 'Unit price' },
        { id: 'Amount', label: 'Amount' },
        ...(data?.type === 'hours' ? [{ id: 'hrs Spent', label: 'hrs Spent' }] : []),
        { id: 'Amount Spent', label: 'Amount Spent' },
        { id: 'Balance', label: 'Balance' },
        ...(data?.type === 'hours' ? [{ id: 'balance_hours', label: 'Balance Hours' }] : []),
        { id: 'complete', label: 'Complete%' },
    ];

    return (
        <Card sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Box p={3}>
                <Typography className=" Escalation-view-main-heading"> Risk Mitigation Budget Request View</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                        Risk Mitigation Budget Request  View
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>

            <Box
                p={4}
                mt={4}
                mb={2}
                rowGap={3}
                columnGap={20}
                display="grid"
                sx={{
                    background: "#F6F6F6",
                    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
                }}
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(2, 2fr)',
                }}
            >
                <RenderField label="Requested ID" value={data?.request_id} />
                <RenderField label="Risk ID " value={data?.identification_and_assessment?.risk_id} />
            </Box>
            <Typography
                mt={3}
                sx={{ fontWeight: "bold", padding: "10px" }}>Resources Required</Typography>
            <Box
                p={2}
                mt={1}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(1, 1fr)',
                }}
            >
                <Card sx={{ borderRadius: 0 }}>
                    <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                        <TableSelectedAction
                        />
                        <Scrollbar>
                            <Table size={table.dense ? 'small' : 'large'} >
                                <TableHeadCustom
                                    order={table.order}
                                    orderBy={table.orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={data?.length}
                                    numSelected={table?.selected?.length}
                                    onSort={table.onSort}
                                />
                                <TableBody>
                                    {mitigationResources.map((resource, index) => (
                                        <TableRow key={resource.id}>
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell align="left">{resource.code || ''}</TableCell>
                                            <TableCell align="left">{resource.description || ''}</TableCell>
                                            <TableCell align="left">{resource.quantity || ''}</TableCell>
                                            <TableCell align="left">{resource.unit || '0'}</TableCell>
                                            <TableCell align="left">{resource.unit_price || ''}</TableCell>
                                            <TableCell align="left">{resource.amount || ''}</TableCell>
                                            {data?.type === 'hours' ? <TableCell align="left">{resource.hours_spent || ''}</TableCell> : ""}
                                            <TableCell align="left">{resource.amount_spent || ''}</TableCell>
                                            <TableCell align="left">{resource.balance || ''}</TableCell>
                                            {data?.type === 'hours' ? <TableCell align="left">{resource.balance_hours || ''}</TableCell> : ""}
                                            <TableCell align="left">{resource.complete || ''}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Scrollbar>
                    </TableContainer>
                </Card>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                sx={{
                    background: "#F6F6F6",
                    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
                }}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField
                    label="Budget Approval Status"
                    value={
                        data?.budget_approval_status == null
                            ? ''
                            : data.budget_approval_status === 0
                                ? 'No'
                                : 'Yes'
                    }

                />
                <RenderField label="Total Balance" value={data?.total_balance} />
                <RenderField label="Total Budgeted Amount" value={data?.total_budgeted_amount} />
            </Box>
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                sx={{
                    background: "#F6F6F6",
                    boxShadow: " 0px 0px 4px 0px rgba(0, 0, 0, 0.25)"
                }}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                {data?.type === 'hours' ? <RenderField label="Total Hours Spent " value={data?.total_hours_spent} /> : " "}
                < RenderField label="Total Spent Amount" value={data?.total_amount_spent} />
            </Box>
           
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
            </Box>
        </Card>
    );
};
export default RiskMitigationRequestTable;