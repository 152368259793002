export const calculateCommittedSubcontractorCost = (row, plannedSubcontractorCost) => {
    const original = parseFloat(row.original_subcontractor_cost) || 0;
    const approvedChanges = parseFloat(plannedSubcontractorCost) || 0;
    const purchaseOrder = parseFloat(row.purchase_order) || 0;
    const materialReq = parseFloat(row.material_requisition) || 0;
    return original + approvedChanges + purchaseOrder + materialReq;
};


export const calculateNonCommittedCostToDate = (calculatedRevisedBudget, committedCost) => {
    const revisedBudget = parseFloat(calculatedRevisedBudget) || 0;
    // console.log("🚀 ~ calculateNonCommittedCostToDate ~ revisedBudget:", revisedBudget , committedCost)
    return revisedBudget - committedCost;
};

export const calculateEstimatedFinalCost = (committedCost, nonCommittedCostToDate, nonCommittedEstimatedCost) => {
    const committed = parseFloat(committedCost) || 0;
    const nonCommitted = parseFloat(nonCommittedCostToDate) || 0;
    const nonCommittedEstimated = parseFloat(nonCommittedEstimatedCost) || 0;
    return committed + nonCommitted + nonCommittedEstimated;
};

export const calculateVariance = (estimatedFinalCost, calculatedRevisedBudget) => {
    const estimated = parseFloat(estimatedFinalCost) || 0;
    const revised = parseFloat(calculatedRevisedBudget) || 0;
    // console.log("🚀 ~ calculateVariance ~ estimated:", estimated, revised) 
    return estimated - revised;
};

export const calculatePerformedPercentage = (performedAmount, calculatedRevisedBudget) => {
    const performed = parseFloat(performedAmount) || 0;
    const revised = parseFloat(calculatedRevisedBudget) || 0;
    // console.log("🚀 ~ calculatePerformedPercentage ~ performed:", performed, revised)
    // if (revised === 0) return 0; // Prevent division by zero
    return (performed / revised) * 100;
};

export const calculateActualCost = (resourcesUsed, expenses) => {
    const resources = parseFloat(resourcesUsed) || 0;
    const exp = parseFloat(expenses) || 0;
    return resources + exp;
};

export const calculateGrossProfit = (performedAmount, actualCost) => {
    const performed = parseFloat(performedAmount) || 0;
    const actual = parseFloat(actualCost) || 0;
    return performed - actual;
};

export const calculateGrossProfitPercentage = (grossProfit, revisedBudget) => {
    const gp = parseFloat(grossProfit) || 0;
    const budget = parseFloat(revisedBudget) || 0;
    if (budget === 0) return 0; // Prevent division by zero
    return (gp / budget) * 100;
};

export const calculateRemainingBudget = (revisedBudget, actualCost) => {
    const budget = parseFloat(revisedBudget) || 0;
    const actual = parseFloat(actualCost) || 0;
    return budget - actual;
};

export const calculateRetainage = (performedAmount) => {
    const performed = parseFloat(performedAmount) || 0;
    return performed * 0.05;
};


//Sub total of original_budgeted_amount
export const calculateSubtotal = (data, field) => {
    return data.reduce((sum, row) => {
        const value = parseFloat(row[field]);
        return sum + (isNaN(value) ? 0 : value);
    }, 0);
};
// A + B  upper revised budget
export const calculateRevisedBudgetUpper = (originalBudgetedAmount, approvedChangeOrders) => {
    const originalBudget = parseFloat(originalBudgetedAmount) || 0;
    const approvedChanges = parseFloat(approvedChangeOrders) || 0;
    // console.log("🚀 ~ calculateRevisedBudgetUpper ~ approvedChanges:", originalBudget,  approvedChanges)
    return originalBudget + approvedChanges;
};

// A + B  Lower budget
export const calculateRevisedBudgetLower = (originalBudgetedAmount, approvedChangeOrders) => {
    const originalBudget = parseFloat(originalBudgetedAmount) || 0;
    const approvedChanges = parseFloat(approvedChangeOrders) || 0;
    return originalBudget + approvedChanges;
};


export const calculateTotalVariance = (tableData) => {
    return tableData.reduce((sum, row) => {
        const estimatedFinalCost = calculateEstimatedFinalCost(
            row.committedCost,
            row.nonCommittedCostToDate,
            row.nonCommittedEstimatedCost
        );
        const revisedBudget = row.revisedBudget || 0;
        const rowVariance = calculateVariance(estimatedFinalCost, revisedBudget);
        return sum + rowVariance;
    }, 0);
};
