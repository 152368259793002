export const TABLE_HEAD = [
    { id: 'sr.no', label: 'Sr.no', key: 'sr.no' },
    { id: 'customer_id', label: 'Customer ID', key: 'customer_id' },
    { id: 'first_name', label: 'First Name', key: 'first_name' },
    { id: 'last_name', label: 'Last Name', key: 'last_name' },
    { id: 'email', label: 'Email', key: 'email' },
    { id: 'company_name', label: 'Company Name', key: 'company_name' },
    { id: 'phone_number', label: 'Phone Number', key: 'phone_number' },
    { id: 'address', label: 'Address', key: 'address' },
    { id: 'city', label: 'City', key: 'city' },
    { id: 'state', label: 'State', key: 'state' },
    { id: 'zip_code', label: 'Zip Code', key: 'zip_code' },
    { id: 'action', label: 'Action', key: 'action' },
   
];