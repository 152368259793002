import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,

} from 'src/components/hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';



const IncidentReportingCreate = ({ open, onClose, reload, currentItem, closeform }) => {
  console.log("🚀 ~ IncidentReportingCreate ~ currentItem:", currentItem)
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);


  const NewUserSchema = Yup.object().shape({
    incident_id: Yup.string().required('Incident Id is required'),
    incident_description: Yup.string().required('Incident Description is required'),
    date_and_time_of_incident: Yup.mixed()
      .required('Date And Time Of Inciden is required'),
    impacted_areas: Yup.string().required('Impacted Areas is required'),
    immediate_actions_taken: Yup.string().required('Immediate Actions Taken is required'),
    incident_severity: Yup.string().required('Incident Severity is required'),

  });

  const matchedRisk = riskIds.find(risk => risk.id === currentItem?.identification_and_assessment?.id);
  console.log("🚀 ~ IncidentReportingCreate ~ matchedRisk:", matchedRisk, riskIds, currentItem) 
  const defaultValues = useMemo(
    () => {
      const relatedRisk = matchedRisk ? matchedRisk : { id: '', risk_id: 'None' };
      return {
        incident_id: currentItem?.incident_id || '',
        incident_description: currentItem?.description || '',
        date_and_time_of_incident: currentItem?.incident_date ? new Date(currentItem?.incident_date) : new Date(),  // Ensure valid Date object
        impacted_areas: currentItem?.impacted_area || '',
        immediate_actions_taken: currentItem?.immediate_action || '',
        incident_severity: currentItem?.severity || '',
        is_related: relatedRisk, // Set the default related risk to "None" if no match found
      };
    },
    [currentItem, riskIds]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Reset form values when `currentItem` changes
  useEffect(() => {
    reset(defaultValues);
  }, [currentItem, reset, defaultValues]);

  const values = watch();

  // Function to format date as yyyy-mm-dd HH:MM:ss
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };



  useEffect(() => {
    if (currentItem) {
      reset(defaultValues); // Reset the form when editing an existing item
    } else {
      reset({
        incident_id: '',
        incident_description: '',
        date_and_time_of_incident: new Date(),
        impacted_areas: '',
        immediate_actions_taken: '',
        incident_severity: '',
        is_related: { id: '', risk_id: 'None' },
      });
    }
  }, [currentItem, reset]);

  useEffect(() => {
    getIdentification();
  }, []);
  const getIdentification = async () => {
    try {
      const data = await getRequest(endpoints.risk_management.risk_register.identification.identification);
      console.log("🚀 ~ getIdentification=======>>>> ~ data:", data);
      setRiskIds(data || []);
    } catch (err) {
      console.error('Error fetching risk IDs:', err);
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    setLoading(true);

    const formData = new FormData();
    formData.append('incident_id', data.incident_id);
    formData.append('description', data.incident_description);
    formData.append('incident_date', formatDate(data.date_and_time_of_incident));
    formData.append('impacted_area', data.impacted_areas);
    formData.append('immediate_action', data.immediate_actions_taken);
    formData.append('severity', data.incident_severity);

    if (data.is_related && typeof data.is_related === 'object') {
      formData.append('risk_id', data.is_related.id);  // Extract the id field
    } else {
      formData.append('risk_id', data.is_related);  // Just in case it returns an id directly
    }

    try {

      const response = await postRequest(
        currentItem?.id
          ? `${endpoints.risk_management.incident_reporting.incident}/${currentItem.id}?_method=PUT`
          : endpoints.risk_management.incident_reporting.incident,
        formData,
        true
      );
      console.log("🚀 ~ onSubmit ~ response:", response)

      if (response.metadata && response.metadata.success) {
        reset();
        onClose();
        closeform();
        reload();
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
      } else {
        handleErrorMessages(response.message);

      }
    } catch (err) {
      console.error('Error in catch:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });


  const handleCancel = () => {
    reset(defaultValues); 
    onClose();            
    closeform();  
  };



  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(); 
          }
        }}
        fullWidth
        maxWidth="md"
        sx={{
          borderRadius: 'none',
          padding: '60px',
          fontSize: '13px',
          fontFamily: 'arial',
        }}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >

        <FormProvider methods={methods} onSubmit={onSubmit}>

          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
            <span
              className='cardColor'
              style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
              &nbsp;&nbsp; Vendors &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <IconButton onClick={onClose}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </DialogTitle>
         
          <>
            <DialogContent sx={{
              borderRadius: "1px",
            }} >


              <Box
                mt={2}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="sr_no" label="Sr No*" />
                <RHFAutocomplete
                  sx={{ width: '100%' }}
                  name="type_of_vendor"
                  label="Type of Vendor*"  // Update the label as needed
                  options={['Minor', 'Moderate', 'Severe']}  // Static options
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    );
                  }}
                  error={!!methods.formState.errors.frequencey}  // Display error if validation fails
                  helperText={methods.formState.errors.frequencey?.message}  // Show the error message
                />

                
                {/* <DropDownAddValue name="impacted_areas" label="Impacted Areas" /> */}
                <RHFTextField name="first_name" label="Fist Name*" />

                <RHFTextField name="last_name" label="Last Name*" />

                <RHFTextField name="title" label="Title*" />
                
                <RHFTextField name="trade/speciality" label="Trade/Speciality*" />
              
                <RHFTextField name="company_name" label="Company Name*" />
                 
                <RHFTextField name="address" label="Address1*" />
                <RHFTextField name="address2" label="Address2*" />
                <RHFTextField name="city" label="City*" />

              </Box>


            </DialogContent>
          </>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleCancel}>
              Cancel
            </Button>

            <LoadingButton type="submit" variant="contained"  className='dark-button'  loading={isSubmitting}>
              {!currentItem || !currentItem.id ? 'Create' : 'Update Incident'}
            </LoadingButton>
          </DialogActions>
        </FormProvider>

      </Dialog>
    </>
  )
}

export default IncidentReportingCreate