// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import { Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useNavigate } from 'react-router';
import { deleteRequest, getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { LoadingScreen } from 'src/components/loading-screen';
import RiskLearningandInsightsCreate from './create';

const RiskLearningandInsightsJournal = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [JournlsId, setJournlsId] = useState([]);
  const [selectedItem, setSelectedItem] = useState({})
  const [error, setError] = useState(null);

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.No' },
    { id: 'Journal ID', label: 'Journal ID' },
    { id: 'Related Risk ID', label: 'Related Risk ID' },
    { id: 'Key Learnings', label: 'Key Learnings' },
    { id: 'Insights for Future', label: 'Insights for Future' },
    { id: 'Date of Entry', label: 'Date of Entry' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();
  const remove = useBoolean();


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };


  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = Array.isArray(applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  })) ? applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  }) : [];


  const dataInPage = dataFiltered
    ? dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
    : [];
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRows = useCallback(() => {
    if (tableData) {
      const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
      // setTableData(deleteRows);
      table.onUpdatePageDeleteRows({
        totalRows: tableData.length,
        totalRowsInPage: dataInPage?.length || 0,
        totalRowsFiltered: dataFiltered?.length || 0,
      });
    }
  }, [dataFiltered?.length, dataInPage?.length, table, tableData]);


  useEffect(() => {
    getRiskLeaningJournals();
  }, []);
  const getRiskLeaningJournals = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_Learning_Insights.crud);
    console.log("🚀 ~ getRiskLeaningJournals========> ~ data:", data)
    setLoading(false);
    setJournlsId(data?.next_risk_learning_id)
    if (data) {
      setTableData(data.data);
    }
  };

  const deleteCallBack = () => {
    getRiskLeaningJournals()
  }

  const handleDeleteRow = async () => {
    await deleteRequest(`${endpoints.risk_management.risk_Learning_Insights.crud}/${selectedItem.id}`, deleteCallBack);
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form

  }


  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []);
  };

  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >
      <CardContent>
        {loading ? (
          <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingScreen />
          </Box>
        ) : error ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" align="center" color="error">
              {error}
            </Typography>
          </Box>
        ) : (
          <>
                <Header name="Risk Learning and Insights Journal" />
            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
              }}
            >

              <Button
                onClick={create.onTrue}
                variant="contained"
                className="dark-button"

                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                    Risk Learning and Insights Journal
              </Button>

            </Box>

            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',

            }}>
              <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                <div style={{ marginBottom: table.selected.length > 0 ? '2.5rem' : '0' }}>
                  <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        // setActionVisible(checked),
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                </div>
                <Scrollbar >
                  <Table size={table.dense ? 'small' : 'large'}
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataInPage.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={table.selected.includes(row.id)}
                              onChange={() => {
                                table.onSelectRow(row.id); // Select the row
                                console.log('Selected row:', table.selected.includes(row.id));
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {index + 1 + table.page * table.rowsPerPage}
                          </TableCell>
                          <TableCell align="left">{row.journal_id}-RLIJ</TableCell>
                          <TableCell align="left">{row.identification_and_assessment?.risk_id}</TableCell>
                          <TableCell align="left">{row.key_learning}</TableCell>
                          <TableCell align="left">{row.insight_for_future}</TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left" sx={{ display: "flex" }}>
                            <IconButton onClick={handleToggleIcons}>
                              <Iconify icon="eva:more-vertical-fill" />
                            </IconButton>
                            {areIconsVisible && (
                              <>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    remove.onTrue()
                                  }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    create.onTrue();
                                  }}>
                                    <Iconify icon="solar:pen-bold" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View" onClick={() => navigate(`/risk-management/risk-learning-and-insights-journal/view/${row.id}`)}>
                                  <IconButton >
                                    <Iconify icon="solar:eye-bold" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card>


    <RiskLearningandInsightsCreate
      open={create.value}
      onClose={create.onFalse}
      reload={getRiskLeaningJournals} // Use getRiskLeaningJournals directly here
      currentItem={selectedItem}
      JournlsId={JournlsId}
      handleCloseForm={handleCloseForm}

    />
    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these item?
        </>
      }
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRow();
            remove.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />
  </>;
};

export default RiskLearningandInsightsJournal;
