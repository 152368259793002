import axios from 'axios';
// config
import { baseURL } from 'src/config-global';
// ----------------------------------------------------------------------
const axiosInstance = axios.create({ baseURL: baseURL });
const authToken = localStorage.getItem('authToken')
const projectId = localStorage.getItem('projectId')
const companyId = localStorage.getItem('companyId')

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);
export default axiosInstance;
// ----------------------------------------------------------------------
export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosInstance.get(url, {
    headers: apiHeader,
    ...config
  });
  return res.data;
};


export const tokenGetter = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return `Bearer ${searchParams.get('token') || localStorage.getItem('authToken')}` || '';
};
const currentprojectid = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('project_id') || localStorage.getItem('projectId') || ''
};
const currentCompanyId = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('company_id') || localStorage.getItem('companyId') || '';
};

export const simpleHeader = {
  Authorization: tokenGetter(),
  currentprojectid: currentprojectid(),
  currentCompanyId: currentCompanyId(),
}
export const apiHeader = {
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}

export const apiPostHeader = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}
export const multipartPartFormDataHeader = {
  'Content-Type': 'multipart/form-data',
  Accept: 'application/json',
  Authorization: tokenGetter(),
  currentCompanyId: currentCompanyId(),
  currentProjectId: currentprojectid(),
}
const userProjectsID = currentprojectid();
// ----------------------------------------------------------------------
export const endpoints = {
  fetchMessages: '/chat/fetchMessages',
  fetchCompanyMessages: '/company-chat/fetchMessages',
  chat: '/api/chat',
  users: '/users',
  getProjectUsers: '/chat/contacts',
  getLead: '/company/leads',
  editLead: '/company/leads',
  getCompanyUsers: '/company-chat/company-contacts',
  conversationContact: '/chat/conversations',
  companyconversationContact: '/company-chat/conversations',
  chatAttachments: '/chat/chat-attachments?to_id=',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  bids_due: '/company/calendar/bids',
  leads_due: '/company/calendar/leads',
  project_due: '/company/calendar/projects',
  planned_cost_code: '/project/calendar/planned-cost-codes',
  performed_cost_code: '/project/calendar/performed-cost-codes',
  company_project: '/user/company-project',
  profile_info: '/profile',
  chat_details: '/get-current-company-project',
  risk_management: {
    risk_dashboard: {
      dashboardData: "/risk-management/risk-dashboard",
      monthYear: "/risk-management/dashboard-risk-trend",
      heatmap: "/risk-management/dashboard-heatmap",

    },
    risk_register: {
      identification: {
        identification: '/risk-management/identification-and-assessments',
        importFile: '/risk-management/identification-assessment-import',
        bulk_delete: '/risk-management/identification-assessment-bulk-delete',
        settings: '/risk-management/identification-assessment-settings',
        show: (id) => `/risk-management/identification-and-assessments/${id}`,
        project_stages: '/risk-management/project-stages',
        app_role: '/app/project-roles',
        risk_categories: '/risk-management/risk-categories',
        risk_owners: '/risk-management/risk-owners',
        AddUserID: `/users?project_id=${userProjectsID}`,
        risk_assessments: (id) => `/risk-management/identification-and-assessment/${id}/risk-assessments`,
        risk_analyses: (id) => `/risk-management/identification-and-assessment/${id}/risk-analyses`,
        mitigation_plans: (id) => `/risk-management/identification-and-assessment/${id}/mitigation-plans`,
        monitor_and_tracks: (id) => `/risk-management/identification-and-assessment/${id}/monitor-and-tracks`,
        threat_opportunity: '/risk-management/threat-and-opportunities',
        risk_area: '/risk-management/risk-areas'
      }
    },
    //created by me 
    incident_reporting: {
      incident: '/risk-management/incident-reporting',
      IncidentShow: (id) => `/risk-management/incident-reporting/${id}`,
      DelIncident: '/risk-management/incident-reporting',
      bulk_delete: '/risk-management/incident-reporting-bulk-delete',

    },
    risk_change_request: {
      crud: '/risk-management/risk-change-requests',
      riskchangeview: (id) => `/risk-management/risk-change-requests/${id}`,
      bulk_delete: '/risk-management/risk-change-request-bulk-delete',
    },
    risk_stackholder_feedback: {
      crud: '/risk-management/stakeholder-feedback',
      stakehodlerShow: (id) => `/risk-management/stakeholder-feedback/${id}`,
    },
    risk_escalation: {
      crud: '/risk-management/risk-escalation',
      escalation_levels: '/risk-management/escalation-levels',
      escalation_levels_create: '/risk-management/escalation-levels',
      esclation_Show: (id) => `/risk-management/risk-escalation/${id}`,

    },
    risk_priotization: {
      crud: '/risk-management/risk-prioritization',
      priotization_Show: (id) => `/risk-management/risk-prioritization/${id}`,
    },
    risk_response_team: {
      crud: '/risk-management/risk-team-assignment',
      response_team_Show: (id) => `/risk-management/risk-team-assignment/${id}`,
    },
    quaterly_review: {
      crud: '/risk-management/quarterly-risk-review',
      quaterly_review_Show: (id) => `/risk-management/quarterly-risk-review/${id}`,
    },
    mitigation_survey: {
      crud: '/risk-management/risk-mitigation-survey',
      mitigation_survey_Show: (id) => `/risk-management/risk-mitigation-survey/${id}`,
    },
    risk_Learning_Insights: {
      crud: '/risk-management/risk-learning-insight',
      risk_Learning_Insights_Show: (id) => `/risk-management/risk-learning-insight/${id}`,
    },
    risk_trend_analysis: {
      crud: '/risk-management/risk-trend-analysis-report',
      risk_trend_analysis_Show: (id) => `/risk-management/risk-trend-analysis-report/${id}`,
    },
    mitigation_budget_request: {
      crud: '/risk-management/risk-mitigation-budget-request',
      mitigation_budget_request_Show: (id) => `/risk-management/risk-mitigation-budget-request/${id}`,
    },
  },

  job_costing: {
    get_list: '/job-costing',
    setting_customs:'/job-costing-custom-fields',
    setting:'/job-costing-settings',
    setting_post:'/job-costing-settings',
    update:'/job-costing-update',
    undo_last_drop: '/detach-job-costing',
    email: '/job-costing-mail',

  },
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};