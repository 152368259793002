import React from 'react';
import { Page, Text, View, Document, Image, PDFDownloadLink, Font } from '@react-pdf/renderer';
import head from "../../../assets/pdf/hedaerdyynamic.png";
import footer from "../../../assets/pdf/footer4x.png";
import watermark from "../../../assets/pdf/watermark.png";
import styles from './pdfcss';
Font.register({
    family: 'Open Sans',
    fonts: [
        {
            src: 'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,800;1,800&display=swap',
            fontWeight: 800,
        },
    ],
});
const PdfDocument = ({ riskData, profile, projectname }) => {
    console.log("🚀 ~ PdfDocument ~ profile:", profile?.signature)
    console.log("🚀 ~ PdfDocument ~ riskData:", riskData)

    const MyDocument = (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image
                    src={watermark}
                    fixed
                    style={styles.watermark}
                />
                <Image style={styles.headerImage} src={head} fixed />
                <View style={styles.logoContainer} fixed>
                    <Image src={profile?.avatar} style={styles.logo} />
                    {/* {avatarBase64 && <Image src={avatarBase64} style={styles.logo} />} */}
                    {/* {avatarBase64 ? (
                        <Image src={avatarBase64} style={styles.logo} />
                    ) : (
                        <Image src={profile?.avatar} style={styles.logo} />
                    )} */}
                    <View style={styles.companyDetails}>
                        <Text style={styles.companyName}>{profile?.company_name || ''}</Text>
                        <Text style={styles.companyAddress}>{`${profile?.city || ''}, ${profile?.address || ''},${profile?.state.name || ''},${profile?.country.name || ''},${profile?.zip || ''}`}</Text>
                    </View>
                </View>
                <View style={styles.main}>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>Project Name:</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.lighttext}>
                                    {projectname?.project.name}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>Contract Number:</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.lighttext}>
                                    {projectname?.project.contract_number || ''}
                                </Text>
                            </View>
                        </View>
                    </View>
                    {/*  row  2*/}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>Risk ID:</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.lighttext}>
                                    {riskData?.identification_and_assessment?.risk_id}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>Date Identified:</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.lighttext}>
                                    {riskData?.identification_and_assessment?.date_identified
                                        ? new Date(riskData.identification_and_assessment.date_identified).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })
                                        : ''}
                                </Text>
                            </View>

                        </View>
                    </View>
                    {/*  row  3*/}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>Risk Owner:</Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.lighttext}>
                                    {riskData?.identification_and_assessment?.risk_owner?.first_name}
                                    {riskData?.identification_and_assessment?.risk_owner?.last_name}
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>
                                </Text>
                            </View>
                            <View style={styles.tableCell}>
                                <Text style={styles.tableText}>
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.container}>
                    <Text style={styles.companyTitle}>Risk Detail</Text>
                </View>
                <View style={styles.container2}>
                    <Text style={styles.bar}>Identification</Text>
                </View>

                {/* identificfation */}
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk ID:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_id || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Title:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.title || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Category:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_category?.name || ''}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Description:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.description || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Date Identified:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.date_identified
                                    ? new Date(riskData.identification_and_assessment.date_identified).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    })
                                    : ''}                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Identified By:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.identified_by || ''}
                            </Text>
                        </View>
                    </View>

                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Project Stage:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.project_stage?.name || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Exposure:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.exposure || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Owner:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_owner?.first_name || ''} {riskData?.identification_and_assessment?.risk_owner?.last_name || ''}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.containerd}>
                    {riskData?.identification_and_assessment?.media[0]?.file_name ? (
                        <View style={styles.relatedDocumentsSection}>
                            <Text style={styles.relatedDocumentsTitle}>Attached Photo / Documents</Text>
                            <View style={styles.documentRow}>
                                <Text style={styles.riskInfoTitle}>
                                    {riskData?.identification_and_assessment?.media[0]?.file_name.toString() ?? "No File"}
                                </Text>
                                <View style={styles.downloadButtonContainer}>
                                    <Text style={styles.downloadButton}> Download</Text>
                                </View>
                            </View>
                        </View>
                    ) : null}

                </View>
                <View style={styles.container2}>
                    <Text style={styles.bar}>Risk Assessment</Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Qualitative Assessment:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.qualitative_analysis || ' '}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Rating:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.risk_rating || ' '}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Threat or Opportunity:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.threat_opportunity?.name || ' '}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Probability of Occurrence:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.probability_of_occurrence || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Area:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.risk_area?.name || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Impact on Cost:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                ${riskData?.identification_and_assessment?.risk_assessment?.impact_on_cost || ''}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Full Probable Loss:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                ${riskData?.identification_and_assessment?.risk_assessment?.full_probable_loss || ''}
                            </Text>
                        </View>

                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Expected Loss:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                ${riskData?.identification_and_assessment?.risk_assessment?.expected_loss || ''}
                            </Text>
                        </View>

                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Impact on Schedule:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_assessment?.impact_on_schedule || ''}
                            </Text>
                        </View>

                    </View>
                </View>
                <View style={styles.containerd}>
                    <View style={styles.riskInfoContainer}>
                    </View>
                </View>
                <View style={styles.container2}>
                    <Text style={styles.bar}>Risk Assessment Budgeted </Text>
                </View>
                <View style={styles.container2}>
                    <Text style={styles.relatedDocumentsTitle2}>Risk Assessment Resources</Text>
                </View>
                <View style={styles.tableContainer}>
                    <View style={styles.tableRowHeader}>
                        <Text style={styles.tableColHeader}>Sr.No</Text>
                        <Text style={styles.tableColHeader}>Code</Text>
                        <Text style={styles.tableColHeader}>Description</Text>
                        <Text style={styles.tableColHeader}>QTY/ Hrs</Text>
                        <Text style={styles.tableColHeader}>Unit</Text>
                        <Text style={styles.tableColHeader}>Unit Price</Text>
                        <Text style={styles.tableColHeader}>Amount</Text>
                        <Text style={styles.tableColHeader}>hrs Spent</Text>
                        <Text style={styles.tableColHeader}>Amount Spent</Text>
                        <Text style={styles.tableColHeader}>Balance</Text>
                        <Text style={styles.tableColHeader}>Balance Hours</Text>
                        <Text style={styles.tableColHeader}>Complete%</Text>
                    </View>
                    {riskData?.identification_and_assessment?.mitigation_plan?.mitigation_resources?.map((resource, index) => (
                        <View style={styles.tableRow2} key={resource.id}>
                            <Text style={styles.tableCol}>{index + 1}</Text>
                            <Text style={styles.tableCol}>{resource.code || ''}</Text>
                            <Text style={styles.tableCol}>{resource.description || ''}</Text>
                            <Text style={styles.tableCol}>{resource.quantity || ''}</Text>
                            <Text style={styles.tableCol}>{resource.unit || ''}</Text>
                            <Text style={styles.tableCol}>${resource.unit_price || ''}</Text>
                            <Text style={styles.tableCol}>${resource.amount || ''}</Text>
                            <Text style={styles.tableCol}>{resource.hours_spent || ''}</Text>
                            <Text style={styles.tableCol}>${resource.amount_spent || ''}</Text>
                            <Text style={styles.tableCol}>${resource.balance || ''}</Text>
                            <Text style={styles.tableCol}>
                                {riskData?.identification_and_assessment?.mitigation_plan?.budget_approval_status === 0 ? 0 : resource.balance_hours || ''}
                            </Text>                            <Text style={styles.tableCol}>{resource.complete || ''}%</Text>
                        </View>
                    ))}
                </View>
                <View break />
                {riskData?.identification_and_assessment?.mitigation_plan?.budget_approval_status === 1 && (
                    <View style={styles.totalsContainer}>
                        <View style={styles.totalRow}>
                            <Text style={styles.totalLabel}>Total Budgeted Amount</Text>
                            <Text style={styles.lighttext}>
                                ${riskData?.identification_and_assessment?.mitigation_plan?.total_budgeted_amount || '0'}
                            </Text>
                        </View>
                        <View style={styles.totalRow}>
                            <Text style={styles.totalLabel}>Total Spent Amount</Text>
                            <Text style={styles.lighttext}>
                                ${riskData?.identification_and_assessment?.mitigation_plan?.total_amount_spent || '0'}
                            </Text>
                        </View>
                        <View style={styles.totalRow}>
                            <Text style={styles.totalLabel}>Total Balance</Text>
                            <Text style={styles.lighttext}>
                                ${riskData?.identification_and_assessment?.mitigation_plan?.total_balance || '0'}
                            </Text>
                        </View>
                    </View>
                )}
                <View style={styles.container2}>
                    <Text style={styles.bar}>Risk Analysis </Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Severity Rating:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.severity_rating || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Impact Level Pre-Mitigation:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.impact_level_pre_mitigation || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Likelihood Pre-Mitigation:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.likelihood_pre_mitigation || ''}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Severity Pre-Mitigation Updated:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.severity_pre_mitigation || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Priority:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.priority || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Severity Post-Mitigation:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.severity_post_mitigation || ''}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>

                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Likelihood Post-Mitigation:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.likelihood_post_mitigation || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Related Task IDs:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.related_task_ids || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Affected Areas:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.affected_areas || ''}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Risk Triggers:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.risk_analysis?.risk_triggers || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}></Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}></Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.container2}>
                    <Text style={styles.bar}>Mitigation Plan </Text>
                </View>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Mitigation Strategy:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.mitigation_plan?.mitigation_strategy || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Assigned To:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.mitigation_plan?.assigned_user.first_name || ''}
                                {riskData?.identification_and_assessment?.mitigation_plan?.assigned_user.last_name || ''}
                            </Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.darkText}>Budgeted Approval Status:</Text>
                        </View>
                        <View style={styles.tableCell}>
                            <Text style={styles.identificationText}>
                                {riskData?.identification_and_assessment?.mitigation_plan?.budget_approval_status === 1 ? 'Yes' : 'No'}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.signatureContainer}>
                    <Text style={styles.statement}>
                        I have reviewed thoroughly and completed this report
                    </Text>
                    <Image style={styles.signatureImage} src={profile?.signature} /> {/* Replace with dynamic path */}
                    <Text style={styles.projectManagerInfo}>
                        {profile?.first_name}{profile?.last_name}
                    </Text>
                </View>

                <Image style={styles.footerImage} src={footer} fixed />
                <Text
                    style={{
                        position: 'absolute',
                        bottom: 25,
                        right: 68,
                        fontSize: 10,
                        fontWeight: 800,
                        color: '#fff',
                    }}
                    render={({ pageNumber, totalPages }) => (
                        `${String(pageNumber).padStart(2, '0')}/${String(totalPages).padStart(2, '0')}`
                    )}
                    fixed
                />
            </Page>
        </Document>
    );
    return (
        <div>
            <PDFDownloadLink
                document={MyDocument}
                fileName="risk_register_complete.pdf"
                className="dark-button"
                style={{
                    color: '#fff', 
                    padding: '10px 20px',
                    textDecoration: 'none',
                    borderRadius: '5px',
                    display: 'inline-block',
                    fontWeight: 'bold',
                    textAlign: 'center',
                }}
            >
                {({ loading }) => (loading ? '...' : 'PDF')}
            </PDFDownloadLink>

        </div>
    );
};
export default PdfDocument;