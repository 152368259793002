import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, ListItemText, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import './style.css';
import RenderField from './render-field';
import FileThumbnail from 'src/components/file-thumbnail';
import PdfButton from './pdf';
import { getRequest } from 'src/services';
import { apiHeader, endpoints, simpleHeader } from 'src/utils/axios';
import { baseURL } from 'src/config-global';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';

const Identification = ({ data, riskData }) => {
  console.log("🚀 ~ ============== ", riskData)
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const searchParams = useSearchParams();
  const [profile, SetProfile] = useState();
  const [projectname, setprojectname] = useState();
  console.log("🚀 ~ Identification ~ projectname:", projectname)

  useEffect(() => {
    getProfile();
    getChatDetails()
  }, []);
  const getProfile = async () => {
    try {
      const data = await getRequest(endpoints.profile_info, {
        headers: {
          "Cache-Control": 'no-cache',
          apiHeader
        },
      });
      SetProfile(data);
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };
  const getChatDetails = () => {
    axios.get(`${baseURL}${endpoints.chat_details}`, {
      headers: simpleHeader
    }).then(res => {
      console.log("🚀 ~ getChatDetails============= ~ res:", res)
      localStorage.getItem('chatDetails', JSON.stringify(res.data))
      if (res.status === 200) {
        setprojectname(res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <Card>
      <Box p={3} display="flex" justifyContent="space-between" alignItems="center">
        <Typography className="">Risk Register</Typography>
        <Box display="flex" alignItems="center">
          <PdfButton
            riskData={riskData}
            projectname={projectname}
            profile={profile}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h4" class="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
            Identification{' '}
          </Typography>
        </Box>
        <Box>
          <Button
            className='dark-button'
            sx={{ marginRight: '20px', marginTop: '22px' }}
            onClick={goBack}
            size="md"
            variant="contained"
            startIcon={<Iconify icon="ep:back" width={16} />}
          >
            Go Back
          </Button>
        </Box>
      </Box>
      <Box
        p={4}
        mt={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Risk ID" value={data?.risk_id} />
        <RenderField label="Risk Title" value={data?.title} />
        <RenderField label="Project Stage" value={data?.project_stage?.name} />
      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Risk Category" value={data?.risk_category?.name} />
        <RenderField label="Risk Description" value={data?.description} />
        <RenderField label="Exposure" value={data?.exposure} />
      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Date Identified" value={formatDate(data?.date_identified)} />
        <RenderField label="Identified By" value={data?.identified_by} />
        <RenderField label="Risk Owner" value={data?.risk_owner?.name} />
      </Box>
      <Divider />
      <Box p={4}>
        <Typography className='my-risk-label'>Attached Photo / Documents</Typography>
        {data?.media?.length > 0 ?
          <Stack
            mt={2}
            key={1}
            spacing={1.5}
            direction="row"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: 40,
                height: 40,
                flexShrink: 0,
                borderRadius: 1,
                overflow: 'hidden',
                position: 'relative',
                backgroundColor: 'background.neutral',
              }}
            >
              <FileThumbnail
                imageView
                file={data?.media[0]?.original_url}
                sx={{ width: 28, height: 28 }}
              />
            </Stack>
            <ListItemText
              primary={data?.media[0]?.name}
              secondary={data?.media[0]?.updated_at.slice(0, 10)}
              primaryTypographyProps={{
                noWrap: true,
                typography: 'body2',
              }}
              secondaryTypographyProps={{
                mt: 0.25,
                noWrap: true,
                component: 'span',
                typography: 'caption',
                color: 'text.disabled',
              }}
            />
          </Stack>
          :
          "N/A"
        }
      </Box>
    </Card>
  );
};
export default Identification;