import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import MainDashboardLayout from 'src/layouts/dashboard/main-layout';
import JobCostingPage from 'src/pages/dashboard/JobCosting';
import GenerateReportMain from 'src/pages/dashboard/JobCosting/genereate-reaport-main';

export const JobCostingRotes = [
    {
        path: '/job-costing',
        element: (
            <MainDashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                    <Outlet />
                </Suspense>
            </MainDashboardLayout>
        ),
        children: [
            {
                element: <JobCostingPage />,
                index: true, // Home page (default page) under "/job-costing"
            },
            {
                path: 'generate-report', // Define the path for generating reports
                element: <GenerateReportMain />, // Route for generating reports
            },
        ],
    },
];
