import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import * as Yup from 'yup';
import {
  handleErrorMessages
} from '../../risk-register/feching';

const RiskPrioritizationCreate = ({
  open,
  onClose,
  reload,
  currentItem,
  handleCloseForm,
}) => {
  console.log("🚀 ~RiskPrioritizationCreate  currentItem:", currentItem)
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);
  const [isFetchingRisks, setIsFetchingRisks] = useState(true);
  const [selectedRisk, setSelectedRisk] = useState(null);

  // Fetch risk IDs on component mount
  useEffect(() => {
    const fetchIdentification = async () => {
      try {
        const data = await getRequest(
          endpoints.risk_management.risk_register.identification.identification
        );
        setRiskIds(data || []);
      } catch (err) {
        console.error('Error fetching risk IDs:', err);
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to fetch risk IDs.',
        });
      } finally {
        setIsFetchingRisks(false);
      }
    };
    fetchIdentification();
  }, [enqueueSnackbar]);

  // Find the matched risk based on currentItem
  const matchedRisk = useMemo(() => {
    if (currentItem && riskIds.length > 0) {
      return (
        riskIds.find(
          (risk) => risk.id === currentItem?.identification_and_assessment?.id
        ) || { id: '', risk_id: 'None' }
      );
    }
    return { id: '', risk_id: '' };
  }, [currentItem, riskIds]);

  // Validation schema
  const NewUserSchema = Yup.object().shape({
    // risk_id: Yup.string().required('Risk ID is required'),
    priority_level: Yup.string().required('Priority Level is required'),
    rational_prioritization: Yup.string().required('Rationale for Prioritization is required'),
    review_date: Yup.date().required('Review Date is required').nullable(),
    prioritized_by: Yup.string().required('Prioritized By is required'),
    remarks: Yup.string().nullable(),
  });

  // Default form values
  const defaultValues = useMemo(
    () => ({
      risk_id: matchedRisk && matchedRisk.id ? matchedRisk : "",
      priority_level: currentItem?.priority_level || '',
      description: currentItem?.identification_and_assessment?.description || '',
      priority: currentItem?.identification_and_assessment?.risk_analysis?.priority || '', 
      rational_prioritization: currentItem?.rational_prioritization || '',
      review_date: currentItem?.review_date ? new Date(currentItem.review_date) : null,
      prioritized_by: currentItem?.prioritized_by || '',
      status: currentItem?.status || '', 
      remarks: currentItem?.remarks || '',
    }),
    [currentItem, matchedRisk]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    watch, 
    setValue,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  
  useEffect(() => {
    if (!isFetchingRisks) {
      reset(defaultValues);
    }
  }, [currentItem, defaultValues, reset, isFetchingRisks]);

  // Handle form submission
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const formData = new FormData();

    formData.append('risk_id', selectedRisk?.id || ''); 
    formData.append('priority_level', data.priority_level);
    formData.append('rational_prioritization', data.rational_prioritization);
    formData.append('review_date', data.review_date ? data.review_date.toISOString().split('T')[0] : '');
    formData.append('prioritized_by', data.prioritized_by);
    formData.append('remarks', data.remarks || '');
    formData.append('status', data.status);
    formData.append('priority', data.priority); 
    try {
      const endpoint = currentItem?.id
        ? `${endpoints.risk_management.risk_priotization.crud}/${currentItem.id}?_method=PUT`
        : endpoints.risk_management.risk_priotization.crud;

      const response = await postRequest(endpoint, formData, true);
      console.log("🚀 ~ onSubmit ~ response:", response, formData, endpoint)

      if (response.metadata?.success) {
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
        handleClose();
        reload();
      } else {
        handleErrorMessages(response.message);
      }
    } catch (err) {
      console.error('Submission error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });

  // Handle dialog close with form reset
  const handleClose = () => {
    reset(defaultValues);
    handleCloseForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose} 
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'Arial, sans-serif',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <span
            className="cardColor"
            style={{
              padding: '5px',
              marginLeft: '-25px',
              color: 'white',
              fontWeight: '700',
            }}
          >
            &nbsp;&nbsp; Risk Prioritization &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ borderRadius: '1px' }}>
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFAutocomplete
              name="risk_id"
              label="Risk ID"
              options={[{ id: '', risk_id: 'None' }, ...riskIds]}
              getOptionLabel={(option) => option.risk_id || 'None'}
              isOptionEqualToValue={(option, value) => option.id === value?.id || value === ''}
              renderOption={(props, option) => (
                <li {...props} key={option.id || 'none'}>
                  {option.risk_id}
                </li>
              )}
              onChange={(_, selectedOption) => {
                setSelectedRisk(selectedOption || { id: '', risk_id: 'None' });
                setValue('risk_id', selectedOption || { id: '', risk_id: 'None' });

                if (selectedOption && selectedOption.id) {
                  const matchedRisk = riskIds.find((risk) => risk.id === selectedOption.id);
                  if (matchedRisk) {
                    setValue('priority', matchedRisk.risk_analysis?.priority || '');
                    setValue('description', matchedRisk.description || '');
                  }
                } else {
                  setValue('description', '');
                  setValue('priority', '');
                }
              }}
              value={methods.watch('risk_id')} 
              disabled={isFetchingRisks}
            />



            <RHFTextField
              name="description"
              label="Description"
              InputLabelProps={{ shrink: true }} 
            />

               <RHFTextField
               type="number"
              name="priority"
              label="Risk Priority "
            />
            <RHFAutocomplete
              sx={{ width: '100%' }}
              name="priority_level"
              label="Priority"  
              options={['High', 'Medium', 'Low']}  
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
              error={!!methods.formState.errors.frequencey}  // Display error if validation fails
              helperText={methods.formState.errors.frequencey?.message}  // Show the error message
            />

        
            <RHFTextField
              name="rational_prioritization"
              label="Rationale for Prioritization"
            />
            <Controller
              name="review_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Review Date"
                  {...field}
                  renderInput={(params) => (
                    <RHFTextField
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : ''}
                    />
                  )}
                />
              )}
            />
            <RHFTextField name="prioritized_by" label="Prioritized By" />
            <RHFAutocomplete
              sx={{ width: '100%' }}
              name="status"
              label="Status"
              options={['Pending', 'Approved', 'Updated']}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option}>
                    {option}
                  </li>
                );
              }}
              error={!!methods.formState.errors.frequencey}  // Display error if validation fails
              helperText={methods.formState.errors.frequencey?.message}  // Show the error message
            />
         
          </Box>
          <Box mt={2} rowGap={3} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)' }}>
            <RHFTextField
              multiline
              rows={4}
              name="remarks"
              label="Remarks / Notes"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            className="dark-button"
            loading={isSubmitting}
          >
            {!currentItem.id ? 'Create Risk Prioritization' : 'Update Risk Prioritization'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default RiskPrioritizationCreate;
