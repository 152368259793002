import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import HeatMap from './heat-map';
import MitigationCard from './mitigation-cirlce-card';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';

export default function HeatmapAndstatuscomp({ data }) {
  const {
    implementedCounts,
    ongoingCounts,
    overdueCounts,
    total,
  } = useMemo(() => {
    const implementedCounts = data?.mitigation_plan_status?.implemeted_mitigation_counts || 0;
    const ongoingCounts = data?.mitigation_plan_status?.ongoing_mitigation_counts || 0;
    const overdueCounts = data?.mitigation_plan_status?.over_due_mitigation_counts || 0;
    const total = implementedCounts + ongoingCounts + overdueCounts;
    console.log("Total Mitigation Counts:", total); // Log total to console
    return { implementedCounts, ongoingCounts, overdueCounts, total };
  }, [data]);

  const [heatdata, setheatdata] = useState();
  const [heatzerodata, setheatzerodata] = useState();

  useEffect(() => {
    getHeatmap();
  }, []);
  const getHeatmap = async () => {
    try {
      const data = await getRequest(endpoints.risk_management.risk_dashboard.heatmap);
      console.log("🚀 ~ getDashboardData ~ data:", data);
      setheatdata(data.heat_map_data);
      setheatzerodata(data.heat_zero_values);
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };
  return (
    <Grid container spacing={2} mt={4}>
      <Grid
        item
        xs={12}
        lg={8}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <HeatMap data={heatdata} zerovalue={heatzerodata} />
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'stretch',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <MitigationCard
          title="Mitigation Status"
          total={total} 
          chart={{
            series: [
              { label: 'Implemented', value: implementedCounts },
              { label: 'Ongoing', value: ongoingCounts },
              { label: 'Overdue', value: overdueCounts },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
}
HeatmapAndstatuscomp.propTypes = {
  data: PropTypes.shape({
    mitigation_plan_status: PropTypes.shape({
      implemeted_mitigation_counts: PropTypes.number,
      ongoing_mitigation_counts: PropTypes.number,
      over_due_mitigation_counts: PropTypes.number,
    }),
  }),
};