import { Helmet } from 'react-helmet-async';
// sections
import Mainpage from './job-costing-viewpage';
// ----------------------------------------------------------------------
export default function JobCostingPage() {
    return (
        <>
            <Helmet>
                <title> Job Costing</title>
            </Helmet>
            <Mainpage />
        </>
    );
}
