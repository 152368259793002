import { useCallback, useEffect, useState } from 'react';
import { Button, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetUpper, calculateVariance } from './Calculations/formulas';
import Iconify from 'src/components/iconify';
import ChangeOrder from './change-order-details-modal';
import { useBoolean } from 'src/hooks/use-boolean';
import { LoadingButton } from '@mui/lab';
import { handleErrorMessages } from 'src/components/risk-management/risk-register/feching';

// ----------------------------------------------------------------------

export default function JobcostingTableRow({
    row,
    selected,
    onSelectRow,
    onDeleteRow,
    onDrag,
    onDragOver,
    onDrop,
    refreshTableData,
    onConfirmDrop,
    visibleColumns,
    
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [rowData, setRowData] = useState(row);
    const [refreshTrigger, setRefreshTrigger] = useState(false); 
    useEffect(() => {
        setRowData(row);
    }, [row]);
    const [committedSubcontractorCost, setCommittedSubcontractorCost] = useState(0);
    const [nonCommittedCostToDate, setNonCommittedCostToDate] = useState(0);
    const [estimatedFinalCost, setEstimatedFinalCost] = useState(0);
    const [variance, setVariance] = useState(0);
    const [performedPercentage, setPerformedPercentage] = useState(0);
    const [actualCost, setActualCost] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [grossProfitPercentage, setGrossProfitPercentage] = useState(0);
    const [remainingBudget, setRemainingBudget] = useState(0);
    const [retainage, setRetainage] = useState(0);
    const [revisedBudget, setRevisedBudget] = useState(0);
    const [approvedChangeOrders, setApprovedChangeOrders] = useState();
    const [plannedSubcontractorCost, setplannedSubcontractorCost] = useState();
    const [isUndoLoading, setIsUndoLoading] = useState(false);




    const confirm = useBoolean()

    useEffect(() => {
        const budgetedAmount = parseFloat(rowData?.change_orders?.[0]?.budgeted_amount);
        setApprovedChangeOrders(budgetedAmount);
        const plannedSubcontractorCost = parseFloat(rowData?.change_orders?.[0]?.planned_subcontractor_cost);
        setplannedSubcontractorCost(plannedSubcontractorCost);
    }, [rowData]);

    useEffect(() => {
        //14
        const calculatedRevisedBudget = calculateRevisedBudgetUpper(
            row.original_budgeted_amount,
            approvedChangeOrders
        );
        setRevisedBudget(calculatedRevisedBudget);

        //19
        const calculatedValue = calculateCommittedSubcontractorCost(row, plannedSubcontractorCost);
        setCommittedSubcontractorCost(calculatedValue);
        //20
        const calculatedNonCommittedCost = calculateNonCommittedCostToDate(calculatedRevisedBudget, committedSubcontractorCost);
        setNonCommittedCostToDate(calculatedNonCommittedCost);
        //22
        const calculatedEstimatedFinalCost = calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            parseFloat(row.non_committed_estimate_cost_to_date) || 0
        );
        setEstimatedFinalCost(calculatedEstimatedFinalCost);
        //23
        const calculatedVariance = calculateVariance(estimatedFinalCost, parseFloat(calculatedRevisedBudget) || 0);
        setVariance(calculatedVariance);
        //25
        const calculatedPerformedPercentage = calculatePerformedPercentage(
            row.performed_amount,
            parseFloat(calculatedRevisedBudget) || 0
        );
        setPerformedPercentage(calculatedPerformedPercentage);
        //28
        const calculatedActualCost = calculateActualCost(
            parseFloat(row.resources_used) || 0,
            parseFloat(row.expenses) || 0
        );
        setActualCost(calculatedActualCost);
        //29
        const calculatedGrossProfit = calculateGrossProfit(
            parseFloat(row.performed_amount) || 0,
            actualCost
        );
        setGrossProfit(calculatedGrossProfit);
        //30
        const calculatedGrossProfitPercentage = calculateGrossProfitPercentage(
            grossProfit,
            parseFloat(calculatedRevisedBudget) || 0
        );
        setGrossProfitPercentage(calculatedGrossProfitPercentage);
        //31
        const calculatedRemainingBudget = calculateRemainingBudget(
            parseFloat(calculatedRevisedBudget) || 0,
            actualCost
        );
        setRemainingBudget(calculatedRemainingBudget);
        //32
        const calculatedRetainage = calculateRetainage(row.performed_amount);
        setRetainage(calculatedRetainage);
    }, [
        row.original_subcontractor_cost,
        row.approved_change_orders_subcontractors,
        row.purchase_order,
        row.material_requisition,
        row.revised_budget,
        committedSubcontractorCost,
        row.non_committed_estimate_cost_to_date,
        estimatedFinalCost,
        row.performed_amount,
        row.resources_used,
        row.expenses,
        actualCost,
        grossProfit,
        row.original_budgeted_amount,
        row.approved_change_orders, 
    onConfirmDrop,
        approvedChangeOrders,
        plannedSubcontractorCost,
    ]);
    const handleDropSuccess = async (event, targetRow) => {
        if (onDrop) {
            onDrop(event, targetRow);
        }
        if (onConfirmDrop) {
            await onConfirmDrop();
            // console.log("again kr ab    ")
        }
        setRefreshTrigger((prev) => !prev); // Trigger recalculation
    };

  const handleFieldChange = (field, value) => {
    const defaultValues = {
      payment_status: 'pending',
      risk_level: 'Low',
      quality_checks: 'passed',
      compliance: 'Yes',
    };

    // If no value is selected or it's the default, pass the default value
    const newValue = value || defaultValues[field] || '';

    const updatedRow = {
      ...rowData,
      [field]: newValue,
    };

    setRowData(updatedRow);

    // Send the value to the API
    debouncedApiCall(field, newValue, updatedRow.id);
  };

    const apiCall = async (field, numericValue, id) => {
        const formData = new FormData();
        formData.append(field, numericValue);
        formData.append('id', id);
        try {
            const response = await postRequest(
                `${endpoints.job_costing.update}/${id}`,
                formData,
                true
            );
            // console.log("🚀 ~ response ()()()()():", response);
            if (response.metadata && response.metadata.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: "Base Contract Updated Successfully",
                });
            
                refreshTableData && refreshTableData();
            } else {
                throw new Error(response.metadata.message);
            }
        } catch (error) {
            enqueueSnackbar({
                variant: 'error',
                message: error.message || 'Failed to save data.',
            });
        }
    };
    const debouncedApiCall = useCallback(debounce(apiCall, 1000), [])

    const handleUndo = async (id) => {
        setIsUndoLoading(true); 
        try {
            const responseData = await postRequest(
                `${endpoints.job_costing.undo_last_drop}/${id}`
            );
            const detachedChangeOrder = rowData?.change_orders?.find((item) => item.id);
            if (responseData.metadata && responseData.metadata.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: `Change Order ${detachedChangeOrder.id} reverted successfully!`,
                });
                refreshTableData && refreshTableData();
            } else {
                handleErrorMessages(responseData.metadata.message || 'Undo action failed.');
            }
        } catch (error) {
            console.error('Error performing undo action:', error);
            enqueueSnackbar({
                variant: 'error',
                message: 'Undo action failed.',
            });
        } finally {
            setIsUndoLoading(false);
        }
    };

    const renderMainRow = () => (
      <TableRow
        hover
        selected={selected}
        key={rowData.id}
        style={{
          backgroundColor:
            rowData?.change_orders?.length > 0
              ? 'rgb(205 238 238)'
              : rowData.isMerged
              ? 'red'
              : 'transparent',
        }}
        draggable
        onDrag={(event) => onDrag(event, rowData)}
        onDragOver={onDragOver}
        onDrop={(event) => handleDropSuccess(event, rowData)}
      >
        <TableCell>
          <LoadingButton
            className="dark-button"
            variant="contained"
            startIcon={<Iconify icon="iconoir:undo-circle-solid" />}
            sx={{
              mx: 1,
              backgroundColor: 'primary.main',
              color: 'white',
              '&.MuiLoadingButton-loading': {
                backgroundColor: 'primary.main',
              },
              '& .MuiCircularProgress-root': {
                color: 'white',
                width: '24px !important',
                height: '24px !important',
              },
            }}
            onClick={() => handleUndo(rowData.id)}
            loading={isUndoLoading}
            disabled={!rowData?.change_orders || rowData.change_orders.length < 1}
          >
            Undo
          </LoadingButton>
        </TableCell>
        <TableCell sx={{ cursor: 'pointer' }} onClick={() => confirm.onTrue()}>
          <Tooltip title={rowData?.change_orders?.map((item) => item.id).join(' ')}>View</Tooltip>
        </TableCell>

        <TableCell>{rowData.project_id}</TableCell>
        {visibleColumns.includes('facility') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.facility || ''}
              onChange={(e) => handleFieldChange('facility', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
        {visibleColumns.includes('level') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.level || ''}
              onChange={(e) => handleFieldChange('level', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
        {visibleColumns.includes('milestone') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.milestone || ''}
              onChange={(e) => handleFieldChange('milestone', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}

        {visibleColumns.includes('category') && (
          <TableCell>
            {typeof rowData?.cost_code_category === 'object' && rowData.cost_code_category !== null
              ? `${rowData.cost_code_category?.code ?? ''} - ${
                  rowData.cost_code_category?.description ?? ''
                }`
              : ''}
          </TableCell>
        )}
        {/* //activity */}
        <TableCell>
          {typeof rowData?.cost_codes === 'object' && rowData.cost_codes !== null
            ? `${rowData.cost_codes?.code ?? ''} - ${rowData.cost_codes?.description ?? ''}`
            : ''}
        </TableCell>

        {visibleColumns.includes('start_date') && (
          <TableCell>
            {rowData.start_date
              ? new Date(rowData.start_date).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('end_date') && (
          <TableCell>
            {rowData.end_date
              ? new Date(rowData.end_date).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('duration') && (
          <TableCell>{rowData.cost_codes?.duration ?? ''}</TableCell>
        )}
        {visibleColumns.includes('trade') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.trade || ''}
              onChange={(e) => handleFieldChange('trade', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
        {visibleColumns.includes('responsible_party') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.responsible_party || ''}
              onChange={(e) => handleFieldChange('responsible_party', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
        <TableCell>
          {rowData.original_budgeted_amount ? `$${rowData.original_budgeted_amount}` : ''}
        </TableCell>
        <TableCell>
          {approvedChangeOrders && !isNaN(approvedChangeOrders)
            ? `$${approvedChangeOrders.toFixed(2)}`
            : ''}
        </TableCell>
        <TableCell>{`$${revisedBudget.toFixed(2)}`}</TableCell>
        <TableCell>
          {rowData.original_subcontractor_cost ? `$${rowData.original_subcontractor_cost}` : ''}
        </TableCell>
        <TableCell>
          {plannedSubcontractorCost && !isNaN(plannedSubcontractorCost)
            ? `$${plannedSubcontractorCost.toFixed(2)}`
            : ''}
        </TableCell>
        <TableCell>
          <TextField
            sx={{ minWidth: '100px' }}
            value={rowData.purchase_order ? `$${rowData.purchase_order}` : ''}
            onChange={(e) => handleFieldChange('purchase_order', e.target.value.replace('$', ''))}
            variant="outlined"
            size="small"
          />
        </TableCell>
        <TableCell>
          <TextField
            sx={{ minWidth: '100px' }}
            value={rowData.material_requisition ? `$${rowData.material_requisition}` : ''}
            onChange={(e) =>
              handleFieldChange('material_requisition', e.target.value.replace(/[^0-9.-]+/g, ''))
            }
            variant="outlined"
            size="small"
          />
        </TableCell>

        <TableCell>
          {committedSubcontractorCost && !isNaN(committedSubcontractorCost)
            ? `$${committedSubcontractorCost.toFixed(2)}`
            : ''}
        </TableCell>
        <TableCell>
          {nonCommittedCostToDate && !isNaN(nonCommittedCostToDate)
            ? `$${nonCommittedCostToDate.toFixed(2)}`
            : ''}
        </TableCell>
        <TableCell>
          <TextField
            sx={{ minWidth: '100px' }}
            value={
              rowData.non_committed_estimate_cost_to_date
                ? `$${rowData.non_committed_estimate_cost_to_date}`
                : ''
            }
            onChange={(e) =>
              handleFieldChange(
                'non_committed_estimate_cost_to_date',
                e.target.value.replace('$', '')
              )
            }
            variant="outlined"
            size="small"
          />
        </TableCell>
        <TableCell>
          {estimatedFinalCost && !isNaN(estimatedFinalCost)
            ? `$${estimatedFinalCost.toFixed(2)}`
            : ''}
        </TableCell>
        <TableCell>
          {variance === 0 ? '$0' : (variance && !isNaN(variance) ? `$${variance.toFixed(2)}` : '')}
        </TableCell>

        <TableCell>
          {rowData.performed_amount && !isNaN(rowData.performed_amount)
            ? `$${rowData.performed_amount}`
            : ''}
        </TableCell>
        <TableCell>{`${performedPercentage.toFixed(2)}%`}</TableCell>
        <TableCell>
          {rowData.resources_used ? `$${rowData.resources_used}` : ''}
        </TableCell>
        <TableCell>
          <TextField
            sx={{ minWidth: '100px' }}
            value={rowData.expenses ? `$${rowData.expenses}` : ''}
            onChange={(e) =>
              handleFieldChange('expenses', e.target.value.replace(/[^0-9.-]+/g, ''))
            }
            variant="outlined"
            size="small"
          />
        </TableCell>
        <TableCell>{actualCost && !isNaN(actualCost) ? `$${actualCost.toFixed(2)}` : ''}</TableCell>
        <TableCell>
          {grossProfit && !isNaN(grossProfit) ? `$${grossProfit.toFixed(2)}` : ''}
        </TableCell>
        <TableCell>
          {grossProfitPercentage && !isNaN(grossProfitPercentage)
            ? `${grossProfitPercentage.toFixed(2)}%`
            : ''}
        </TableCell>
        <TableCell>
          {remainingBudget && !isNaN(remainingBudget) ? `$${remainingBudget.toFixed(2)}` : ''}
        </TableCell>

        <TableCell>{`$${retainage.toFixed(2)}`}</TableCell>
        {visibleColumns.includes('requisition_number') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.requisition_number ? `$${rowData.requisition_number}` : ''}
              onChange={(e) =>
                handleFieldChange('requisition_number', e.target.value.replace(/[^0-9.-]+/g, ''))
              }
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
        <TableCell>
          <TextField
            sx={{ minWidth: '100px' }}
            value={rowData.invoice_number || ''}
            onChange={(e) => handleFieldChange('invoice_number', e.target.value)}
            variant="outlined"
            size="small"
          />
        </TableCell>
        {visibleColumns.includes('payment_status') && (
          <TableCell>
            <Select
              value={rowData.payment_status || 'pending'}
              defaultValue="pending"
              onChange={(e) => handleFieldChange('payment_status', e.target.value)}
              variant="outlined"
              size="small"
              displayEmpty
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="Reviewed">Reviewed</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
            </Select>
          </TableCell>
        )}
        {visibleColumns.includes('risk_level') && (
          <TableCell>
            <Select
              value={rowData.risk_level || 'Low'}
              onChange={(e) => handleFieldChange('risk_level', e.target.value)}
              defaultValue="Low"
              variant="outlined"
              size="small"
              displayEmpty
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </TableCell>
        )}
        {visibleColumns.includes('quality_checks') && (
          <TableCell>
            <Select
              value={rowData.quality_checks || 'passed'}
              onChange={(e) => handleFieldChange('quality_checks', e.target.value)}
              defaultValue="Passed"
              variant="outlined"
              size="small"
              displayEmpty
            >
              <MenuItem value="passed">Passed</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="failed">Failed</MenuItem>
            </Select>
          </TableCell>
        )}
        {visibleColumns.includes('compliance') && (
          <TableCell>
            <Select
              value={rowData.compliance || 'Yes'}
              defaultValue="Yes"

              onChange={(e) => handleFieldChange('compliance', e.target.value)}
              variant="outlined"
              size="small"
              displayEmpty
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </TableCell>
        )}
        {visibleColumns.includes('contingency_used') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.contingency_used ? rowData.contingency_used : ''}
              onChange={(e) =>
                handleFieldChange('contingency_used', e.target.value.replace(/[^0-9.-]+/g, ''))
              }
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: rowData.contingency_used ? '$' : null, // Display dollar sign if value exists
              }}
            />
          </TableCell>
        )}
        {visibleColumns.includes('notes') && (
          <TableCell>
            <TextField
              sx={{ minWidth: '100px' }}
              value={rowData.notes || ''}
              onChange={(e) => handleFieldChange('notes', e.target.value)}
              variant="outlined"
              size="small"
            />
          </TableCell>
        )}
      </TableRow>
    );
    return (
        <>
            {renderMainRow()}
            <ChangeOrder
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Change Orders"
                rowData={rowData}
             
            />
        </> );}