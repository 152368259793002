import MainDashboardLayout from 'src/layouts/dashboard/main-layout';

import AccountsDashboard from 'src/pages/dashboard/accounts-management';

import RiskView from 'src/components/accounts-management/risk-register/risk-view';
import RiskRegister from 'src/components/accounts-management/risk-register/risk-register';

import IncidentReporting from 'src/components/accounts-management/SideCards.js/incident-reporting/Incident-Reporting';
import IncidentView from 'src/components/accounts-management/SideCards.js/incident-reporting/incident-view';

import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';

import RiskChangeRequest from 'src/components/accounts-management/SideCards.js/risk-change-request/risk-change-request';
import RiskChangeView from 'src/components/accounts-management/SideCards.js/risk-change-request/risk-change-view';

import AccountsSideBarLayout from 'src/layouts/dashboard/accounts-SideBar-layout';

import AccountsPayable from 'src/components/accounts-management/SideCards.js/accounts-payable/risk-change-request';
import AccountsPayableView from 'src/components/accounts-management/SideCards.js/accounts-payable/risk-change-view';

import Expenses from 'src/components/accounts-management/SideCards.js/expenses/risk-change-request';
import ExpensesView from 'src/components/accounts-management/SideCards.js/expenses/risk-change-view';

import Payroll from 'src/components/accounts-management/SideCards.js/payroll/risk-change-request';
import PayrollView from 'src/components/accounts-management/SideCards.js/payroll/risk-change-view';

import Taxes from 'src/components/accounts-management/SideCards.js/taxes/risk-change-request';
import TaxesView from 'src/components/accounts-management/SideCards.js/taxes/risk-change-view';

import Inventory from 'src/components/accounts-management/SideCards.js/inventory/risk-change-request';
import InventoryView from 'src/components/accounts-management/SideCards.js/inventory/risk-change-view';

import GeneralLedger from 'src/components/accounts-management/SideCards.js/general-ledger/risk-change-request';
import GeneralLedgerView from 'src/components/accounts-management/SideCards.js/general-ledger/risk-change-view';

import BalanceSheet from 'src/components/accounts-management/SideCards.js/balance-sheet/risk-change-request';
import BalanceSheetView from 'src/components/accounts-management/SideCards.js/balance-sheet/risk-change-view';

import BankInformation from 'src/components/accounts-management/SideCards.js/bank-information/risk-change-request';
import BankInformationView from 'src/components/accounts-management/SideCards.js/bank-information/risk-change-view';

import FinanceStatement from 'src/components/accounts-management/SideCards.js/finance-statement/risk-change-request';
import FinanceStatementView from 'src/components/accounts-management/SideCards.js/finance-statement/risk-change-view';

import Employees from 'src/components/accounts-management/SideCards.js/employees/risk-change-request';
import EmployeesView from 'src/components/accounts-management/SideCards.js/employees/risk-change-view';

import Reports from 'src/components/accounts-management/SideCards.js/reports/risk-change-request';
import ReportsView from 'src/components/accounts-management/SideCards.js/reports/risk-change-view';

import Documents from 'src/components/accounts-management/SideCards.js/documents/risk-change-request';
import DocumentsView from 'src/components/accounts-management/SideCards.js/documents/risk-change-view';

import Calendar from 'src/components/accounts-management/SideCards.js/calendar/risk-change-request';
import CalendarView from 'src/components/accounts-management/SideCards.js/calendar/risk-change-view';

import AuditLogs from 'src/components/accounts-management/SideCards.js/audit-logs/risk-change-request';
import AuditLogsView from 'src/components/accounts-management/SideCards.js/audit-logs/risk-change-view';

import Settings from 'src/components/accounts-management/SideCards.js/settings/risk-change-request';
import SettingsView from 'src/components/accounts-management/SideCards.js/settings/risk-change-view';

const contentComponents = [
  {
    name: 'Risk Register',
    route: '/risk-register',
    component: RiskRegister,
    subRoutes: [{ name: 'View', route: '/view/:id', component: RiskView }],
  },
  {
    name: 'Incident Reporting',
    route: '/incident-reporting',
    component: IncidentReporting,
    subRoutes: [{ name: 'IncidentView', route: '/incidentView/:id', component: IncidentView }],
  },
  {
    name: 'Risk Change Request',
    route: '/risk-change-request',
    component: RiskChangeRequest,
    subRoutes: [{ name: 'RiskChangeView', route: '/view/:id', component: RiskChangeView }],
  },

  {
    name: 'Accounts Payable',
    route: '/accounts-payable',
    component: AccountsPayable,
    subRoutes: [{ name: 'Stake-holder', route: '/view/:id', component: AccountsPayableView }],
  },

  {
    name: 'Expenses',
    route: '/expenses',
    component: Expenses,
    subRoutes: [{ name: 'Expenses-View', route: '/view/:id', component: ExpensesView }],
  },

  {
    name: 'Payroll',
    route: '/payroll',
    component: Payroll,
    subRoutes: [{ name: 'PayrollView', route: '/view/:id', component: PayrollView }],
  },

  {
    name: 'Taxes',
    route: '/taxes',
    component: Taxes,
    subRoutes: [{ name: 'TaxesView', route: '/view/:id', component: TaxesView }],
  },

  {
    name: 'Inventory',
    route: '/inventory',
    component: Inventory,
    subRoutes: [{ name: 'InventoryView', route: '/view/:id', component: InventoryView }],
  },

  {
    name: 'General Ledger',
    route: '/general-ledger',
    component: GeneralLedger,
    subRoutes: [{ name: 'GeneralLedgerView', route: '/view/:id', component: GeneralLedgerView }],
  },

  {
    name: 'Balance Sheet',
    route: '/balance-sheet',
    component: BalanceSheet,
    subRoutes: [{ name: 'BalanceSheetView', route: '/view/:id', component: BalanceSheetView }],
  },

  {
    name: 'Bank Information',
    route: '/bank-information',
    component: BankInformation,
    subRoutes: [
      { name: 'BankInformationView', route: '/view/:id', component: BankInformationView },
    ],
  },

  {
    name: 'Finance Statement',
    route: '/finance-statement',
    component: FinanceStatement,
    subRoutes: [
      { name: 'FinanceStatementView', route: '/view/:id', component: FinanceStatementView },
    ],
  },

  {
    name: 'Employees',
    route: '/employees',
    component: Employees,
    subRoutes: [{ name: 'EmployeesView', route: '/view/:id', component: EmployeesView }],
  },

  {
    name: 'Reports',
    route: '/reports',
    component: Reports,
    subRoutes: [{ name: 'ReportsView', route: '/view/:id', component: ReportsView }],
  },

  {
    name: 'Documents',
    route: '/documents',
    component: Documents,
    subRoutes: [{ name: 'DocumentsView', route: '/view/:id', component: DocumentsView }],
  },

  {
    name: 'Calendar',
    route: '/calendar',
    component: Calendar,
    subRoutes: [{ name: 'CalendarView', route: '/view/:id', component: CalendarView }],
  },

  {
    name: 'Audit Logs',
    route: '/audit-logs',
    component: AuditLogs,
    subRoutes: [{ name: 'AuditLogsView', route: '/view/:id', component: AuditLogsView }],
  },

  {
    name: 'Settings',
    route: '/settings',
    component: Settings,
    subRoutes: [{ name: 'SettingsView', route: '/view/:id', component: SettingsView }],
  },
];

export const AccountRoutes = [
  {
    path: '/accounts-management',
    element: (
      <MainDashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainDashboardLayout>
    ),
    children: [
      { element: <AccountsDashboard />, index: true },
      ...contentComponents.flatMap(({ route, component: Component, subRoutes }) => {
        const mainRoute = {
          path: route.slice(1),
          element: (
            <AccountsSideBarLayout>
              <Component />
            </AccountsSideBarLayout>
          ),
        };

        const subRouteElements = subRoutes
          ? subRoutes.map(({ route: subRoute, component: SubComponent }) => ({
              path: `${route.slice(1)}${subRoute}`,
              element: (
                <AccountsSideBarLayout>
                  <SubComponent />
                </AccountsSideBarLayout>
              ),
            }))
          : [];
        return [mainRoute, ...subRouteElements];
      }),
    ],
  },
];
