import React from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import RenderField from '../../risk-register/render-field';
const PrioritizationTable = ({ data }) => {
    console.log("🚀 ~ PrioritizationTable ~ data:", data)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Card>
            <Box p={3}>
                <Typography className=" Prioritization-view-main-heading"> Prioritization Report</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                       Risk  Prioritization Details{' '}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label=" Risk ID" value={data?.identification_and_assessment?.risk_id} />
                <RenderField label=" Priority Level" value={data?.priority_level} />
                <RenderField label="Rationale for Prioritization " value={data?.rational_prioritization} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Review Date" value={data?.review_date} />
                <RenderField label="Remarks" value={data?.remarks} />
                <RenderField label=" Prioritized By" value={data?.prioritized_by} />
            </Box>
            <Divider />
          
        </Card>
    );
};

export default PrioritizationTable;
