import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';

import Iconify from 'src/components/iconify';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';

import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { handleErrorMessages } from '../../risk-register/feching';

const RiskEffectivenessSurveyCreate = ({ // Fixed component name
  open,
  onClose,
  reload,
  currentItem,
  surveuyId,
  handleCloseForm,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);
  const [isFetchingRisks, setIsFetchingRisks] = useState(true);

  // Fetch risk IDs on component mount
  useEffect(() => {
    const fetchIdentification = async () => {
      try {
        const data = await getRequest(
          endpoints.risk_management.risk_register.identification.identification
        );
        setRiskIds(data || []);
      } catch (err) {
        console.error('Error fetching risk IDs:', err);
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to fetch risk IDs.',
        });
      } finally {
        setIsFetchingRisks(false);
      }
    };
    fetchIdentification();
  }, [enqueueSnackbar]);

  // Find the matched risk based on currentItem
  const matchedRisk = useMemo(() => {
    if (currentItem && riskIds.length > 0) {
      return (
        riskIds.find(
          (risk) => risk.id === currentItem?.identification_and_assessment?.id
        ) || { id: '', risk_id: 'None' }
      );
    }
    return { id: '', risk_id: 'None' };
  }, [currentItem, riskIds]);

  // Validation schema
  const NewUserSchema = Yup.object().shape({
    survey_id: Yup.string().required('Survey ID is required'), // Changed message
    original_risk_id: Yup.object().shape({
      id: Yup.string().required('Original Risk ID is required'),
    }).required(),
    effectiveness_scale: Yup.string().required(
      'Effectiveness Scale is required'
    ),
    effectiveness_comment: Yup.string().required(
      'Comments on Effectiveness are required'
    ),
    suggestions: Yup.string(), // Made optional if not required
  });

  const defaultValues = useMemo(() => ({
    survey_id: currentItem?.survey_id || surveuyId || '',
    original_risk_id: matchedRisk || { id: '', risk_id: 'None' },
    effectiveness_scale: currentItem?.effectiveness_scale || '',
    effectiveness_comment: currentItem?.effectiveness_comment || '',
    suggestions: currentItem?.suggestions || '',
  }), [currentItem, surveuyId, matchedRisk]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  useEffect(() => {
    if (!isFetchingRisks) {
      reset(defaultValues);
    }
  }, [currentItem, defaultValues, reset, isFetchingRisks]);

  // Handle form submission
  const onSubmit = handleSubmit(async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data);
    setLoading(true);
    const cleansurveyId = data.survey_id.replace('-RMES', '');

    const formData = new FormData();
    formData.append('risk_id', data.original_risk_id.id);
    formData.append('survey_id', cleansurveyId);
    formData.append('effectiveness_scale', data.effectiveness_scale);
    formData.append('effectiveness_comment', data.effectiveness_comment);
    formData.append('suggestions', data.suggestions);
    try {
      const response = await postRequest(
        currentItem?.id
          ? `${endpoints.risk_management.mitigation_survey.crud}/${currentItem.id}?_method=PUT`
          : endpoints.risk_management.mitigation_survey.crud,
        formData,
        true
      );
      console.log("🚀 ~ onSubmit ~ response:", response)

      if (response.metadata && response.metadata.success) {
        reset();
        onClose();
        reload();
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
      } else {
        handleErrorMessages(response.message);
      }
    } catch (err) {
      console.error('Error in catch:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });

  // Handle dialog close with form reset
  const handleClose = () => {
    reset(defaultValues);
    handleCloseForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ padding: '60px', fontSize: '13px', fontFamily: 'Arial' }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider {...methods}> {/* Corrected FormProvider usage */}
        <form onSubmit={onSubmit}> {/* Added form element */}
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              className="cardColor"
              style={{
                padding: '5px',
                marginLeft: '-25px',
                color: 'white',
                fontWeight: '700',
              }}
            >
              Risk Mitigation Effectiveness Survey Form
            </span>
            <IconButton onClick={handleClose}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              display="grid"
              gap={2}
              gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }}
            >
              <RHFTextField
                name="survey_id"
                label="Survey ID"
                disabled
              // Removed value prop; RHF manages the value
              />
              <RHFAutocomplete
                name="original_risk_id"
                label="Original Risk ID"
                options={[{ id: '', risk_id: 'None' }, ...riskIds]}
                getOptionLabel={(option) => option.risk_id || 'None'}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                renderOption={(props, option) => (
                  <li {...props} key={option.id || 'none'}>
                    {option.risk_id}
                  </li>
                )}
                disabled={isFetchingRisks}
              />
              <RHFTextField
                type="number"
                name="effectiveness_scale"
                label="Effectiveness Scale"
                inputProps={{ min: 0, max: 5 }}
              />
              <RHFTextField name="effectiveness_comment" label="Comments on Effectiveness" />
              <RHFTextField name="suggestions" label="Suggestions for Improvement" />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" className='dark-button' loading={isSubmitting || loading}>
              {!currentItem || !currentItem.id ? 'Create Risk Mitigation Effectiveness Survey' : 'Update  Risk Mitigation Effectiveness Survey'}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default RiskEffectivenessSurveyCreate; // Updated export with corrected name
