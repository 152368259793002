import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar';
import DropDownAddValue from 'src/components/drop-down-add-value/drop-down-add-value';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { getDropdownsId, handleErrorMessages } from './feching';
import Iconify from 'src/components/iconify';

const CreateAssessment = ({
  open,
  onClose,
  threatOpportunity,
  reloadThreatOpportunity,
  riskArea,
  reloadRiskArea,
  currentItem,
  reload,
  data,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    qualitative_analysis: Yup.string().nullable().required('Qualitative Analysis is required'),
    threat_opportunity: Yup.string().nullable().required('Threat or Opportunity is required'),
    risk_area: Yup.string().required('Risk Area is required'),
  });

  const defaultValues = useMemo(() => ({
    qualitative_analysis: data?.qualitative_analysis || '',
    threat_opportunity: data?.threat_opportunity?.name || '',
    risk_area: data?.risk_area?.name || '',
    probability_of_occurrence: data?.probability_of_occurrence || 0,
    impact_on_cost: data?.impact_on_cost || 0,
    impact_on_schedule: data?.impact_on_schedule || 0,
    risk_rating: data?.risk_rating || 0,
    expected_loss: data?.expected_loss || 0,
    full_probable_loss: data?.full_probable_loss || 0,
  }), [data]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const calculateRiskRating = (probabilityOfOccurrence, impactOnCost, impactOnSchedule) => {
    const sum =  impactOnCost + impactOnSchedule;
    const multiplied = probabilityOfOccurrence * (sum);
    const finalRiskRating = multiplied / 2;

    return {
      sum,
      multiplied,
      finalRiskRating: finalRiskRating.toFixed(2), 
    };
  };

  const handleInputChange = (name, value) => {
    const numericValue = value === '' ? 0 : Math.max(0, Math.min(10, parseFloat(value)));

    setValue(name, numericValue);

    const probabilityOfOccurrence = parseFloat(methods.getValues('probability_of_occurrence')) || 0;
    const impactOnCost = parseFloat(methods.getValues('impact_on_cost')) || 0;
    const impactOnSchedule = parseFloat(methods.getValues('impact_on_schedule')) || 0;

    // Update the risk rating only if all inputs are valid numbers
    const { sum, multiplied, finalRiskRating } = calculateRiskRating(
      probabilityOfOccurrence,
      impactOnCost,
      impactOnSchedule
    );

    console.log(`ratiing>>> : Sum = ${sum}, Multiplied = ${multiplied}, Final Risk Rating = ${finalRiskRating}`);
    setValue('risk_rating', finalRiskRating);
  };


  const onSubmit = handleSubmit(async (data) => {
    const formData = new FormData();
    formData.append(
      'threat_opportunity_id',
      getDropdownsId(threatOpportunity, data.threat_opportunity)
        ? getDropdownsId(threatOpportunity, data.threat_opportunity)
        : null
    );
    formData.append('risk_area_id', getDropdownsId(riskArea, data.risk_area));
    formData.append('qualitative_analysis', data.qualitative_analysis);
    formData.append('probability_of_occurrence', data.probability_of_occurrence);
    formData.append('impact_on_cost', data.impact_on_cost);
    formData.append('impact_on_schedule', data.impact_on_schedule);
    formData.append('risk_rating', data.risk_rating);
    formData.append('expected_loss', data.expected_loss);
    formData.append('full_probable_loss', data.full_probable_loss);
    setLoading(true);
    try {
      const response = await postRequest(
        endpoints.risk_management.risk_register.identification.risk_assessments(id),
        formData,
        true
      );
      setLoading(false);
      if (response.metadata) {
        if (response.metadata.success) {
          reset();
          onClose();
          enqueueSnackbar({
            variant: 'success',
            message: response.metadata.message,
          });
          reload();
        } else {
          handleErrorMessages(response.metadata.message);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log('Error in catch:', err);
    }
  });

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            boxSizing: 'content-box',
          }}
        >
          <span
          className='cardColor'
            style={{
              padding: '7px',
              marginLeft: '-25px',
              color: 'white',
              fontWeight: '700',
            }}
          >
            &nbsp;&nbsp; Risk Assessment &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ borderRadius: '1px' }}>
          <Typography mb={2} sx={{ fontWeight: 'bold' }}>
            Qualitative Assessment
          </Typography>
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
          >
            <RHFTextField
              name="qualitative_analysis"
              label={
                <>
                  Qualitative Analysis
                  {methods.getValues('qualitative_analysis') ? '' : (
                    <span style={{ color: 'red' }}> *</span>
                  )}
                </>
              }
            />
            <DropDownAddValue
              name="threat_opportunity"
              label="Threat or Opportunity*"
              options={threatOpportunity}
              reload={() => reloadThreatOpportunity()}
              url={endpoints.risk_management.risk_register.identification.threat_opportunity}
            />
            <DropDownAddValue
              name="risk_area"
              label="Risk Area*"
              options={riskArea}
              reload={() => reloadRiskArea()}
              url={endpoints.risk_management.risk_register.identification.risk_area}
            />
          </Box>
          <Typography mt={3} mb={2} sx={{ fontWeight: 'bold' }}>
            Quantitative Assessment
          </Typography>
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
          >
            <RHFTextField
              name="probability_of_occurrence"
              label="Probability of Occurrence '( 0 - 10)'"
              type="number"
              onChange={(e) => handleInputChange('probability_of_occurrence', e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 10} }} // Allows decimals
            />
            <RHFTextField
              name="impact_on_cost"
              label="Impact on Cost '( 0 - 10)'"
              type="number"
              onChange={(e) => handleInputChange('impact_on_cost', e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 10 } }} // Allows decimals
            />
            <RHFTextField
              name="impact_on_schedule"
              label="Impact on Schedule '( 0 - 10)'"
              type="number"
              onChange={(e) => handleInputChange('impact_on_schedule', e.target.value)}
              InputProps={{ inputProps: { min: 0, max: 10 } }} // Allows decimals
            />
            <RHFTextField
              name="risk_rating"
              label="Risk Rating"
              type="number"
              disabled
              InputProps={{ inputProps: { min: 0 } }}
            />
            <RHFTextField
              name="expected_loss"
              label="Expected Loss (Currency)"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
            <RHFTextField
              name="full_probable_loss"
              label="Full Probable Loss (Currency)"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={ handleClose }>
            Cancel
          </Button>

          <LoadingButton type="submit" variant="contained"  className='dark-button' loading={isSubmitting}>
            {Object.keys(data || {}).length === 0 ? 'Create Assessment' : 'Update Assessment'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default CreateAssessment;