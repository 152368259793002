import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Montserrat',
    fontSize: 4, // Smaller font size for compactness
    color: '#333',
    backgroundColor: '#f9f9f9',
    width: '297mm', // A4 width in landscape
    height: '210mm', // A4 height in landscape
    padding: 5,
  },
  logoContainer: {
    position: 'absolute',
    top: 20,
    left: 170,
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 50, 
    
    height: 50,
    flexShrink: 1,
  },
  companyDetails: {
    marginLeft: 10,
    color: '#ffffff',
    flexDirection: 'column',
  },
  companyName: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    lineHeight: 1.2,
  },
  companyAddress: {
    fontSize: 8,
    marginTop: 2,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#8DD06C',
    color: '#000',
  },

  headermain: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },

  headerImage: {
    width: '100%',
    marginBottom: 10,
    padding: 10,
  },

  footerImage: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    padding: 10,
  },

  footerPageNumber: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    fontSize: 6,
    fontWeight: 'bold',
    color: '#fff',
  },

  date: {
    fontSize: 4,
    fontWeight: 'bold',
    color: '#000',
    paddingTop: 2,
  },

  headings: {
    fontWeight: 'bold',
    fontSize: 12,
    marginBottom: 4,
  },

  tableContainer: {
    width: '100%',
    marginTop: 10,
  },

  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#8DD06C',
    color: '#000',
    width: '100%',
  },

  tableHeaderCell: {
    height: '40px',
    width: '6.66%',
    padding: 3,
  },
  headerTextLine: {
    fontWeight: 'bold',
    fontSize: 6,
    textAlign: 'center',
    padding: 1,
    wordWrap: 'break-word',
  },

  namecontarct: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #d1d8e0',
  },
  tableRow2: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 12,
  },

  tableCell: {
    padding: 4,
    textAlign: 'center',
    fontSize: 6,
    borderLeft: '1px solid #A3A3A3',
    borderBottom: '1px solid #A3A3A3',
    width: '6.66%',
    color: '#000',
  },

  tableCellbold: {
    padding: 4,
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 6,
    borderLeft: '1px solid #A3A3A3',
    borderBottom: '1px solid #A3A3A3',
    width: '6.66%',
  },

  tableSubtotalRow: {
    backgroundColor: '#C7C7C7', // Light Gray
  },
  tableRowEven: {
    backgroundColor: '#EEEEEE', // Light Gray
  },

  tableRowOdd: {
    backgroundColor: '#E1E1E1', // Slightly darker Gray
  },

  tableRowEven: {
    backgroundColor: '#EEEEEE',
  },

  tableRowOdd: {
    backgroundColor: '#E1E1E1',
  },

  downloadLink: {
    textDecoration: 'none',
    color: '#3498db',
    backgroundColor: '#ecf0f1',
    borderRadius: 5,
    border: '1px solid #bdc3c7',
    cursor: 'pointer',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default styles;
