// src/components/content/RiskRegister.js
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import {Select, MenuItem, Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import RiskChangeRequestCreate from './risk-chnage-create';
import { LoadingButton } from '@mui/lab';

import { Typography } from '@mui/material';


import CalendarPage from 'src/pages/dashboard/calendar';

const Calendar = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [areIconsVisible, setAreIconsVisible] = useState(false);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Customer', label: 'Customer' },
    { id: 'Project Name', label: 'Project Name' },
    { id: 'Invoice ID', label: 'Invoice ID' },
    { id: 'Billing Date', label: 'Billing Date' },
    { id: 'Description', label: 'Description' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Balance Due', label: 'Balance Due' },
    { id: 'Notes/Remarks', label: 'Notes/Remarks' },
    { id: 'Status', label: 'Status' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ RiskChangeRequest ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );



  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getRiskChangeRequest();
  }, []);

  const getRiskChangeRequest = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_change_request.crud);
    console.log("🚀 ~ getRiskChangeRequest ~ data:", data)
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getRiskChangeRequest()
  }

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(`${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  }


  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_change_request.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getRiskChangeRequest();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };



  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>
          

            <Header name="CALENDAR" />
            



            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "space-between",
                gap:"12px",
                
              }}
            >

                   
             <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
                gap:"12px"
              }}
            >
             



               </Box>

            </Box>



              <Card sx={{
                boxShadow: '0px 0px 30px 0px #64748B1A',
              }}>    
             <CalendarPage/>
            </Card>
          </>
        )}
      </CardContent>
    </Card >
   
   

   
    

  </>;
};

export default Calendar;
