// src/components/content/RiskRegister.js
import { Box, Button, Card, CardContent, Checkbox, IconButton, MenuItem, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import {
	emptyRows, getComparator, TableEmptyRows, TableHeadCustom,
	TableNoData, TablePaginationCustom, TableSelectedAction, useTable
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../header';
import { formatDate } from 'src/utils/format-time';
import { TABLE_HEAD } from './table-head';

const ComprehensiveRiskInventory = () => {
	const remove = useBoolean();
	const popover = usePopover();
	const csvLinkRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [actionVisible, setActionVisible] = useState(true);
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		getIdentification();
	}, []);
	const getIdentification = async () => {
		setLoading(true);
		const data = await getRequest(endpoints.risk_management.risk_register.identification.identification);
		setLoading(false);
		if (data) {
			setTableData(data);
		}
	};
	const table = useTable();
	const confirm = useBoolean();
	const [filters, setFilters] = useState({
		name: '',
		role: [],
		status: 'all',
	});
	const dataFiltered = applyFilter({
		inputData: tableData,
		comparator: getComparator(table.order, table.orderBy),
		filters,
	});
	const dataInPage = dataFiltered.slice(
		table.page * table.rowsPerPage,
		table.page * table.rowsPerPage + table.rowsPerPage
	);

	const denseHeight = table.dense ? 52 : 72;
	const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
	const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
	const handleDeleteRows = useCallback(() => {
		const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
		setTableData(deleteRows);
		table.onUpdatePageDeleteRows({
			totalRows: tableData.length,
			totalRowsInPage: dataInPage.length,
			totalRowsFiltered: dataFiltered.length,
		});
	}, [dataFiltered.length, dataInPage.length, table, tableData]);

	const handleExport = () => {
		if (csvLinkRef.current) {
			csvLinkRef.current.link.click();
		}
	};
	const getCsvHeaders = () => {
		return TABLE_HEAD.map(head => ({
			label: head.label,
			key: head.id,
		}));
	};

	const getCsvData = (data) => {
		return data.map((row) => {
			console.log("🚀 ~ returndata.map ~ data:", data)
			return {
				'Risk ID': row.risk_id,
				'Description': row.description,
				'Category': row.risk_category?.name || '',
				'Date Identified': formatDate(row.date_identified),
				'Last Reviewed': formatDate(row.monitor_and_track?.last_review_date),
				'Status': row.mitigation_plan?.status || '',
			};
		});
	};
	return <>
		<Card
			sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
		>
			{loading ? (
				<Box height={700}>
					<LoadingScreen />
				</Box>
			) : (
				<>
					<CardContent>
						<Header name="Comprehensive Risk Inventory Table" />
						<Box
							sx={{
								display: "flex",
								paddingBottom: "20px",
								justifyContent: "flex-end",
								gap: "16px",
							}}
						>
							<Button
								onClick={popover.onOpen}
								className="dark-button"
								variant="contained"
								startIcon={<Iconify icon="solar:export-bold" />}
							>
								Export
							</Button>
							<CustomPopover
								open={popover.open}
								onClose={popover.onClose}
								arrow="right-top"
								sx={{ width: 140 }}
							>
								<MenuItem
									onClick={() => {
										handleExport();
										popover.onClose();
									}}
								>
									Download as CSV
								</MenuItem>
							</CustomPopover>
						</Box>
						<Card sx={{
							boxShadow: '0px 0px 30px 0px #64748B1A',
						}}>
							<TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
								{actionVisible && (
									<div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
										<TableSelectedAction
											dense={table.dense}
											numSelected={table.selected.length}
											rowCount={tableData.length}
											onSelectAllRows={(checked) =>
												table.onSelectAllRows(
													setActionVisible(checked),
													checked,
													tableData.map((row) => row.id)
												)
											}
											action={
												<Tooltip title="Delete">
													<IconButton color="primary" onClick={remove.onTrue}>
														<Iconify icon="solar:trash-bin-trash-bold" />
													</IconButton>
												</Tooltip>
											}
										/>
									</div>
								)}
								<Scrollbar >
									<Table size={table.dense ? 'small' : 'large'}
										className="tableehead"
									>
										<TableHeadCustom
											order={table.order}
											orderBy={table.orderBy}
											headLabel={TABLE_HEAD}
											rowCount={tableData.length}
											numSelected={table.selected.length}
											onSort={table.onSort}
											onSelectAllRows={(checked) =>
												table.onSelectAllRows(
													checked,
													tableData.map((row) => row.id)
												)
											}
										/>
										<TableBody>
											{dataInPage.map((row, index) => (
												<TableRow key={row.id}>
													<TableCell padding="checkbox">
														<Checkbox
															checked={table.selected.includes(row.id)}
															onChange={() => table.onSelectRow(row.id)}
														/>
													</TableCell>
													<TableCell align="left">{row.risk_id}</TableCell>
													<TableCell align="left">{row.description}</TableCell>
													<TableCell align="left">{row.risk_category?.name}</TableCell>
													<TableCell align="left">{formatDate(row.date_identified)}</TableCell>
													<TableCell align="left">{formatDate(row.monitor_and_track?.last_review_date)}</TableCell>
													<TableCell align="left">{row?.mitigation_plan?.status}</TableCell>
												</TableRow>
											))}
											<TableEmptyRows
												height={denseHeight}
												emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
											/>
											<TableNoData notFound={notFound} />
										</TableBody>
									</Table>
								</Scrollbar>
							</TableContainer>
							<TablePaginationCustom
								count={dataFiltered.length}
								page={table.page}
								rowsPerPage={table.rowsPerPage}
								onPageChange={table.onChangePage}
								onRowsPerPageChange={table.onChangeRowsPerPage}
								dense={table.dense}
								onChangeDense={table.onChangeDense}
							/>
							<CSVLink
								ref={csvLinkRef}
								filename="Comprehensive_risk_inventory.csv"
								headers={getCsvHeaders()}
								data={getCsvData(tableData)}
								style={{ display: 'none' }}
							/>
						</Card>
					</CardContent>
				</>
			)}
		</Card>
		<ConfirmDialog
			open={confirm.value}
			onClose={confirm.onFalse}
			title="Delete"
			content={
				<>
					Are you sure want to delete items?
				</>
			}
			action={
				<Button
					variant="contained"
					className='dark-button'
					onClick={() => {
						handleDeleteRows();
						confirm.onFalse();
					}}
				>
					Delete
				</Button>
			}
		/>
	</>;
};
export default ComprehensiveRiskInventory;