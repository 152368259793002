/* eslint-disable no-lone-blocks */
import axios from 'axios';
import { apiPostHeader, multipartPartFormDataHeader, simpleHeader } from '../utils/axios';
import { baseURL } from '../config-global';
import { handleErrorMessages } from 'src/components/risk-management/risk-register/feching';
import { enqueueSnackbar } from "notistack";

export const postRequest=async (endPoint,data,isMultiPart)=>{
  {
    try{
      const response = await axios.post(`${baseURL}${endPoint}`,data,{
        headers:isMultiPart ? multipartPartFormDataHeader : apiPostHeader
      });
      return response.data;
    }
    catch(error){
      return error.response?.data

    }
  }
}

export const getRequest=async (endPoint,isArray=true)=>{
  {
    try{
      const response = await axios.get(`${baseURL}${endPoint}`,{
        headers:simpleHeader
      });
      return response?.data?.payload || null;
    }
    catch(error){
      return isArray ? [] : {}
    }
  }
}
export const deleteRequest=async (endPoint,callBack)=>{
  {
    try{
      const response = await axios.delete(`${baseURL}${endPoint}`,{
        headers:simpleHeader
      });
      if(response?.data){
        const myMetaData=response?.data?.metadata
        if(myMetaData.success){
          callBack()
          enqueueSnackbar({
            variant: 'success',
            message: myMetaData.message,
          });
        }else {
          handleErrorMessages(myMetaData.message);
        }
      }
      return response?.data
    }
    catch(error){
      enqueueSnackbar({
        variant: 'error',
        message: 'Oops, something went wrong',
      });
      return error
    }
  }
}
