import React, { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import { Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import RiskStackhodlerFeedbackCreate from './create';

const StakeholderFeedback = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [error, setError] = useState(null); // Add error state for error handling
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [actionVisible, setActionVisible] = useState(true);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.No' },
    { id: 'Feedback ID', label: 'Feedback ID' },
    { id: 'Company Name', label: 'Company Name' },
    { id: 'Company E-mail', label: 'Company E-mail' },
    { id: 'Related Risk ID', label: 'Related Risk ID' },
    { id: 'Feedback', label: 'Feedback' },
    { id: 'Suggestions for Improvement', label: 'Suggestions for Improvement' },
    { id: 'Action', label: 'Action' },
  ];

  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getStackHolder();
  }, []);

  const getStackHolder = async () => {
    setLoading(true);
    setError(null); // Reset error before fetching
    try {
      const payload = await getRequest(endpoints.risk_management.risk_stackholder_feedback.crud);
      if (payload) {
        setTableData(payload?.data);
      }
    } catch (error) {
      console.error("Error fetching stakeholder feedback", error);
      setError("Failed to load stakeholder feedback. Please try again."); // Set error state
    } finally {
      setLoading(false);
    }
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getStackHolder();
  };

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };


  const handleDeleteRows = async (id) => {
    await deleteRequest(`${endpoints.risk_management.risk_stackholder_feedback.crud}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      // Move to the previous page if the current page becomes empty
      table.onChangePage(null, table.page - 1);
    } else {
      // Otherwise, update the current page if no pagination change is required
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  };

  return (
    <>
      <Card
        sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
      >        <CardContent>
          {loading ? (
            <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <LoadingScreen />
            </Box>
          ) : error ? (
            <Box sx={{ p: 3 }}>
              <Typography variant="h6" align="center" color="error">
                {error}
              </Typography>
            </Box>
          ) : (
            <>
              <Header name="Stakeholder Feedback" />
              <Box sx={{ display: 'flex', paddingBottom: '20px', justifyContent: 'flex-end' }}>
                <Button
                  onClick={() => {
                    setSelectedItem({});
                    create.onTrue();
                  }}
                  className="dark-button"
                  variant="contained"
                  startIcon={<Iconify icon="mingcute:add-line" />}
                >
                  Stakeholder Feedback
                </Button>
              </Box>

              <Card sx={{
                boxShadow: '0px 0px 30px 0px #64748B1A',
              }}>
                <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                      <div style={{ marginBottom: table.selected.length > 0 ? '2.7rem' : '0' }}>
                        <TableSelectedAction
                          dense={table.dense}
                          numSelected={table.selected.length}
                          rowCount={tableData.length}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(
                              setActionVisible(checked),
                              checked,
                              tableData.map((row) => row.id)
                            )
                          }
                          action={
                            <Tooltip title="Delete"  >
                              <IconButton color="primary" onClick={confirm.onTrue}>
                                <Iconify icon="solar:trash-bin-trash-bold" />
                              </IconButton>
                            </Tooltip>
                          }
                        />
                      </div>
                  {/* <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(checked, tableData.map((row) => row.id))
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  /> */}
                  <Scrollbar>
                    {notFound ? (
                      <Box sx={{ p: 3 }}>
                        <Typography variant="h6" align="center">
                          No feedback data available.
                        </Typography>
                      </Box>
                    ) : (
                      <Table size={table.dense ? 'small' : 'large'} className="tableehead">
                        <TableHeadCustom
                          order={table.order}
                          orderBy={table.orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={tableData.length}
                          numSelected={table.selected.length}
                          onSort={table.onSort}
                          onSelectAllRows={(checked) =>
                            table.onSelectAllRows(checked, tableData.map((row) => row.id))
                          }
                        />
                        <TableBody>
                          {dataInPage.map((row, index) => (
                            <TableRow key={row.id}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={table.selected.includes(row.id)}
                                  onChange={() => table.onSelectRow(row.id)}
                                />
                              </TableCell>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{row.feedback_id}</TableCell>
                              <TableCell align="left">{row.company_name}</TableCell>
                              <TableCell align="left">{row.company_email}</TableCell>
                              <TableCell align="left">{row.identification_and_assessment?.risk_id}</TableCell>
                              <TableCell align="left">{row.feedback}</TableCell>
                              <TableCell align="left">{row.suggestion}</TableCell>
                              <TableCell align="left" sx={{ display: 'flex' }}>

                                <IconButton onClick={handleToggleIcons}>
                                  <Iconify icon="eva:more-vertical-fill" />
                                </IconButton>
                                {areIconsVisible && (
                                  <>
                                    <Tooltip title="Delete">
                                      <IconButton onClick={() => { setSelectedItem(row); confirm.onTrue(); }}>
                                        <Iconify icon="solar:trash-bin-trash-bold" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Edit">
                                      <IconButton onClick={() => { setSelectedItem(row); create.onTrue(); }}>
                                        <Iconify icon="solar:pen-bold" />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="View" onClick={() => navigate(`/risk-management/stakeholder-feedback/view/${row.id}`)}>
                                      <IconButton >
                                        <Iconify icon="solar:eye-bold" />
                                      </IconButton>
                                    </Tooltip>
                                  </>
                                )}

                              </TableCell>
                            </TableRow>
                          ))}
                          <TableEmptyRows
                            height={denseHeight}
                            emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                          />
                          <TableNoData notFound={notFound} />
                        </TableBody>
                      </Table>
                    )}
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
        </CardContent>
      </Card>

      <RiskStackhodlerFeedbackCreate
        open={create.value}
        onClose={create.onFalse}
        reload={getStackHolder}
        currentItem={selectedItem}
        closeform={() => handleCloseForm()}

      />

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content="Are you sure you want to delete the selected item?"
        action={
          <Button
            variant="contained"
            className='dark-button'
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
};

export default StakeholderFeedback;
