import React, { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { Box, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import './style.css';
import RenderField from '../risk-register/render-field';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useBoolean } from 'src/hooks/use-boolean';
import CreateAnalysis from './risk-analysis-create';
import { getSeverityLabel } from '../risk-register/Severity-rating-function/rating-coloring';
import "../risk-register/Severity-rating-function/RiskAnalysis.css"
const RiskAnalysis = ({ data, reload }) => {
  console.log("🚀 ~ RiskAnalysis ~ data:", data)
  const { id } = useParams()
  const add = useBoolean();

  const [riskAnalysis, setriskAnalysis] = useState([])
  const [getLoading, setGetLoading] = useState(false);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    getRiskAnalysis()
  }, []);

  const getRiskAnalysis = async () => {
    setGetLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_register.identification.risk_analyses(id));
    setGetLoading(false);
    if (data) {
      console.log(data)
      setriskAnalysis(data);
    }
  };
  const severityInfo = getSeverityLabel(data?.severity_rating);
  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h4" class="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
            Risk Analysis
          </Typography>
        </Box>
        <Box
          sx={{ marginRight: '20px', marginTop: '22px' }}
        >
          <Tooltip title="Edit">
            <IconButton onClick={add.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        p={4}
        mt={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >

        <RenderField label="Severity Rating" value={severityInfo.label} className={severityInfo.className} />
        <RenderField label="Impact Level Pre-Mitigation" value={data?.impact_level_pre_mitigation} />
        <RenderField label="Likelihood Pre-Mitigation" value={data?.likelihood_pre_mitigation} />
      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Severity Pre-Mitigation
Updated"
          value={data?.severity_pre_mitigation} />
        <RenderField label="Priority" value={data?.priority} />
        <RenderField label="Severity Post-Mitigation" value={data?.severity_post_mitigation} />
      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Likelihood Post-Mitigation
" value={data?.likelihood_post_mitigation} />
        <RenderField label="Related Task IDs" value={data?.related_task_ids} />
        <RenderField label="Affected Areas" value={data?.affected_areas} />
      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Risk Triggers" value={data?.risk_triggers} />
      </Box>
      <CreateAnalysis
        data={data}
        open={add.value}
        onClose={add.onFalse}
        reload={reload}
      />
    </Card>
  );
};
export default RiskAnalysis;