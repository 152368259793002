import { Grid } from '@mui/material';
import React from 'react';
import CounterCard from '../SideCards.js/countercards';

const Countercards = ({ data }) => {
	console.log("🚀 ~ Countercards ~ data:", data);
	const arr = [
		{
			name: "Total Risk",
			counter: data?.risk_identification_counts || 0,
			colors: ['#FF5733', '#FF8F33', '#FFC133'], 
		},
		{
			name: "Overdue",
			counter: data?.over_due_risk_identification_counts || 0,
			colors: ['#33FF57', '#33FFA6', '#A6FF33'], 
		},
		{
			name: "Under Mitigated",
			counter: data?.under_mititigated_counts || 0,
			colors: ['#3357FF', '#33A6FF', '#5733FF'], 
		},
	];
	return (
		<Grid container spacing={3} marginTop={3}>
			{arr.map((item, index) => (
				<Grid item xs={12} sm={6} md={4} key={index}>
					<CounterCard
						title={item.name}
						total={item.counter}
						chart={{
							series: [25, 18, 12, 51, 68, 11, 39, 37, 27, 20],
							colors: item.colors, 
						}}
					/>
				</Grid>
			))}
		</Grid>
	);
};
export default Countercards;