import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import TablePagination from '@mui/material/TablePagination';

// ----------------------------------------------------------------------

export default function TablePaginationCustoms({
    dense,
    onChangeDense,
    rowsPerPageOptions = [0],
    sx,
    ...other
}) {
    return (
        <Box sx={{ position: 'relative', ...sx }}>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                {...other}
                sx={{
                    borderTopColor: 'transparent',
                }}
            />

            {onChangeDense && (
                <FormControlLabel
                    label="Dense"
                    control={<Switch checked={dense} onChange={onChangeDense} />}
                    sx={{
                        pl: 2,
                        py: 1.5,
                        top: 0,
                        position: {
                            sm: 'absolute',
                        },
                    }}
                />
            )}
        </Box>
    );
}

TablePaginationCustoms.propTypes = {
    dense: PropTypes.bool,
    onChangeDense: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    sx: PropTypes.object,
};
