import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useBoolean } from 'src/hooks/use-boolean';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import {
    emptyRows,
    getComparator,
    TableEmptyRows, TableNoData,
    TablePaginationCustom,
    TableSelectedAction,
    useTable
} from 'src/components/table';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import UserTableFiltersResult from 'src/sections/user/user-table-filters-result';
import { TABLE_HEAD } from './table-head-costing';
import { useTheme } from '@emotion/react';
import { getRequest, postRequest } from 'src/services';
import { apiHeader, endpoints } from 'src/utils/axios';
import JobcostingTableRow from './job-costing-table-row';
import JobcostingChangeorderTableRow from './job-costing-change-order-rows';
import { useSnackbar } from 'src/components/snackbar';
import SettingModal from './settings-jobcosting-modal';
import { baseURLORG } from 'src/config-global';
import { LoadingScreen } from 'src/components/loading-screen';
import JobCostingSummaryPage from './Subtotoal-job-costing';
import JobCostingChangeOrderSubTotal from './subtotoal-changeOrderMain';
import { head } from 'lodash';

// ----------------------------------------------------------------------
const defaultFilters = {
    name: '',
    role: [],
    status: 'all',
};
// ----------------------------------------------------------------------
export default function JobCostingList() {
    const table = useTable();
    const table2 = useTable();
    const router = useRouter();
    const confirm = useBoolean();
    const add = useBoolean();
    const openFilters = useBoolean();
    const navigate = useNavigate()
    const popover = usePopover();

    const [tableData, setTableData] = useState([]);
    console.log("🚀 ~ JobCostingList ~ tableData:", tableData)
    const [tableData2, setTableData2] = useState([]);
    const [loading, setLoading] = useState();
    const [droppedRowId, setDroppedRowId] = useState(null);
    const [jobCostinglabels, setjobCostinglabels] = useState([]);
    const [projectID, setsetprojectID] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [droppedRow, setDroppedRow] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState([]); 

    const { enqueueSnackbar } = useSnackbar();

    const [filteredData, setFilteredData] = useState(tableData);
    const [filterss, setFilterss] = useState({
        facility: '',
        level: '',
        milestone: '',
        category: '',
    });
    console.log("🚀 ~ JobCostingList ~ filterss:", filterss)
    
  useEffect(() => {
    let data = [...tableData];
    const applicableFilters = Object.keys(filterss).filter((key) =>
      visibleColumns.includes(key)
    );
    applicableFilters.forEach((key) => {
      if (filterss[key]) {
        data = data.filter((row) => {
          if (key === 'category') {
            // Handle category filtering when cost_code_category is an object
            const categoryValue = row.cost_code_category
              ? `${row.cost_code_category.code ?? ''} ${row.cost_code_category.description ?? ''}`
              : '';
            return categoryValue.toLowerCase().includes(filterss[key].toLowerCase());
          } else {
            // Apply regular filtering for other columns
            return row[key]?.toString().toLowerCase().includes(filterss[key].toLowerCase());
          }
        });
      }
    });

    setFilteredData(data);
  }, [filterss, tableData, visibleColumns]);



    const handleFilterChange = (key, value) => {
        setFilterss((prev) => ({ ...prev, [key]: value }));
    };



    useEffect(() => {
        getJobCostigList()
    }, []);
    const getJobCostigList = async () => {
        try {
          const data = await getRequest(endpoints.job_costing.get_list, {
            headers: {
              apiHeader,
            },
          });
          const changeOrderTable = data.filter(
            (item) => item.is_change_order === 1 && item.is_order_attached === 0
          );
          const baseChangetable = data
            .filter(
              (item) =>
                !(item.is_change_order === 1 && item.is_order_attached === 1) &&
                !changeOrderTable.some((changeItem) => changeItem.id === item.id)
            )
            .map((item) => ({ ...item, hidden: false }));
          setTableData(baseChangetable);
          setTableData2(changeOrderTable);

          if (data.length > 0) {
            setsetprojectID(data[0]?.project_id || '');
          }
        //   console.log(
        //     '🚀 ~ baseChangetable, changeOrderTable ><><><><><><>:',
        //     baseChangetable,
        //     changeOrderTable
        //   );
        } catch (error) {
            console.error("Error fetching job costing list:", error);
        }
    };
    const handleDragStart = (event, rowData) => {
        setIsDragging(true);
        event.dataTransfer.setData('application/json', JSON.stringify(rowData));
        event.dataTransfer.effectAllowed = 'move';
        window.scrollTo({
            top: 300,
            behavior: 'smooth',
        });
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };
    const handleDrop = (event, targetRow) => {
        event.preventDefault();

        const data = event.dataTransfer.getData('application/json');
        let droppedData;

        try {
            droppedData = JSON.parse(data);
        } catch (error) {
            console.error('Failed to parse dropped data:', error);
            return;
        }
        if (!droppedData || !targetRow || typeof droppedData !== 'object' || typeof targetRow !== 'object') {
            console.error('Dropped data or target row is invalid');
            return;
        }
        setDroppedRow({ dropped: droppedData, target: targetRow });
        setShowModal(true);
    };
    const handleConfirmDrop = async () => {
        if (!droppedRow || !droppedRow.dropped || !droppedRow.target) {
            console.error('Dropped row or target row is missing:', droppedRow);
            return;
        }
        const { dropped, target } = droppedRow;

        try {
            await apiCall({
                url: `${endpoints.job_costing.update}/${target.id}`,
                data: {
                    order_id: dropped.change_order_id,
                    budgeted_amount: dropped.budgeted_amount,
                    planned_subcontractor_cost: dropped.planned_subcontractor_cost,
                },
                successMessage: "Change Order Added to Base Contractor successfully",
                errorMessage: "Failed to Change Order to Base Contractor values",
            });
            getJobCostigList()
            setTableData((prevData) =>
                prevData.map((row) =>
                    row.id === target.id
                        ? { ...row, someUpdatedField: dropped.someValue } // Adjust as per actual fields
                        : row
                )
            );
            setTableData2((prevData2) =>
                prevData2.filter((row) => row.id !== dropped.id)
            );

            // Clear droppedRow and close modal
            setDroppedRow(null);
            setShowModal(false);
            getJobCostigList()
        } catch (error) {
            console.error('API call failed:', error);
            enqueueSnackbar('Failed to merge values!', { variant: 'error' });
        }
    };

  const handleCancelDrop = () => {
    if (!droppedRow) {
      console.error('Dropped row is missing:', droppedRow);
      return;
    }
    setDroppedRow(null);
    setShowModal(false);
  };


    const apiCall = async ({ url, data, successMessage, errorMessage }) => {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => formData.append(key, value));
        try {
            const response = await postRequest(url, formData, true);
            if (response.metadata && response.metadata.success) {
                console.log("is this working", response);
                enqueueSnackbar(successMessage, { variant: 'success' });

            } else {
                throw new Error(response.metadata.message || errorMessage);
            }
        } catch (error) {
            console.error(error);
            throw new Error(errorMessage);
        }
    };
    const theme = useTheme();
    const [filters, setFilters] = useState(defaultFilters);

    const dataFiltered = applyFilter({
        inputData: tableData,
        comparator: getComparator(table.order, table.orderBy),
        filters,
    });
    const dataInPage = dataFiltered.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
    );
    const dataFiltered2 = applyFilter({
        inputData: tableData2,
        comparator: getComparator(table2.order, table2.orderBy),
        filters,
    });
    const dataInPage2 = dataFiltered2.slice(
        table2.page * table2.rowsPerPage,
        table2.page * table2.rowsPerPage + table2.rowsPerPage
    );
    const denseHeight = table.dense ? 52 : 72;
    const canReset = !isEqual(defaultFilters, filters);
    const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;
    const handleFilters = useCallback(
        (name, value) => {
            table.onResetPage();
            setFilters((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        },
        [table]
    );
    const handleDeleteRow = useCallback(
        (id) => {
            const deleteRow = tableData.filter((row) => row.id !== id);
            setTableData(deleteRow);

            table.onUpdatePageDeleteRow(dataInPage.length);
        },
        [dataInPage.length, table, tableData]
    );
    const handleDeleteRows = useCallback(() => {
        const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
        setTableData(deleteRows);

        table.onUpdatePageDeleteRows({
            totalRows: tableData.length,
            totalRowsInPage: dataInPage.length,
            totalRowsFiltered: dataFiltered.length,
        });
    }, [dataFiltered.length, dataInPage.length, table, tableData]);

    const handleEditRow = useCallback(
        (id) => {

        },
        []
    );

    const handleResetFilters = useCallback(() => {
        setFilters(defaultFilters);
    }, []);

    const handleFieldChange = async (field, value, rowId) => {
        try {
            // Prepare form data
            const formData = new FormData();
            formData.append(field, value);
            formData.append("id", rowId);

            // If field is 'billing_schedule', also set 'payment_terms' to the same value
            if (field === "billing_schedule") {
                formData.append("payment_terms", value);
            }

            // Make the API call
            const response = await postRequest(
                `${endpoints.job_costing.update}/${rowId}`,
                formData,
                true
            );

            if (response.metadata && response.metadata.success) {
                enqueueSnackbar({
                    variant: "success",
                    message: `${field} updated successfully.`,
                });
                getJobCostigList();
            } else {
                throw new Error(response.metadata.message || "Update failed");
            }
        } catch (error) {
            console.error("Failed to update field:", error);
            enqueueSnackbar({
                variant: "error",
                message: error.message || `Failed to update ${field}.`,
            });
        }
    };


    useEffect(() => {
        const fetchAndRenderLabels = async () => {
            await getSettingsLabels(); // Fetch settings first
            renderLabels();           // Then render based on fetched settings
        };
        fetchAndRenderLabels();
    }, []);

    const getSettingsLabels = async () => {
        try {
            setLoading(true)
            const response = await getRequest(endpoints.job_costing.setting_customs);
            if (response) {
                const defaultCheckedKeys = [
                    "facility",
                    "level",
                    "milestone",
                    "duration",
                    "trade",
                    "responsible_party",
                    "billing_schedule",
                    "payment_terms",
                    "payment_status",
                    "risk_level",
                    "quality_checks",
                    "compliance",
                    "contingency_used",
                    "notes",
                ]; 
                const excludedKeys = ["id", "creator_id", "project_name", "contract_number", "project_id", "job_cost_graph", "schedule_performance", "cost_performance" ] ;
                const labels = Object.keys(response)
                    .filter((key) => !excludedKeys.includes(key))
                    .map((key) => ({
                        name: key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase()),
                        value: defaultCheckedKeys.includes(key) || response[key] === 1,
                        key: key,
                        visible: !defaultCheckedKeys.includes(key),
                    }));
                setjobCostinglabels(labels);
                const visibleColumns = labels.map((label) => ({
                    key: label.key,
                    value: label.value ? 1 : 0,
                }));
                const payload = visibleColumns?.reduce((acc, column) => {
                    acc[column.key] = column.value;
                    return acc;
                }, {});
                
                const renderResponse = await postRequest(endpoints.job_costing.setting_post, payload);
                if (renderResponse.metadata && renderResponse.metadata.success) {
                    enqueueSnackbar("Settings updated successfully!", { variant: "success" });
                    setVisibleColumns(labels.filter((label) => !label.value).map((label) => label.key));
                } else {
                    throw new Error(renderResponse.metadata.message || "Error updating settings.");
                }
                setLoading(false)
            }
        } catch (error) {
            console.error("Error fetching and rendering settings labels:", error);
            enqueueSnackbar("Error fetching settings labels.", { variant: "error" });
            setLoading(false)

        }
    };
    const renderLabels = async () => {
        try {
            const visibleColumns = jobCostinglabels.map((label) => ({
                key: label.key,
                value: label.value ? 1 : 0,
            }));
            console.log("🚀 ~ visibleColumns ~ visibleColumns:", visibleColumns)
            const payload = visibleColumns.reduce((acc, column) => {
                acc[column.key] = column.value;
                return acc;
            }, {});
            const response = await postRequest(endpoints.job_costing.setting_post, payload);
            if (response.metadata && response.metadata.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: 'Settings updated successfully!',
                });
                // onSave(JobCostingLabels);
            } else {
                enqueueSnackbar({
                    variant: 'error',
                    message: response.metadata.message || 'Error updating settings.',
                });
            }
        } catch (error) {
            enqueueSnackbar({
                variant: 'error',
                message: 'Error submitting form.',
            });
            console.error('Error submitting form:', error);
        } finally {
        }
    };
    const handleSaveSettings = (labels) => {
        setjobCostinglabels(labels);
        setVisibleColumns(labels.filter((label) => !label.value).map((label) => label.key));
    };
    useEffect(() => {
        const defaultVisibleColumns = TABLE_HEAD.map((head) => head.id);
        setVisibleColumns(defaultVisibleColumns);
    }, []);

    const isColumnVisible = (columnKey) => {
        if (['action', 'change_order_id','category'].includes(columnKey)) {
            return true;
        }
        return !jobCostinglabels.some((label) => label.key === columnKey && label.value);
    };
  
  return (
      <Box sx={{ width: '100%', padding: '10px', marginTop: '0px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            margin: '10px 0',
            flexWrap: 'nowrap',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              fontSize: '13px',
              whiteSpace: 'nowrap',
            }}
          >
            <strong>Project Name:</strong> &nbsp; {tableData[0]?.project_name || ''}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontSize: '13px',
              whiteSpace: 'nowrap',
            }}
          >
            <strong>Contract Number:</strong> &nbsp; {tableData[0]?.contract_number || ''}
          </Typography>

          {visibleColumns.includes('billing_schedule') && (
            <FormControl variant="outlined" size="small">
              <InputLabel>Billing Schedule (Days)</InputLabel>
              <Select
                sx={{ width: '220px' }}
                label="Billing Schedule (Days)"
                value={tableData[0]?.billing_schedule || ''}
                onChange={(e) =>
                  handleFieldChange('billing_schedule', e.target.value, tableData[0]?.id)
                }
              >
                <MenuItem value="15 Days">15 Days</MenuItem>
                <MenuItem value="30 Days">30 Days</MenuItem>
                <MenuItem value="45 Days">45 Days</MenuItem>
              </Select>
            </FormControl>
          )}
          {visibleColumns.includes('payment_terms') && (
            <FormControl variant="outlined" size="small">
              <InputLabel>Payment Terms (Net Days)</InputLabel>
              <Select
                sx={{ width: '220px' }}
                label="Payment Terms (Net Days)"
                value={tableData[0]?.payment_terms || ''}
                onChange={(e) =>
                  handleFieldChange('payment_terms', e.target.value, tableData[0]?.id)
                }
              >
                <MenuItem value="15 Days">15 Days</MenuItem>
                <MenuItem value="30 Days">30 Days</MenuItem>
                <MenuItem value="45 Days">45 Days</MenuItem>
              </Select>
            </FormControl>
          )}
        </Box>
        <Box
          sx={{
            paddingTop: '20px',
            paddingBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div
            style={{
              backgroundColor: '#F4F6F8',
              border: '1px solid #FFD700',
              padding: '10px 10px',
              borderRadius: '5px',
              margin: '10px 0',
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginLeft: '1px',
                fontSize: '13px',
                whiteSpace: 'pre-line',
              }}
            >
              <strong>Note:</strong> &nbsp; To link a <b> Change Order</b> with an Activity, drag
              and drop the row into the <b> Category/Trade</b> section. To undo or separate the
              Change Order, click the<b> Undo</b> button. Certain columns also allow direct data
              input for flexibility. If you need to create a new Cost Code &nbsp;
              <a
                href={`${baseURLORG}/planning/projects/${projectID}/standard-cost-code-categories`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                click here
              </a>
              , or to create a new Change Order &nbsp;
              <a
                href={`${baseURLORG}/planning/projects/${projectID}/change-order-categories`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', fontWeight: 'bold' }}
              >
                click here
              </a>
            </Typography>
          </div>
        </Box>
        <CustomBreadcrumbs
          heading="JOB COSTING"
          links={[{ name: '', href: paths.dashboard.root }]}
          action={
            <>
              <Button
                className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="teenyicons:pdf-solid" />}
                sx={{ mx: 1 }}
                onClick={() => navigate('generate-report')}
              >
                Generate Report
              </Button>
              <Button
                onClick={popover.onOpen}
                className="dark-button"
                variant="contained"
                sx={{ mx: 1 }}
                startIcon={<Iconify icon="mdi:filter" />}
              >
                Filter
              </Button>
              <Button
                onClick={add.onTrue}
                className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="material-symbols:settings" />}
              >
                Setting
              </Button>
              <CustomPopover
                open={popover.open}
                onClose={popover.onClose}
                arrow="bottom-bottom"
                sx={{ width: 1000, padding: 2, background: 'white !important' }}
              >
                <MenuItem
                  disableRipple
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    color: '#000',
                    background: 'white !important',
                    '&:hover': { backgroundColor: 'white !important' },
                  }}
                >
                  <Iconify
                    icon="mdi:close"
                    onClick={popover.onClose}
                    sx={{
                      width: 20,
                      height: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      borderRadius: '50%',
                      backgroundColor: '#F4F6F8',
                      color: '#637381',
                    }}
                  />

                  {visibleColumns.includes('facility') && (
                    <TextField
                      label="Facility"
                      size="small"
                      placeholder="Search.."
                      variant="outlined"
                      value={filterss.facility}
                      onChange={(e) => handleFilterChange('facility', e.target.value)}
                      sx={{
                        flex: 1,
                        background: 'white !important',
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                  )}
                  {visibleColumns.includes('level') && (
                    <TextField
                      label="Level"
                      size="small"
                      placeholder="Search.."
                      variant="outlined"
                      value={filterss.level}
                      onChange={(e) => handleFilterChange('level', e.target.value)}
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                  )}
                  {visibleColumns.includes('milestone') && (
                    <TextField
                      label="Milestone"
                      size="small"
                      placeholder="Search.."
                      variant="outlined"
                      value={filterss.milestone}
                      onChange={(e) => handleFilterChange('milestone', e.target.value)}
                      sx={{
                        flex: 1,
                        '& .MuiOutlinedInput-root': {
                          '&:hover fieldset': {
                            borderColor: '#e0e0e0 !important',
                          },
                        },
                      }}
                    />
                  )}
                  <TextField
                    label="Category"
                    size="small"
                    placeholder="Search.."
                    variant="outlined"
                    value={filterss.category}
                    onChange={(e) => handleFilterChange('category', e.target.value)}
                    sx={{
                      flex: 1,
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#e0e0e0 !important',
                        },
                      },
                    }}
                  />
                </MenuItem>
              </CustomPopover>
            </>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Card>
          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              onResetFilters={handleResetFilters}
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <Dialog
            fullWidth
            maxWidth="xs"
            open={showModal}
            // onClose={handleCancelDrop}
            transitionDuration={{
              enter: theme.transitions.duration.shortest,
              exit: theme.transitions.duration.shortest - 80,
            }}
          >
            <DialogTitle>Confirm Change Order</DialogTitle>
            <DialogContent>
              <p>Do you want to add this Change Order to the Base Contract table?</p>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleCancelDrop}
              >
                Cancel
              </Button>
              <Button
                className="dark-button"
                variant="contained"
                onClick={() => handleConfirmDrop()}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <SettingModal
            open={add.value}
            onClose={add.onFalse}
            onSave={handleSaveSettings}
            labels={jobCostinglabels}
            setjobCostinglabels={setjobCostinglabels}
          />
          <Box>
            <h3 style={{ pading: '20px', marginRight: '10px' }}>BASE CONTRACT</h3>
          </Box>
          <TableContainer
            sx={{ position: 'relative', overflow: 'unset' }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />
            <Scrollbar>
              {loading ? (
                <Box height={700}>
                  <LoadingScreen />
                </Box>
              ) : (
                <Table
                  size={table.dense ? 'small' : 'medium'}
                  sx={{
                    minWidth: '100%',
                    marginBottom: '20px',
                  }}
                >
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.filter(
                        (headCell) => isColumnVisible(headCell.id) // Check if column is visible
                      ).map((headCell) => (
                        <TableCell key={headCell.id} sx={{ minWidth: headCell.minWidth }}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      ?.slice(
                        table.page * table.rowsPerPage,
                        table.page * table.rowsPerPage + table.rowsPerPage
                      )
                      .map((row) => (
                        <JobcostingTableRow
                          key={row.id}
                          row={row}
                          onDrop={(event) => handleDrop(event, row)}
                          onDrag={(event) => handleDragOver(event)}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => handleDeleteRow(row.id)}
                          onEditRow={() => handleEditRow(row.id)}
                          refreshTableData={() => getJobCostigList()}
                          onConfirmDrop={handleConfirmDrop} // Pass handleConfirmDrop function
                          visibleColumns={visibleColumns} // Pass visible columns
                        />
                      ))}
                    <JobCostingSummaryPage tableData={tableData} visibleColumns={visibleColumns} />

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                    />
                    <TableNoData notFound={notFound} />
                  </TableBody>
                </Table>
              )}
            </Scrollbar>
          </TableContainer>
          <TablePaginationCustom
            count={filteredData.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
          <Box>
            <h3 style={{ pading: '20px', marginRight: '10px' }}>CHANGE ORDER</h3>
          </Box>

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData?.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table
                size={table.dense ? 'small' : 'medium'}
                sx={{ minWidth: '100%', marginTop: '20px' }}
              >
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.filter((headCell) => isColumnVisible(headCell.id)).map(
                      (headCell) => (
                        <TableCell key={headCell.id} sx={{ minWidth: headCell.minWidth }}>
                          {headCell.label}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData2?.map((row) => (
                    <JobcostingChangeorderTableRow
                      key={row.id}
                      row={row} // Pass each row data here
                      selected={table.selected.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      handleDragStart={handleDragStart}
                      refreshTableData={getJobCostigList}
                      visibleColumns={visibleColumns} // Pass visible columns
                    />
                  ))}
                  <JobCostingChangeOrderSubTotal
                    tableData={tableData2}
                    visibleColumns={visibleColumns}
                  />
                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />
                  <TableNoData notFound={notFound} />
                </TableBody>

                <TableRow style={{ width: '100%' }}>
                  <TableCell align="left">
                    <strong>Total:</strong>
                  </TableCell>

                  <TableCell />
                </TableRow>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered2.length}
            page={table2.page}
            rowsPerPage={table2.rowsPerPage}
            onPageChange={table2.onChangePage}
            onRowsPerPageChange={table2.onChangeRowsPerPage}
            dense={table2.dense}
            onChangeDense={table2.onChangeDense}
          />
        </Card>

        <ConfirmDialog
          open={confirm.value}
          onClose={confirm.onFalse}
          title="Delete"
          content={<>Are you sure want to delete items?</>}
          action={
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                handleDeleteRows();
                confirm.onFalse();
              }}
            >
              Delete
            </Button>
          }
        />
      </Box>
    );
}
// ----------------------------------------------------------------------
function applyFilter({ inputData, comparator, filters }) {
    const { name, status, role } = filters;
    const stabilizedThis = inputData.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    inputData = stabilizedThis.map((el) => el[0]);
    if (name) {
        inputData = inputData.filter(
            (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
        );
    }
    if (status !== 'all') {
        inputData = inputData.filter((user) => user.status === status);
    }
    if (role.length) {
        inputData = inputData.filter((user) => role.includes(user.role));
    }
    return inputData;
}