import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Box, Button, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';

const RiskStackhodlerFeedbackCreate = ({ open, onClose, reload, currentItem, closeform }) => {
  console.log("🚀 ~ RiskStackhodlerFeedbackCreate ~ currentItem:", currentItem)
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);
  const [nextFeedbackId, setNextFeedbackId] = useState(''); // State for next available feedback ID
  console.log("🚀 ~ RiskStackhodlerFeedbackCreate ~ nextFeedbackId:", nextFeedbackId)


  // Schema for form validation using Yup
  const NewUserSchema = Yup.object().shape({
    related_risk_id: Yup.object({
      id: Yup.string().required('Risk ID is required'),
    }).required('Risk ID is required'),
    company_name: Yup.string().required('Company Name is required'),
    company_email: Yup.string().required('Company E-mail is required'),
    feedback: Yup.string().required('Feedback is required'),
    suggestion: Yup.string().required('Suggestion is required'),
  });

  // Memoized matchedRisk to avoid unnecessary calculations
  const matchedRisk = useMemo(() => {
    return riskIds.find(risk => risk.id === currentItem?.identification_and_assessment?.id) || { id: '', risk_id: 'None' };
  }, [currentItem, riskIds]);

  // Default form values
  const defaultValues = useMemo(() => ({
    feedback_id: currentItem?.feedback_id || nextFeedbackId,
    company_name: currentItem?.company_name || '',
    company_email: currentItem?.company_email || '',
    related_risk_id: matchedRisk,
    feedback: currentItem?.feedback || '',
    suggestion: currentItem?.suggestion || '',
  }), [currentItem, matchedRisk, nextFeedbackId]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const { reset, handleSubmit, formState: { isSubmitting } } = methods;

  // Reset form when currentItem or defaultValues change
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);


  // Fetch the next available feedback ID
  const fetchNextFeedbackId = useCallback(async () => {
    if (!currentItem?.id) { // Only fetch for new records
      try {
        const response = await getRequest(endpoints.risk_management.risk_stackholder_feedback.crud);
        console.log("🚀 ~ fetchNextFeedbackId ~ response:", response)
        setNextFeedbackId(response?.next_feedback_id || '');
      } catch (error) {
        console.error('Error fetching next feedback ID:', error);
      }
    }
  }, [currentItem]);


  // Fetch identification data (Risk IDs)
  const getIdentification = useCallback(async () => {
    try {
      const data = await getRequest(endpoints.risk_management.risk_register.identification.identification);
      setRiskIds(data || []);
    } catch (err) {
      console.error('Error fetching risk IDs:', err);
    }
  }, []);

  useEffect(() => {
    getIdentification();
    fetchNextFeedbackId(); // Fetch next feedback ID when form opens
  }, [getIdentification, fetchNextFeedbackId]);


  // Handle form submission
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('feedback_id', data.feedback_id);
      formData.append('company_name', data.company_name);
      formData.append('company_email', data.company_email);
      formData.append('risk_id', data.related_risk_id.id);
      formData.append('feedback', data.feedback);
      formData.append('suggestion', data.suggestion);

      const endpoint = currentItem?.id
        ? `${endpoints.risk_management.risk_stackholder_feedback.crud}/${currentItem.id}?_method=PUT`
        : endpoints.risk_management.risk_stackholder_feedback.crud;

      const response = await postRequest(endpoint, formData, true);

      if (response.metadata && response.metadata.success) {
        reset();
        onClose();
        closeform();
        reload();
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
      } else {
        handleErrorMessages(response.metadata?.message || 'An error occurred.');
      }
    } catch (err) {
      console.error('Error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'Arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Stakeholder Feedback   &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={onClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
     
        <DialogContent sx={{ borderRadius: '1px' }}>
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField name="feedback_id" label="Feedback ID" disabled />
            <RHFTextField name="company_name" label="Company Name" />
            <RHFTextField name="company_email" label="Company E-mail" type="email" />
            <RHFAutocomplete
              name="related_risk_id"
              label="Related Risk ID"
              options={[{ id: '', risk_id: 'None' }, ...riskIds]}
              getOptionLabel={(option) => option.risk_id || 'None'}
              isOptionEqualToValue={(option, value) => option.id === value?.id || value === '' || option.id === ''}
              renderOption={(props, option) => (
                <li {...props} key={option.id || 'none'}>
                  {option.risk_id}
                </li>
              )}
            />
          </Box>
          <Box
            mt={3}
            rowGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField name="feedback" label="Feedback" multiline rows={4} />
            <RHFTextField name="suggestion" label="Suggestions For Improvement" multiline rows={4} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" className='dark-button'
            loading={isSubmitting}>
            {!currentItem?.id ? 'Create Stakeholder' : 'Update Stakeholder'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default RiskStackhodlerFeedbackCreate;
