// Import necessary dependencies
import PropTypes from 'prop-types';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { endpoints } from 'src/utils/axios';
import { getRequest } from 'src/services';
// ----------------------------------------------------------------------
export default function RiskTrendChart({ title, subheader }) {
  const popover = usePopover();
  const [seriesData, setSeriesData] = useState('Daily');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [monthYearData, setMonthYearData] = useState({ daily: [], weekly: [], monthly: [] });

  useEffect(() => {
    const formattedDate = selectedDate.toISOString().split('T')[0];
    getMonthYear(seriesData, formattedDate);
  }, [seriesData, selectedDate]);

  const getMonthYear = async (type, formattedDate) => {
    try {
      let url;
      const headers = {
        'Content-Type': 'application/json',
      };
      const currentYear = new Date(formattedDate).getFullYear();
      switch (type) {
        case 'Weekly':
          url = `${endpoints.risk_management.risk_dashboard.monthYear}?type=weekly&risk_trend_date=${formattedDate}`;
          break;
        case 'Monthly':
          url = `${endpoints.risk_management.risk_dashboard.monthYear}?type=monthly&risk_trend_year=${currentYear}`;
          break;
        case 'Daily':
        default:
          url = `${endpoints.risk_management.risk_dashboard.monthYear}?type=daily&risk_trend_date=${formattedDate}`;
          break;
      }
      const responseData = await getRequest(url, { headers });
      console.log("🚀 ~ getMonthYear ~ responseData:", responseData);
      setMonthYearData({
        daily: responseData.daily_identification_counts || [],
        weekly: responseData.weekly_identification_counts || [],
        monthly: responseData.monthly_identification_counts || [],
      });
    } catch (error) {
      console.error('Error fetching month/year data: ', error);
    }
  };

  const handleChangeSeries = useCallback(
    (newValue) => {
      popover.onClose();
      setSeriesData(newValue);
      const formattedDate = selectedDate.toISOString().split('T')[0]; // Format the date
      getMonthYear(newValue, formattedDate); // Pass the formatted date
    },
    [popover, selectedDate]
  );

  const dailyCounts = monthYearData.daily;
  const weeklyCounts = monthYearData.weekly;
  const monthlyCounts = monthYearData.monthly;

  // Get the last seven days including the selected date
  const categories = [];
  for (let i = 7; i >= 0; i--) {
    const date = new Date(selectedDate);
    date.setDate(selectedDate.getDate() - i); // Get the date for each of the last 7 days
    categories.push(date.toLocaleDateString('en-US', { weekday: 'short' })); // Add day name to categories
  }


  // Utility function to get the start and end dates of a week based on the given date
  const getWeekRange = (date) => {
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);

    // Start on Sunday (assuming US locale) and end on Saturday
    const day = date.getDay();
    const diffToStart = date.getDate() - day; // go back to Sunday
    const diffToEnd = diffToStart + 6; // go forward to Saturday

    startOfWeek.setDate(diffToStart);
    endOfWeek.setDate(diffToEnd);

    // Format the dates in 'MM/DD' format
    const startFormatted = startOfWeek.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    const endFormatted = endOfWeek.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

    return `${startFormatted} - ${endFormatted}`;
  };

  const chartData = {
    Daily: {
      categories: categories,
      data: [
        {
          name: 'Risk',
          data: dailyCounts.length ? dailyCounts.slice(-7) : [0, 0, 0, 0, 0, 0, 0], // Fallback to zeros if no data
        },
      ],
    },
    Weekly: {
      categories: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
      data: [
        {
          name: 'Risk',
          data: weeklyCounts.length ? weeklyCounts : [0, 0, 0, 0], // Fallback to zeros if no data
        },
      ],
    },
    Monthly: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      data: [
        {
          name: 'Risk',
          data: monthlyCounts.length ? monthlyCounts : [], // Monthly data from API
        },
      ],
    },
  };

  console.log("Monthly Data:", monthYearData);
  console.log("Counts:", dailyCounts, weeklyCounts, monthlyCounts);
  const chartOptions = useChart({
    colors: ['#4CAF50'],
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartData[seriesData].categories,
    },
    tooltip: {
      y: {
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          if (seriesData === 'Weekly') {
            // Get the start and end date of the week for the selectedDate
            const weekDate = new Date(selectedDate);
            weekDate.setDate(weekDate.getDate() + dataPointIndex * 7); // Adjust date for each week
            const weekRange = getWeekRange(weekDate);

            return `: ${value} ,
             Date: ${weekRange}`;
          }
          return `${value}`;
        },
      },
    },
  });

  const handleDateChange = (newValue) => {
    setSelectedDate(newValue);
    // Pass the selected date to getMonthYear after formatting it
    const formattedDate = newValue.toISOString().split('T')[0];
    getMonthYear(seriesData, formattedDate);
  };

  return (
    <>
      <Card sx={{ boxShadow: '0px 0px 30px 0px #64748B1A' }}>
        <CardHeader
          title={
            <Box display="flex" alignItems="center">
              {title}  &nbsp;
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <Box sx={{ ml: 2 }}>
                    <input {...params} />
                  </Box>
                )}
                sx={{
                  '& .MuiInputBase-root': {
                    height: '30px',
                    padding: '5px',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '1px solid #ccc',
                  },
                  '& .MuiInputBase-input': {
                    padding: '0',
                  },
                }}
              />
            </Box>
          }
          subheader={subheader}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {seriesData}
              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />
        <Box sx={{ mt: 5.2, mx: 3 }}>
          <Chart dir="ltr" type="bar" series={chartData[seriesData].data} options={chartOptions} height={320} />
        </Box>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {Object.keys(chartData).map((option) => (
          <MenuItem
            key={option}
            selected={option === seriesData}
            onClick={() => handleChangeSeries(option)}
          >
            {option}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}

RiskTrendChart.propTypes = {
  subheader: PropTypes.string,
  title: PropTypes.string,
};
