import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Card,
    TableContainer,
    Select,
    MenuItem,
    Typography,
    TableHead,
    TextField,
} from '@mui/material';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import './tablelist.css';
import TablePaginationCustoms from './table=pagination-custom';

export default function RiskFilterTable({ title, tabledata }) {
    const [firstDropdownValue, setFirstDropdownValue] = useState('');
    const [secondDropdownValue, setSecondDropdownValue] = useState('Exposure');
    const [rangeValue1, setRangeValue1] = useState('');
    const [rangeValue2, setRangeValue2] = useState('');

    const totalRisks = tabledata?.total_risks?.map(risk => ({
        id: risk?.id,
        risk_id: risk?.risk_id,
        description: risk?.description,
        date_identified: risk?.date_identified,
        exposure: risk?.exposure || 0,
        priority: risk?.riskAnalysis?.priority || 0,
        riskRating: risk?.riskAssessment?.risk_rating || 0,
        project_stage: risk?.project_stage || null,
    })) || [];

    const handleDropdownChange = (e) => {
        const newValue = e.target.value;
        setSecondDropdownValue(newValue);
        setRangeValue1('');
        setRangeValue2('');
    };

    const handleRangeChange = (isLower, value) => {
        if (isLower) {
            setRangeValue1(value);
        } else {
            setRangeValue2(value);
        }
    };

    const table = useTable();
    const dataFiltered = applyFilter({ inputData: totalRisks });

    const filteredData = dataFiltered?.filter((row) => {
        const exposureValue = parseFloat(row.exposure) || 0; // Convert exposure to a number
        const minRange = parseFloat(rangeValue1) || -Infinity; // Minimum exposure range
        const maxRange = parseFloat(rangeValue2) || Infinity; // Maximum exposure range
        const isWithinExposureRange = exposureValue >= minRange && exposureValue <= maxRange;

        const rowProjectStage = row.project_stage;

        const isMatchingProjectStage = firstDropdownValue ? rowProjectStage === firstDropdownValue : true;

        if (secondDropdownValue === "Exposure") {
            return isWithinExposureRange && isMatchingProjectStage;
        }
        else if (secondDropdownValue === "Priority") {
            const priorityValue = parseFloat(row.priority) || 0;
            const isValidPriority = priorityValue >= minRange && priorityValue <= maxRange;

            return isValidPriority && isMatchingProjectStage;
        }
        else if (secondDropdownValue === "Risk Rating") {
            const riskRatingValue = parseFloat(row.riskRating) || 0;
            const isValidRiskRating = riskRatingValue >= minRange && riskRatingValue <= maxRange;

            return isValidRiskRating && isMatchingProjectStage;
        }

        return true; 
    });

    useEffect(() => {
        console.log(`🚀 ~ Selected Exposure Range: ${rangeValue1} to ${rangeValue2} | Total Matching Records:   ${filteredData.length}`);
    }, [filteredData]);


    const dataInPage = filteredData?.slice(
        table.page * table.rowsPerPage,
        table.page * table.rowsPerPage + table.rowsPerPage
    );

    const headLabel = [
        { id: 'risk_id', label: 'Risk ID' },
        { id: 'description', label: 'Risk Description' },
        { id: 'date_identified', label: 'Date Of Issue' },
    ];

    return (
        <Card sx={{ padding: '0px', boxShadow: '0px 0px 30px 0px #64748B1A', height:"470px"  }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Typography variant="h6">{title}</Typography>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Box sx={{ width: 130 }}>
                        <Select
                            value={secondDropdownValue}
                            onChange={handleDropdownChange}
                            displayEmpty
                            sx={{ minWidth: 65, height: 35 }}
                        >
                            {/* <MenuItem value="">Select Filter</MenuItem> */}
                            <MenuItem value="Exposure">Exposure</MenuItem>
                            <MenuItem value="Priority">Priority</MenuItem>
                            <MenuItem value="Risk Rating">Risk Rating</MenuItem>
                        </Select>
                    </Box>

                    {(secondDropdownValue === "Exposure" || secondDropdownValue === "Priority" || secondDropdownValue === "Risk Rating") && (
                        <>
                            <TextField
                                type="number"
                                value={rangeValue1}
                                onChange={(e) => handleRangeChange(true, e.target.value)}
                                size="small"
                                variant="outlined"
                                placeholder={`Min ${secondDropdownValue}`}
                                sx={{ width: 70 }}
                            />
                            <TextField
                                type="number"
                                value={rangeValue2}
                                onChange={(e) => handleRangeChange(false, e.target.value)}
                                size="small"
                                variant="outlined"
                                placeholder={`Max ${secondDropdownValue}`}
                                sx={{ width: 70 }}
                            />
                        </>
                    )}
                    <Select
                        value={firstDropdownValue}
                        onChange={(e) => {
                            const selectedStageId = e.target.value; // Get the selected stage ID
                            const selectedStage = tabledata?.project_stages.find(stage => stage.name === selectedStageId);
                            console.log("🚀 ~ RiskFilterTable ~ selectedStage:", selectedStage);
                            if (selectedStage) {
                                setFirstDropdownValue(selectedStage.name); // Store the ID of the selected stage
                            } else {
                                setFirstDropdownValue(''); 
                            }
                        }}
                        displayEmpty
                        sx={{ minWidth: 150, height: 35 }}
                    >
                        <MenuItem value="" >All</MenuItem>
                        {tabledata?.project_stages?.map((stage) => (
                            <MenuItem key={stage.id} value={stage.name}>
                                {stage.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>


            <Card sx={{ padding: '0px' ,borderRadius:"0px" }}>
            <TableContainer sx={{ overflow: 'unset' }}>
                <Scrollbar>
                    <Table size={table.dense ? 'small' : 'large'}>
                        <TableHead className='table-head-custom'>
                            <TableRow className='table-head-custom'>
                                {headLabel.map((header) => (
                                    <TableCell
                                    className='table-head-custom'
                                        key={header.id}
                                        style={{ backgroundColor: '#F4F6F8', color: '#637381' }}
                                    >
                                        {header.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataInPage?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.risk_id}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>{row.date_identified}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Scrollbar>
                    <TablePaginationCustoms
                    count={filteredData?.length}
                    page={table?.page}
                    rowsPerPage={table?.rowsPerPage}
                    onPageChange={table?.onChangePage}
                    onRowsPerPageChange={table?.onChangeRowsPerPage}
                />
            </TableContainer>
            </Card>
        </Card>
    );
}

RiskFilterTable.propTypes = {
    title: PropTypes.string.isRequired,
    tabledata: PropTypes.object.isRequired,
};
