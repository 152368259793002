import React from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import RenderField from '../../risk-register/render-field';
const RiskMitigatioonSurveyTable = ({ data }) => {
    console.log("🚀 ~ RiskMitigatioonSurveyTable ~ data:", data)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Card>
            <Box p={3}>
                <Typography className=" Escalation-view-main-heading"> Risk Mitigation Survey Report</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                         Risk Mitigation Survey Details{' '}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label=" Survey ID" value={data?.survey_id} />
                <RenderField label=" Related Risk ID" value={data?.identification_and_assessment?.risk_id} />
                <RenderField label=" Effectiveness Scale " value={data?.effectiveness_scale} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Comments on Effectiveness" value={data?.effectiveness_comment} />
                <RenderField label="Suggestions for Improvement" value={data?.suggestions} />
            </Box>
            <Divider />
          
        </Card>
    );
};

export default RiskMitigatioonSurveyTable;
