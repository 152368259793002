
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'src/routes/hooks'
import { getRequest } from 'src/services'
import { endpoints } from 'src/utils/axios'
import RiskAnalysis from '../risk-anaysis/risk-analysis'
import Identification from './identification'
import MitigationPlan from './mitigation-plan'
import MonitoringTracking from './monitoring-tracking'
import RiskAssessment from './risk-assessment'
const RiskView = () => {
  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
  console.log('myToke', token)

  const { id } = useParams();
  const [riskData, setRiskData] = useState(null);

  useEffect(() => {
    getRISkregisterShow();
  }, [])

  const getRISkregisterShow = async () => {
    const data = await getRequest(endpoints.risk_management.risk_register.identification.show(id));
    console.log("🚀 ~ getRISkregisterShow ~ data:", data);
    setRiskData(data)
  }

  return (
    <>
      {riskData && (
        <>
          <Identification data={riskData?.identification_and_assessment} riskData={riskData} />
          <RiskAssessment data={riskData?.identification_and_assessment.risk_assessment} reload={() => getRISkregisterShow()} />
          <RiskAnalysis data={riskData?.identification_and_assessment.risk_analysis}
            reload={() => getRISkregisterShow()}
          />
          <MitigationPlan
            data={riskData?.identification_and_assessment.mitigation_plan}
            reload={() => getRISkregisterShow()}
            srNumber={riskData?.srNumber}
          />
          <MonitoringTracking data={riskData?.identification_and_assessment.monitor_and_track}
            reload={() => getRISkregisterShow()}
          />
        </>
      )}
    </>
  );
};

export default RiskView