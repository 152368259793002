import { useMemo } from 'react';
import { useLocales } from 'src/locales';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------
const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
const ICONS = {
    job: icon('ic_job'),
    blog: icon('ic_blog'),
    chat: icon('ic_chat'),
    mail: icon('ic_mail'),
    user: icon('ic_user'),
    file: icon('ic_file'),
    lock: icon('ic_lock'),
    tour: icon('ic_tour'),
    order: icon('ic_order'),
    label: icon('ic_label'),
    blank: icon('ic_blank'),
    kanban: icon('ic_kanban'),
    folder: icon('ic_folder'),
    banking: icon('ic_banking'),
    booking: icon('ic_booking'),
    invoice: icon('ic_invoice'),
    product: icon('ic_product'),
    calendar: icon('ic_calendar'),
    disabled: icon('ic_disabled'),
    external: icon('ic_external'),
    menuItem: icon('ic_menu_item'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    clipboard: icon('ic_clipboard'),
    incident : icon('ic-incident'),
    arrows: icon('ic-change-req'),
    uppdownArrows: icon('ic-priority'),
    team: icon('ic-Response'),
    calendar2: icon('ic-Quarterly'),
    Survey: icon('ic-Survey'),
    Analysis: icon('ic-Analysis'),
    Inventory: icon('ix-Inventory'),
    Budget: icon('ic-Budget'),
    Learning: icon('ic-Learning'),
};
// ----------------------------------------------------------------------
export function useRiskNavData() {
    const { t } = useLocales();
    const data = useMemo(
        () => [
            // ----------------------------------------------------------------------
            {
                subheader: t('Risk Management'),
                items: [
            
                    {
                        title: t('Risk Register'),
                        path: '/risk-management/risk-register',
                        icon: ICONS.clipboard,
                    },
                    {
                        title: t('Incident Reporting'),
                        path: '/risk-management/incident-reporting',
                        icon: ICONS.incident,
                    },
                    {
                        title: t('Risk Change Request'),
                        path: '/risk-management/risk-change-request',
                        icon: ICONS.arrows,
                    },
                    {
                        title: t('Stakeholder Feedback'),
                        path: '/risk-management/stakeholder-feedback',
                        icon: ICONS.chat,
                    },
                    {
                        title: t('Risk Prioritization'),
                        path: '/risk-management/risk-prioritization',
                        icon: ICONS.uppdownArrows,
                    },
                    {
                        title: t('Risk Escalation'),
                        path: '/risk-management/risk-escalation',
                        icon: ICONS.analytics,
                    },
                    {
                        title: t('Risk Response Team Assignment'),
                        path: '/risk-management/risk-response-team-assignment',
                        icon: ICONS.team,
                    },
                    {
                        title: t('Quarterly Risk Review'),
                        path: '/risk-management/quarterly-risk-review',
                        icon: ICONS.calendar2,
                    },
                    {
                        title: t('Risk Mitigation Effectiveness Survey'),
                        path: '/risk-management/risk-mitigation-effectiveness-survey',
                        icon: ICONS.Survey,
                    },
                    {
                        title: t('Risk Trends and Analysis Report'),
                        path: '/risk-management/risk-trends-and-analysis-report',
                        icon: ICONS.Analysis,
                    },
                    {
                        title: t('Comprehensive Risk Inventory'),
                        path: '/risk-management/comprehensive-risk-inventory',
                        icon: ICONS.Inventory,
                    },
                    {
                        title: t('Risk Mitigation Budget Request'),
                        path: '/risk-management/risk-mitigation-budget-request',
                        icon: ICONS.Budget,
                    },
                    {
                        title: t('Risk Action Log'),
                        path: '/risk-management/risk-action-log',
                        icon: ICONS.file,
                    },
                    {
                        title: t('Risk Learning and Insights Journal'),
                        path: '/risk-management/risk-learning-and-insights-journal',
                        icon: ICONS.Learning,
                    },
                ]
            }
        ],
        [t]
    );
    return data;
}