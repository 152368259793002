// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  callbackUrl: process.env.REACT_APP_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; 

export const baseURL = `https://web.ezelogs.com/api`
export const baseURLORG = `https://web.ezelogs.com`
// export const baseURL = 'http://200.10.12.81:8080/api'

export const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiYzg4NTQxZTU4NzMyNWYzMTM0M2JhMmY1Y2U2YTk1ZmFkMDZhMzBlNDFlZDgzMmRlMmIzMGEyNjNjZjQxODhhMDQzZDZmODg2M2NlN2Y5N2QiLCJpYXQiOjE3MTM4NzYzNjYuNjM3ODQ4LCJuYmYiOjE3MTM4NzYzNjYuNjM3ODUyLCJleHAiOjE3NDU0MTIzNjYuNjI5NzAzLCJzdWIiOiIyNjIwIiwic2NvcGVzIjpbXX0.aoXq75_5XmPAUF9RXOf3yGam7jWZ7CplThrvgOUdtty8WEfeUxg26v7Ml1V9jeXHnnOfyuGYX2lI0GoY_Xyu4DDMDIBRCH9jl-HcmrPWvjSNCZY7iP14aNgKdoGdkV4T8vnsg2Z5oyZ7yIyx05wsKsqG1Wm8cXAJgrBUYbq68ntIpJMn_zhHQXT1-VXXWnJ77O0MN0XCpuz-TKjtAm0CdZbdEa66owO-w1ifi7U_UmR31zJGzVgkjjr3BCb1rBAXkG-KhQP_t6338nP7qBptl28DtABOUe41wyVOE6J4Mv21dHfXZEzcQmz8gSdyz1cGYYWZvjCqUibTYm_3R7PHfrZ2dAPWTpbmI2h8jqBz2QjdT1uSDj4SU2Iw3FEmxjgLRz2jor7bCAqhj_QCdYxpeB4_kvUGBZ3_P1hHf9LGjFR1cqc8LzjrW_DhsWhXZGr8ijH3oRmvVjx49cf_ji55OLQgVNgnhbwz9UnluIoAaS4CUunDgpi5QUMJzqFsEqHI6QM1BlUqCA6YIYH-UIVjZSpT_hi7GkieidvxHBPYHigpDkwJlzw9Ru1ftg9GbpPAfKx9AE7iOpJWAXj5i9Lv7iWhXB2vABwqZx35fOE09JXKu7pxf8_CV-nCamvfI-Xy3gbeoqpl2wWvklUgOe2yb_HdnFpR-3xyruHTMDuzK-o'