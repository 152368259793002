// src/components/content/RiskRegister.js
import React, { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import {Select, MenuItem, Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { paths } from 'src/routes/paths';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useNavigate } from 'react-router';
import { LoadingScreen } from 'src/components/loading-screen';
import RiskChangeRequestCreate from './risk-chnage-create';
import { LoadingButton } from '@mui/lab';

import { Typography } from '@mui/material';


const BankInformation = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [areIconsVisible, setAreIconsVisible] = useState(false);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.no' },
    { id: 'Bank Name', label: 'Bank Name' },
    { id: 'Account Number', label: 'Account Number' },
    { id: 'Account Type', label: 'Account Type' },
    { id: 'Balance', label: 'Balance' },
    { id: 'Description', label: 'Description' },
    { id: 'Last Sync Date', label: 'Last Sync Date' },
    { id: 'Address', label: 'Address' },
    { id: 'Phone', label: 'Phone' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const remove = useBoolean();

  const confirm = useBoolean();
  const create = useBoolean();
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ RiskChangeRequest ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRow = useCallback(
    (id) => {
      setTableData((prevTableData) => {
        const deleteRow = prevTableData.filter((row) => row.id !== id);
        table.onUpdatePageDeleteRow(dataInPage.length);
        return deleteRow;
      });
    },
    [dataInPage.length, table]
  );



  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getRiskChangeRequest();
  }, []);

  const getRiskChangeRequest = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_change_request.crud);
    console.log("🚀 ~ getRiskChangeRequest ~ data:", data)
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getRiskChangeRequest()
  }

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleDeleteRows = async (id) => {
    await deleteRequest(`${endpoints.risk_management.risk_change_request.crud}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
  }


  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_change_request.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getRiskChangeRequest();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };



  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>
          

            <Header name="BANK & CREDIT CARD INFORMATION" />
            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "space-between",
                gap:"12px",
                
              }}
            >

            <div>

            </div>

             <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
                gap:"12px"
              }}
            >
              
              <Button
                onClick={() => {
                  setSelectedItem({});
                  create.onTrue();
                }}
                  className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Create
              </Button>
               </Box>

            </Box>



              <Card sx={{
                boxShadow: '0px 0px 30px 0px #64748B1A',
              }}>    
              <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                  {actionVisible && (
                    <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                      <TableSelectedAction
                        dense={table.dense}
                        numSelected={table.selected.length}
                        rowCount={tableData.length}
                        onSelectAllRows={(checked) =>
                          table.onSelectAllRows(
                            setActionVisible(checked),
                            checked,
                            tableData.map((row) => row.id)
                          )
                        }
                        action={
                          <Tooltip title="Delete">
                            <IconButton color="primary" onClick={remove.onTrue}>
                              <Iconify icon="solar:trash-bin-trash-bold" />
                            </IconButton>
                          </Tooltip>
                        }
                      />
                    </div>
                  )}
                <Scrollbar >
                  <Table size={table.dense ? 'small' : 'large'}
                    className="tableehead"
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataInPage.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={table.selected.includes(row.id)}
                              onChange={() => table.onSelectRow(row.id)}
                            />
                          </TableCell>
                          <TableCell align="left">{index + 1 + table.page * table.rowsPerPage}</TableCell>
                          <TableCell align="left">{row.request_id}</TableCell>
                          <TableCell align="left">{row.identification_and_assessment?.risk_id}</TableCell>
                          <TableCell align="left">{row.requested_changes}</TableCell>
                          <TableCell align="left">{row.reason_for_change}</TableCell>
                          <TableCell align="left">{row.requested_by}</TableCell>
                          <TableCell align="left">{row.approval_status}</TableCell>
                          <TableCell align="left" sx={{ display: "flex" }}>

                            <IconButton onClick={handleToggleIcons}>
                              <Iconify icon="eva:more-vertical-fill" />
                            </IconButton>
                            {areIconsVisible && (
                              <>

                            <Tooltip title="Delete">
                              <IconButton  onClick={() => {
                                setSelectedItem(row);  // Set the item you want to delete
                                confirm.onTrue();      // Trigger the confirmation dialog
                              }}>
                                <Iconify icon="solar:trash-bin-trash-bold" />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Edit">
                              <IconButton  onClick={() => {
                                setSelectedItem(row);
                                create.onTrue();
                              }}>
                                <Iconify icon="solar:pen-bold" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View" onClick={() => navigate(`/risk-management/risk-change-request/view/${row.id}`)}>
                              <IconButton >
                                <Iconify icon="solar:eye-bold" />
                              </IconButton>
                            </Tooltip>
                              </>
                            )}

                          </TableCell>
                        </TableRow>
                      ))}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card >
    <RiskChangeRequestCreate
      open={create.value}
      onClose={create.onFalse}
      reload={() => getRiskChangeRequest()}
      currentItem={selectedItem}
      closeform={() => handleCloseForm()}
    />
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content="Are you want to delete selected items?"
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />

    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <LoadingButton
          loading={deleteLoading}
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleBulkDelete();
          }}
        >
          Delete
        </LoadingButton>
      }
    />

  </>;
};

export default BankInformation;
