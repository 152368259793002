import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import RiskTrendAnalaysisTable from './risk-trend-analsysis-table';
const RiskTrendAnalaysisView = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [RiskTrendAnalaysisDetails, setRiskTrendAnalaysisDetails] = useState();  // New state for incident details

    useEffect(() => {
        getRiskAnalysisTrend();
    }, [])

    const getRiskAnalysisTrend = async () => {
        const data = await getRequest(endpoints.risk_management.risk_trend_analysis.risk_trend_analysis_Show(id));
        console.log("🚀 ~ getRiskAnalysisTrend ~ data:", data)
        setRiskTrendAnalaysisDetails(data);

    }
    return (
        <RiskTrendAnalaysisTable data={RiskTrendAnalaysisDetails} />
    );
};
export default RiskTrendAnalaysisView;