import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';

import icon from "../../../assets/icons/info.png"
import head from "../../../assets/icons/head (1).png"
import { Grid } from '@mui/material';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 320,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


// const getusername = (users) => {
//     console.log("🚀 ~ getusername ~ users:", users)
//     return {
//     }
// }

export default function renderEventContent(eventInfo, employeelength) {
    const length = employeelength?.length;
    console.log("🚀 ~ renderEventContent ~ length:", length)

    return (
        <div style={{ overflow: "hidden", backgroundColor: "#8C22F580", color: "white", borderRadius: "6px", padding: "5px" }} >
            <div display="flex"   >
                <span style={{ float: "right", marginTop: "-18px", }} >
                    <HtmlTooltip
                        placement="right-start"
                        title={
                            <React.Fragment>
                                <>
                                    <img src={head} alt="" />
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="center" >
                                            <h3>Assign Individual</h3>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="Start" style={{ marginTop: "-30px" }}>
                                        <u> <h4>Assign Labor</h4></u>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>Employee</h4>
                                        <p>Employee 1</p>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>Trade Worker / Title</h4>
                                        <p>Trade  1</p>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>No of Hrs</h4>
                                        <p> 8</p>
                                    </Grid>
                                    <hr style={{ marginTop: "-15px" }} />
                                    <br />

                                    {/* <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="Start" style={{ marginTop: "-30px" }}>
                                        <u> <h4>Assign Material</h4></u>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>Material</h4>
                                        <p>Material 1</p>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>No of Hrs</h4>
                                        <p> 8</p>
                                    </Grid>

                                    <hr style={{ marginTop: "-15px" }} />
                                    <br />
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="Start" style={{ marginTop: "-30px" }}>
                                        <u> <h4>Assign Equipment</h4></u>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>Employee</h4>
                                        <p>Employee 1</p>
                                    </Grid>

                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>No of Hrs</h4>
                                        <p> 8</p>
                                    </Grid>

                                    <hr style={{ marginTop: "-15px" }} />
                                    <br />
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="Start" style={{ marginTop: "-30px" }}>
                                        <u> <h4>Assign Subcontractor</h4></u>
                                    </Grid>
                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>Subcontractor</h4>
                                        <p>Subcontractor 1</p>
                                    </Grid>

                                    <Grid item xs={6} md={3} lg={12} display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: "-30px" }}>
                                        <h4>No of Hrs</h4>
                                        <p> 8</p>
                                    </Grid>

                                    <hr style={{ marginTop: "-15px" }} /> */}
                                </>
                            </React.Fragment>
                        }
                    >
                        <Button style={{ marginRight: "-20px" }}>
                            <img src={icon} alt="" width="15px" />
                        </Button>
                    </HtmlTooltip>
                </span>
                <p>{eventInfo.timeText}-{eventInfo.event.title}</p>
                <p>Assign User- {length}</p>
                <p>Labour Hrs- 8</p>
            </div>

        </div>
    );
}