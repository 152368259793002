import { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
    IconButton,
    Box,
    useTheme,
    OutlinedInput,
    Select,
    MenuItem,
    Checkbox,
    InputLabel,
    FormControl,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useBoolean } from 'src/hooks/use-boolean';
import RiskRoleDialog from 'src/components/risk-project-user-drop/role-drop-dialog';

const TeamMembersAdd = ({ name, label, options, url = '', reload = () => { }, value, onMemberSelect }) => {
    console.log("🚀 ~ TeamMembersAdd ~ options:", options)
    const [usersID, setUsersID] = useState([]);
    console.log("🚀 ~ TeamMembersAdd ~ usersID:", usersID)
    const searchParams = useSearchParams();
    const theme = useTheme();
    const add = useBoolean();
    const { enqueueSnackbar } = useSnackbar();

    const NewUserSchema = Yup.object().shape({
        risk_owner_id: Yup.array()
            .min(1, `${label} is required`)
            .of(Yup.string().required()),
    });

    // Updated Default Values to be an Array
    const defaultValues = useMemo(
        () => ({
            risk_owner_id: value || [],
        }),
        [value]
    );

    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        control,
        setValue,
        formState: { isSubmitting },
    } = methods;

    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';

    const defaultFilters = {
        name: '',
        role: [],
        status: 'all',
    };
    const [filters, setFilters] = useState(defaultFilters);

    useEffect(() => {
        getProjectStageOptions();
    }, []);

    const getProjectStageOptions = async () => {
        try {
            const data = await getRequest(endpoints.risk_management.risk_register.identification.AddUserID, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const transformedOptions = data.map(user => ({
                id: user.id,
                name: `${user.first_name} ${user.last_name}`,
            }));
            setUsersID(transformedOptions);
        } catch (error) {
            console.error("Error fetching user data: ", error);
            enqueueSnackbar("Failed to fetch users", { variant: "error" });
        }
    };

    const assignedToValue = useWatch({ control, name: 'risk_owner_id' }) || [];
    const selectedUsers = usersID.filter(user => assignedToValue.includes(user.id));

    const handleOwnerChange = (event, selectedIds) => {
        setValue(name, selectedIds);
        onMemberSelect(selectedIds); // Pass selected IDs up to parent
    };

    const handleUserAdded = () => {
        getProjectStageOptions();
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Controller
                name={name}
                control={control}
                defaultValue={value || []}
                render={({ field }) => (
                    <FormControl sx={{ width: 230 }}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            {...field}
                            multiple
                            label={label}
                            input={<OutlinedInput label={label} />}
                            renderValue={(selected) =>
                                selected
                                    .map((id) => {
                                        const user = usersID.find((u) => u.id === id);
                                        return user ? user.name : '';
                                    })
                                    .join(', ')
                            }
                            onChange={(event) => {
                                const selectedIds = event.target.value;
                                field.onChange(selectedIds);
                                handleOwnerChange(event, selectedIds); 
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxWidth: 100,
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {usersID.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    <Checkbox
                                        disableRipple
                                        size="small"
                                        checked={field.value.includes(option.id)}
                                    />
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
            <IconButton
                onClick={add.onTrue}
                className='cardColor'
                sx={{
                    margin: '5px 10px',
                    fontSize: 25,
                    padding: '7px',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: '#7AB13A',
                    },
                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Iconify icon="mingcute:add-line" />
            </IconButton>

            <RiskRoleDialog
                open={add.value}
                onClose={add.onFalse}
                reload={handleUserAdded}
            />
        </Box>
    );
};

export default TeamMembersAdd;
