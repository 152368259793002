/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Switch,
	Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'notistack';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Iconify from 'src/components/iconify';
import { handleErrorMessages } from '../../risk-register/feching';

const RiskMitigationBudgetCreate = ({ open, onClose, reload, currentItem, requestID }) => {
	console.log("🚀 ~ RiskMitigationBudgetCreate ~ currentItem ,currentItem:", currentItem.mitigation_budget_resources)
	const [switchChecked, setSwitchChecked] = useState(0);
	const [selectedBudgetStatus, setSelectedBudgetStatus] = useState(currentItem?.budgetstatus ?? 0);
	const [needResources, setNeedResources] = useState(0);
	const [originalValues, setOriginalValues] = useState(null);
	const [riskIds, setRiskIds] = useState([]);
	const [isFetchingRisks, setIsFetchingRisks] = useState(true);
	const [selectedRisk, setSelectedRisk] = useState(null);
	console.log("🚀 ~ RiskMitigationBudgetCreate ~ selectedRisk:", selectedRisk)


	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();

	const NewUserSchema = Yup.object().shape({
	});

	useEffect(() => {
		const fetchIdentification = async () => {
			try {
				const data = await getRequest(
					endpoints.risk_management.risk_register.identification.identification
				);
				console.log("🚀 ~ fetchIdentification ~ data:", data)
				setRiskIds(data || []);
			} catch (err) {
				console.error('Error fetching risk IDs:', err);
				enqueueSnackbar({
					variant: 'error',
					message: 'Failed to fetch risk IDs.',
				});
			} finally {
				setIsFetchingRisks(false);
			}
		};
		fetchIdentification();
	}, [enqueueSnackbar]);

	const matchedRisk = useMemo(() => {
		if (currentItem && riskIds.length > 0) {
			return (
				riskIds.find(
					(risk) => risk.id === currentItem?.identification_and_assessment?.id
				) || { id: '', risk_id: 'None' }
			);
		}
		return { id: '', risk_id: 'None' };
	}, [currentItem, riskIds]);

	const handleSwitchChange = (event) => {
		const isChecked = event.target.checked;
		setSwitchChecked(isChecked ? 1 : 0);
		setNeedResources(isChecked ? 1 : 0);
		if (!isChecked) {
			setSelectedBudgetStatus(0);
			setValue('budgetstatus', 0);
		}
		if (isChecked && fields.length === 0) {
			append({
				id: '',
				sr_number: 1,
				code: '',
				description: '',
				quantity: '',
				unit: '',
				unit_price: '',
				amount: '',
				hours_spent: '',
				amount_spent: '',
				balance: '',
				balance_hours: ''
			});
		}
	};


	// const clearMitigationFields = (index) => {
	// 	setValue(`mitigation[${index}].code`, '');
	// 	setValue(`mitigation[${index}].description`, '');
	// 	setValue(`mitigation[${index}].quantity`, '');
	// 	setValue(`mitigation[${index}].unit`, '');
	// 	setValue(`mitigation[${index}].unit_price`, '');
	// 	setValue(`mitigation[${index}].amount`, '');
	// 	setValue(`mitigation[${index}].hours_spent`, '');
	// 	setValue(`mitigation[${index}].amount_spent`, '');
	// 	setValue(`mitigation[${index}].balance`, '');
	// 	setValue(`mitigation[${index}].balance_hours`, '');
	// };



	const defaultValues = {
		request_id: currentItem?.request_id || requestID || '',
		risk_id: matchedRisk && matchedRisk.id ? matchedRisk : { id: '', risk_id: 'None' },
		sr_number: 1,
		mitigation: currentItem?.mitigation_budget_resources?.map((resource, index) => ({
			id: resource?.id || '',
			sr_number: index + 1,
			code: resource?.code || '',
			description: resource?.description || '',
			quantity: resource?.quantity || '',
			unit: resource?.unit || '',
			unit_price: resource?.unit_price || '',
			amount: resource?.amount || '',
			hours_spent: resource?.hours_spent || '',
			amount_spent: resource?.amount_spent || '',
			balance: resource?.balance || '',
			balance_hours: resource?.balance_hours || '',
			complete: resource?.complete || 0,  
		})) || [
				{
					id: '',
					sr_number: 1,
					code: '',
					description: '',
					quantity: '',
					unit: '',
					unit_price: '',
					amount: '',
					hours_spent: '',
					amount_spent: '',
					balance: '',
					balance_hours: ''
				}
			],
		budgetstatus: currentItem?.budget_approval_status ?? 0,
		amount_spent: currentItem?.amount_spent || 0,
		start_date: currentItem?.start_date ? new Date(currentItem?.start_date) : null,
		end_date: currentItem?.end_date ? new Date(currentItem?.end_date) : null,
		status: currentItem?.status || 'Not Started', // Default to 'Not Started'
		effectiveness_rating: currentItem?.effectiveness_rating || '',
		meeting_notes: currentItem?.meeting_notes || '',
		total_balance: currentItem?.total_balance || 0,
		total_budgeted_amount: currentItem?.total_budgeted_amount || 0,
		total_amount_spent: currentItem?.total_amount_spent || 0,
		total_hours_spent: currentItem?.total_hours_spent || 0,  // Set default value
		type: currentItem?.type || ''
	};
	const methods = useForm({
		resolver: yupResolver(NewUserSchema),
		defaultValues,
	});

	const handleRiskChange = (selectedOption) => {
		setSelectedRisk(selectedOption || { id: '', risk_id: 'None' });
		if (selectedOption && selectedOption.id) {
			const matchedRisk = riskIds.find((risk) => risk.id === selectedOption.id);
			console.log("🚀 ~ handleRiskChange ~ matchedRisk<><><><>><><><><>:", matchedRisk)
			if (matchedRisk) {
				setSwitchChecked(1);
				setNeedResources(1);
				setValue(`mitigation[0].code`, matchedRisk.mitigation_plan?.resources[0]?.code || '');
				setValue(`mitigation[0].description`, matchedRisk.description || '');
				setValue(`mitigation[0].quantity`, matchedRisk.mitigation_plan?.resources[0]?.quantity || '');
				setValue(`mitigation[0].unit`, matchedRisk.mitigation_plan?.resources[0]?.unit || '');
				setValue(`mitigation[0].unit_price`, matchedRisk.mitigation_plan?.resources[0]?.unit_price || '');
				setValue(`mitigation[0].amount`, matchedRisk.mitigation_plan?.resources[0]?.amount || '');
				setValue(`mitigation[0].hours_spent`, matchedRisk.mitigation_plan?.resources[0]?.hours_spent || '');
				setValue(`mitigation[0].amount_spent`, matchedRisk.mitigation_plan?.resources[0]?.amount_spent || '');
				setValue(`mitigation[0].balance`, matchedRisk.mitigation_plan?.resources[0]?.balance || '');
				setValue(`mitigation[0].balance_hours`, matchedRisk.mitigation_plan?.resources[0]?.balance_hours || '');
				setValue(`mitigation[0].complete`, matchedRisk.mitigation_plan?.resources[0]?.complete || '');
				console.log("🚀 ~ handleRiskChange ~<><><><>><><><><>:", matchedRisk.mitigation_plan?.resources[0]?.code)

			}
		} else {
			clearMitigationFields(0);
		}
	};


	const {
		control,
		reset,
		handleSubmit,
		setError,
		clearErrors,
		setValue,
		getValues,
		formState: { isSubmitting },
	} = methods;

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'mitigation',  // Ensure this matches the form field name
	});


	useEffect(() => {
		console.log("🚀 ~ RiskMitigationCreate ~ fields:", fields)
		console.log("Switch Checked:", switchChecked);
		console.log("Mitigation Resources:", mitigation);
	}, [switchChecked, setValue, fields]);

	const mitigation = useWatch({ control, name: 'mitigation' });
	useEffect(() => {
		const totalAmount = mitigation?.reduce((acc, curr) => {
			return acc + (parseFloat(curr.amount) || 0);
		}, 0);
		setValue('amount_spent', totalAmount);
	}, [mitigation, setValue]);

	const handleTotalBudgetedAmountChange = () => {
		const mitigations = getValues('mitigation'); // Get all mitigation activities
		const totalBudgetedAmount = mitigations.reduce((acc, curr) => {
			const amount = parseFloat(curr.amount) || 0;
			return acc + amount;
		}, 0);

		setValue('total_budgeted_amount', totalBudgetedAmount); // Set the total budgeted amount
		handleTotalAmountSpentChange(); // Recalculate total amount spent
		handleTotalBalanceChange(); // Recalculate total balance
	};
	const handleTotalHoursSpentChange = () => {
		const mitigations = getValues('mitigation'); // Get all mitigation activities
		const totalHoursSpent = mitigations.reduce((acc, curr) => {
			const hoursSpent = parseFloat(curr.hours_spent) || 0;
			return acc + hoursSpent;
		}, 0);

		setValue('total_hours_spent', totalHoursSpent); // Set the total hours spent
		handleTotalAmountSpentChange(); // Recalculate total amount spent
		handleTotalBalanceChange(); // Recalculate total balance
	};
	const handleTotalAmountSpentChange = () => {
		const mitigations = getValues('mitigation') || []; // Get all mitigation entries
		const totalAmountSpent = mitigations.reduce((sum, mitigation) => {
			const amountSpent = parseFloat(mitigation.amount_spent) || 0;
			return sum + amountSpent;
		}, 0);

		setValue('total_amount_spent', totalAmountSpent.toFixed(3)); // Set the total amount spent
		handleTotalBalanceChange(); // Recalculate total balance
	};
	const handleTotalBalanceChange = () => {
		const totalBudgetedAmount = parseFloat(getValues('total_budgeted_amount')) || 0;
		const totalAmountSpent = parseFloat(getValues('total_amount_spent')) || 0;

		const totalBalance = totalBudgetedAmount - totalAmountSpent;
		setValue('total_balance', totalBalance.toFixed(3)); // Set the total balance
	};
	const handleQuantityOrPriceChange = (index) => {
		const quantity = parseFloat(getValues(`mitigation[${index}].quantity`)) || 0;
		const unitPrice = parseFloat(getValues(`mitigation[${index}].unit_price`)) || 0;

		const amount = quantity * unitPrice;
		console.log("🚀 ~ handleQuantityOrPriceChange ~ amount:", amount)
		setValue(`mitigation[${index}].amount`, amount.toFixed(3)); // Set the calculated amount value
		handleTotalBudgetedAmountChange(); // Update the total budgeted amount
		calculateBalance(index); // Recalculate balance after amount is set
		handlePercentCompleteChange(index); // <-- Call here
		handleTotalAmountSpentChange();    // Trigger total amount spent recalculation


	};
	const handlePercentCompleteChange = (index) => {
		const amount = parseFloat(getValues(`mitigation[${index}].amount`)) || 0;
		const amountSpent = parseFloat(getValues(`mitigation[${index}].amount_spent`)) || 0;

		if (amount > 0) {
			const percentComplete = ((amountSpent / amount) * 100).toFixed(2); // Updated calculation logic
			setValue(`mitigation[${index}].complete`, percentComplete); // Set the calculated % Complete
		} else {
			setValue(`mitigation[${index}].complete`, '0'); // Set 0% if amount is 0
		}
	};
	const calculateAmountSpent = (index) => {
		const unitPrice = parseFloat(getValues(`mitigation[${index}].unit_price`)) || 0;
		const hoursSpent = parseFloat(getValues(`mitigation[${index}].hours_spent`)) || 0;

		const amountSpent = unitPrice * hoursSpent;

		setValue(`mitigation[${index}].amount_spent`, amountSpent.toFixed(2)); // Set with 2 decimal places
		calculateBalance(index); // Recalculate balance after amount_spent is set
		handlePercentCompleteChange(index); // <-- Call here
		handleTotalHoursSpentChange(); // Update total hours spent


	};
	const calculateBalanceHour = (index) => {
		const hours = parseFloat(getValues(`mitigation[${index}].quantity`)) || 0;
		const hoursSpent = parseFloat(getValues(`mitigation[${index}].hours_spent`)) || 0;
		const balanceHour = hours - hoursSpent;
		setValue(`mitigation[${index}].balance_hours`, balanceHour.toFixed(2));
	};
	const calculateBalance = (index) => {
		const amount = parseFloat(getValues(`mitigation[${index}].amount`)) || 0;
		const amountSpent = parseFloat(getValues(`mitigation[${index}].amount_spent`)) || 0;
		const balance = amount - amountSpent;
		setValue(`mitigation[${index}].balance`, balance.toFixed(2));
	};

	const handleRemove = (index) => {
		remove(index); 
		const currentMitigations = getValues('mitigation');

		currentMitigations.forEach((item, idx) => {
			item.sr_number = idx + 1; 
		});
		setValue('mitigation', currentMitigations);
		handleRecalculate(); 
		handleTotalAmountSpentChange();
	};

	const handleRecalculate = () => {
		const mitigations = getValues('mitigation');

		const totalBudgetedAmount = mitigations.reduce((acc, curr) => {
			const amount = parseFloat(curr.amount) || 0;
			return acc + amount;
		}, 0);
		setValue('total_budgeted_amount', totalBudgetedAmount.toFixed(0));
		const totalHoursSpent = mitigations.reduce((acc, curr) => {
			const hoursSpent = parseFloat(curr.hours_spent) || 0;
			return acc + hoursSpent;
		}, 0);

		setValue('total_hours_spent', totalHoursSpent.toFixed(3));
		const totalAmountSpent = totalBudgetedAmount * totalHoursSpent;
		setValue('total_amount_spent', totalAmountSpent.toFixed(3));
		const totalBalance = totalBudgetedAmount - totalAmountSpent;
		setValue('total_balance', totalBalance.toFixed(3));
	};
	const handleAddMore = () => {
		const currentMitigations = getValues('mitigation');
		const newSrNumber = currentMitigations.length + 1; // Increment the Sr. number based on the current length

		append({
			id: '',
			sr_number: newSrNumber, // Set the new Sr. number based on the length
			code: '',
			description: '',
			quantity: '',
			unit: '',
			unit_price: '',
			amount: '',
			hours_spent: '',
			amount_spent: '',
			balance: '',
			balance_hours: ''
		});
		handleTotalAmountSpentChange(); 
	};

	const typeCheckValue = useWatch({ control, name: 'type' });
	console.log("🚀 ~ RiskMitigationCreate ~ typeCheckValue:", typeCheckValue)

	const clearMitigationFields = (index) => {
		console.log("fields cklear kr di hai......")
		setValue(`mitigation[${index}].code`, '');
		setValue(`mitigation[${index}].description`, '');
		setValue(`mitigation[${index}].quantity`, '');
		setValue(`mitigation[${index}].unit`, '');
		setValue(`mitigation[${index}].unit_price`, '');
		setValue(`mitigation[${index}].amount`, '');
		setValue(`mitigation[${index}].hours_spent`, '');
		setValue(`mitigation[${index}].amount_spent`, '');
		setValue(`mitigation[${index}].balance`, '');
		setValue(`mitigation[${index}].balance_hours`, '');
	};

	const Typeoptions = [
		{ label: 'quantity', value: 0 },
		{ label: 'hours', value: 1 },
	];
	const onSubmit = handleSubmit(async (form) => {
		console.log("🚀 ~ onSubmit ~ form:", form);
		let hasError = false;
		let hasWarning = false; // Flag to track warning errors
		if (!form.request_id || form.request_id.trim() === '') {
			setError("request_id", {
				type: "manual",
				message: "Requested ID is required.",
			});
			enqueueSnackbar("Requested ID is required", { variant: "error" });
			hasError = true;
		}
		if (!form.risk_id) {
			setError("risk_id", {
				type: "manual",
				message: "Risk ID is required",
			});
			enqueueSnackbar("Risk ID is required.", { variant: "error" });
			hasError = true;
		}
		if (switchChecked === 1 && needResources === 1) {
			form.mitigation.forEach((item, index) => {
				if (!item.code) {
					setError(`mitigation[${index}].code`, {
						type: "manual",
						message: "Code is required",
					});
					enqueueSnackbar("Code is required.", { variant: "error" });
					hasError = true;
				}
				if (!item.quantity || item.quantity <= 0) {
					setError(`mitigation[${index}].quantity`, {
						type: "manual",
						message: typeCheckValue === 1 ? "Hours is required" : "Quantity is required",
					});
					enqueueSnackbar(
						typeCheckValue === 1
							? "Hours is required"
							: "Quantity is required",
						{ variant: "error" }
					);
					hasError = true;
				}
				if (!item.unit_price || item.unit_price <= 0) {
					setError(`mitigation[${index}].unit_price`, {
						type: "manual",
						message: "Unit Price is required",
					});
					enqueueSnackbar("Unit Price is required.", { variant: "error" });
					hasError = true;
				}
			}
			);

			if (hasError) return;
		} else {
			form.mitigation.forEach((_, index) => {
				clearErrors(`mitigation[${index}].code`);
				clearErrors(`mitigation[${index}].quantity`);
				clearErrors(`mitigation[${index}].unit_price`);
			});
		}
		if (form.request_id) {
			clearErrors("request_id");
		}
		if (form.risk_id) {
			clearErrors("risk_id");
		}
		const typeValue = typeCheckValue === 1 ? 'hours' : 'quantity'; // Set type based on watched value
		console.log("🚀 ~ onSubmit ~ typeCheckValue:", typeCheckValue);
		if (hasWarning) {
			enqueueSnackbar("Please resolve warning errors before submitting.", { variant: "warning" });
			return;
		}
		const payload = {
			request_id: form?.request_id?.trim() || null,
			risk_id: selectedRisk.id || { id: '', risk_id: 'None' },
			mitigation_budget_resources: switchChecked ? form?.mitigation : [],
			amount_spent: form?.amount_spent || '',
			budget_approval_status: form?.budgetstatus === 0 ? 0 : 1,
			total_balance: form?.budgetstatus !== 0 ? form?.total_balance : 0,
			total_budgeted_amount: form?.budgetstatus !== 0 ? form?.total_budgeted_amount : 0,
			total_amount_spent: form?.budgetstatus !== 0 ? form?.total_amount_spent : 0,
			total_hours_spent: form?.budgetstatus !== 0 ? form?.total_hours_spent : 0,
			need_resources: switchChecked,
			complete: form?.complete,
			quantity: typeCheckValue === 0 ? form?.mitigation?.quantity : [],
			hours: typeCheckValue === 1 ? form?.mitigation?.quantity : 0,
			balance_hours: typeCheckValue === 1 ? form?.mitigation?.balance_hours : 0,
			type: typeValue,
		};

		if (payload.request_id === null) {
			enqueueSnackbar("Mitigation strategy is required", { variant: "error" });
			return;
		}

		try {
			const response = await postRequest(
				currentItem?.id
				?`${endpoints.risk_management.mitigation_budget_request.crud}/${currentItem.id}?_method=PUT`
					: `${endpoints.risk_management.mitigation_budget_request.crud}`,
				payload,
				false
			);
			console.log("🚀 ~ onSubmit ~ response:", response);
			if (response.metadata && response.metadata.success) {
				reload();
				onClose();
				reset(form);  // Reset the form with the newly submitted currentItem
				enqueueSnackbar(response.metadata.message, { variant: 'success' });
			} else {
				handleErrorMessages(response.metadata.message);
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	});
	const budgetStatusOptions = [
		{ label: 'Yes', value: 1 },
		{ label: 'No', value: 0 },
	];

	useEffect(() => {
		if (currentItem) {
			if (currentItem.mitigation_budget_resources && currentItem.mitigation_budget_resources.length > 0) {
				setSwitchChecked(1);
				setNeedResources(1);
			} else {
				setSwitchChecked(0);
				setNeedResources(0);
				setSelectedBudgetStatus(0);
			}
		} else {
			setSwitchChecked(0);
			setNeedResources(0);
		}
	}, [currentItem]);

	useEffect(() => {
		console.log("Switch Checked:", switchChecked);
		console.log("Need Resources:", needResources);
	}, [switchChecked, needResources]);

	useEffect(() => {
		if (currentItem) {
			setOriginalValues({
				...defaultValues,
			});
			reset({
				...defaultValues,
				type: currentItem?.type === 'hours' ? 1 : 0,
			});
			setValue('type', currentItem?.type === 'hours' ? 1 : 0);
		} else {
			setValue('type', 0);
		}
	}, [currentItem, reset, open, setValue]);

	useEffect(() => {
		console.log("Switch Checked:", switchChecked);
		if (switchChecked === 0) {
			setSelectedBudgetStatus(0);
			setValue('total_amount_spent', 0);
			setValue('total_balance', 0);
			setValue('total_budgeted_amount', 0);
			setValue('total_hours_spent', 0);
		} else if (getValues('budgetstatus') === 1) {
			setSelectedBudgetStatus(1);
			handleTotalBudgetedAmountChange();
			handleTotalAmountSpentChange();
			handleTotalHoursSpentChange();
			handleTotalBalanceChange();
		} else {
			console.log("buget No hai");
			setSelectedBudgetStatus(0);
		}
	}, [switchChecked, getValues('budgetstatus'), setSelectedBudgetStatus, setValue]);

	const handleClose = () => {

		if (currentItem) {
			console.log("🚀 ~ handleClose ~ currentItem:", currentItem);
			if (currentItem.mitigation_budget_resources && currentItem.mitigation_budget_resources.length > 0) {
				setSwitchChecked(1);
				setNeedResources(1);
			} else {
				setSwitchChecked(0);
				setNeedResources(0);
			}
		} else {
			setSwitchChecked(0);
			setNeedResources(0);
		}
		if (currentItem) {
			setOriginalValues({
				...defaultValues,
			});
			reset({
				...defaultValues,
				type: currentItem?.type === 'hours' ? 1 : 0,
			});
			setValue('type', currentItem?.type === 'hours' ? 1 : 0);
		} else {
			setValue('type', 0);
		}
		clearErrors();
		onClose();
	};

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose();
				}
			}}
			fullWidth
			maxWidth="md"
			sx={{
				borderRadius: 'none',
				padding: '60px',
				fontSize: '13px',
				fontFamily: 'arial',
			}}
			transitionDuration={{
				enter: theme.transitions.duration.shortest,
				exit: theme.transitions.duration.shortest - 80,
			}}
		>
			<FormProvider methods={methods} onSubmit={onSubmit}>
				<DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
					<span
						className='cardColor'
						style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
						&nbsp;&nbsp;  Risk Mitigation Budget Request Form &nbsp;&nbsp;&nbsp;&nbsp;
					</span>
					<IconButton onClick={handleClose}>
						<Iconify icon="mdi:close" />
					</IconButton>


				</DialogTitle>

				<DialogContent sx={{ borderRadius: '1px' }}>
					<Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
						<RHFTextField name="request_id" label="Requested ID" disabled />
						<RHFAutocomplete
							name="risk_id"
							label="Risk ID"
							options={[{ id: '', risk_id: 'None' }, ...riskIds]}
							getOptionLabel={(option) => option.risk_id || 'None'}
							isOptionEqualToValue={(option, value) => option.id === value.id}
							renderOption={(props, option) => (
								<li {...props} key={option.id || 'none'}>
									{option.risk_id}
								</li>
							)}
							disabled={isFetchingRisks}
							value={selectedRisk} // Set the controlled value here
							onChange={(_, selectedOption) => handleRiskChange(selectedOption)}
						/>

					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
						<Typography sx={{ fontWeight: "bold" }}>Do You Need Resources</Typography>
						<Switch
							checked={switchChecked === 1}
							onChange={handleSwitchChange}
							color="primary" // You can set the color of the switch here
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</Box>
					<Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
						{switchChecked === 1 && (
							<RHFAutocomplete
								sx={{ width: '50%' }}
								name="type"
								label="Type Check"
								options={Typeoptions}
								value={Typeoptions.find(option => option.value === getValues('type')) || Typeoptions[0]}
								getOptionLabel={(option) => option?.label || ''}
								onChange={(event, value) => {
									const selectedValue = value ? value.value : '';
									const previousValue = getValues('type');
									if ((previousValue === 0 && selectedValue === 1) || (previousValue === 1 && selectedValue === 0)) {
										fields.forEach((_, index) => clearMitigationFields(index));
									}
									setValue('type', selectedValue);
								}}
							/>

						)}
					</Box>
					{switchChecked === 1 && (
						<>
							{/* Mitigation Resources */}
							{fields.map((item, index) => (
								<>
									<Box key={item.id} mt={2}>
										<Typography mb={2} sx={{ fontWeight: 'bold' }}>Resources Required</Typography>
										<Box
											mb={3}
											display="grid"
											gridTemplateColumns={{
												xs: 'repeat(1, 1fr)',
												sm: 'repeat(3, 1fr)',
											}}
											alignItems="center"
											columnGap={2}
											rowGap={3}
										>
											<Controller
												name={`mitigation[${index}].sr_number`}
												control={control}
												render={({ field }) => (
													<RHFTextField {...field} label="Sr no" type="number" disabled /> // It will now show numbers like 1, 2, 3
												)}
											/>
											<Controller
												name={`mitigation[${index}].code`}
												control={control}
												render={({ field, fieldState: { error } }) => (
													<RHFTextField
														{...field}
														label="Code*"
														error={!!error}
														helperText={error?.message}
													/>
												)}
											/>
											<Controller
												name={`mitigation[${index}].description`}
												control={control}
												render={({ field }) => (
													<RHFTextField {...field} label="Description" />
												)}
											/>
											<Controller
												name={`mitigation[${index}].quantity`}
												control={control}
												render={({ field }) => (
													<RHFTextField
														{...field}
														label={typeCheckValue === 1 ? 'Hours*' : 'Qty*'}
														type="number"
														onChange={(e) => {
															const value = Number(e.target.value); // Convert to a number
															if (value >= 0) {
																field.onChange(value);
																handleQuantityOrPriceChange(index);
																calculateBalanceHour(index);
															}
														}}
														onBlur={() => {
															calculateBalanceHour(index);
														}}
													/>
												)}
											/>
											<RHFTextField name={`mitigation[${index}].unit`} label="Unit"
												type="text"
											/>
											<Controller
												name={`mitigation[${index}].unit_price`}
												control={control}
												render={({ field }) => (
													<RHFTextField
														{...field}
														label="Unit Price*"
														type="number"
														onChange={(e) => {
															const value = Number(e.target.value); // Convert to a number
															if (value >= 0) {
																field.onChange(value);
																handleQuantityOrPriceChange(index);
																calculateAmountSpent(index);
															}
														}}
													/>
												)}
											/>
											<Controller
												name={`mitigation[${index}].amount`}
												control={control}
												render={({ field }) => (
													<RHFTextField
														{...field}
														label="Amount"
														type="number"
														disabled
														onChange={(e) => {
															field.onChange(e);
															if (typeCheckValue === 1) {
																calculateBalance(index);
															} else {
																calculateBalance(index);
																handlePercentCompleteChange(index);
															}
														}}
													/>
												)}
											/>
											<Controller
												name={`mitigation[${index}].amount_spent`}
												control={control}
												render={({ field }) => (
													<RHFTextField
														{...field}
														label="Amount Spent"
														type="number"
														disabled={typeCheckValue === 1} // Disable when typeCheckValue is 1
														onChange={(e) => {
															field.onChange(e); // Update react-hook-form state
															calculateBalance(index); // Recalculate balance
															handlePercentCompleteChange(index); // Handle percent complete change
															handleTotalAmountSpentChange(); // <-- Call this function to recalculate total amount spent
														}}
													/>
												)}
											/>
											<Controller
												name={`mitigation[${index}].balance`}
												control={control}
												render={({ field }) => (
													<RHFTextField
														{...field}
														label="Total Balance"
														type="number"
														disabled
													/>
												)}
											/>
											{selectedBudgetStatus !== 0 && (
												<>
													{typeCheckValue === 1 && (
														<Controller
															name={`mitigation[${index}].hours_spent`}
															control={control}
															render={({ field }) => (
																<RHFTextField
																	{...field}
																	label="Hours Spent"
																	type="number"
																	onChange={(e) => {
																		field.onChange(e);
																		calculateBalanceHour(index);
																		calculateAmountSpent(index);

																	}}
																/>
															)}
														/>
													)}

													{typeCheckValue === 1 && (
														<Controller
															name={`mitigation[${index}].balance_hours`}
															control={control}
															render={({ field }) => (
																<RHFTextField {...field} label="Balance Hours" type="number" disabled />
															)}
														/>
													)}
												</>
											)}
											<Controller
												name={`mitigation[${index}].complete`}
												control={control}
												render={({ field: { onChange, value, ...field } }) => (
													<RHFTextField
														{...field}
														label="Complete %"
														type="number"
														value={value && !isNaN(value) && isFinite(value) ? value : '0'}
														disabled
														onChange={(e) => {
															let inputValue = e.target.value;
															if (inputValue === '' || isNaN(inputValue) || !isFinite(inputValue)) {
																inputValue = 0;
															}
															onChange(parseFloat(inputValue));
														}}
													/>
												)}
											/>
											<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
												<LoadingButton
													className='dark-button'
													variant="contained"
													onClick={handleAddMore}
													sx={{ padding: '8px 16px' }}
												>
													Add More
												</LoadingButton>
												{fields.length > 1 && (
													<Button variant="outlined"
														sx={{ padding: "7px 16px" }}
														color="inherit"
														onClick={() => handleRemove(index)}>
														Remove
													</Button>

												)}
											</Box>
										</Box>
									</Box>
								</>
							))}
							<Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
								<RHFSelect
									native
									sx={{ width: '100%' }}
									name="budgetstatus"
									label="Budget Approval Status"
									InputLabelProps={{ shrink: true }}
									defaultValue={
										getValues('budgetstatus') !== undefined
											? getValues('budgetstatus')
											: 0
									}
									onChange={(event) => {
										const selectedValue = event.target.value ? Number(event.target.value) : 0;
										setValue('budgetstatus', selectedValue);
										setSelectedBudgetStatus(selectedValue);
									}}
								>
									{budgetStatusOptions.map((option) => (
										<option key={option.value} value={option.value}>
											{option.label}
										</option>
									))}
								</RHFSelect>
								{selectedBudgetStatus !== 0 && (
									<>
										<Controller
											name="total_budgeted_amount"
											control={control}
											render={({ field }) => (
												<RHFTextField {...field} label="Total Budgeted Amount" type="number" disabled />
											)}
										/>
										{typeCheckValue === 1 && (
											< Controller
												name="total_hours_spent"
												control={control}
												render={({ field }) => (
													<RHFTextField {...field} label="Total Hours Spent" type="number" disabled />
												)}
											/>
										)}
										<Controller
											name="total_amount_spent"
											control={control}
											render={({ field }) => (
												<RHFTextField {...field} label="Total Amount Spent" type="number" disabled />
											)}
										/>
										<Controller
											name="total_balance"
											control={control}
											render={({ field }) => (
												<RHFTextField {...field} label="Total Balance Amount" type="number" disabled />
											)}
										/>
									</>
								)}
							</Box>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="inherit" onClick={handleClose}>
						Cancel
					</Button>
					<LoadingButton type="submit" variant="contained" className='dark-button'
						loading={isSubmitting}>
						{currentItem ? 'Create Mitigation' : 'Update Mitigation'}
					</LoadingButton>

				</DialogActions>
			</FormProvider>
		</Dialog>
	);
};
export default RiskMitigationBudgetCreate;