import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';
import { endpoints } from 'src/utils/axios';
import { postRequest } from 'src/services';

const SettingModal = ({ open, onClose, onSave, labels }) => {
    const theme = useTheme();
    const [JobCostingLabels, setJobCostingLabels] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true);
            setTimeout(() => {
                const defaultLabels = labels?.map((label) => ({
                    ...label,
                    value: label.value ?? false,
                }));
                setJobCostingLabels(defaultLabels);
                setLoading(false);
            }, 1000);
        } else {
            setJobCostingLabels([]);
        }
    }, [open, labels]);

    const handleSubmit = async () => {
        setLoading(true);
        try { 
            const visibleColumns = JobCostingLabels.map((label) => ({
                key: label.key,
                value: label.value ? 1 : 0, 
            }));
            const payload = visibleColumns.reduce((acc, column) => {
                acc[column.key] = column.value;
                return acc;
            }, {});
            const response = await postRequest(endpoints.job_costing.setting_post, payload);
            if (response.metadata && response.metadata.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: 'Settings updated successfully!',
                });
                onSave(JobCostingLabels); 
                onClose(); 
            } else {
                enqueueSnackbar({
                    variant: 'error',
                    message: response.metadata.message || 'Error updating settings.',
                });
            }
        } catch (error) {
            enqueueSnackbar({
                variant: 'error',
                message: 'Error submitting form.',
            });
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (index) => (event) => {
        const updatedLabels = [...JobCostingLabels];
        updatedLabels[index].value = event.target.checked;
        setJobCostingLabels(updatedLabels); 
    };

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="lg"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'Inter, Arial, sans-serif',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <DialogTitle sx={{ justifyContent: 'space-evenly', boxSizing: 'content-box' }}>
                <span style={{ padding: '7px', marginLeft: '-25px', fontWeight: '700', color: "white" }} className='cardColor'>
                    &nbsp;&nbsp; Job Costing &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
            </DialogTitle>
            <Divider />
            {loading ? (
                <Box height={700}>
                    <LoadingScreen />
                </Box>
            ) : (
                <DialogContent
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                        gap: '20px',
                        padding: '50px',
                        borderRadius: '1px',
                    }}
                >
                    {JobCostingLabels?.map((label, index) => (
                        <Paper
                            key={label.key}
                            style={{
                                padding: '5px 15px',
                                borderRadius: '4px',
                                boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                marginTop: '10px',
                                border: '1px solid #CED4DA',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={label?.value}
                                        onChange={handleCheckboxChange(index)}
                                        disabled={label?.disabled}
                                    />
                                }
                                label={label?.name}
                            />
                        </Paper>
                    ))}
                </DialogContent>
            )}
            <DialogActions>
                <Button variant="outlined" color="inherit" onClick={onClose}>
                    Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" className='dark-button' loading={loading} onClick={handleSubmit}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SettingModal;
