import React from 'react';
import { Box, Typography } from '@mui/material';
import './style.css';

const RenderField = ({ label, value, className }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
      }}
    >
      <Typography className='my-risk-label'>{label}</Typography>
      <Typography className={`my-risk-value ${className}`}>
        {value}
      </Typography>
    </Box>
  );
}

export default RenderField;
