import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
//
import { useState, useEffect, useCallback } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { CALENDAR_COLOR_OPTIONS } from 'src/_mock/_calendar';
// api
import { useGetEvents, updateEvent, payload, eventsData, leadpayload, leadsEventsData } from 'src/api/calendar';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
//
import { useCalendar, useEvent } from '../hooks';
import { StyledCalendar } from '../styles';
import CalendarForm from '../calendar-form';
import CalendarToolbar from '../calendar-toolbar';
import CalendarFilters from '../calendar-filters';
import CalendarFiltersResult from '../calendar-filters-result';
import Image from 'src/components/image';
import { endpoints } from 'src/utils/axios';
import { baseURL, token } from 'src/config-global';
import { Autocomplete, Box, FormControl, Grid, InputLabel, Select, TextField } from '@mui/material';
// import { RHFSelect, RHFTextField } from 'src/components/hook-form';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';

import { useSearchParams } from 'src/routes/hooks';
// import FormProvider from 'src/components/hook-form/form-provider';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';


// ----------------------------------------------------------------------

const defaultFilters = {
  colors: [],
  startDate: null,
  endDate: null,
};

// ----------------------------------------------------------------------
// const BidURL = `http://192.168.100.59:8000/api${endpoints.bids_due}`;
// const LeadURL = `http://192.168.100.59:8000/api${endpoints.leads_due}`;
// const ProjectURL = `http://192.168.100.59:8000/api${endpoints.project_due}`;


const BidURL = `https://web.ezelogs.com/api${endpoints.bids_due}`;
const LeadURL = `https://web.ezelogs.com/api${endpoints.leads_due}`;
const ProjectURL = `https://web.ezelogs.com/api${endpoints.project_due}`;


export default function CalendarView() {

  const navigate = useNavigate();

  const searchParams = useSearchParams();

  const authToken = searchParams.get('token') || '';
  const headers = {
    Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
    currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
    currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
  }



  ///////////////////////////////////////////

  const theme = useTheme();

  const settings = useSettingsContext();

  const smUp = useResponsive('up', 'sm');

  const openFilters = useBoolean();
  const [userList, setUserList] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventLoading] = useState(false);
  const [activeType, setActiveType] = useState('project'); // Initialize with the default active type
  const [ticket, setTicket] = useState('')
  // const { events, eventsLoading } = useGetEvents();

  const [slectedtype, setSlectedtype] = useState('project')
  console.log("events", events)

  useEffect(() => {
    getProjectEvents(ProjectURL)
    localStorage.setItem('authToken', authToken)
    getUsers()
    getTicket()
  }, [])


  const onTypeChange = (val) => {
    setActiveType(val);
    setSlectedtype(val);

    if (val == 'leads') {
      getLeadsEvents(LeadURL)
    } else if (val == 'bids') {
      getBidsEvents(BidURL)
    } else if (val == 'project') {
      // getEvents(URL3)
      getProjectEvents(ProjectURL)
    }
  }

  const getUsers = () => {
    setEventLoading(true)
    fetch(`${baseURL}/company/users`, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {
        let arr = []
        if (data.metadata) {
          if (data.metadata.success) {
            data.payload.map((user) => {
              if (user.role === 'Lead Management') {
                arr.push({ name: `${user.first_name}  ${user.last_name}`, id: user.id })
              }
            })
          }
        }
        setUserList(arr)
        console.log(data, "ya hy users ")


      }).catch(err => {
        console.log(err)


      })
  }
  const getTicket = () => {

    fetch(`${baseURL}/company/leads/ticket-number`, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {
        console.log(data, "ticket")
        setTicket(data?.ticket_number)
      }).catch(err => {
        console.log(err)


      })
  }

  const getLeadsEvents = (url) => {
    setEventLoading(true)
    fetch(url, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {

        const events = data?.payload.map((event) => ({
          ...event,
          textColor: getRandomHexColor(),
          date: formatDueDate(event.due_date)
        }));
        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log(err)
        setEventLoading(false)

      })
  }

  const getBidsEvents = (url) => {
    setEventLoading(true)
    fetch(url, {
      method: 'GET',
      headers: headers,
      // HTTP_ORIGIN: 'https://www.mis.ezelogs.com',
    }).then(res => res.json())
      .then((data) => {

        const events = data?.payload.map((event) => ({
          ...event,
          textColor: getRandomHexColor(),
          title: event.project_title,
          date: event.bid_due_date_and_time
        }));
        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log(err)
        setEventLoading(false)

      })
  }

  const getProjectEvents = (url) => {
    // const events = payload.map((event) => ({
    //   // ...event,
    //   textColor: getRandomHexColor(),
    //   title: event.name,
    //   start: formatDueDate(event.start_date),
    //   end: formatDueDate(event.end_date),

    // }));
    console.log("transform", events);
    setEvents(events)
    setEventLoading(true)
    fetch(url, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {

        const events = data?.payload.map((event) => ({
          ...event,
          textColor: getRandomHexColor(),
          title: event.name,
          start: formatDueDate(event.start_date),
          end: formatDueDate(event.end_date),

        }));
        console.log("transform", events);
        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log("this is", err)
        setEventLoading(false)

      })
  }







  const formatDueDate = (dateString) => {
    const dateObject = new Date(dateString);

    // Check if dateObject is valid
    if (isNaN(dateObject)) {
      // Handle invalid date (e.g., return an error message)
      return "Invalid Date";
    }

    // Convert to ISO string
    const formattedDate = dateObject.toISOString();
    return formattedDate;
  }


  function getRandomHexColor() {
    // Generate random values for red, green, and blue components
    var red = Math.floor(Math.random() * 256);
    var green = Math.floor(Math.random() * 256);
    var blue = Math.floor(Math.random() * 256);

    // Convert decimal values to hexadecimal and ensure they have two digits
    var redHex = red.toString(16).padStart(2, '0');
    var greenHex = green.toString(16).padStart(2, '0');
    var blueHex = blue.toString(16).padStart(2, '0');

    // Construct the hex color string
    var hexColor = '#' + redHex + greenHex + blueHex;

    return hexColor;
  }



  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const {
    payloadIds,
    calendarRef,
    //
    view,
    date,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onSelectRange,
    onClickEvent,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    selectEvent,
    selectEventId,
    selectedRange,
    //
    onClickEventInFilters,
  } = useCalendar();

  const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);
  console.log(selectEvent, "selected")
  useEffect(() => {
    onInitialView();
  }, [onInitialView]);

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

  const dataFiltered = applyFilter({
    inputData: events,
    filters,
    dateError,
  });

  const renderResults = (
    <CalendarFiltersResult
      filters={filters}
      onFilters={handleFilters}
      canReset={canReset}
      onResetFilters={handleResetFilters}
      results={dataFiltered.length}
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );
  // Add an onClick handler to each button
  const handleButtonClick = (type) => {
    onTypeChange(type);
  };

  const handleEventClick = (eventInfo) => {
    console.log('Event clicked:', eventInfo)
    const queryParams = new URLSearchParams({
      token: searchParams.get('token') || localStorage.getItem('authToken') || '',
      company_id: searchParams.get('company_id') || localStorage.getItem('companyId'),
      project_id: searchParams.get('project_id') || localStorage.getItem('projectId')
    }).toString();
    ;
    navigate(`/project-calendar?${queryParams}`);
  };


  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>

        {/*
        <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        > */}
        {/* <Image alt="grid" src="/assets/images/ezelogs-logo2.png" /> */}
        {/* <Typography variant="h4" textAlign={'center'} sx={{ flex: 1, marginRight: 22 }}>Company Calendar</Typography> */}



        {/* <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpenForm}
          >
            New Event
          </Button> */}
        {/* </Stack> */}

        {canReset && renderResults}

        <Box display={'flex'} marginBottom={2} >

          {/*
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['Company Calender', 'Project Calender']}
            sx={{ width: '20%' }}
            // value={slectedtype}
            // onChange={(e, value) => onTypeChange(value)}
            renderInput={(params) => <TextField {...params} label="Select Calender" />}
          /> */}

          <FormControl>
            <Select
              style={{ width: "250px" }}
              value={activeType}
              onChange={(event) => handleButtonClick(event.target.value)}
            >
              <MenuItem value="project" disabled={activeType === 'project'}>Projects</MenuItem>
              <MenuItem value="leads" disabled={activeType === 'leads'}>Leads</MenuItem>
              <MenuItem value="bids" disabled={activeType === 'bids'}>Bids</MenuItem>
            </Select>
          </FormControl>

          {/* <Button
            variant="contained"
            sx={{
              width: 100,
              padding: 1.5,
              marginLeft: 1,
              background: activeType === 'project' ? '#8BC443' : '#BEB397',
              ':disabled': {
                background: '#8BC443', // Color for disabled state
                color: 'white'
              },
              ':hover': {
                background: '#A79F76', // Color for hover state
              },
            }}
            onClick={() => handleButtonClick('project')}
            disabled={activeType === 'project'}
          >
            Projects
          </Button>

          <Button
            variant="contained"
            sx={{
              width: 100,
              padding: 1.5,
              marginLeft: 1,
              background: activeType === 'leads' ? '#8BC443' : '#BEB397',
              ':disabled': {
                background: '#8BC443', // Color for disabled state
                color: 'white'
              },
              ':hover': {
                background: '#A79F76', // Color for hover state
              },
            }}
            onClick={() => handleButtonClick('leads')}
            disabled={activeType === 'leads'}
          >
            Leads
          </Button>

          <Button
            variant="contained"
            sx={{
              width: 100,
              padding: 1.5,
              marginLeft: 1,
              background: activeType === 'bids' ? '#8BC443' : '#BEB397',
              ':disabled': {
                background: '#8BC443', // Color for disabled state
                color: 'white'
              },
              ':hover': {
                background: '#A79F76', // Color for hover state
              },
            }}
            onClick={() => handleButtonClick('bids')}
            disabled={activeType === 'bids'}
          >
            Bids
          </Button> */}

          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['project', 'leads', 'bids']}
            sx={{ width: '30%', marginLeft: 2 }}
            value={slectedtype}
            onChange={(e, value) => onTypeChange(value)}
            renderInput={(params) => <TextField {...params} label="Select " />}
          /> */}


        </Box>

        {
          slectedtype &&
          <Card>
            <StyledCalendar>
              <CalendarToolbar
                date={date}
                view={view}
                loading={eventsLoading}
                onNextDate={onDateNext}
                onPrevDate={onDatePrev}
                onToday={onDateToday}
                onChangeView={onChangeView}
                onOpenFilters={openFilters.onTrue}
              />

              <Calendar
                weekends
                editable
                droppable
                selectable
                rerenderDelay={10}
                allDayMaintainDuration
                eventResizableFromStart
                ref={calendarRef}
                initialDate={date}
                initialView={view}
                dayMaxEventRows={3}
                eventDisplay="block"
                events={dataFiltered}
                headerToolbar={false}
                select={onSelectRange}
                eventClick={handleEventClick}
                _resize={true}
                eventResizeStart={(args) => console.log(args)}
                eventResizeStop={(arg) => console.log(arg)}
                height={smUp ? 720 : 'auto'}
                eventDrop={(arg) => {
                  console.log('event drop huye', arg);
                  // onDropEvent(arg, updateEvent);
                }}
                eventResize={(arg) => {
                  console.log('event resize', arg);
                  // onResizeEvent(arg, updateEvent);
                }}
                plugins={[
                  listPlugin,
                  dayGridPlugin,
                  timelinePlugin,
                  timeGridPlugin,
                  interactionPlugin,
                ]}
              />
            </StyledCalendar>
          </Card>
        }
      </Container>

      <Dialog
        // fullWidth
        // maxWidth="sm"
        open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {selectEvent?.id == undefined ? 'Create Lead Details' : 'Update Lead'}
        </DialogTitle>

        <CalendarForm
          selectEvent={selectEvent}
          userList={userList}
          LeadURL={LeadURL}
          payloadIds={payloadIds}
          getLeadsEvents={getLeadsEvents}
          currentEvent={currentEvent}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={onCloseForm}
          ticket={ticket}
          refetchTicket={getTicket}
          isEdit={selectEvent?.id == undefined ? false : true}
        />
      </Dialog>



      {/* show detail model */}
      {/* <Dialog
        fullWidth
        maxWidth="xs"
        open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {openForm && <> {currentEvent?.id ? 'Edit Event' : 'Add Project'}</>}
        </DialogTitle>

        <CalendarForm
          currentEvent={currentEvent}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={onCloseForm}
        />
      </Dialog> */}
      {/* show detail model */}



      <CalendarFilters
        open={openFilters.value}
        onClose={openFilters.onFalse}
        //
        filters={filters}
        onFilters={handleFilters}
        //
        canReset={canReset}
        onResetFilters={handleResetFilters}
        //
        dateError={dateError}
        //
        events={events}
        colorOptions={CALENDAR_COLOR_OPTIONS}
        onClickEvent={onClickEventInFilters}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, filters, dateError }) {
  const { colors, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  inputData = stabilizedThis.map((el) => el[0]);

  if (colors.length) {
    inputData = inputData.filter((event) => colors.includes(event.color));
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (event) =>
          fTimestamp(event.start) >= fTimestamp(startDate) &&
          fTimestamp(event.end) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
