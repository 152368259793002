import isEqual from 'lodash/isEqual';
import { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { useSearchParams } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
import { CardContent, Checkbox, IconButton, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import Header from '../SideCards.js/header';
import SettingModal from './settings-modal';
import RiskRegisterCreate from './create';
import { useNavigate } from 'react-router';
import { endpoints } from 'src/utils/axios';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { LoadingScreen } from 'src/components/loading-screen';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { CSVLink } from 'react-csv';
import { useRef } from 'react'; // Import useRef
import ImportDilaog from './importModal';
import FileAttachment from 'src/components/file-thumbnail/file-attachments';
import PdfTable from './reisterMainpdf';
import axios from 'axios';
import { baseURL } from 'src/config-global';
import { TABLE_HEAD } from './register-table-head';
import { getSeverityLabel } from './Severity-rating-function/rating-coloring';
import "./Severity-rating-function/RiskAnalysis.css";
import { getCsvData } from './getCsv';

export default function RiskRegister() {
  const csvLinkRef = useRef();
  const create = useBoolean();
  const remove = useBoolean();
  const searchParams = useSearchParams();
  const table = useTable();
  const confirm = useBoolean();
  const add = useBoolean();
  const navigate = useNavigate();
  const [openDialog2, setOpenDialog2] = useState(false);
  const handleOpenDialog2 = () => setOpenDialog2(true);
  const handleCloseDialog2 = () => setOpenDialog2(false);
  const [projectStageOptions, setProjectStageOptions] = useState([]);
  const [riskCategoryOptions, setRiskCategoryOptions] = useState([]);
  const [riskOwnersOptions, setRiskOwnersOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  console.log("🚀 ~ tableData>>>>>>>:", tableData)
  const [getLoading, setGetLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [riskLabels, setRiskLabels] = useState([]);
  const [actionVisible, setActionVisible] = useState(true);
  const [profile, SetProfile] = useState();
  const [projectname, setprojectname] = useState();
  console.log("🚀 ~ projectname:", projectname)
  const [filteredData, setFilteredData] = useState([]);
  const [areIconsVisible, setAreIconsVisible] = useState(false);


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };


  const isColumnVisible = (columnKey) => {
    const label = riskLabels.find((label) => label.key === columnKey);
    return label ? !label.value : true;
  };
  const popover = usePopover();
  useEffect(() => {
    getSettingsLabels();
    renderLabels()
  }, []);
  const getSettingsLabels = async () => {
    try {
      const response = await getRequest(endpoints.risk_management.risk_register.identification.settings);
      console.log("🚀 ~ getSettingsLabels ~ response:", response);
      if (response) {
        const labels = Object.keys(response[0])
          .filter(key => !['id', 'project_id', 'creator_id', 'risk_id', 'risk_title', 'project_stage', 'risk_category', 'description'].includes(key))
          .map((key) => {
            const displayKey = key === 'monitor_and_track_status' ? 'current_status' : key;
            return {
              name: displayKey.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
              value: response[0][key] === 1,
              key: displayKey,
              visible: response[0][key] !== undefined,
            };
          });
        setRiskLabels(labels);
      }
    } catch (error) {
      console.error('Error fetching settings labels:', error);
    }
  };

  const renderLabels = async (payload = {}) => {
    try {
      if (Object.keys(payload).length === 0) {
        const updates = riskLabels.reduce((acc, label) => {
          let key = label.key;
          if (key === 'current_status') {
            key = 'monitor_and_track_status';
          }
          acc[key] = 0;
          return acc;
        }, {});
        console.log("🚀 ~ renderLabels ~ default updates:", updates); // Debug the updates
        const response = await postRequest(endpoints.risk_management.risk_register.identification.settings, updates);
        if (response.metadata && response.metadata.success) {
          getSettingsLabels();
        } else {
          console.error('Error initializing settings:', response.metadata.message);
        }
      }
    } catch (error) {
      console.error('Error rendering labels:', error);
    } finally {
    }
  };

  const handleNewRiskCreated = () => {
    getIdentification(); 
    const newTotalRecords = tableData.length + 1;
    const rowsPerPage = table.rowsPerPage;
    if (newTotalRecords > (table.page + 1) * rowsPerPage) {
      const newPage = Math.floor(newTotalRecords / rowsPerPage); 
      table.onChangePage(null, newPage); 
    }
  };
  useEffect(() => {
    getProjectStageOptions();
    getRiskCategoryOptions();
    getRiskOwnersOptions();
    getIdentification();
  }, []);

  const handleSaveSettings = (labels) => {
    setRiskLabels(labels);
    getIdentification();
  };

  const getProjectStageOptions = async () => {
    const data = await getRequest(endpoints.risk_management.risk_register.identification.project_stages);
    if (data) {
      setProjectStageOptions(data);
    }
  };
  const getRiskCategoryOptions = async () => {
    const data = await getRequest(endpoints.risk_management.risk_register.identification.risk_categories);
    if (data) {
      setRiskCategoryOptions(data);
    }
  };
  const getRiskOwnersOptions = async () => {
    const data = await getRequest(endpoints.risk_management.risk_register.identification.risk_owners);
    console.log("🚀 ~ getRiskOwnersOptions ~ data:", data)
    if (Array.isArray(data)) {
      data.unshift({ id: '', name: 'None' });
      setRiskOwnersOptions(data);

    } else {
      setRiskOwnersOptions([{ id: '', name: 'None' }]);

    }
  };
  const getIdentification = async () => {
    setGetLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_register.identification.identification);
    setGetLoading(false);
    if (data) {
      setTableData(data);
    }
  };

  useEffect(() => {
    getProfile();
    getChatDetails()

  }, []);
  const getProfile = async () => {
    try {
      const data = await getRequest(endpoints.profile_info);
      SetProfile(data);
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };
  const getChatDetails = () => {
    axios.get(`${baseURL}${endpoints.chat_details}`, {
      headers: {
        Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
        currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
        currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
      }
    }).then(res => {
      localStorage.getItem('chatDetails', JSON.stringify(res.data))
      if (res.status === 200) {
        setprojectname(res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }
  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData.length > 0 ? tableData : [],
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  console.log("🚀 ~ RiskRegister ~ tableData:", tableData)
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  console.log("🚀 ~ RiskRegister ~ dataInPage:", dataInPage)
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getIdentification()
  }
  const handleDeleteRows = async (id) => {
    setDeleteLoading(true)
    await deleteRequest(`${endpoints.risk_management.risk_register.identification.identification}/${selectedItem.id}`, deleteCallBack);
    const updatedTableData = tableData.filter((row) => row.id !== id);
    setTableData(updatedTableData);
    if (updatedTableData.length <= table.page * table.rowsPerPage && table.page > 0) {
      table.onChangePage(null, table.page - 1);
    } else {
      table.onUpdatePageDeleteRow(dataInPage.length);
    }
    setDeleteLoading(false)
  }
  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.risk_register.identification.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false); // Step 2: Hide action after delete
        getIdentification();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };
  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const getCsvHeaders = () => {
    return TABLE_HEAD
      .filter(({ label, id }) =>
        id !== 'actions' && // Explicitly exclude the actions column
        isColumnVisible(id) // Check visibility for other columns
      )
      .map(({ id, label }) => ({
        label,
        key: id
      }));
  };
  console.log("🚀 ~ getCsvHeaders ~ TABLE_HEAD:", TABLE_HEAD)
  console.log("🚀 ~ getCsvData ~ tableData:", tableData)
  const handleExport = () => {
    if (csvLinkRef.current) {
      const csvData = getCsvData(tableData, riskLabels, isColumnVisible);
      csvLinkRef.current.link.click(csvData);
    }
  };
  const handleExportPdf = () => {
    if (csvLinkRef.current) {
      setFilteredData(dataFiltered.slice(0, 13));
    }
  };
  return (
    <>
      <Card
        sx={{ height: '100%' ,marginTop:"10px" ,overflow:"hidden"  }}
      >
        <CardContent>
          {getLoading ? (
            <Box height={700}>
              <LoadingScreen />
            </Box>
          ) : (
            <>
              <Header name="CUSTOMERS" />
                <Box
                  sx={{
                    paddingTop: '20px',
                    paddingBottom: '20px',
                    display: 'flex',
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <div style={{
            
                  }}>
                  
                  </div>

                  <Box sx={{ display: 'flex', gap: '10px' }}>
               
                
                    <Button
                      className="dark-button"
                      variant="contained"
                      startIcon={<Iconify icon="mingcute:add-line" />}
                    
                    >
                      Import
                    </Button>
                    <Button
                      className="dark-button"
                      variant="contained"
                      startIcon={<Iconify icon="mingcute:add-line" />}
                    
                    >
                      Export
                    </Button>
                    <Button
                      onClick={popover.onOpen}
                      className="dark-button"
                      variant="contained"
                      sx={{ mx: 1 }}
                      startIcon={<Iconify icon="mdi:filter" />}
                    >
                      Filter
                    </Button>
                    <Button
                      className="dark-button"
                      variant="contained"
                      startIcon={<Iconify icon="mingcute:add-line" />}
                      onClick={() => {
                        setSelectedItem({});
                        add.onTrue();
                      }}
                    >
                      Create
                    </Button>
         
                  </Box>
                  <CustomPopover
                    open={popover.open}
                    onClose={popover.onClose}
                    arrow="bottom-bottom"
                    sx={{ width: 1000, padding: 2, background: 'white !important' }}
                  >
                    <MenuItem
                      disableRipple
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 2,
                        color: '#000',
                        background: 'white !important',
                        '&:hover': { backgroundColor: 'white !important' },
                      }}
                    >
                      <Iconify
                        icon="mdi:close"
                        onClick={popover.onClose}
                        sx={{
                          width: 20,
                          height: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                          borderRadius: '50%',
                          backgroundColor: '#F4F6F8',
                          color: '#637381',
                        }}
                      />

                        <TextField
                          label="Customer ID"
                          size="small"
                          placeholder="Search.."
                          variant="outlined"
                   
                          sx={{
                            flex: 1,
                            background: 'white !important',
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#e0e0e0 !important',
                              },
                            },
                          }}
                        />
                      
                        <TextField
                          label="First Name"
                          size="small"
                          placeholder="Search.."
                          variant="outlined"
                    
                          sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#e0e0e0 !important',
                              },
                            },
                          }}
                        />
              
                        <TextField
                          label="Last Name"
                          size="small"
                          placeholder="Search.."
                          variant="outlined"
                        
                          sx={{
                            flex: 1,
                            '& .MuiOutlinedInput-root': {
                              '&:hover fieldset': {
                                borderColor: '#e0e0e0 !important',
                              },
                            },
                          }}
                        />
                     
                      <TextField
                        label="Email"
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                      
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                      <TextField
                        label="Phone No."
                        size="small"
                        placeholder="Search.."
                        variant="outlined"
                      
                        sx={{
                          flex: 1,
                          '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                              borderColor: '#e0e0e0 !important',
                            },
                          },
                        }}
                      />
                    </MenuItem>
                  </CustomPopover>



                  <SettingModal
                    open={openDialog2}
                    onClose={handleCloseDialog2}
                    onSave={handleSaveSettings}
                  />
                </Box>


                <Card sx={{
                  boxShadow: '0px 0px 30px 0px #64748B1A',
                }}>       
                         <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                    <div style={{ marginBottom: table.selected.length > 0 ? '1.5rem' : '0' }}>
                    <TableSelectedAction
                      dense={table.dense}
                      numSelected={table.selected.length}
                      rowCount={tableData.length}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          setActionVisible(checked),
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                      action={
                        <Tooltip title="Delete"  >
                          <IconButton color="primary" onClick={remove.onTrue}>
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                    </div>
                
                  <Scrollbar>
                    <Table size={table.dense ? 'small' : 'large'} >
                      <TableHead>
                        <TableRow>
                          <TableCell >
                            <Checkbox
                              indeterminate={table.selected.length > 0 && table.selected.length < tableData.length}
                              checked={tableData.length > 0 && table.selected.length === tableData.length}
                              onChange={(e) => table.onSelectAllRows(e.target.checked, tableData.map((row) => row.id))}
                            />
                          </TableCell>
                          {TABLE_HEAD.map((headCell) =>
                            isColumnVisible(headCell.key) ? (
                              <>
                                <TableCell key={headCell.id}>{headCell.label}</TableCell>
                              </>
                            ) : null
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataInPage.map((row) => (
                          <>
                            <TableRow key={row.id}>
                              <TableCell >
                                <Checkbox
                                  checked={table.selected.includes(row.id)}
                                  onChange={() => {
                                    table.onSelectRow(row.id); // Select the row
                                    console.log('Selected row:', table.selected.includes(row.id)); // Log the selected row to the console
                                  }}
                                />
                              </TableCell>
                              {isColumnVisible('actions') && (
                                <TableCell align="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <IconButton onClick={handleToggleIcons}>
                                    <Iconify icon="eva:more-vertical-fill" />
   
                                                   </IconButton>

                                  {areIconsVisible && (
                                    <>
                                      <Tooltip title="Delete">
                                        <IconButton onClick={() => {
                                          setSelectedItem(row);
                                          confirm.onTrue();
                                        }}>
                                          <Iconify icon="solar:trash-bin-trash-bold" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Edit">
                                        <IconButton onClick={() => {
                                          setSelectedItem(row);
                                          add.onTrue();
                                        }}>
                                          <Iconify icon="solar:pen-bold" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="View" onClick={() => navigate(`/risk-management/risk-register/view/${row.id}`)}>
                                        <IconButton>
                                          <Iconify icon="solar:eye-bold" />
                                     
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </TableCell>
                              )}
                              {isColumnVisible('risk_id') && <TableCell align="left">{row?.risk_id}</TableCell>}
                              {isColumnVisible('risk_title') && <TableCell align="left">{row?.title}</TableCell>}
                              {isColumnVisible('project_stage') && <TableCell align="left">{row?.project_stage?.name}</TableCell>}
                              {isColumnVisible('risk_category') && <TableCell align="left">{row?.risk_category?.name}</TableCell>}
                              {isColumnVisible('description') && <TableCell align="left">{row?.description}</TableCell>}
                              {isColumnVisible('base_case') && <TableCell align="left">{row?.base_case}</TableCell>}
                              {isColumnVisible('exposure') && <TableCell align="left">{row?.exposure}</TableCell>}
                              {isColumnVisible('date_identified') && <TableCell align="left">{formatDate(row?.date_identified)}</TableCell>}
                              {isColumnVisible('identified_by') && <TableCell align="left">{row?.identified_by}</TableCell>}
                              {isColumnVisible('risk_owner') && <TableCell align="left">{row?.risk_owner?.name}</TableCell>}
                              {isColumnVisible('attachment') && (
                                <TableCell align="left">
                                  <a href={row?.file?.url} target="_blank" rel="noopener noreferrer">
                                    <FileAttachment imageView file={row?.file?.url} sx={{ width: 28, height: 28 }} />
                                  </a>
                                </TableCell>
                              )}
                              {isColumnVisible('qualitative_analysis') && <TableCell align="left">{row?.risk_assessment?.qualitative_analysis}</TableCell>}
                              {isColumnVisible('threat_or_opportunity') && <TableCell align="left">{row?.risk_assessment?.threat_opportunity?.name}</TableCell>}
                              {isColumnVisible('risk_area') && <TableCell align="left">{row?.risk_assessment?.risk_area?.name}</TableCell>}
                              {isColumnVisible('probability_of_occurrence') && <TableCell align="left">{row?.risk_assessment?.probability_of_occurrence}</TableCell>}
                              {isColumnVisible('impact_on_cost') && <TableCell align="left">{row?.risk_assessment?.impact_on_cost}</TableCell>}
                              {isColumnVisible('impact_on_schedule') && <TableCell align="left">{row?.risk_assessment?.impact_on_schedule}</TableCell>}
                              {isColumnVisible('risk_rating') && <TableCell align="left">{row?.risk_assessment?.risk_rating}</TableCell>}
                              {isColumnVisible('expected_loss') && <TableCell align="left">{row?.risk_assessment?.expected_loss}</TableCell>}
                              {isColumnVisible('full_probable_loss') && <TableCell align="left">{row?.risk_assessment?.full_probable_loss}</TableCell>}
                              {isColumnVisible('severity_rating') && (
                                <TableCell align="left" 
                                className={getSeverityLabel(row?.risk_analysis?.severity_rating?.score).className}>
                                  {getSeverityLabel(row?.risk_analysis?.severity_rating?.score).label}
                                </TableCell>
                              )}
                              {isColumnVisible('impact_level_pre_mitigation') && <TableCell align="left">{row?.risk_analysis?.impact_level_pre_mitigation}</TableCell>}
                              {isColumnVisible('likelihood_pre_mitigation') && <TableCell align="left">{row?.risk_analysis?.likelihood_pre_mitigation}</TableCell>}
                              {isColumnVisible('severity_pre_mitigation_updated') && <TableCell align="left">{row?.risk_analysis?.severity_pre_mitigation}</TableCell>}
                              {isColumnVisible('priority') && <TableCell align="left">{row?.risk_analysis?.priority}</TableCell>}
                              {isColumnVisible('severity_post_mitigation') && <TableCell align="left">{row?.risk_analysis?.severity_post_mitigation}</TableCell>}
                              {isColumnVisible('likelihood_post_mitigation') && <TableCell align="left">{row?.risk_analysis?.likelihood_post_mitigation}</TableCell>}
                              {isColumnVisible('related_task_ids') && <TableCell align="left">{row?.risk_analysis?.related_task_ids}</TableCell>}
                              {isColumnVisible('affected_areas') && <TableCell align="left">{row?.risk_analysis?.affected_areas}</TableCell>}
                              {isColumnVisible('risk_triggers') && <TableCell align="left">{row?.risk_analysis?.risk_triggers}</TableCell>}
                              {isColumnVisible('mitigation_strategy') && <TableCell align="left">{row?.mitigation_plan?.mitigation_strategy}</TableCell>}
                              {isColumnVisible('assigned_to') && <TableCell align="left">{row?.mitigation_plan?.assigned_user?.name}</TableCell>}
                              {isColumnVisible('budget_approval_status') && (
                                <TableCell align="left">
                                  {row?.mitigation_plan?.budget_approval_status === 1 ? 'YES' : 'NO'}
                                </TableCell>
                              )}
                              {isColumnVisible('total_budgeted_amount') && <TableCell align="left">{row?.mitigation_plan?.total_budgeted_amount}</TableCell>}
                              {isColumnVisible('total_amount_spent') && <TableCell align="left">{row?.mitigation_plan?.total_amount_spent}</TableCell>}
                              {isColumnVisible('total_balance') && <TableCell align="left">{row?.mitigation_plan?.total_balance}</TableCell>}
                              {isColumnVisible('status') && <TableCell align="left">{row?.mitigation_plan?.status}</TableCell>}
                              {isColumnVisible('start_date') && <TableCell align="left">{formatDate(row?.mitigation_plan?.start_date)}</TableCell>}
                              {isColumnVisible('end_date') && <TableCell align="left">{formatDate(row?.mitigation_plan?.end_date)}</TableCell>}
                              {isColumnVisible('effectiveness_rating') && <TableCell align="left">{row?.mitigation_plan?.effectiveness_rating}</TableCell>}
                              {isColumnVisible('meeting_notes') && <TableCell align="left">{row?.mitigation_plan?.meeting_notes}</TableCell>}
                              {isColumnVisible('monitoring_activities') && <TableCell align="left">{row?.monitor_and_track?.monitoring_activity}</TableCell>}
                              {isColumnVisible('review_frequency') && <TableCell align="left">{row?.monitor_and_track?.review_frequency}</TableCell>}
                              {isColumnVisible('last_review_date') && <TableCell align="left">{formatDate(row?.monitor_and_track?.last_review_date)}</TableCell>}
                              {isColumnVisible('next_review_date') && <TableCell align="left">{formatDate(row?.monitor_and_track?.next_review_date)}</TableCell>}
                              {isColumnVisible('monitoring_notes') && <TableCell align="left">{row?.monitor_and_track?.monitoring_notes}</TableCell>}
                              {isColumnVisible('review_notes') && <TableCell align="left">{row?.monitor_and_track?.review_notes}</TableCell>}
                              {isColumnVisible('current_status') && <TableCell align="left">{row?.monitor_and_track?.status}</TableCell>}
                            </TableRow>
                          </>
                        ))}
                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                        />
                        <TableNoData notFound={notFound} sx={{ width: "100%", textAlign: 'center' }} />
                      </TableBody >

                    </Table>
                  </Scrollbar>
                </TableContainer>
                <TablePaginationCustom
                  count={dataFiltered.length}
                  page={table.page}
                  rowsPerPage={table.rowsPerPage}
                  onPageChange={table.onChangePage}
                  onRowsPerPageChange={table.onChangeRowsPerPage}
                  dense={table.dense}
                  onChangeDense={table.onChangeDense}
                />
              </Card>
            </>
          )}
          {(
            <CSVLink
              ref={csvLinkRef}
              filename="RiskRegister_Report.csv"
              headers={getCsvHeaders()}
              data={getCsvData(tableData, riskLabels, isColumnVisible)}   
                         style={{ display: 'none' }} 
            />
          )}
        </CardContent>
      </Card>
      <RiskRegisterCreate
        projectStageOptions={projectStageOptions}
        riskCategoryOptions={riskCategoryOptions}
        riskOwnersOptions={riskOwnersOptions}
        reloadProjectStageOptions={getProjectStageOptions}
        reloadRiskCategoryOptions={getRiskCategoryOptions}
        reloadRiskOwnersOptions={getRiskOwnersOptions}
        currentItem={selectedItem}
        open={add.value}
        onClose={add.onFalse}
        reload={handleNewRiskCreated}
        closeform={() => handleCloseForm()}
      />
      <ImportDilaog
        open={create.value}
        onClose={create.onFalse}
        reload={getIdentification}
      />
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete this items?
          </>
        }
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className='dark-button'
            onClick={() => {
              handleDeleteRows();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
      <ConfirmDialog
        open={remove.value}
        onClose={remove.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete these items?
          </>
        }
        action={
          <LoadingButton
            loading={deleteLoading}
            variant="contained"
            className='dark-button'
            onClick={() => {
              handleBulkDelete();
            }}
          >
            Delete
          </LoadingButton>
        }
      />
    </>
  );
}
function applyFilter({ inputData, comparator, filters }) {
  const { name, status, role } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  inputData = stabilizedThis.map((el) => el[0]);
  if (name) {
    inputData = inputData.filter(
      (user) => user.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }
  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }
  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }
  return inputData;
}