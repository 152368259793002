import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    DialogActions,
    Button,
    CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import { handleErrorMessages } from 'src/components/risk-management/risk-register/feching';
import { LoadingScreen } from 'src/components/loading-screen';

const ShareByEmailpopup = ({ open, onClose, pdfFileUrl }) => {
    const [loading, setLoading] = useState(true);
    const [from, setFrom] = useState('');

    // Validation schema for the form fields
    const EmailSchema = Yup.object().shape({
        to: Yup.string().email('Invalid email format').required('To email is required'),
        cc: Yup.string().email('Invalid email format').nullable(),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required'),
    });

    const methods = useForm({
        resolver: yupResolver(EmailSchema),
        defaultValues: {
            from: '',
            to: '',
            cc: '',
            message: '',
        },
    });

    const { reset, handleSubmit, setValue } = methods;

    useEffect(() => {
        if (pdfFileUrl) {
            setValue('subject', pdfFileUrl?.name || '');
            setLoading(false); // Set loading to false once the file URL is ready
        }
    }, [pdfFileUrl, setValue]);

    const handleClose = () => {
        onClose();
        reset();
        setLoading(false); // Reset loading state when dialog is closed
    };

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('from', data.from);
        formData.append('to', data.to);
        formData.append('cc', data.cc || '');
        formData.append('message', data.message);
        if (pdfFileUrl?.file) {
            formData.append('file', pdfFileUrl.file); // Attach the binary file
        }
        try {
            const response = await postRequest(endpoints.job_costing.email, formData, true);
            if (response.metadata?.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: 'Email sent successfully',
                });
                handleClose();
            } else {
                handleErrorMessages(response.message || 'Email sending failed.');
            }
        } catch (err) {
            console.error('Submission error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const data = await getRequest(endpoints.profile_info);
                setFrom(data.email || '');
                setValue('from', data.email || '');
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };
        fetchProfile();
    }, [setValue]);

    if (loading) {
        return (
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="650px">
                    <LoadingScreen />
                </Box>
            </Dialog>
        );
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle>
                    <span style={{ fontWeight: '700', color: 'white' }} className="cardColor">
                        New Message
                    </span>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns="1fr">
                        <RHFTextField name="from" label="From" disabled />
                        <RHFTextField name="to" label="To" />
                        <RHFTextField name="cc" label="CC" optional />
                        <RHFTextField name="subject" label="Subject" />
                        <RHFTextField
                            name="message"
                            label="Message"
                            multiline
                            rows={5}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        className="dark-button"
                        variant="contained"
                        disabled={loading}
                        loading={loading}
                        type="submit"
                    >
                        Send
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default ShareByEmailpopup;
