import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import Iconify from 'src/components/iconify';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { RHFTextField } from 'src/components/hook-form';
import { formatDate, handleErrorMessages } from '../../risk-register/feching';
import { MultiFilePreview, Upload } from 'src/components/upload';
import { DatePicker } from '@mui/x-date-pickers';

const RiskTrendsAndAnalysisReportCreate = ({
  open,
  onClose,
  reload,
  currentItem,
  reportID,
  handleCloseForm
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [filePreview, setFilePreview] = useState([]);
  const [isFileDeleted, setIsFileDeleted] = useState(false);

  const NewUserSchema = Yup.object().shape({
    report_id: Yup.string().required('Report ID is required'),
    reporting_period: Yup.mixed().required('Reporting Period is required'),
    key_risk_trend_identified: Yup.string().required('Key Risk Trends Identified are required'),
    recommendations: Yup.string().required('Recommendations are required'),
  });
  const defaultValues = useMemo(() => ({
    report_id: currentItem?.report_id || reportID || '',
    reporting_period: currentItem?.reporting_period ? new Date(currentItem.reporting_period) : null,
    key_risk_trend_identified: currentItem?.key_risk_trend_identified || '',
    recommendations: currentItem?.recommendations || '',
  file: currentItem?.file || '',
  }), [currentItem, reportID]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur',
  });
  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;
  useEffect(() => {
      reset(defaultValues);
  }, [currentItem, defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    const cleansurveyId = data.report_id.replace('-RTAR', '');
    try {
      console.log('Form data submitted:', data);
      const formData = new FormData();
      formData.append('report_id', cleansurveyId);
      formData.append('reporting_period', formatDate(data.reporting_period));
      formData.append('key_risk_trend_identified', data.key_risk_trend_identified);
      formData.append('recommendations', data.recommendations);
      if (isFileDeleted) {
        formData.append('is_file_deleted', true);
      }
      if (files.length > 0) {
        formData.append('file', files[0]);
      }
      setLoading(true);
      const result = await postRequest(
        currentItem?.id
          ? `${endpoints.risk_management.risk_trend_analysis.crud}/${currentItem.id}?_method=PUT`
          : endpoints.risk_management.risk_trend_analysis.crud,
        formData,
        true
      );
      console.log('API Response:', result);
      setLoading(false);
      if (result.metadata?.success) {
        setIsFileDeleted(false);
        reset();
        setFiles([]);
        setFilePreview([]);
        handleCloseForm()
        onClose();
        reload();
        enqueueSnackbar('Risk Trends and Analysis Report Created Successfully', { variant: 'success' });
      } else {
        handleErrorMessages(result.metadata.message);
      }
    } catch (err) {
      setLoading(false);
      console.error('Error in catch:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    }
  });

  const handleClose = () => {
    reset(defaultValues);
    onClose();
    handleCloseForm()
  };

  const handleRemoveFile = () => {
    setIsFileDeleted(true);
    setFiles([]);
    setFilePreview([]);
  };

  useEffect(() => {
    if (currentItem) {
      if (currentItem.file != null) {
        setFilePreview([{ ...currentItem.file, preview: currentItem.file.url }]);
      } else {
        setFilePreview([]);
      }
    }
  }, [currentItem]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }));
      setFiles([...newFiles]);
      console.log('Files added:', newFiles);
    },
    []
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ padding: '60px', fontSize: '13px', fontFamily: 'Arial' }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider {...methods}>
        <form onSubmit={onSubmit}>
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span
              className="cardColor"
              style={{
                padding: '5px',
                marginLeft: '-25px',
                color: 'white',
                fontWeight: '700',
              }}
            >
              Risk Trends and Analysis Report Form
            </span>
            <IconButton onClick={handleClose}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box
              mt={2}
              display="grid"
              gap={2}
              gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }}
            >
              <RHFTextField
                name="report_id"
                label="Reported ID"
                disabled
              />

              <Controller
                name="reporting_period"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    name="reporting_period"
                    label={`Reporting Period ${error ? '*' : ''}`}
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <RHFTextField name="key_risk_trend_identified" label="Key Risk Trends Identified" />
              <RHFTextField name="recommendations" label="Recommendations" />
            </Box>
            <Box
              mt={4}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
              }}
            >
              <Typography variant="subtitle2">Related Documents</Typography>
              <Upload onDrop={handleDrop} onRemove={handleRemoveFile} />
            </Box>
            <Box sx={{ my: 3 }}>
              <MultiFilePreview
                files={files?.length > 0 ? files : filePreview}
                onRemove={handleRemoveFile}
                setFileDeleted={setIsFileDeleted}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" className='dark-button' loading={isSubmitting || loading}>
              {!currentItem || !currentItem.id ? 'Create Risk Trends and Analysis Report' : 'Update Risk Trends and Analysis Report'}
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default RiskTrendsAndAnalysisReportCreate;