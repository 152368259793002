import { Box, Grid } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

export default function HeatMap({ data, zerovalue }) {
  console.log("🚀 ~ HeatMap ~ data:", data, zerovalue);

  const processedData = Array.isArray(data) && data.length > 0
    ? data.map(seriesItem => ({
      name: seriesItem?.name?.toString() || 'N/A', // Fallback for undefined names
      data: seriesItem?.data.map((value, xIndex) => {
        const impact = xIndex + 1;  // Impact is based on X index (1, 2, 3, ...)
        const probability = parseInt(seriesItem?.name) || 0;  // Fallback for undefined probability
        // If value is 0, return 0; otherwise, return the product of Impact and Probability
        return value === 0 ? 0 : impact * probability;
      })
    }))
    : [];

  const heatmapOptions = {
    chart: {
      height: 150,
      type: 'heatmap',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.1,
        colorScale: {
          ranges: [
            { from: 0, to: 20, color: '#a8d94a' },
            { from: 21, to: 40, color: '#388e3c' },
            { from: 41, to: 60, color: '#f9a825' },
            { from: 61, to: 80, color: '#e65100' },
            { from: 81, to: 100, color: '#c62828' }
          ]
        }
      }
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#000']
      },
      formatter: function (val) {
        return val === 0 ? '0' : val;
      }
    },
    xaxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      title: {
        text: 'Impact',
        style: {
          color: '#000',
          fontSize: '14px'
        }
      },
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: true
      }
    },
    yaxis: {
      categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      title: {
        text: 'Probability',
        style: {
          color: '#000',
          fontSize: '14px'
        }
      }
    },
    grid: {
      padding: {
        right: 0
      }
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex, w }) {
          // Safely retrieve values with fallback
          const impactIndex = dataPointIndex;
          const probabilityIndex = seriesIndex;
          const retrievedValue = data?.[probabilityIndex]?.data?.[impactIndex]; // Use optional chaining

          const impact = w.globals.labels[impactIndex] || 'N/A'; // Fallback for undefined impact
          const probability = w.globals.seriesNames[probabilityIndex] || 'N/A'; // Fallback for undefined probability
          const heatmapValue = val;

          return `
            <div style="text-align: left;">
              <strong>X-Impact:</strong> ${impact} <br/>
              <strong>Y-Probability:</strong> ${probability} <br/>
              <strong>Severity Rating:</strong> ${heatmapValue === 0 ? '0' : heatmapValue} <br/>
              <strong>Total Risks:</strong> ${retrievedValue !== undefined ? retrievedValue : 'N/A'} <br/>
            </div>
          `;
        }
      },
      style: {
        fontSize: '12px',
        colors: ['#000']
      },
      marker: {
        show: false
      }
    }
  };

  return (
    <Box
      className="radialbox"
      sx={{
        boxShadow: '0px 0px 30px 0px #64748B1A'
      }}
      aria-labelledby="risk-heatmap-title"
    >
      <h3 id="risk-heatmap-title">Risk Heat Map</h3>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box>
            <div id="chart" aria-label="Risk Heat Map Chart">
              <ReactApexChart
                options={heatmapOptions}
                series={processedData}
                type="heatmap"
                height={275}
                width="100%"
              />
            </div>
          </Box>
        </Grid>
      </Grid>
      <span>
        You have <strong>{zerovalue || 0}</strong> risks with 0 severity rating
      </span>
    </Box>
  );
}
