// src/components/content/RiskRegister.js
import { useCallback, useEffect, useState } from 'react';
import Header from '../header';
import { Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import Scrollbar from 'src/components/scrollbar';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { isEqual } from 'lodash';
import { ConfirmDialog } from 'src/components/custom-dialog';
import RiskEsclationCreate from './create.js';
import { useNavigate } from 'react-router';
import { deleteRequest, getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { LoadingScreen } from 'src/components/loading-screen';

const RiskEscalation = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [escalationOptions, setEscalationOptions] = useState([]);
  const [escalationId, setEscalationID] = useState([]);
  const [selectedItem, setSelectedItem] = useState({})
  const [error, setError] = useState(null);

  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.No' },
    { id: 'Escalation ID', label: 'Escalation ID' },
    { id: 'Original Risk ID', label: 'Original Risk ID' },
    { id: 'Reason for Escalation', label: 'Reason for Escalation' },
    { id: 'Escalation Level', label: 'Escalation Level' },
    { id: 'Action Required', label: 'Action Required' },
    { id: 'Escalation Date', label: 'Escalation Date' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();
  const remove = useBoolean();


  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };


  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = Array.isArray(applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  })) ? applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  }) : [];


  const dataInPage = dataFiltered
    ? dataFiltered.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage)
    : [];
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleDeleteRows = useCallback(() => {
    if (tableData) {
      const deleteRows = tableData.filter((row) => !table.selected.includes(row.id));
      // setTableData(deleteRows);
      table.onUpdatePageDeleteRows({
        totalRows: tableData.length,
        totalRowsInPage: dataInPage?.length || 0,
        totalRowsFiltered: dataFiltered?.length || 0,
      });
    }
  }, [dataFiltered?.length, dataInPage?.length, table, tableData]);


  useEffect(() => {
    getRiskEscalation();
  }, []);
  const getRiskEscalation = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.risk_escalation.crud);
    console.log("🚀 ~ getRiskEscalation========> ~ data:", data)
    setLoading(false);
    setEscalationID(data?.next_esclation_id)
    if (data) {
      setTableData(data.data);
    }
  };
  useEffect(() => {
    getEscalationOptions();
  }, []);
  const getEscalationOptions = async () => {
    const data = await getRequest(endpoints.risk_management.risk_escalation.escalation_levels);
    if (data) {
      setEscalationOptions(data);
    }
  };

  const deleteCallBack = () => {
    getRiskEscalation()
  }

  const handleDeleteRow = async () => {
    await deleteRequest(`${endpoints.risk_management.risk_escalation.crud}/${selectedItem.id}`, deleteCallBack);
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form

  }


  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };
  
  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >
      <CardContent>
        {loading ? (
          <Box sx={{ height: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingScreen />
          </Box>
        ) : error ? (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6" align="center" color="error">
              {error}
            </Typography>
          </Box>
        ) : (
          <>
            <Header name="Risk Escalation" />
            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
              }}
            >

              <Button
                onClick={create.onTrue}
                variant="contained"
                className="dark-button"

                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Risk Escalation
              </Button>

            </Box>

            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',

            }}>
              <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                <div style={{ marginBottom: table.selected.length > 0 ? '2.5rem' : '0' }}>
                  <TableSelectedAction
                    dense={table.dense}
                    numSelected={table.selected.length}
                    rowCount={tableData.length}
                    onSelectAllRows={(checked) =>
                      table.onSelectAllRows(
                        // setActionVisible(checked),
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                    action={
                      <Tooltip title="Delete">
                        <IconButton color="primary" onClick={confirm.onTrue}>
                          <Iconify icon="solar:trash-bin-trash-bold" />
                        </IconButton>
                      </Tooltip>
                    }
                  />
                </div>
                <Scrollbar >
                  <Table size={table.dense ? 'small' : 'large'}
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataInPage.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={table.selected.includes(row.id)}
                              onChange={() => {
                                table.onSelectRow(row.id); // Select the row
                                console.log('Selected row:', table.selected.includes(row.id));
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {index + 1 + table.page * table.rowsPerPage}
                          </TableCell>
                          <TableCell align="left">{row.escalation_id}-REF</TableCell>
                          <TableCell align="left">{row.identification_and_assessment?.risk_id}</TableCell>
                          <TableCell align="left">{row.reason}</TableCell>
                          <TableCell align="left">{row.escalation_level?.name}</TableCell>
                          <TableCell align="left">{row.action_required}</TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left" sx={{ display: "flex" }}>
                            <IconButton onClick={handleToggleIcons}>
                              <Iconify icon="eva:more-vertical-fill" />
                            </IconButton>
                            {areIconsVisible && (
                              <>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    remove.onTrue()
                                  }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    create.onTrue();
                                  }}>
                                    <Iconify icon="solar:pen-bold" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View" onClick={() => navigate(`/risk-management/risk-escalation/View/${row.id}`)}>
                                  <IconButton >
                                    <Iconify icon="solar:eye-bold" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card>

    <RiskEsclationCreate
      escalationOptions={escalationOptions}
      reloadEscalationOptions={getEscalationOptions}
      reload={getRiskEscalation} // Use getRiskEscalation directly here
      currentItem={selectedItem}
      escalationId={escalationId}
      open={create.value} onClose={create.onFalse}
      handleCloseForm={handleCloseForm}
    />

    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these item?
        </>
      }
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRow();
            remove.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />
    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />
  </>;
};

export default RiskEscalation;
