import React, { useEffect, useState, useMemo } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../../risk-register/feching';
import Iconify from 'src/components/iconify';
const RiskChangeRequestCreate = ({ open, onClose, reload, currentItem, closeform }) => {
    console.log("🚀 ~ RiskChangeRequestCreate ~ currentItem:", currentItem)
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [riskIds, setRiskIds] = useState([]);

    const NewUserSchema = Yup.object().shape({
        risk_id: Yup.object({
            id: Yup.string().required('Risk ID is required'),
            risk_id: Yup.string().required('Risk ID is required'),
        }).required('Risk ID is required'),
        request_id: Yup.string().required('Original Risk ID is required'),
        requested_changes: Yup.string().required('Requested Changes are required'),
        reason_for_change: Yup.string().required('Reason for Change is required'),
        requested_by: Yup.string().required('Requested By is required'),
        approval_status: Yup.string().required('Approval Status is required'),
    });

    const matchedRisk = riskIds.find(risk => risk.id === currentItem?.identification_and_assessment?.id) || { id: '', risk_id: 'None' };

    const defaultValues = useMemo(
        () => ({
            risk_id: matchedRisk,
            request_id: currentItem?.request_id || '',
            requested_changes: currentItem?.requested_changes || '',
            reason_for_change: currentItem?.reason_for_change || '',
            requested_by: currentItem?.requested_by || '',
            approval_status: currentItem?.approval_status || '',
        }),
        [currentItem, riskIds]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    useEffect(() => {
        reset(defaultValues);
    }, [currentItem, reset, defaultValues]);

    useEffect(() => {
        getIdentification();
    }, []);
    const getIdentification = async () => {
        try {
            const data = await getRequest(endpoints.risk_management.risk_register.identification.identification);
            console.log("🚀 ~ getIdentification=======>>>> ~ data:", data);
            setRiskIds(data || []);
        } catch (err) {
            console.error('Error fetching risk IDs:', err);
        }
    };
    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {
        console.log('Form data submitted:', data);
        setLoading(true);
        const formData = new FormData();
        console.log("🚀 ~ onSubmit ~riskhange===== :", formData)
        formData.append('risk_id', data.risk_id.id);
        formData.append('request_id', data.request_id);
        formData.append('requested_changes', data.requested_changes);
        formData.append('reason_for_change', data.reason_for_change);
        formData.append('requested_by', data.requested_by);
        formData.append('approval_status', data.approval_status);
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.risk_management.risk_change_request.crud}/${currentItem.id}?_method=PUT`
                    : endpoints.risk_management.risk_change_request.crud,
                formData,
                true
            );
            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                closeform();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response?.message || 'An error occurred.');
            }
        } catch (err) {
            console.error('Error:', err);
            handleErrorMessages(err.message || 'Submission failed.');
        } finally {
            setLoading(false);
        }
    });

    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span
                        className='cardColor'
                        style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp;  Taxes  &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={onClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                        <RHFTextField name="request_id" label="Request ID" />

                        <RHFAutocomplete
                            name="risk_id"
                            label="  Original Risk ID"
                            options={[{ id: '', risk_id: 'None' }, ...riskIds]}
                            getOptionLabel={(option) => option.risk_id || 'None'}
                            isOptionEqualToValue={(option, value) => option.id === value?.id || value === '' || option.id === ''}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id || 'none'}>
                                    {option.risk_id}
                                </li>
                            )}
                            error={!!methods.formState.errors.risk_id}
                            helperText={methods.formState.errors.risk_id?.message}
                        />
                        <RHFTextField name="requested_changes" label="Request Changes" />
                        <RHFTextField name="reason_for_change" label="Reason for Change" />
                        <RHFTextField name="requested_by" label="Requested By" />
                        <RHFAutocomplete
                            name="approval_status"
                            label="Approval Status"
                            options={['Pending', 'Review Required', 'Approved', 'Rejected']}
                            getOptionLabel={(option) => option}
                            isOptionEqualToValue={(option, value) => option === value}
                            renderOption={(props, option) => <li {...props} key={option}>{option}</li>}
                            error={!!methods.formState.errors.approval_status}
                            helperText={methods.formState.errors.approval_status?.message}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton type="submit" variant="contained" className='dark-button'
                        loading={isSubmitting}>
                        {!currentItem || !currentItem.id ? 'Create Risk Change Request' : 'Update Risk Change Request'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default RiskChangeRequestCreate;