
export  const getSeverityLabel = (value) => {
    if (value === undefined || value === null || value === '') {
        return { label: '', className: '' };
    }
    let label;
    let className;

    if (value >= 0 && value <= 2) {
        label = `${value} (Very Low)`;
        className = 'very-low';
    } else if (value > 2 && value <= 4) {
        label = `${value} (Low)`;
        className = 'low';
    } else if (value > 4 && value <= 6) {
        label = `${value} (Medium)`;
        className = 'medium';
    } else if (value > 6 && value <= 8) {
        label = `${value} (High)`;
        className = 'high';
    } else if (value > 8 && value <= 10) {
        label = `${value} (Very High)`;
        className = 'very-high';
    } else {
        label = `${value}`;
        className = ''; // No class for out of range values
    }

    return { label, className };
};
