import { enqueueSnackbar } from "notistack";
import { useMemo } from "react";
import {fetcher } from "src/utils/axios";
import useSWR from "swr";
const options = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  };
export function useFetch(URL) {
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, options);
  
    const memoizedValue = useMemo(
      () => ({
        payload: data?.payload || [],
        loading: isLoading,
        error: error,
        validating: isValidating,
        empty: !isLoading && !data?.payload.length,
      }),
      [data?.contacts, error, isLoading, isValidating]
    );
  
    return memoizedValue;
  }


  export function getDropdownsId(array, value) {
    const foundItem = array?.find((item) => item.name === value);
    return foundItem ? foundItem.id : undefined;
  }


  export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}


export const handleErrorMessages = (message) => {
  if (typeof message === 'object') {
    const errorMessageArray = Object.values(message).flatMap((messages) => messages);
    errorMessageArray.forEach((msg) =>
      enqueueSnackbar({
        variant: 'error',
        message: msg,
      })
    );
  } else if (Array.isArray(message)) {
    message.forEach((msg) =>
      enqueueSnackbar({
        variant: 'error',
        message: msg,
      })
    );
  } else if (message) {
    enqueueSnackbar({
      variant: 'error',
      message: message,
    });
  }
};
