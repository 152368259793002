import { Box, Grid, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

export default function DashboardHeader({ Title }) {

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 1,  overflow: "hidden" }}>
            <Box sx={{ marginTop: "3px" }}>
              <Iconify icon="ion:home" />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

            <Typography variant='h5' sx={{ 
                fontSize: '1.80rem', 
                fontWeight: 300,     
              }} >{Title}
            </Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>

            <Typography variant="h6" sx={{ 
                fontSize: '1.80rem', 
                fontWeight: 300,     
              }} >How to set up Accounting</Typography>

            <Box sx={{ marginTop: "-5px"  }}>
           <Iconify icon="ion:logo-youtube"  sx={{ 
              width: '50px',    
              height: '35px',   
              color: 'red',   
            }}  />
           </Box>


           <Typography variant="h6" sx={{ fontSize: '1rem', fontWeight: 300 }}>
           Accounting Dashboard
           </Typography>

            </Box>

            </Box>

          </Box>
        </Grid>
      </Grid>
    </>
  );
}
