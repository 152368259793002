import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import RiskTeamResponeTable from './risk-team-table';
const RiskTeamViewPage = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [ResponseteamDetails, setResponseteamDetails] = useState();  // New state for incident details

    useEffect(() => {
        getResponseteam();
    }, [])

    const getResponseteam = async () => {
        const data = await getRequest(endpoints.risk_management.risk_response_team.response_team_Show(id));
        console.log("🚀 ~ getResponseteam ~ data:", data)
        setResponseteamDetails(data);

    }
    return (
        <RiskTeamResponeTable data={ResponseteamDetails} />
    );
};
export default RiskTeamViewPage;