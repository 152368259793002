import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import RiskLearningInsightTable from './risk-learning-table';

const RiskLearningInsightsView  = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [RiskLearningInsightDetails, setRiskLearningInsightDetails] = useState();  // New state for incident details

    useEffect(() => {
        getRiskLearningInsight();
    }, [])

    const getRiskLearningInsight = async () => {
        const data = await getRequest(endpoints.risk_management.risk_Learning_Insights.risk_Learning_Insights_Show(id));
        console.log("🚀 ~ getRiskLearningInsight ~ data:", data)
        setRiskLearningInsightDetails(data);

    }
    return (
        <RiskLearningInsightTable data={RiskLearningInsightDetails} />
    );
};
export default RiskLearningInsightsView ;