import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import './jobCostig.css';
import { calculateNonCommittedCostToDate, calculateRetainage, calculateVariance } from './Calculations/formulas';

export  const calculateSubtotal = (field, data) => {
    return data.reduce((sum, row) => {
        const value = parseFloat(row[field] || 0); 
        return sum + value; 
    }, 0);
};

export const calculateNestedSubtotal = (field, nestedField, data) => {
    return data.reduce((sum, row) => {
        const nestedData = row[nestedField] || [];
        const nestedSum = nestedData.reduce((nestedSum, nestedRow) => {
            const value = parseFloat(nestedRow[field] || 0); 
            return nestedSum + value;
        }, 0);
        return sum + nestedSum; 
    }, 0);
};

const calculateRetainageSum = (data) => {
  return data.reduce((sum, row) => {
    const retainage = calculateRetainage(row.performed_amount);
    return sum + retainage;
  }, 0);
};


const JobCostingSummaryPage = ({ tableData, visibleColumns, onSubtotalsCalculated }) => {
  const [approvedChangeOrders, setApprovedChangeOrders] = useState(0);
  const [plannedSubcontractorCost, setPlannedSubcontractorCost] = useState(0);

  useEffect(() => {
    const totalApprovedChangeOrders = calculateNestedSubtotal(
      'budgeted_amount',
      'change_orders',
      tableData
    );
    const totalPlannedSubcontractorCost = calculateNestedSubtotal(
      'planned_subcontractor_cost',
      'change_orders',
      tableData
    );

    setApprovedChangeOrders(totalApprovedChangeOrders);
    setPlannedSubcontractorCost(totalPlannedSubcontractorCost);
  }, [tableData]);
  // Regular calculations for other fields
  const totalOriginalBudgetedAmount = calculateSubtotal('original_budgeted_amount', tableData);
  const totalRevisedBudget = totalOriginalBudgetedAmount + approvedChangeOrders;
  const totalCommittedSubcontractorCost = calculateNestedSubtotal(
    'budgeted_amount',
    'change_orders',
    tableData || []
  );
  const totalNonCommittedCostToDate = calculateNonCommittedCostToDate(
    totalRevisedBudget,
    totalCommittedSubcontractorCost
  );
  const totalPerformedAmount = calculateSubtotal('performed_amount', tableData);
  const totalEstimatedFinalCost = totalCommittedSubcontractorCost + totalNonCommittedCostToDate;
  const totalNonCommittedEstimatedCostToDate = calculateSubtotal(
    'non_committed_estimate_cost_to_date',
    tableData
  );
  // Additional calculations remain unchanged

  // const totalVariance = calculateVariance(totalEstimatedFinalCost, totalRevisedBudget);
  const totalVariance = tableData.reduce((sum, row) => {
    const rowVariance = calculateVariance(
      row.estimated_final_cost, // Adjust to actual field name
      parseFloat(totalRevisedBudget) || 0
    );
    return sum + rowVariance;
  }, 0);
  const totalActualCost = calculateSubtotal('actual_cost', tableData);
  const totalGrossProfit = totalPerformedAmount - totalActualCost;
  const totalRemainingBudget = totalRevisedBudget - totalActualCost;
  const totalOriginalSubcontractorCost = calculateSubtotal(
    'original_subcontractor_cost',
    tableData
  );
  const totalPurchaseOrder = calculateSubtotal('purchase_order', tableData);
  const totalMaterialRequisition = calculateSubtotal('material_requisition', tableData);
  const totalResourcesUsed = calculateSubtotal('resources_used', tableData);
  const totalContingencyUsed = calculateSubtotal('contingency_used', tableData);
  const totalRetainage = calculateRetainageSum(tableData);
  const totalExpenses = calculateSubtotal('expenses', tableData);


  useEffect(() => {
    const totalApprovedChangeOrders = calculateNestedSubtotal(
      'budgeted_amount',
      'change_orders',
      tableData
    );
    const totalPlannedSubcontractorCost = calculateNestedSubtotal(
      'planned_subcontractor_cost',
      'change_orders',
      tableData
    );

    setApprovedChangeOrders(totalApprovedChangeOrders);
    setPlannedSubcontractorCost(totalPlannedSubcontractorCost);

    // Calculate other subtotals
    const totalOriginalBudgetedAmount = calculateSubtotal('original_budgeted_amount', tableData);
    const totalRevisedBudget = totalOriginalBudgetedAmount + totalApprovedChangeOrders;
    const totalCommittedSubcontractorCost = calculateNestedSubtotal(
      'budgeted_amount',
      'change_orders',
      tableData || []
    );
    const totalNonCommittedCostToDate = calculateNonCommittedCostToDate(
      totalRevisedBudget,
      totalCommittedSubcontractorCost
    );
    const totalPerformedAmount = calculateSubtotal('performed_amount', tableData);
    const totalEstimatedFinalCost = totalCommittedSubcontractorCost + totalNonCommittedCostToDate;
    const totalNonCommittedEstimatedCostToDate = calculateSubtotal(
      'non_committed_estimate_cost_to_date',
      tableData
    );
    const totalVariance = tableData.reduce((sum, row) => {
      return sum + calculateVariance(row.estimated_final_cost, totalRevisedBudget);
    }, 0);
    const totalResourcesUsed = calculateSubtotal('resources_used', tableData);
    // const totalExpenses = calculateSubtotal('expenses', tableData);
    const totalActualCost = totalExpenses - totalResourcesUsed  // changed
    const totalGrossProfit = totalPerformedAmount - totalActualCost;
    const totalRemainingBudget = totalRevisedBudget - totalActualCost;
    const totalOriginalSubcontractorCost = calculateSubtotal(
      'original_subcontractor_cost',
      tableData
    );
    const totalPurchaseOrder = calculateSubtotal('purchase_order', tableData);
    const totalMaterialRequisition = calculateSubtotal('material_requisition', tableData);

    const totalContingencyUsed = calculateSubtotal('contingency_used', tableData);

    // Pass all subtotals to the parent component
    if (onSubtotalsCalculated) {
      onSubtotalsCalculated({
        totalOriginalBudgetedAmount,
        totalApprovedChangeOrders,
        totalRevisedBudget,
        totalOriginalSubcontractorCost,
        totalPurchaseOrder,
        totalMaterialRequisition,
        totalCommittedSubcontractorCost,
        totalNonCommittedCostToDate,
        totalNonCommittedEstimatedCostToDate,
        totalEstimatedFinalCost,
        totalVariance,
        totalPerformedAmount,
        totalPerformedPercentage:
          totalRevisedBudget !== 0
            ? ((totalPerformedAmount / totalRevisedBudget) * 100).toFixed(2)
            : '0.00%',
        totalResourcesUsed,
        totalExpenses,
        totalActualCost,
        totalGrossProfit,
        totalRemainingBudget,
        totalContingencyUsed,
      });
    }
  }, [tableData, onSubtotalsCalculated]);




  return (
    <TableRow style={{ fontWeight: 'bold', backgroundColor: '#F4F6F8' }}>
      <TableCell>
        <b style={{ marginRight: '3px' }}>BASE</b>
        <b style={{ marginRight: '3px' }}>CONTRACT</b>
        <b>TOTAL</b>
      </TableCell>
      <TableCell />

      {visibleColumns.includes('facility') && <TableCell />}

      {visibleColumns.includes('level') && <TableCell />}

      {visibleColumns.includes('milestone') && <TableCell />}

      {visibleColumns.includes('category') && <TableCell />}

      {visibleColumns.includes('activity') && <TableCell />}

      {visibleColumns.includes('start_date') && <TableCell />}

      {visibleColumns.includes('end_date') && <TableCell />}

      {visibleColumns.includes('duration') && <TableCell />}

      {visibleColumns.includes('trade') && <TableCell />}

      {visibleColumns.includes('responsible_party') && <TableCell />}

      {visibleColumns.includes('original_budgeted_amount') && (
        <TableCell className="bolder">${totalOriginalBudgetedAmount.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('approved_change_orders') && (
        <TableCell className="bolder">${approvedChangeOrders.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('revised_budget_amount') && (
        <TableCell className="bolder">${totalRevisedBudget.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('original_subcontractor_cost') && (
        <TableCell className="bolder">${totalOriginalSubcontractorCost.toFixed(2)}</TableCell>
      )}
      {visibleColumns.includes('approved_change_order_subcontractor') && <TableCell />}

      {visibleColumns.includes('purchase_order') && (
        <TableCell className="bolder">${totalPurchaseOrder.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('material_requisition') && (
        <TableCell className="bolder">${totalMaterialRequisition.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('committed_revised_subcontractor_cost') && (
        <TableCell className="bolder">${totalCommittedSubcontractorCost.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('non_committed_cost_to_date') && (
        <TableCell className="bolder">${totalNonCommittedCostToDate.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('non_committed_estimate_cost_to_date') && (
        <TableCell className="bolder">${totalNonCommittedEstimatedCostToDate.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('estimated_final_cost') && (
        <TableCell className="bolder">${totalEstimatedFinalCost.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('variance_project_budgeted') && (
        <TableCell className="bolder">${totalVariance.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('performed_amount') && (
        <TableCell className="bolder">${totalPerformedAmount.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('performed_percentage') && (
        <TableCell className="bolder">
          {totalRevisedBudget !== 0
            ? `${((totalPerformedAmount / totalRevisedBudget) * 100).toFixed(2)}%`
            : '0.00%'}
        </TableCell>
      )}

      {visibleColumns.includes('resources_used') && (
        <TableCell className="bolder">${totalResourcesUsed.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('expenses') && (
        <TableCell className="bolder">${totalExpenses.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('actual_cost') && (
        <TableCell className="bolder">${totalActualCost.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('gross_profit') && (
        <TableCell className="bolder">${totalGrossProfit.toFixed(2)}</TableCell>
      )}
      {visibleColumns.includes('gross_profit_percentage') && <TableCell />}

      {visibleColumns.includes('remaining_budget') && (
        <TableCell className="bolder">${totalRemainingBudget.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('retainage_percentage') && (
        <TableCell className="bolder">${totalRetainage.toFixed(2)}</TableCell>
      )}

      {visibleColumns.includes('requisition_number') && <TableCell />}

      {visibleColumns.includes('invoice_number') && <TableCell />}

      {visibleColumns.includes('payment_status') && <TableCell />}

      {visibleColumns.includes('risk_level') && <TableCell />}

      {visibleColumns.includes('quality_checks') && <TableCell />}

      {visibleColumns.includes('compliance') && <TableCell />}

      {visibleColumns.includes('contingency_used') && (
        <TableCell className="bolder">{totalContingencyUsed.toFixed(2)}</TableCell>
      )}
      {visibleColumns.includes('notes') && <TableCell />}
    </TableRow>
  );
};
export default JobCostingSummaryPage;