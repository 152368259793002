import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'src/routes/hooks'
import { getRequest } from 'src/services'
import { endpoints } from 'src/utils/axios'
import StackhoolderViewTable from './stakehodler-viewtable';
const StackeholderView = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)
    const { id } = useParams();
    const [stackDetails, setstackDetails] = useState();
    useEffect(() => {
        getRiskChangeShow();
    }, [])

    const getRiskChangeShow = async () => {
        const data = await getRequest(endpoints.risk_management.risk_stackholder_feedback.stakehodlerShow(id));
        console.log("🚀 ~ getRiskChangeShow ~ data:", data)
        setstackDetails(data);
    }
    return (
        <StackhoolderViewTable data={stackDetails} />
    );
};
export default StackeholderView;