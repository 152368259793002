
import ReportTable from './report-table';
export default function GenrateReport() {
    return (
        <>
                    <ReportTable />
        </>
    );
}



