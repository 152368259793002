import { Helmet } from 'react-helmet-async';
// sections
import GenrateReport from './generate-report';

// ----------------------------------------------------------------------

export default function GenerateReportMain() {
    return (
        <>
            <Helmet>
                <title> Job Costing</title>
            </Helmet>
            <GenrateReport />
        </>
    );
}
