import PropTypes from 'prop-types';
import {Box } from '@mui/material';
import Risksidebar from 'src/components/riskSidebar/risk-sdiebar';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';

export default function RiskSideBarLayout({ children }) {
  const nav = useBoolean();
  const renderNavVertical = <Risksidebar openNav={nav.value} onCloseNav={nav.onFalse} />;
  const lgUp = useResponsive('up', 'lg');
  return (
    <>
        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp && renderNavVertical}
        <Box
          sx={{
            overflow: 'hidden',
          width:"100%",
          
          }}
        >
        {children}
        </Box>
        </Box>
    </>
  );
}

RiskSideBarLayout.propTypes = {
  children: PropTypes.node,
};
