import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Chart from 'src/components/chart';

// ----------------------------------------------------------------------
export default function CounterCard({ title, total, chart, sx, ...other }) {
    const theme = useTheme();

    const {
        colors = [theme.palette.primary.light, theme.palette.primary.main],
        series,
        options,
    } = chart;

    const chartOptions = {
        colors, // Use the passed colors for each card
        fill: {
            type: 'bar',
            gradient: {
                colorStops: [
                    { offset: 0, color: colors[0] }, // Use first color
                    { offset: 100, color: colors[1] }, // Use second color
                ],
            },
        },
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '68%',
                borderRadius: 2,
            },
        },
        tooltip: {
            enabled: false,
        },
        // tooltip: {
        //     x: { show: false },
        //     y: {
        //         formatter: (value) => fNumber(value),
        //         title: {
        //             formatter: () => '',
        //         },
        //     },
        //     marker: { show: false },
        // },
        ...options,
    };

    return (
        <Card sx={{ display: 'flex', alignItems: 'center', p: 4, ...sx }} {...other}>
            <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle2">{title}</Typography>
                <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}></Stack>
                <Typography variant="h3">{fNumber(total)}</Typography>
            </Box>
            <Chart type="bar" series={[{ data: series }]} options={chartOptions} width={80} height={46} />
        </Card>
    );
}

CounterCard.propTypes = {
    chart: PropTypes.object,
    sx: PropTypes.object,
    title: PropTypes.string,
    total: PropTypes.number,
};
