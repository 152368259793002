import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';
//
import { HEADER, NAV } from '../config-layout';
import {
  Searchbar,
  AccountPopover,
  SettingsButton,
  LanguagePopover,
  ContactsPopover,
  NotificationsPopover,
} from '../_common';
import { Box } from '@mui/system';
import { MenuItem, Typography } from '@mui/material';
import axios from 'axios';
import { baseURL } from 'src/config-global';
import { endpoints } from 'src/utils/axios';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';







// ----------------------------------------------------------------------

export default function MainHeader({ onOpenNav }) {
  const theme = useTheme();
  const searchParams = useSearchParams();
  const settings = useSettingsContext();

  const [companyDetails, setCompanyDetails] = useState({})

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';
const navigate=useNavigate()
  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;
  const router = useRouter();



  const getChatDetails = () => {
    axios.get(`${baseURL}${endpoints.chat_details}`, {
      headers: {
        Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
        currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
        currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
      }
    }).then(res => {
      localStorage.getItem('chatDetails', JSON.stringify(res.data))
      if (res.status === 200) {
        setCompanyDetails(res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }


  useEffect(() => {
    getChatDetails()
  }, [])




  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}


      <Logo sx={{ mr: 2.5 }} />
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <Searchbar /> */}
        {/* <LanguagePopover /> */}

        {/* <NotificationsPopover /> */}

        {/* <ContactsPopover /> */}

        {/* <SettingsButton /> */}
        {/* <Link href='https://web.ezelogs.com/risk-management' target='_blank' style={{ textDecoration: 'none', color: '#8BC443' }}>
            <MenuItem sx={{ textDecoration: 'none' }}>
              Back To Dashboard <ArrowRightIcon ms="2" fontSize="small" />
            </MenuItem>
          </Link> */}
          {/* <a href='https://web.ezelogs.com/risk-management' target='_blank' style={{ textDecoration: 'none', color: '#8BC443' }}> */}
        <MenuItem onClick={() => navigate('/risk-management')} sx={{ textDecoration: 'none',color: '#8BC443' }}>
            Back To Dashboard <ArrowRightIcon ms="2" fontSize="small" />
          </MenuItem>
          {/* </a> */}
        <AccountPopover />
      </Stack>
    </>
  );


  function extractPathFromUrl() {
    const url = window.location.href
    // const urlParts = url.split('/');
    // const lastPart = urlParts[urlParts.length - 1];
    // return lastPart;


    const baseUrl = url.split('://')[1].split('/')[1]; // Extract base URL
    // console.log("baseurl", baseUrl)
    let yug = baseUrl.split('?')
    return yug[0];

  }

  console.log("extractPathFromUrl", extractPathFromUrl())

  // New SubHeader Component
  const SubHeader = () => {
    return (
      <>
        <div style={{ backgroundColor: '#FFFFFF', width: '100%', marginTop: "0px", padding: '10px 40px', boxShadow: '0px 0px 10px 0px rgba(28, 187, 7, 0.3)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

          <Box >
            <Typography variant="h5">{extractPathFromUrl() == 'company-calendar' ? 'Company Calender' : extractPathFromUrl() == 'project-calendar' ? 'Project Calender' : extractPathFromUrl() == "company-calendar/project-calendar" ? "Project Calender" : extractPathFromUrl() == 'company-chat-view' ? 'Company Chat' : extractPathFromUrl() == 'chat-view' ? 'Project Chat' : extractPathFromUrl() == 'ezelog-mail' ? 'Intra Mail' : 'Company Mail'}</Typography>
          </Box>


          <Box>
            <Typography variant="h5" sx={{ color: 'orange' }}>{companyDetails?.company?.name}</Typography>
          </Box>


          {/* Add your sub-header content here */}
          <Box >
            <LanguagePopover />
          </Box>
        </div>
      </>
    );
  };

  return (
    <>
      <AppBar
        sx={{
          // border: "1px solid red",
          height: HEADER.H_MOBILE,
          backgroundColor: '#FFFFFF',
          paddingRight: '0px !important',
          boxShadow: '0px 0px 18px 0px rgba(28, 187, 7, 0.6);',
          // zIndex: theme.zIndex.appBar + 1,
          // ...bgBlur({
          //   color: theme.palette.background.default,
          // }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(lgUp && {
            // width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
            height: HEADER.H_DESKTOP,
            ...(offsetTop && {
              height: HEADER.H_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              // width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DESKTOP_OFFSET,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            // height: 1,
            px: { lg: 5 },
            py: { lg: 5 },
          }}
        >
          {renderContent}

        </Toolbar>
      </AppBar>
    </>
  );
}

MainHeader.propTypes = {
  onOpenNav: PropTypes.func,
};
