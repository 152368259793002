// src/components/RiskLearningAndInsightsCreate.js
import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Box,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { endpoints } from 'src/utils/axios';
import { getRequest, postRequest } from 'src/services';
import { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';

const handleErrorMessages = (message) => {
    console.error(message);
};
const RiskLearningAndInsightsCreate = ({
    open,
    onClose,
    reload,
    currentItem,
    JournlsId,
    handleCloseForm,
}) => {
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [riskIds, setRiskIds] = useState([]);
    const [isFetchingRisks, setIsFetchingRisks] = useState(true);

    // Fetch risk IDs on component mount
    useEffect(() => {
        const fetchIdentification = async () => {
            try {
                const data = await getRequest(
                    endpoints.risk_management.risk_register.identification.identification
                );
                setRiskIds(data || []);
            } catch (err) {
                console.error('Error fetching risk IDs:', err);
                enqueueSnackbar({
                    variant: 'error',
                    message: 'Failed to fetch risk IDs.',
                });
            } finally {
                setIsFetchingRisks(false);
            }
        };
        fetchIdentification();
    }, [enqueueSnackbar]);

    // Find the matched risk based on currentItem
    const matchedRisk = useMemo(() => {
        if (currentItem && riskIds.length > 0) {
            return (
                riskIds.find(
                    (risk) => risk.id === currentItem?.identification_and_assessment?.id
                ) || { id: '', risk_id: 'None' }
            );
        }
        return { id: '', risk_id: 'None' };
    }, [currentItem, riskIds]);

    // Validation Schema
    const NewUserSchema = Yup.object().shape({
        journal_id: Yup.string().required('Journal ID is required'),
        original_risk_id: Yup.object()
            .shape({
                id: Yup.string().required('Risk ID is required'),
                risk_id: Yup.string(),
            })
            .nullable()
            .required('Related Risk ID is required'),
        key_learning: Yup.string().required('Key Learnings is required'),
        insight_for_future: Yup.string().required('Insights for Future is required'),
        date: Yup.date()
            .typeError('Date of Entry must be a valid date')
            .required('Date of Entry is required'),
    });

    // Default Values
    const defaultValues = useMemo(() => ({
        journal_id: currentItem?.journal_id || JournlsId || '',
        original_risk_id: matchedRisk && matchedRisk.id ? matchedRisk : null,
        key_learning: currentItem?.key_learning || '',
        insight_for_future: currentItem?.insight_for_future || '',
        date: currentItem?.date ? new Date(currentItem.date) : null,
    }), [currentItem, JournlsId, matchedRisk]);

    // Initialize React Hook Form
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
        mode: 'onBlur',
    });

    const {
        reset,
        control,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    // Reset form when defaultValues change
    useEffect(() => {
        if (!isFetchingRisks) {
            reset(defaultValues);
        }
    }, [currentItem, defaultValues, reset, isFetchingRisks]);

    // Handle form submission
    const onSubmit = handleSubmit(async (data) => {
        console.log("🚀 ~ onSubmit ~ data:", data);
        setLoading(true);
        const cleansurveyId = data.journal_id.replace('-RLIJ', '');

        const formData = new FormData();
        formData.append('journal_id', cleansurveyId);
        formData.append('risk_id', data.original_risk_id.id);
        formData.append('key_learning', data.key_learning);
        formData.append('insight_for_future', data.insight_for_future);
        formData.append(
            'date',
            data.date ? data.date.toISOString().split('T')[0] : ''
        );
        try {
            const response = await postRequest(
                currentItem?.id
                    ? `${endpoints.risk_management.risk_Learning_Insights.crud}/${currentItem.id}?_method=PUT`
                    : endpoints.risk_management.risk_Learning_Insights.crud,
                formData,
                true
            );
            console.log("🚀 ~ onSubmit ~ response:", response)

            if (response.metadata && response.metadata.success) {
                reset();
                onClose();
                reload();
                enqueueSnackbar({
                    variant: 'success',
                    message: response.metadata.message,
                });
            } else {
                handleErrorMessages(response.message);
                enqueueSnackbar({
                    variant: 'error',
                    message: response.message || 'Submission failed.',
                });
            }
        } catch (err) {
            console.error('Error in catch:', err);
            handleErrorMessages(err.message || 'Submission failed.');
            enqueueSnackbar({
                variant: 'error',
                message: err.message || 'Submission failed.',
            });
        } finally {
            setLoading(false);
        }
    });

    // Handle dialog close with form reset
    const handleClose = () => {
        reset(defaultValues);
        handleCloseForm();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            sx={{ padding: '60px', fontSize: '13px', fontFamily: 'Arial' }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <span
                            className="cardColor"
                            style={{
                                padding: '5px',
                                marginLeft: '-25px',
                                color: 'white',
                                fontWeight: '700',
                            }}
                        >
                            Risk Learning and Insights Journal
                        </span>
                        <IconButton onClick={handleClose}>
                            <Iconify icon="mdi:close" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            mt={2}
                            display="grid"
                            gap={2}
                            gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }}
                        >
                            <RHFTextField
                                name="journal_id"
                                label="Journal ID"
                                disabled
                            />
                            <RHFAutocomplete
                                name="original_risk_id"
                                label="Related Risk ID"
                                options={[{ id: '', risk_id: 'None' }, ...riskIds]}
                                getOptionLabel={(option) => option.risk_id || 'None'}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id || 'none'}>
                                        {option.risk_id}
                                    </li>
                                )}
                                disabled={isFetchingRisks}
                            />
                            <RHFTextField name="key_learning" label="Key Learnings" />
                            <RHFTextField name="insight_for_future" label="Insights for Future" />
                            <Controller
                                name="date"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DatePicker
                                        label="Date of Entry"
                                        value={field.value}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                fullWidth
                                                error={!!error}
                                                helperText={error ? error.message : null}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={handleClose}
                            disabled={isSubmitting || loading}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            className='dark-button'
                            loading={isSubmitting || loading}
                            disabled={isSubmitting || loading}
                        >
                            {!currentItem || !currentItem.id
                                ? 'Create Risk Learning and Insights'
                                : 'Update Risk Learning and Insights'}
                        </LoadingButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default RiskLearningAndInsightsCreate;
