import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import { LoadingScreen } from 'src/components/loading-screen';

const SettingModal = ({ open, onClose, onSave }) => {
	const theme = useTheme();
	const [riskLabels, setRiskLabels] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (open) {
			getSettingsLabels();
		}
	}, [open]);
	const getSettingsLabels = async () => {
		setLoading(true);
		try {
			const response = await getRequest(endpoints.risk_management.risk_register.identification.settings);
			console.log("🚀 ~ getSettingsLabels ~ response:", response);
			if (response) {
				const labels = Object.keys(response[0])
					.filter(key => !['id', 'project_id', 'creator_id', 'risk_id', 'risk_title', 'project_stage', 'risk_category', 'description'].includes(key))
					.map((key) => {
						const displayKey = key === 'monitor_and_track_status' ? 'current_status' : key;
						return {
							name: displayKey.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()),
							value: response[0][key] === 1,
							key: displayKey,
							visible: response[0][key] !== undefined, 
						};
					});
				setRiskLabels(labels);
			}
		} catch (error) {
			console.error('Error fetching settings labels:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleCheckboxChange = (index) => (event) => {
		const updatedLabels = [...riskLabels];
		updatedLabels[index].value = event.target.checked;
		setRiskLabels(updatedLabels);
	};
	const handleSubmit = async () => {
		setLoading(true);
		try {
			const updates = riskLabels.reduce((acc, label) => {
				if (label.visible) {
					let key = label.key;
					if (key === 'current_status') {
						key = 'monitor_and_track_status';
					}
					acc[key] = label.value ? 1 : 0; 
				}
				return acc;
			}, {});
			console.log("🚀 ~ handleSubmit ~ updates:", updates); 
			const response = await postRequest(endpoints.risk_management.risk_register.identification.settings, updates);
			if (response.metadata && response.metadata.success) {
				enqueueSnackbar({
					variant: 'success',
					message: 'Settings updated successfully!',
				});
				onSave(riskLabels);
				onClose();
				getSettingsLabels();
			} else {
				console.error('Error updating settings:', response.metadata.message);
			}
		} catch (error) {
			console.error('Error submitting form:', error);
		} finally {
			setLoading(false); 
		}
	};
	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== 'backdropClick') {
					onClose();
				}
			}}
			fullWidth
			maxWidth="lg"
			sx={{
				borderRadius: 'none',
				padding: '60px',
				fontSize: '13px',
				fontFamily: 'Inter, Arial, sans-serif',
			}}
			transitionDuration={{
				enter: theme.transitions.duration.shortest,
				exit: theme.transitions.duration.shortest - 80,
			}}
		>
			<DialogTitle sx={{ justifyContent: 'space-evenly', boxSizing: 'content-box' }}>
				<span style={{ padding: '7px', marginLeft: '-25px', fontWeight: '700', color: "white" }} className='cardColor' >
					&nbsp;&nbsp; SETTING &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
				</span>
				<br />
				<br />
				<Typography
					sx={{
						fontSize: "18px",
						paddingBottom: '20px',
					}}
				>
					Note: You can show or hide columns in your Risk Register by checking or unchecking the provided checkboxes.
				</Typography>
				<Divider />
			</DialogTitle>

			{loading ? (
				<Box height={700}>
					<LoadingScreen />
				</Box>
			) : (
				<DialogContent
					sx={{
						display: 'grid',
						gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
						gap: '20px',
						padding: '50px',
						borderRadius: '1px',
					}}
				>
					{riskLabels?.map((label, index) => (
						label.visible && (
							<Paper
								key={label.key}
								style={{
									padding: '5px 15px',
									borderRadius: '4px',
									boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
									marginTop: '10px',
									border: '1px solid #CED4DA',
								}}
							>
								<FormControlLabel
									control={<Checkbox checked={label.value} onChange={handleCheckboxChange(index)} disabled={label.disabled} />} // Disable checkboxes
									label={label.name}
								/>
							</Paper>
						)
					))}
				</DialogContent>
			)}
			<DialogActions>
				<Button variant="outlined" color="inherit" onClick={onClose}>
					Cancel
				</Button>
				<LoadingButton type="submit" variant="contained" className='dark-button'  onClick={handleSubmit} loading={loading}>
					Save
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default SettingModal;
