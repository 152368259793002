export const TABLE_HEAD = [
    { id: 'actions', label: 'Actions', key: 'actions' },
    { id: 'risk_id', label: 'Risk ID', key: 'risk_id' },
    { id: 'risk_title', label: 'Risk Title', key: 'risk_title' },
    { id: 'project_stage', label: 'Project Stage', key: 'project_stage' },
    { id: 'risk_category', label: 'Risk Category', key: 'risk_category' },
    { id: 'description', label: 'Risk Description', key: 'description' },
    { id: 'base_case', label: 'Base Case', key: 'base_case' },
    { id: 'exposure', label: 'Exposure', key: 'exposure' },
    { id: 'date_identified', label: 'Date Identified', key: 'date_identified' },
    { id: 'identified_by', label: 'Identified By', key: 'identified_by' },
    { id: 'risk_owner', label: 'Risk Owner', key: 'risk_owner' },
    { id: 'attachment', label: 'Attachment', key: 'attachment' },
    { id: 'qualitative_analysis', label: 'Qualitative Analysis', key: 'qualitative_analysis' },
    { id: 'threat_or_opportunity', label: 'Threat or Opportunity', key: 'threat_or_opportunity' },
    { id: 'risk_area', label: 'Risk Area', key: 'risk_area' },
    { id: 'probability_of_occurrence', label: 'Probability of Occurrence', key: 'probability_of_occurrence' },
    { id: 'impact_on_cost', label: 'Impact on Cost', key: 'impact_on_cost' },
    { id: 'impact_on_schedule', label: 'Impact on Schedule', key: 'impact_on_schedule' },
    { id: 'risk_rating', label: 'Risk Rating', key: 'risk_rating' },
    { id: 'expected_loss', label: 'Expected Loss', key: 'expected_loss' },
    { id: 'full_probable_loss', label: 'Full Probable Loss', key: 'full_probable_loss' },
    { id: 'severity_rating', label: 'Severity Rating', key: 'severity_rating' },
    { id: 'impact_level_pre_mitigation', label: 'Impact Level Pre Mitigation', key: 'impact_level_pre_mitigation' },
    { id: 'likelihood_pre_mitigation', label: 'Likelihood Pre Mitigation', key: 'likelihood_pre_mitigation' },
    { id: 'severity_pre_mitigation_updated', label: 'Severity Pre Mitigation', key: 'severity_pre_mitigation_updated' },
    { id: 'priority', label: 'Priority', key: 'priority' },
    { id: 'severity_post_mitigation', label: 'Severity Post Mitigation', key: 'severity_post_mitigation' },
    { id: 'likelihood_post_mitigation', label: 'Likelihood Post Mitigation', key: 'likelihood_post_mitigation' },
    { id: 'related_task_ids', label: 'Related Task Ids', key: 'related_task_ids' },
    { id: 'affected_areas', label: 'Affected Areas', key: 'affected_areas' },
    { id: 'risk_triggers', label: 'Risk Triggers', key: 'risk_triggers' },
    { id: 'mitigation_strategy', label: 'Mitigation Strategy', key: 'mitigation_strategy' },
    { id: 'assigned_to', label: 'Assigned To', key: 'assigned_to' },
    { id: 'budget_approval_status', label: 'Budget Approval Status', key: 'budget_approval_status' },
    { id: 'total_budgeted_amount', label: 'Total Budgeted Amount', key: 'total_budgeted_amount' },
    { id: 'total_amount_spent', label: 'Total Amount Spent', key: 'total_amount_spent' },
    { id: 'total_balance', label: 'Total Balance', key: 'total_balance' },
    { id: 'status', label: 'Mitigation Status', key: 'status' },
    { id: 'start_date', label: 'Mitigation Start Date', key: 'start_date' },
    { id: 'end_date', label: 'Mitigation End Date', key: 'end_date' },
    { id: 'effectiveness_rating', label: 'Effectiveness Rating', key: 'effectiveness_rating' },
    { id: 'meeting_notes', label: 'Risk Workshop / Meeting Notes (If Any)', key: 'meeting_notes' },
    { id: 'monitoring_activities', label: 'Monitoring Activities', key: 'monitoring_activities' },
    { id: 'review_frequency', label: 'Review Frequency', key: 'review_frequency' },
    { id: 'last_review_date', label: 'Last Review Date', key: 'last_review_date' },
    { id: 'next_review_date', label: 'Next Review Date', key: 'next_review_date' },
    { id: 'monitoring_notes', label: 'Monitoring Notes', key: 'monitoring_notes' },
    { id: 'review_notes', label: 'Review Notes', key: 'review_notes' },
    { id: 'monitor_and_track_status', label: 'Current Status', key: 'current_status' }
];