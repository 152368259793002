import { Box, Typography } from '@mui/material'
import React from 'react'
import Iconify from 'src/components/iconify'
import './header.css'
import { useNavigate } from 'react-router'
const Header = ({name}) => {
    const navigate=useNavigate()
    const goBack = () => {
        navigate(-1); 
      };
  return (
    <>
     <Box sx={{display:"flex",gap:2}}>
     <Iconify onClick={goBack} icon="weui:back-filled" width={18} className='back-sidebar-icon'/>
     <Typography className='back-sidebar-header'>{name}</Typography>
     </Box>
    </>
  )
}

export default Header