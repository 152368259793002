import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
// routes
import { useParams } from 'src/routes/hooks';
// components
import FormProvider, {
    RHFTextField
} from 'src/components/hook-form';

import { useSnackbar } from 'src/components/snackbar';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from '../risk-register/feching';
import Iconify from 'src/components/iconify';

const CreateAnalysis = ({
    open,
    onClose,
    data,
    reload,
}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();


    const NewUserSchema = Yup.object().shape({
        severity_rating: Yup.number()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' || originalValue === null) ? null : value)
            .typeError('Severity Rating must be a number')
            .required('Severity Rating is required')
        ,
        impact_level_pre_mitigation: Yup.number()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' || originalValue === null) ? null : value)
            .typeError('Impact Level Pre-Mitigation must be a number')
            .required('Impact Level Pre-Mitigation is required')
            .min(0, 'Impact Level Pre-Mitigation must be 0 or greater'), // Updated to allow decimals like 0.1
        likelihood_pre_mitigation: Yup.number()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' || originalValue === null) ? null : value)
            .typeError('Likelihood Pre-Mitigation must be a number')
            .required('Likelihood Pre-Mitigation is required')
            .min(0, 'Likelihood Pre-Mitigation must be 0 or greater'), // Updated to allow decimals like 0.1
        severity_pre_mitigation: Yup.number()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' || originalValue === null) ? null : value)
            .typeError('Severity Pre-Mitigation must be a number')
            .required('Severity Pre-Mitigation is required')
            .min(0, 'Severity Pre-Mitigation must be 0 or greater'), // Updated to allow decimals like 0.1
        severity_post_mitigation: Yup.number()
            .nullable()
            .transform((value, originalValue) => (originalValue === '' || originalValue === null) ? null : value)
            .typeError('Severity Post-Mitigation must be a number')
            .required('Severity Post-Mitigation is required')
            .min(0, 'Severity Post-Mitigation must be 0 or greater'), // Updated to allow decimals like 0.1
    });



    const defaultValues = useMemo(
        () => ({
            severity_rating: data?.severity_rating || '',
            impact_level_pre_mitigation: data?.impact_level_pre_mitigation || '',
            likelihood_pre_mitigation: data?.likelihood_pre_mitigation || '',
            severity_pre_mitigation: data?.severity_pre_mitigation || '',
            priority: data?.priority || '',
            severity_post_mitigation: data?.severity_post_mitigation || '',
            likelihood_post_mitigation: data?.likelihood_post_mitigation || '',
            related_task_ids: data?.related_task_ids || '',
            affected_areas: data?.affected_areas || '',
            risk_triggers: data?.risk_triggers || '',
        }),
        [data]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });
    const {
        reset,
        watch,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const values = watch();

    const calculatePriority = (impactLevel, likelihood) => {
        if (!impactLevel || !likelihood) {
            return 0; // or whatever default value makes sense
        }
        return (impactLevel * likelihood); // Ensure it's an integer
    };
    useEffect(() => {
        const impactLevel = watch('impact_level_pre_mitigation');
        const likelihood = watch('likelihood_pre_mitigation');

        const calculatedPriority = calculatePriority(impactLevel, likelihood);
        setValue('priority', calculatedPriority.toFixed(2));  // Update the priority field
    }, [watch('impact_level_pre_mitigation'), watch('likelihood_pre_mitigation'), setValue]);

    useEffect(() => {
        if (open) {
            reset(defaultValues);
        }
    }, [data, open, reset, defaultValues]);
    const onSubmit = handleSubmit(async (data) => {
        console.log("🚀 ~ CreateAnalysis ~ data:", data)
        const formData = new FormData();
        formData.append('severity_rating', data?.severity_rating);
        formData.append('impact_level_pre_mitigation', data?.impact_level_pre_mitigation);
        formData.append('likelihood_pre_mitigation', data?.likelihood_pre_mitigation);
        formData.append('severity_pre_mitigation', data?.severity_pre_mitigation);
        formData.append('priority', data?.priority);
        formData.append('severity_post_mitigation', data?.severity_post_mitigation);
        formData.append('likelihood_post_mitigation', data?.likelihood_post_mitigation);
        formData.append('related_task_ids', data?.related_task_ids);
        formData.append('affected_areas', data?.affected_areas);
        formData.append('risk_triggers', data?.risk_triggers);
        setLoading(true);
        try {
            const data = await postRequest(endpoints.risk_management.risk_register.identification.risk_analyses(id),
                formData,
                true
            );
            setLoading(false);
            if (data.metadata) {
                if (data.metadata.success) {
                    reset();
                    onClose();
                    reload();
                    enqueueSnackbar({
                        variant: 'success',
                        message: data.metadata.message,
                    });
                } else {
                    handleErrorMessages(data.metadata.message);
                }
            }
        } catch (err) {
            setLoading(false);
            console.log('Error in catch:', err);
        }
    });
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose();
                }
            }}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: 'none',
                padding: '60px',
                fontSize: '13px',
                fontFamily: 'arial',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
                    <span 
                    className='cardColor'
                    style={{  padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
                        &nbsp;&nbsp; Risk Analysis &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <IconButton onClick={onClose}>
                        <Iconify icon="mdi:close" />
                    </IconButton>
                </DialogTitle>


                <DialogContent
                    sx={{
                        borderRadius: '1px',
                    }}
                >
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >

                        <RHFTextField
                            type="number"
                            name="severity_rating"
                            label={
                                <>
                                    Severity Rating
                                    {watch('severity_rating') ? '' : <span style={{ color: 'red' }}> *</span>}
                                </>
                            }
                            onChange={(e) => {
                                const { value } = e.target;
                                if (value === '') {
                                    setValue('severity_rating', null); // or use ''
                                } else {
                                    const numericValue = Math.max(0, Math.min(10, (value)));
                                    setValue('severity_rating', numericValue);
                                }
                            }}
                            InputProps={{
                                inputProps: { min: 0, max: 10, step: 0.1 } // Allows decimals
                            }}
                        />

                        <RHFTextField
                            type="number"
                            name="impact_level_pre_mitigation"
                            label={
                                <>
                                    Impact Level Pre-Mitigation
                                    {watch('impact_level_pre_mitigation') ? '' : <span style={{ color: 'red' }}> *</span>}
                                </>
                            }
                        />

                        <RHFTextField
                            type="number"
                            name="likelihood_pre_mitigation"
                            label={
                                <>
                                    Likelihood Pre-Mitigation
                                    {watch('likelihood_pre_mitigation') ? '' : <span style={{ color: 'red' }}> *</span>}
                                </>
                            }
                        />

                        <RHFTextField
                            type="number"
                            name="severity_pre_mitigation"
                            label={
                                <>
                                    Severity Pre-Mitigation Updated
                                    {watch('severity_pre_mitigation') ? '' : <span style={{ color: 'red' }}> *</span>}
                                </>
                            }
                        />

                        <RHFTextField type="number" name="priority" label="Priority" disabled />
                        <RHFTextField
                            type="number"
                            name="severity_post_mitigation"
                            label={
                                <>
                                    Severity Post-Mitigation
                                    {watch('severity_post_mitigation') ? '' : <span style={{ color: 'red' }}> *</span>}
                                </>
                            }
                        />

                    </Box>
                    <Box
                        mt={2}
                        rowGap={3}
                        columnGap={2}
                        display="grid"
                        gridTemplateColumns={{
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                        }}
                    >
                        <RHFTextField type="number" name="likelihood_post_mitigation" label="Likelihood Post-Mitigation" />
                        <RHFTextField name="related_task_ids" label="Related Task IDs" />
                        <RHFTextField name="affected_areas" label="Affected Areas" />
                        <RHFTextField name="risk_triggers" label="Risk Triggers" />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="inherit" onClick={onClose}>
                        Cancel
                    </Button>

                    <LoadingButton type="submit" className='dark-button'  variant="contained" loading={isSubmitting}>
                        {!data ? 'Create Analysis' : 'Update Analysis'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
};
export default CreateAnalysis;