
import { Card, CardContent } from '@mui/material';
import { useEffect, useState } from 'react';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import {
  HeatmapAndstatuscomp,
  Countercards,
  ListfilterTable,
  BarChartStageGrapgh,
  HeadingCard
} from 'src/components/risk-management';

export default function Dashboard() {
  const [data,SetData]= useState()
  useEffect(() => {
    getDasboardData();
  }, []);

  
  const getDasboardData = async () => {
    try {
      const data = await getRequest(endpoints.risk_management.risk_dashboard.dashboardData);
      SetData(data);
    } catch (error) {
      console.error('Error fetching profile: ', error);
    }
  };

  return (
    <>
      <Card sx={{ width: '100%', margin: 'auto', mt: 4, p: 3, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
        <CardContent>
          <HeadingCard />
          <Countercards   data={data} />
          <ListfilterTable  data={data} />
          <BarChartStageGrapgh  data={data} />
          <HeatmapAndstatuscomp  data={data} />
        </CardContent>
      </Card>
    </>
  );
}



