// src/components/content/RiskRegister.js
import { LoadingButton } from '@mui/lab';

import CreateVendor from './create-vendor';

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../header';
import IncidentReportingCreate from './create';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

const IncidentReporting = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const [showCreateView, setShowCreateView] = useState(false);

  const handleCreateClick = () => {
    setShowCreateView(true);
  };

  const handleBackClick = () => {
    setShowCreateView(false);
  };

  const remove = useBoolean();
  const TABLE_HEAD = [
    { id: 'Sr.no', label: 'Sr.no' },
    { id: 'First Name', label: 'First Name' },
    { id: 'Last Name', label: 'Last Name' },
    { id: 'Email', label: 'Email' },
    { id: 'Title', label: 'Title' },
    { id: 'Company Name', label: 'Company Name' },
    { id: 'Type of Vendors', label: 'Type of Vendors' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();

  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });
  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getIncidentReporting();
  }, []);

  const adjustPageAfterDeletion = useCallback(() => {
    const currentPageRowCount = dataFiltered.slice(
      table.page * table.rowsPerPage,
      table.page * table.rowsPerPage + table.rowsPerPage
    ).length;

    if (currentPageRowCount === 0 && table.page > 0) {
      table.onChangePage(table.page - 1); // Go to the previous page
    } else if (dataFiltered.length === 0) {
      table.onChangePage(0); // If no data remains, move to the first page
    }
  }, [dataFiltered.length, table]);

  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const getIncidentReporting = async () => {
    setLoading(true);
    const data = await getRequest(endpoints.risk_management.incident_reporting.incident);
    console.log('🚀 ~ getIncidentReporting========> ~ data:', data);
    setLoading(false);
    if (data) {
      setTableData(data);
    }
  };

  const deleteCallBack = () => {
    confirm.onFalse();
    getIncidentReporting();
  };
  const handleDeleteRows = async () => {
    await deleteRequest(
      `${endpoints.risk_management.incident_reporting.DelIncident}/${selectedItem.id}`,
      deleteCallBack
    );
    adjustPageAfterDeletion(); // Adjust the page after deletion
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log('🚀 ~ handleBulkDelete ~ Ids:', Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(
          endpoints.risk_management.incident_reporting.bulk_delete,
          { ids: Ids }
        );
        console.log('🚀 ~ handleBulkDelete ~ response:', response);
        remove.onFalse();
        setActionVisible(false);
        adjustPageAfterDeletion();
        setSelectedItem({});
        table.onSelectAllRows(false, []);
        getIncidentReporting();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return ''; // Return an empty string if date is invalid

    // Extract date components
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();

    // Extract time components
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 becomes 12)

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
  };

  return (
    <>
      {!showCreateView ? (
        <div>
          <Card sx={{ height: '100%', marginTop: '10px', overflow: 'hidden' }}>
            {' '}
            <CardContent>
              {loading ? (
                <Box height={700}>
                  <LoadingScreen />
                </Box>
              ) : (
                <>
                  <Header name="Vendors" />
                  <Box
                    sx={{
                      display: 'flex',
                      paddingBottom: '20px',
                      justifyContent: 'flex-end',
                      gap: '12px',
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: '#d3d3d3',
                        border: '1px solid #ccc',
                        color: '#333',
                      }}
                    >
                      Import
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#d3d3d3',
                        border: '1px solid #ccc',
                        color: '#333',
                      }}
                    >
                      Export
                    </Button>
                    <Button
                      style={{
                        backgroundColor: '#d3d3d3',
                        border: '1px solid #ccc',
                        color: '#333',
                      }}
                    >
                      Filters
                    </Button>

                    <Button
                      className="dark-button"
                      variant="contained"
                      startIcon={<Iconify icon="mingcute:add-line" />}
                      onClick={handleCreateClick}
                    >
                      Create
                    </Button>
                  </Box>

                  <Card
                    sx={{
                      boxShadow: '0px 0px 30px 0px #64748B1A',
                    }}
                  >
                    <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                      {actionVisible && (
                        <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                          <TableSelectedAction
                            dense={table.dense}
                            numSelected={table.selected.length}
                            rowCount={tableData.length}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                setActionVisible(checked),
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                            action={
                              <Tooltip title="Delete">
                                <IconButton color="primary" onClick={remove.onTrue}>
                                  <Iconify icon="solar:trash-bin-trash-bold" />
                                </IconButton>
                              </Tooltip>
                            }
                          />
                        </div>
                      )}

                      <Scrollbar>
                        <Table size={table.dense ? 'small' : 'large'} className="tableehead">
                          <TableHeadCustom
                            order={table.order}
                            orderBy={table.orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={tableData.length}
                            numSelected={table.selected.length}
                            onSort={table.onSort}
                            onSelectAllRows={(checked) =>
                              table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                              )
                            }
                          />
                          <TableBody>
                            {dataInPage.map((row, index) => (
                              <TableRow key={row.id}>
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={table.selected.includes(row.id)}
                                    onChange={() => table.onSelectRow(row.id)}
                                  />
                                </TableCell>
                                <TableCell align="left">
                                  {index + 1 + table.page * table.rowsPerPage}
                                </TableCell>
                                <TableCell align="left">{row.incident_id}</TableCell>
                                <TableCell align="left">{row.description}</TableCell>
                                <TableCell align="left">{formatDate(row.incident_date)}</TableCell>
                                <TableCell align="left">{row.impacted_area}</TableCell>
                                <TableCell align="left">{row.immediate_action}</TableCell>
                                <TableCell align="left">{row.severity}</TableCell>
                                <TableCell align="left" sx={{ display: 'flex' }}>
                                  <IconButton onClick={handleToggleIcons}>
                                    <Iconify icon="eva:more-vertical-fill" />
                                  </IconButton>
                                  {areIconsVisible && (
                                    <>
                                      <Tooltip title="Delete">
                                        <IconButton
                                          onClick={() => {
                                            setSelectedItem(row); // Set the item you want to delete
                                            confirm.onTrue(); // Trigger the confirmation dialog
                                          }}
                                        >
                                          <Iconify icon="solar:trash-bin-trash-bold" />
                                        </IconButton>
                                      </Tooltip>

                                      <Tooltip title="Edit">
                                        <IconButton
                                          onClick={() => {
                                            setSelectedItem(row);
                                            create.onTrue();
                                          }}
                                        >
                                          <Iconify icon="solar:pen-bold" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip
                                        title="View"
                                        onClick={() =>
                                          navigate(
                                            `/risk-management/incident-reporting/incidentView/${row.id}`
                                          )
                                        }
                                      >
                                        <IconButton>
                                          <Iconify icon="solar:eye-bold" />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableEmptyRows
                              height={denseHeight}
                              emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                            />
                            <TableNoData notFound={notFound} />
                          </TableBody>
                        </Table>
                      </Scrollbar>
                    </TableContainer>
                    <TablePaginationCustom
                      count={dataFiltered.length}
                      page={table.page}
                      rowsPerPage={table.rowsPerPage}
                      onPageChange={table.onChangePage}
                      onRowsPerPageChange={table.onChangeRowsPerPage}
                      dense={table.dense}
                      onChangeDense={table.onChangeDense}
                    />
                  </Card>
                </>
              )}
            </CardContent>
          </Card>
          <IncidentReportingCreate
            open={create.value}
            currentItem={selectedItem}
            onClose={create.onFalse}
            reload={() => getIncidentReporting()}
            closeform={() => handleCloseForm()}
          />
          <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title="Delete"
            content="Are you want to delete selected item?"
            action={
              <Button
                variant="contained"
                className="dark-button"
                onClick={() => {
                  handleDeleteRows();
                  confirm.onFalse();
                }}
              >
                Delete
              </Button>
            }
          />

          <ConfirmDialog
            open={remove.value}
            onClose={remove.onFalse}
            title="Delete"
            content={<>Are you sure want to delete these items?</>}
            action={
              <LoadingButton
                loading={deleteLoading}
                variant="contained"
                className="dark-button"
                onClick={() => {
                  handleBulkDelete();
                }}
              >
                Delete
              </LoadingButton>
            }
          />
        </div>
      ) : (
        // Rendered JSX after clicking "Create"
        <div
          style={{
            padding: '16px',
          }}
        >
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between', // Push items to start and end
                alignItems: 'center', // Vertically center the items
                width: '100%', // Ensure it spans the full width of the parent
              }}
            >
              <div></div>

              <Button variant="contained" className="dark-button" onClick={handleBackClick}>
                Back
              </Button>
            </div>

            <CreateVendor />
          </div>
        </div>
      )}
    </>
  );
};

export default IncidentReporting;
