import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------
const CHART_HEIGHT = 345;
const LEGEND_HEIGHT = 50;

const StyledChart = styled(Chart)(({ theme }) => ({
    height: CHART_HEIGHT,
    '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
        height: '100% !important',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        borderTop: `dashed 1px ${theme.palette.divider}`,
        marginTop: '8px',
    },
}));

// ----------------------------------------------------------------------
export default function MitigationCard({ title = '', subheader = '', total = 0, chart = {}, ...other }) {
    console.log("🚀 ~ MitigationCard ~ total:", total)
    const theme = useTheme();

    const {
        colors = [
            theme.palette.primary.main,
            theme.palette.warning.main,
            theme.palette.error.main,
        ],
        series = [],
        options = {},
    } = chart;

    const chartSeries = (series || []).map((i) => i.value);

    const chartOptions = useChart({
        colors: colors,
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        labels: (series || []).map((i) => i.label),
        legend: {
            floating: true,
            position: 'bottom',
            horizontalAlign: 'center',
        },
        fill: {
            type: 'solid',
        },
        plotOptions: {
            radialBar: {
                hollow: { size: '48%' },
                dataLabels: {
                    value: {
                        offsetY: 16,
                        formatter: (val) => fNumber(val), // Remove percentage sign
                    },
                    total: {
                        formatter: (w) => {
                            const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                            return fNumber(sum);
                        },
                    },
                },
            },
        },
        ...options,
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 1 }} />
            <StyledChart
                dir="ltr"
                type="radialBar"
                series={chartSeries}
                options={chartOptions}
                height={300}
            />
        </Card>
    );
}

MitigationCard.propTypes = {
    chart: PropTypes.shape({
        series: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })).isRequired,
        colors: PropTypes.array,
        options: PropTypes.object,
    }).isRequired,
    subheader: PropTypes.string,
    title: PropTypes.string,
    total: PropTypes.number,
};

MitigationCard.defaultProps = {
    subheader: '',
    title: '',
    total: 0,
};
