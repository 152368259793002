import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'src/routes/hooks'
import { getRequest } from 'src/services'
import { endpoints } from 'src/utils/axios'
import IncidentTable from './incidenttable';
const IncidentView = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [incidentDetails, setIncidentDetails] = useState();  // New state for incident details

    useEffect(() => {
        getIncidentShow();
    }, [])

    const getIncidentShow = async () => {
        const data = await getRequest(endpoints.risk_management.incident_reporting.IncidentShow(id));
        console.log("🚀 ~ getIncidentShow ~ data:", data)
        setIncidentDetails(data);

    }
    return (
        <IncidentTable data={incidentDetails} />
    );
};
export default IncidentView;