/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider, { RHFAutocomplete, RHFSelect, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'notistack';
import { getRequest, postRequest } from 'src/services';
import { useParams } from 'react-router';
import { endpoints } from 'src/utils/axios';
import { handleErrorMessages } from './feching';
import Iconify from 'src/components/iconify';

const RiskMitigationCreate = ({ open, onClose, data, reload, }) => {
  console.log("🚀 ~ RiskMitigationCreate ~ data:", data)
  const { id } = useParams();
  const [usersID, setUsersID] = useState([]);
  const [warning, setWarning] = useState('');
  const [switchChecked, setSwitchChecked] = useState(0);
  const [selectedBudgetStatus, setSelectedBudgetStatus] = useState(data?.budgetstatus ?? 0);
  const [status, setStatus] = useState(data?.status || 'Not Started');
  const [needResources, setNeedResources] = useState(0);
  const [originalValues, setOriginalValues] = useState(null);

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setSwitchChecked(isChecked ? 1 : 0);
    setNeedResources(isChecked ? 1 : 0);
    if (!isChecked) {
      setSelectedBudgetStatus(0);
      setValue('budgetstatus', 0);
    }
    if (isChecked && fields.length === 0) {
      append({
        id: '',
        sr_number: 1,
        code: '',
        description: '',
        quantity: '',
        unit: '',
        unit_price: '',
        amount: '',
        hours_spent: '',
        amount_spent: '',
        balance: '',
        balance_hours: ''
      });
    }
  };

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    end_date: status === 'Completed' && Yup.mixed().required('End Date is required'),
  });

  const defaultValues = {
    mitigation_strategy: data?.mitigation_strategy || '',
    assigned_user_id: data?.assigned_user_id || '',
    sr_number: 1,
    mitigation: data?.mitigation_resources?.map((resource, index) => ({
      id: resource?.id || '',
      sr_number: index + 1,
      code: resource?.code || '',
      description: resource?.description || '',
      quantity: resource?.quantity || '',
      unit: resource?.unit || '',
      unit_price: resource?.unit_price || '',
      amount: resource?.amount || '',
      hours_spent: resource?.hours_spent || '',
      amount_spent: resource?.amount_spent || '',
      balance: resource?.balance || '',
      balance_hours: resource?.balance_hours || '',
      complete: resource?.complete || 0,  // Set default value
    })) || [
        {
          id: '',
          sr_number: 1,
          code: '',
          description: '',
          quantity: '',
          unit: '',
          unit_price: '',
          amount: '',
          hours_spent: '',
          amount_spent: '',
          balance: '',
          balance_hours: ''
        }
      ],
    budgetstatus: data?.budget_approval_status ?? 0,
    amount_spent: data?.amount_spent || 0,
    start_date: data?.start_date ? new Date(data?.start_date) : null,
    end_date: data?.end_date ? new Date(data?.end_date) : null,
    status: data?.status || 'Not Started', // Default to 'Not Started'
    effectiveness_rating: data?.effectiveness_rating || '',
    meeting_notes: data?.meeting_notes || '',
    total_balance: data?.total_balance || 0,
    total_budgeted_amount: data?.total_budgeted_amount || 0,
    total_amount_spent: data?.total_amount_spent || 0,
    total_hours_spent: data?.total_hours_spent || 0,  // Set default value
    type: data?.type || ''
  };
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    control,
    reset,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'mitigation',  // Ensure this matches the form field name
  });


  useEffect(() => {
    console.log("🚀 ~ RiskMitigationCreate ~ fields:", fields)
    console.log("Switch Checked:", switchChecked);
    console.log("Mitigation Resources:", mitigation);
  }, [switchChecked, setValue, fields]);

  const mitigation = useWatch({ control, name: 'mitigation' });
  useEffect(() => {
    const totalAmount = mitigation?.reduce((acc, curr) => {
      return acc + (parseFloat(curr.amount) || 0);
    }, 0);
    setValue('amount_spent', totalAmount);
  }, [mitigation, setValue]);

  const handleTotalBudgetedAmountChange = () => {
    const mitigations = getValues('mitigation'); // Get all mitigation activities
    const totalBudgetedAmount = mitigations.reduce((acc, curr) => {
      const amount = parseFloat(curr.amount) || 0;
      return acc + amount;
    }, 0);

    setValue('total_budgeted_amount', totalBudgetedAmount); // Set the total budgeted amount
    handleTotalAmountSpentChange(); // Recalculate total amount spent
    handleTotalBalanceChange(); // Recalculate total balance
  };
  const handleTotalHoursSpentChange = () => {
    const mitigations = getValues('mitigation'); // Get all mitigation activities
    const totalHoursSpent = mitigations.reduce((acc, curr) => {
      const hoursSpent = parseFloat(curr.hours_spent) || 0;
      return acc + hoursSpent;
    }, 0);

    setValue('total_hours_spent', totalHoursSpent); // Set the total hours spent
    handleTotalAmountSpentChange(); // Recalculate total amount spent
    handleTotalBalanceChange(); // Recalculate total balance
  };
  const handleTotalAmountSpentChange = () => {
    const mitigations = getValues('mitigation') || []; // Get all mitigation entries
    const totalAmountSpent = mitigations.reduce((sum, mitigation) => {
      const amountSpent = parseFloat(mitigation.amount_spent) || 0;
      return sum + amountSpent;
    }, 0);

    setValue('total_amount_spent', totalAmountSpent.toFixed(3)); // Set the total amount spent
    handleTotalBalanceChange(); // Recalculate total balance
  };
  const handleTotalBalanceChange = () => {
    const totalBudgetedAmount = parseFloat(getValues('total_budgeted_amount')) || 0;
    const totalAmountSpent = parseFloat(getValues('total_amount_spent')) || 0;

    const totalBalance = totalBudgetedAmount - totalAmountSpent;
    setValue('total_balance', totalBalance.toFixed(3)); // Set the total balance
  };
  const handleQuantityOrPriceChange = (index) => {
    const quantity = parseFloat(getValues(`mitigation[${index}].quantity`)) || 0;
    const unitPrice = parseFloat(getValues(`mitigation[${index}].unit_price`)) || 0;

    const amount = quantity * unitPrice;
    console.log("🚀 ~ handleQuantityOrPriceChange ~ amount:", amount)
    setValue(`mitigation[${index}].amount`, amount.toFixed(3)); // Set the calculated amount value
    handleTotalBudgetedAmountChange(); // Update the total budgeted amount
    calculateBalance(index); // Recalculate balance after amount is set
    handlePercentCompleteChange(index); // <-- Call here
    handleTotalAmountSpentChange();    // Trigger total amount spent recalculation


  };
  const handlePercentCompleteChange = (index) => {
    const amount = parseFloat(getValues(`mitigation[${index}].amount`)) || 0;
    const amountSpent = parseFloat(getValues(`mitigation[${index}].amount_spent`)) || 0;

    if (amount > 0) {
      const percentComplete = ((amountSpent / amount) * 100).toFixed(2); // Updated calculation logic
      setValue(`mitigation[${index}].complete`, percentComplete); // Set the calculated % Complete
    } else {
      setValue(`mitigation[${index}].complete`, '0'); // Set 0% if amount is 0
    }
  };
  const calculateAmountSpent = (index) => {
    const unitPrice = parseFloat(getValues(`mitigation[${index}].unit_price`)) || 0;
    const hoursSpent = parseFloat(getValues(`mitigation[${index}].hours_spent`)) || 0;

    const amountSpent = unitPrice * hoursSpent;

    setValue(`mitigation[${index}].amount_spent`, amountSpent.toFixed(2)); // Set with 2 decimal places
    calculateBalance(index); // Recalculate balance after amount_spent is set
    handlePercentCompleteChange(index); // <-- Call here
    handleTotalHoursSpentChange(); // Update total hours spent


  };
  const calculateBalanceHour = (index) => {
    const hours = parseFloat(getValues(`mitigation[${index}].quantity`)) || 0;
    const hoursSpent = parseFloat(getValues(`mitigation[${index}].hours_spent`)) || 0;
    const balanceHour = hours - hoursSpent;
    setValue(`mitigation[${index}].balance_hours`, balanceHour.toFixed(2));
  };
  const calculateBalance = (index) => {
    const amount = parseFloat(getValues(`mitigation[${index}].amount`)) || 0;
    const amountSpent = parseFloat(getValues(`mitigation[${index}].amount_spent`)) || 0;
    const balance = amount - amountSpent;
    setValue(`mitigation[${index}].balance`, balance.toFixed(2));
  };

  const handleRemove = (index) => {
    remove(index); // Remove the resource at the specified index
    const currentMitigations = getValues('mitigation');

    // Reassign Sr. numbers starting from 1 after removing an item
    currentMitigations.forEach((item, idx) => {
      item.sr_number = idx + 1; // Sr. number starts from 1 and increments for each remaining item
    });

    // Update the form values after recalculation
    setValue('mitigation', currentMitigations);
    handleRecalculate(); // Recalculate the totals after removal
    handleTotalAmountSpentChange(); // Update the total amount spent
  };

  const handleRecalculate = () => {
    const mitigations = getValues('mitigation');

    const totalBudgetedAmount = mitigations.reduce((acc, curr) => {
      const amount = parseFloat(curr.amount) || 0;
      return acc + amount;
    }, 0);
    setValue('total_budgeted_amount', totalBudgetedAmount.toFixed(0));
    const totalHoursSpent = mitigations.reduce((acc, curr) => {
      const hoursSpent = parseFloat(curr.hours_spent) || 0;
      return acc + hoursSpent;
    }, 0);

    setValue('total_hours_spent', totalHoursSpent.toFixed(3));
    const totalAmountSpent = totalBudgetedAmount * totalHoursSpent;
    setValue('total_amount_spent', totalAmountSpent.toFixed(3));
    const totalBalance = totalBudgetedAmount - totalAmountSpent;
    setValue('total_balance', totalBalance.toFixed(3));
  };

  const formatChanger = (currentDate) => {
    if (!currentDate) {
      return ''; // Return an empty string if the date is null or undefined
    }
    const year = currentDate?.getFullYear();
    const month = String(currentDate?.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate?.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const handleAddMore = () => {
    const currentMitigations = getValues('mitigation');
    const newSrNumber = currentMitigations.length + 1; // Increment the Sr. number based on the current length

    append({
      id: '',
      sr_number: newSrNumber, // Set the new Sr. number based on the length
      code: '',
      description: '',
      quantity: '',
      unit: '',
      unit_price: '',
      amount: '',
      hours_spent: '',
      amount_spent: '',
      balance: '',
      balance_hours: ''
    });
    handleTotalAmountSpentChange(); // Trigger recalculation when a new resource is added
  };

  const typeCheckValue = useWatch({ control, name: 'type' });
  console.log("🚀 ~ RiskMitigationCreate ~ typeCheckValue:", typeCheckValue)

  const clearMitigationFields = (index) => {
    console.log("fields cklear kr di hai......")
    setValue(`mitigation[${index}].code`, '');
    setValue(`mitigation[${index}].description`, '');
    setValue(`mitigation[${index}].quantity`, '');
    setValue(`mitigation[${index}].unit`, '');
    setValue(`mitigation[${index}].unit_price`, '');
    setValue(`mitigation[${index}].amount`, '');
    setValue(`mitigation[${index}].hours_spent`, '');
    setValue(`mitigation[${index}].amount_spent`, '');
    setValue(`mitigation[${index}].balance`, '');
    setValue(`mitigation[${index}].balance_hours`, '');
  };



  const Typeoptions = [
    { label: 'quantity', value: 0 },
    { label: 'hours', value: 1 },
  ];
  const onSubmit = handleSubmit(async (formData) => {
    console.log("🚀 ~ onSubmit ~ formData:", formData);
    let hasError = false;
    let hasWarning = false; // Flag to track warning errors
    if (!formData.mitigation_strategy || formData.mitigation_strategy.trim() === '') {
      setError("mitigation_strategy", {
        type: "manual",
        message: "Mitigation strategy is required.",
      });
      enqueueSnackbar("Mitigation strategy is required", { variant: "error" });
      hasError = true;
    }
    if (!formData.assigned_user_id) {
      setError("assigned_user_id", {
        type: "manual",
        message: "Assigned user is required",
      });
      enqueueSnackbar("Assigned user is required.", { variant: "error" });
      hasError = true;
    }
    if (switchChecked === 1 && needResources === 1) {
      formData.mitigation.forEach((item, index) => {
        if (!item.code) {
          setError(`mitigation[${index}].code`, {
            type: "manual",
            message: "Code is required",
          });
          enqueueSnackbar("Code is required.", { variant: "error" });
          hasError = true;
        }
        if (!item.quantity || item.quantity <= 0) {
          setError(`mitigation[${index}].quantity`, {
            type: "manual",
            message: typeCheckValue === 1 ? "Hours is required" : "Quantity is required",
          });
          enqueueSnackbar(
            typeCheckValue === 1
              ? "Hours is required"
              : "Quantity is required",
            { variant: "error" }
          );
          hasError = true;
        }
        if (!item.unit_price || item.unit_price <= 0) {
          setError(`mitigation[${index}].unit_price`, {
            type: "manual",
            message: "Unit Price is required",
          });
          enqueueSnackbar("Unit Price is required.", { variant: "error" });
          hasError = true;
        }
      }
      );

      if (hasError) return;
    } else {
      formData.mitigation.forEach((_, index) => {
        clearErrors(`mitigation[${index}].code`);
        clearErrors(`mitigation[${index}].quantity`);
        clearErrors(`mitigation[${index}].unit_price`);
      });
    }
    if (formData.mitigation_strategy) {
      clearErrors("mitigation_strategy");
    }
    if (formData.assigned_user_id) {
      clearErrors("assigned_user_id");
    }
    const typeValue = typeCheckValue === 1 ? 'hours' : 'quantity'; // Set type based on watched value
    console.log("🚀 ~ onSubmit ~ typeCheckValue:", typeCheckValue);
    if (hasWarning) {
      enqueueSnackbar("Please resolve warning errors before submitting.", { variant: "warning" });
      return;
    }
    const payload = {
      mitigation_strategy: formData?.mitigation_strategy?.trim() || null,
      assigned_user_id: formData?.assigned_user_id || '',
      mitigation_resources: switchChecked ? formData?.mitigation : [],
      amount_spent: formData?.amount_spent || '',
      status: status || '',
      start_date: status !== 'Not Started' ? formatChanger(formData?.start_date) || '' : '',
      end_date: status !== 'Not Started' ? formatChanger(formData?.end_date) || '' : '',
      effectiveness_rating: status !== 'Not Started' ? formData?.effectiveness_rating || '' : '',
      meeting_notes: status !== 'Not Started' ? formData?.meeting_notes || '' : '',
      budget_approval_status: formData?.budgetstatus === 0 ? 0 : 1,
      total_balance: formData?.budgetstatus !== 0 ? formData?.total_balance : 0,
      total_budgeted_amount: formData?.budgetstatus !== 0 ? formData?.total_budgeted_amount : 0,
      total_amount_spent: formData?.budgetstatus !== 0 ? formData?.total_amount_spent : 0,
      total_hours_spent: formData?.budgetstatus !== 0 ? formData?.total_hours_spent : 0,
      need_resources: switchChecked,
      complete: formData?.complete,
      quantity: typeCheckValue === 0 ? formData?.mitigation?.quantity : [],
      hours: typeCheckValue === 1 ? formData?.mitigation?.quantity : 0,
      balance_hours: typeCheckValue === 1 ? formData?.mitigation?.balance_hours : 0,
      type: typeValue, // Set type based on the selected option
    };

    if (payload.mitigation_strategy === null) {
      enqueueSnackbar("Mitigation strategy is required", { variant: "error" });
      return;
    }
    try {
      const responseData = await postRequest(endpoints.risk_management.risk_register.identification.mitigation_plans(id), payload, false);
      console.log("🚀 ~ onSubmit ~ responseData:", responseData);
      if (responseData.metadata && responseData.metadata.success) {
        onClose();
        reload();
        reset(formData);  // Reset the form with the newly submitted data
        enqueueSnackbar(responseData.metadata.message, { variant: 'success' });
      } else {
        handleErrorMessages(responseData.metadata.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  });
  const getProjectStageOptions = async () => {
    try {
      const data = await getRequest(endpoints.risk_management.risk_register.identification.AddUserID);
      const transformedOptions = data.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      }));
      setUsersID(transformedOptions);
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
  };
  const assignedToValue = useWatch({ control, name: 'assigned_user_id' });
  const selectedUser = usersID.find(user => user.id === assignedToValue) || null;
  const budgetStatusOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];
  const statusOptions = [
    { label: 'Not Started', value: 'Not Started' },
    { label: 'In Progress', value: 'In Progress' },
    { label: 'Completed', value: 'Completed' },
  ];
  const effectivenessOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ];
  useEffect(() => {
    getProjectStageOptions();
  }, []);

  useEffect(() => {
    if (data) {
      if (data.mitigation_resources && data.mitigation_resources.length > 0) {
        setSwitchChecked(1);
        setNeedResources(1);
      } else {
        setSwitchChecked(0);
        setNeedResources(0);
        setSelectedBudgetStatus(0);
      }
    } else {
      setSwitchChecked(0);
      setNeedResources(0);
    }
  }, [data]);

  useEffect(() => {
    console.log("Switch Checked:", switchChecked);
    console.log("Need Resources:", needResources);
  }, [switchChecked, needResources]);

  useEffect(() => {
    if (data) {
      setOriginalValues({
        ...defaultValues,
        status: data?.status || 'Not Started',
      });
      reset({
        ...defaultValues,
        type: data?.type === 'hours' ? 1 : 0,
        status: data?.status || 'Not Started',
      });
      setValue('type', data?.type === 'hours' ? 1 : 0);
    } else {
      setValue('type', 0);
    }
  }, [data, reset, open, setValue]);

  useEffect(() => {
    console.log("Switch Checked:", switchChecked);
    if (switchChecked === 0) {
      setSelectedBudgetStatus(0);
      setValue('total_amount_spent', 0);
      setValue('total_balance', 0);
      setValue('total_budgeted_amount', 0);
      setValue('total_hours_spent', 0);
    } else if (getValues('budgetstatus') === 1) {
      setSelectedBudgetStatus(1);
      handleTotalBudgetedAmountChange();
      handleTotalAmountSpentChange();
      handleTotalHoursSpentChange();
      handleTotalBalanceChange();
    } else {
      console.log("buget No hai");
      setSelectedBudgetStatus(0);
    }
  }, [switchChecked, getValues('budgetstatus'), setSelectedBudgetStatus, setValue]);

  const handleClose = () => {
    if (data) {
      console.log("🚀 ~ handleClose ~ data:", data);

      // Check if mitigation resources exist and set the switch accordingly
      if (data.mitigation_resources && data.mitigation_resources.length > 0) {
        setSwitchChecked(1);
        setNeedResources(1);
      } else {
        setSwitchChecked(0);
        setNeedResources(0);
      }
    } else {
      setSwitchChecked(0);
      setNeedResources(0);
    }
    if (data) {
      setOriginalValues({
        ...defaultValues,
        status: data?.status || 'Not Started',
      });
      reset({
        ...defaultValues,
        type: data?.type === 'hours' ? 1 : 0,
        status: originalValues?.status || 'Not Started',
      });
      setValue('type', data?.type === 'hours' ? 1 : 0);
      setStatus(originalValues?.status || 'Not Started');
    } else {
      setValue('type', 0);
      setStatus('Not Started');
    }
    clearErrors();
    setWarning('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Risk Mitigation Plan &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>


        </DialogTitle>

        <DialogContent sx={{ borderRadius: '1px' }}>
          <Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
            <RHFTextField name="mitigation_strategy" label="Mitigation Strategy" />
            <RHFAutocomplete
              sx={{ width: '100%' }}
              name="assigned_user_id"
              label="Assigned To"
              options={usersID}
              value={selectedUser}
              getOptionLabel={(option) => option?.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, value) => setValue('assigned_user_id', value ? value.id : '')}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            <Typography sx={{ fontWeight: "bold" }}>Do You Need Resources</Typography>
            <Switch
              checked={switchChecked === 1}
              onChange={handleSwitchChange}
              color="primary" // You can set the color of the switch here
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
            {switchChecked === 1 && (
              <RHFAutocomplete
                sx={{ width: '50%' }}
                name="type"
                label="Type Check"
                options={Typeoptions}
                value={Typeoptions.find(option => option.value === getValues('type')) || Typeoptions[0]}
                getOptionLabel={(option) => option?.label || ''}
                onChange={(event, value) => {
                  const selectedValue = value ? value.value : '';
                  const previousValue = getValues('type');
                  if ((previousValue === 0 && selectedValue === 1) || (previousValue === 1 && selectedValue === 0)) {
                    fields.forEach((_, index) => clearMitigationFields(index));
                  }
                  setValue('type', selectedValue);
                }}
              />

            )}
          </Box>
          {switchChecked === 1 && (
            <>
              {/* Mitigation Resources */}
              {fields.map((item, index) => (
                <>
                  <Box key={item.id} mt={2}>
                    <Typography mb={2} sx={{ fontWeight: 'bold' }}>Resources Required</Typography>
                    <Box
                      mb={3}
                      display="grid"
                      gridTemplateColumns={{
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(3, 1fr)',
                      }}
                      alignItems="center"
                      columnGap={2}
                      rowGap={3}
                    >
                      <Controller
                        name={`mitigation[${index}].sr_number`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField {...field} label="Sr no" type="number" disabled /> // It will now show numbers like 1, 2, 3
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].code`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <RHFTextField
                            {...field}
                            label="Code*"
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].description`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField {...field} label="Description" />
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].quantity`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label={typeCheckValue === 1 ? 'Hours*' : 'Qty*'}
                            type="number"
                            onChange={(e) => {
                              const value = Number(e.target.value); // Convert to a number
                              if (value >= 0) {
                                field.onChange(value);
                                handleQuantityOrPriceChange(index);
                                calculateBalanceHour(index);
                              }
                            }}
                            onBlur={() => {
                              calculateBalanceHour(index);
                            }}
                          />
                        )}
                      />
                      <RHFTextField name={`mitigation[${index}].unit`} label="Unit"
                        type="text"
                      />
                      <Controller
                        name={`mitigation[${index}].unit_price`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label="Unit Price*"
                            type="number"
                            onChange={(e) => {
                              const value = Number(e.target.value); // Convert to a number
                              if (value >= 0) {
                                field.onChange(value);
                                handleQuantityOrPriceChange(index);
                                calculateAmountSpent(index);
                              }
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].amount`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label="Amount"
                            type="number"
                            disabled
                            onChange={(e) => {
                              field.onChange(e);
                              if (typeCheckValue === 1) {
                                calculateBalance(index);
                              } else {
                                calculateBalance(index);
                                handlePercentCompleteChange(index);
                              }
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].amount_spent`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label="Amount Spent"
                            type="number"
                            disabled={typeCheckValue === 1} // Disable when typeCheckValue is 1
                            onChange={(e) => {
                              field.onChange(e); // Update react-hook-form state
                              calculateBalance(index); // Recalculate balance
                              handlePercentCompleteChange(index); // Handle percent complete change
                              handleTotalAmountSpentChange(); // <-- Call this function to recalculate total amount spent
                            }}
                          />
                        )}
                      />
                      <Controller
                        name={`mitigation[${index}].balance`}
                        control={control}
                        render={({ field }) => (
                          <RHFTextField
                            {...field}
                            label="Total Balance"
                            type="number"
                            disabled
                          />
                        )}
                      />
                      {selectedBudgetStatus !== 0 && (
                        <>
                          {typeCheckValue === 1 && (
                            <Controller
                              name={`mitigation[${index}].hours_spent`}
                              control={control}
                              render={({ field }) => (
                                <RHFTextField
                                  {...field}
                                  label="Hours Spent"
                                  type="number"
                                  onChange={(e) => {
                                    field.onChange(e);
                                    calculateBalanceHour(index);
                                    calculateAmountSpent(index);

                                  }}
                                />
                              )}
                            />
                          )}

                          {typeCheckValue === 1 && (
                            <Controller
                              name={`mitigation[${index}].balance_hours`}
                              control={control}
                              render={({ field }) => (
                                <RHFTextField {...field} label="Balance Hours" type="number" disabled />
                              )}
                            />
                          )}
                        </>
                      )}
                      <Controller
                        name={`mitigation[${index}].complete`}
                        control={control}
                        render={({ field: { onChange, value, ...field } }) => (
                          <RHFTextField
                            {...field}
                            label="Complete %"
                            type="number"
                            value={value && !isNaN(value) && isFinite(value) ? value : '0'}
                            disabled
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              if (inputValue === '' || isNaN(inputValue) || !isFinite(inputValue)) {
                                inputValue = 0;
                              }
                              onChange(parseFloat(inputValue));
                            }}
                          />
                        )}
                      />
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <LoadingButton
                          className='dark-button'
                          variant="contained"
                          onClick={handleAddMore}
                          sx={{ padding: '8px 16px' }}
                        >
                          Add More
                        </LoadingButton>
                        {fields.length > 1 && (
                          <Button variant="outlined"
                          sx={{padding:"7px 16px"}}
                            color="inherit"
                            onClick={() => handleRemove(index)}>
                            Remove
                          </Button>

                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              ))}
              <Box mt={2} rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                <RHFSelect
                  native
                  sx={{ width: '100%' }}
                  name="budgetstatus"
                  label="Budget Approval Status"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={
                    getValues('budgetstatus') !== undefined
                      ? getValues('budgetstatus')
                      : 0
                  }
                  onChange={(event) => {
                    const selectedValue = event.target.value ? Number(event.target.value) : 0;
                    setValue('budgetstatus', selectedValue);
                    setSelectedBudgetStatus(selectedValue);
                  }}
                >
                  {budgetStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </RHFSelect>
                {selectedBudgetStatus !== 0 && (
                  <>
                    <Controller
                      name="total_budgeted_amount"
                      control={control}
                      render={({ field }) => (
                        <RHFTextField {...field} label="Total Budgeted Amount" type="number" disabled />
                      )}
                    />
                    {typeCheckValue === 1 && (
                      < Controller
                        name="total_hours_spent"
                        control={control}
                        render={({ field }) => (
                          <RHFTextField {...field} label="Total Hours Spent" type="number" disabled />
                        )}
                      />
                    )}
                    <Controller
                      name="total_amount_spent"
                      control={control}
                      render={({ field }) => (
                        <RHFTextField {...field} label="Total Amount Spent" type="number" disabled />
                      )}
                    />
                    <Controller
                      name="total_balance"
                      control={control}
                      render={({ field }) => (
                        <RHFTextField {...field} label="Total Balance Amount" type="number" disabled />
                      )}
                    />
                  </>
                )}
              </Box>
            </>
          )}
          <Typography mt={2} mb={2} sx={{ fontWeight: "bold" }}>Mitigation Status</Typography>
          <Box rowGap={3} columnGap={2} display="grid" gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
            <RHFAutocomplete
              name="status"
              label="Status"
              options={statusOptions}
              value={statusOptions.find(option => option.value === status)}
              getOptionLabel={(option) => option?.label || ''}
              onChange={(e, value) => setStatus(value ? value.value : '')}
            />
            {status !== 'Not Started' && (
              <>
                <Controller
                  name="start_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Start Date"
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        const endDate = methods.getValues('end_date');
                        if (newValue && endDate && newValue > endDate) {
                          setWarning('Start Date cannot be later than End Date');
                          setError('start_date', { message: 'Start Date cannot be later than End Date' });
                        } else {
                          setWarning('');
                          clearErrors('start_date');
                          clearErrors('end_date');
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error || !!warning,
                          helperText: error?.message || warning,
                        },
                      }}
                    />
                  )}
                />
                <Controller
                  name="end_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label={status === "Completed" ? "End Date*" : "End Date"}
                      value={field.value}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        const startDate = methods.getValues('start_date');
                        if (newValue && startDate && newValue < startDate) {
                          setWarning('End Date cannot be earlier than Start Date');
                          setError('end_date', { message: 'End Date cannot be earlier than Start Date' });
                        } else {
                          setWarning('');
                          clearErrors('end_date');
                          clearErrors('start_date');
                        }
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error || !!warning,
                          helperText: error?.message || warning,
                        },
                      }}
                    />
                  )}
                />
                <RHFSelect
                  native
                  name="effectiveness_rating"
                  label="Effectiveness Rating"
                  InputLabelProps={{ shrink: true }}
                >
                  <option value="">Not Selected</option>
                  {effectivenessOptions.map((option) => (
                    <option key={option.label} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </RHFSelect>
                <RHFTextField name="meeting_notes" label="Risk Work Shop / Meeting Notes (If Any)" />
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" className='dark-button'
            loading={isSubmitting}>
            {!data ? 'Create Mitigation' : 'Update Mitigation'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default RiskMitigationCreate;