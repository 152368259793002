import PropTypes from 'prop-types';
// @mui
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ChangeOrder({ title, open, onClose, rowData }) {
    const theme = useTheme();

    const fieldsToDisplay = [
        { key: 'id', label: 'ID' },
        { key: 'project_id', label: 'Project ID' },
        { key: 'description', label: 'Description' },
        { key: 'code', label: 'Code' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'unit', label: 'Unit' },
        { key: 'budgeted_amount', label: 'Budgeted Amount' },
        { key: 'budgeted_hours', label: 'Budgeted Hours' },
        { key: 'planned_labor_cost', label: 'Planned Labor Cost' },
        { key: 'planned_material_cost', label: 'Planned Material Cost' },
        { key: 'planned_equipment_cost', label: 'Planned Equipment Cost' },
        { key: 'planned_subcontractor_cost', label: 'Planned Subcontractor Cost' },
    ];

    const renderGridRow = (label, value) => (
        <>
            <Grid item xs={3}>
                <Typography sx={{ fontWeight: 'bold' }}>{label}:</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography sx={{ color: 'text.secondary' }}>{value || '-'}</Typography>
            </Grid>
        </>
    );

    const renderContent = () => {
        if (!rowData?.change_orders?.length) {
            return (
                <Typography sx={{ textAlign: 'center', mt: 2 }}>
                    No change orders available.
                </Typography>
            );
        }

        return rowData.change_orders.map((order, index) => (
            <Grid
                container
                spacing={2}
                key={order.id || index} // Use a unique identifier like 'id' or fallback to 'index' if 'id' is missing
                sx={{ mb: 3, borderBottom: '1px solid #e0e0e0', pb: 2 }}
            >
                {fieldsToDisplay?.map(({ key, label }) => renderGridRow(label, order[key]))}
            </Grid>
        ));
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            sx={{
                borderRadius: '8px',
                fontSize: '14px',
                fontFamily: 'Inter, Arial, sans-serif',
            }}
            transitionDuration={{
                enter: theme.transitions.duration.shortest,
                exit: theme.transitions.duration.shortest - 80,
            }}
        >
            <DialogTitle sx={{ justifyContent: 'space-evenly', boxSizing: 'content-box' }}>
                <span style={{ padding: '7px', marginLeft: '-25px', fontWeight: '700', color: "white" }} className='cardColor' >
                    &nbsp;&nbsp; {title} &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                </span>

            </DialogTitle>
         
            <Divider />
            <DialogContent sx={{ typography: 'body2', px: 4, py: 2 }}>
                {renderContent()}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-end', px: 2 }}>
                <Button
                    variant="outlined"
                    color="inherit"
                    onClick={onClose}
                    sx={{ textTransform: 'none' }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ChangeOrder.propTypes = {
    title: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    rowData: PropTypes.shape({
        change_orders: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                project_id: PropTypes.string,
                description: PropTypes.string,
                code: PropTypes.string,
                quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                unit: PropTypes.string,
                budgeted_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                budgeted_hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                planned_labor_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                planned_material_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                planned_equipment_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                planned_subcontractor_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
    }),
};
