import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import {
    IconButton,
    Box,
    useTheme,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { RHFAutocomplete } from '../hook-form';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import RiskRoleDialog from './role-drop-dialog';
import { useBoolean } from 'src/hooks/use-boolean';

const RiskprojectUserAdd = ({ value, label, options = [], url = '', reload = () => { }, onRiskOwnerChange }) => {
    console.log(value, "thats it ")
    const [usersID, setUsersID] = useState([]);
    const searchParams = useSearchParams();
    const theme = useTheme();
    const add = useBoolean()
    const currentUser = {};
    const { enqueueSnackbar } = useSnackbar();
    const NewUserSchema = Yup.object().shape({
        label: Yup.string().required(`${label} is required`),
    });
    const defaultValues = useMemo(
        () => ({
            risk_owner_id: value || "",
        }),
        [currentUser]
    );
    const methods = useForm({
        resolver: yupResolver(NewUserSchema),
        defaultValues,
    });
    const {

        control,
        setValue,
        formState: { isSubmitting },
    } = methods;

    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';

    useEffect(() => {
        getProjectStageOptions();
    }, []);
    const getProjectStageOptions = async () => {
        try {
            const data = await getRequest(endpoints.risk_management.risk_register.identification.AddUserID);
            const transformedOptions = data.map(user => ({
                id: user.id,
                name: `${user.first_name} ${user.last_name}`,
            }));
            setUsersID(transformedOptions);
        } catch (error) {
            console.error("Error fetching user data: ", error);
        }
    };
    const assignedToValue = useWatch({ control, name: 'risk_owner_id' });
    const selectedUser = usersID.find(user => user.id === assignedToValue) || null;

    const handleOwnerChange = (event, value) => {
        setValue('risk_owner_id', value ? value.id : '');
        onRiskOwnerChange(value ? value.id : null);
    };

    const handleUserAdded = () => {
        getProjectStageOptions();
    };
    return (
        <Box sx={{ display: 'flex', alignItems: 'start' }}>
            <RHFAutocomplete
                sx={{ width: '100%' }}
                name="risk_owner_id"
                label={label}
                options={usersID}
                value={selectedUser}
                getOptionLabel={(option) => option?.name || ''}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={handleOwnerChange}
            />
            <IconButton
                onClick={add.onTrue}
                className='cardColor'
                sx={{
                    margin: '5px 10px',
               
                    fontSize: 25,
                    padding: '7px',
                    borderRadius: '50%',
                    '&:hover': {
                        backgroundColor: '#7AB13A',
                    },
                    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Iconify icon="mingcute:add-line" />
            </IconButton>
            {/* Modal */}
            <RiskRoleDialog
            label={label}
                open={add.value}
                onClose={add.onFalse}
                reload={handleUserAdded}
            />
        </Box>
    );
};
export default RiskprojectUserAdd;