import { Card } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';

export default function RisksByStage({ title, data }) {
    const riskData = data?.risk_by_stage || {};
    const categories = Object.keys(riskData);
    const seriesData = Object.values(riskData);

    const colors = ['#ff4c60', '#47C1BF', '#FEC84B', '#1A73E8', '#8368B7', '#F17D23', '#A94F50', '#5C4C8A', '#FFC107', '#36CFC9'];

    const chartData = {
        series: [
            {
                name: 'Risks by Stage',
                data: seriesData,
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: false,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'flat',
                    distributed: true, 
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Risks',
                },
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'bottom',
            },
            colors: colors, 
        },
    };

    return (
        <Card className="chart" sx={{
            padding: "10px", boxShadow: '0px 0px 30px 0px #64748B1A'
        }}>
            <h4>{title}</h4>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                height={320}
            />
        </Card>
    );
}
