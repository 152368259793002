import * as React from 'react';
import './eventStyles.css'
export default function renderperform(eventInfo) {
    // console.log(":rocket: ~ renderdefault ~ eventInfo:", eventInfo)
    // const { event } = eventInfo;
    // const date = new Date(eventInfo.date);
    // const highlightDate = new Date('2024-05-25');
    // const isHighlightDate = date.getTime() === highlightDate.getTime();

    // function formatDate(date) {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // }
    // function getDatesBetween(startDate, endDate) {
    //     const start = new Date(startDate);
    //     const end = new Date(endDate);
    //     const dates = [];
    //     for (let dt = new Date(start); dt < end; dt.setDate(dt.getDate() + 1)) {
    //         dates.push(formatDate(new Date(dt)));
    //     }
    //     console.log(dates)
    //     return dates;
    // }
    // const getPecentAge = (dates, perfomed) => {
    //     // console.log("🚀 ~ getPecentAge ~ perfomed:", perfomed)
    //     // console.log("🚀 ~ getPecentAge ~ dates:", dates)

    //     const divission = perfomed?.length / dates?.length
    //     return divission * 100 + '%'
    // }
    return (
        <div className='main-planed-perform-event' id={eventInfo.event.id}>
            <div className='planed-performed-container'>
                <p className='planed-performed-title'>{eventInfo.event.title}</p>
                {/* <div className='percentageDiv'>
                    <p>{getPecentAge(getDatesBetween(eventInfo.event.startStr, eventInfo.event.endStr), eventInfo.event._def.extendedProps.performed)}</p>
                </div> */}
                {/* {getDatesBetween(eventInfo.event.startStr,eventInfo.event.endStr).map((item)=>(
            <div className={eventInfo.event._def.extendedProps.performed?.map(obj => formatDate(new Date(obj.date))).includes(item) ? 'colered' : ''} style={{flex:1}}></div>
           ))} */}
            </div>
        </div>
    );
}