import React from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import "./style.css"
import RenderField from '../../risk-register/render-field';
const IncidentTable = ({ data }) => {
    console.log("🚀 ~ IncidentTable ~ data:", data)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Card>
            <Box p={3}>
                <Typography className="incident-view-main-heading">Incident Report</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                        Incident Details{' '}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Incident ID" value={data?.incident_id} />
                <RenderField label="Incident Description" value={data?.description} />
                <RenderField label="Incident Date " value={data?.incident_date} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Impacted Areas" value={data?.impacted_area} />
                <RenderField label="Immediate Actions Taken" value={data?.immediate_action} />
                <RenderField label="Incident Severity" value={data?.severity} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Is Related to Risk ID" value={data?.identification_and_assessment?.risk_id || ''} />
            </Box>
        </Card>
    );
};

export default IncidentTable;
