import React from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import RenderField from '../../risk-register/render-field';
const RiskLearningInsightTable = ({ data }) => {
    console.log("🚀 ~ RiskLearningInsightTable ~ data:", data)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Card>
            <Box p={3}>
                <Typography className=" Escalation-view-main-heading">Risk Learning and Insights Journal View </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                        Risk Learning and Insights Journal{' '}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Journal  ID" value={data?.journal_id}/>
                <RenderField label=" Related Risk ID" value={data?.identification_and_assessment?.risk_id} />
                <RenderField label=" Key Learnings" value={data?.key_learning} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
              
                <RenderField label="Insights for Future" value={data?.insight_for_future} />
                <RenderField label="Date of Entry" value={data?.date} />
            </Box>
        
            <Divider />
        </Card>
    );
};
export default RiskLearningInsightTable;