import PropTypes from 'prop-types';
import { useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
// utils
// import uuidv4 from 'src/utils/uuidv4';
import { fTimestamp } from 'src/utils/format-time';
// api
import { createEvent, updateEvent, deleteEvent, eventsData } from 'src/api/calendar';
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
// import { ColorPicker } from 'src/components/color-utils';
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
import MenuItem from '@mui/material/MenuItem';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';
import * as React from 'react';
import { baseURL, token } from 'src/config-global';

import { MobileDatePicker } from '@mui/x-date-pickers';
import Grid from '@mui/material/Grid'; // Grid version 1
// import Item from '@mui/material/Item'
import axios from 'src/utils/axios';
import { endpoints } from 'src/utils/axios';
import { Autocomplete, Avatar, Chip, IconButton, TextField, Tooltip, alpha } from '@mui/material';
// ----------------------------------------------------------------------

export default function CalendarForm({
  currentEvent,
  // colorOptions,
  payloadIds,
  onClose,
  getLeadsEvents,
  LeadURL,
  userList,
  selectEvent,
  isEdit,
  ticket,
  refetchTicket
}) {
  console.log("🚀 ~ payloadIds:", payloadIds)

  console.log(isEdit, "main Ticket")

  const [selectedContacts, setSelectedContacts] = useState([]);
  const [deleteLeadLoading,setDeletedLeadLoading]=useState(false)

  useEffect(() => {
    setSelectedContacts(payloadIds);
  }, [payloadIds]);

  // Function to format date as yyyy-mm-dd
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const { enqueueSnackbar } = useSnackbar();




  const EventSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    ticket: Yup.number().required('Ticket is required'),
    tag: Yup.string().required('Tag is require'),
    typeofLead: Yup.string().required('Type of Lead is require'),
    priority: Yup.string().required('Priority  Select is require'),
    preferredCommunication: Yup.string().required('Preferred Communication   Select is require'),
    start: Yup.mixed().required('Date of Lead  Select is require'),
    end: Yup.mixed().required('Due date Select is require'),
    score: Yup.number().max(100).min(0).required('Score  is require'),
    sourceofLead: Yup.string().required('Source of Lead  is require'),
    referred: Yup.string().required('If Referred - Referred By*  is require'),

    // start: Yup.mixed().required('Due Date   Select is require'),
    // not required
    // color: Yup.string(),
    // allDay: Yup.boolean(),
    // start: Yup.mixed(),
    // end: Yup.mixed(),
  });

  let type = [
    { id: 1, value: 1, label: 'Proposal', keyvalues: "PROPOSAL" },
    { id: 2, value: 2, label: 'Follow Up', keyvalues: "FOLLOW_UP" },
    { id: 3, value: 3, label: 'Maintenance', keyvalues: "MAINTENANCE" },
  ];


  function findByvalue(keyvalues, type) {
    const item = type.find(item => item.keyvalues === keyvalues);
    return item ? item : null;
  }

  let prefComm = [
    { id: 1, value: 1, label: 'Email', keyvalues: "EMAIL" },
    { id: 2, value: 2, label: 'Text', keyvalues: "TEXT" },
    { id: 3, value: 3, label: 'Phone', keyvalues: "PHONE" },
    { id: 4, value: 4, label: 'Others', keyvalues: "OTHERS" }
  ];

  function findByprefComm(keyvalues, prefComm) {
    const item = prefComm.find(item => item.keyvalues === keyvalues);
    return item ? item : null;
  }
  let Source = [
    { id: 1, value: 1, label: 'Google', keyvalues: " GOOGLE" },
    { id: 2, value: 2, label: 'Facebook', keyvalues: "FACEBOOK" },
    { id: 3, value: 3, label: 'Instgram', keyvalues: "INSTAGRAM" },
    { id: 4, value: 4, label: 'Tiktok', keyvalues: "TIKTOK" },
    { id: 5, value: 5, label: 'Campaign', keyvalues: "CAMPAIGN" },
    { id: 6, value: 6, label: 'Advertisement', keyvalues: "ADVERTISEMENT" },
    { id: 7, value: 7, label: 'Referal', keyvalues: "REFERRAL" },
    { id: 8, value: 8, label: 'Email,', keyvalues: "EMAIL" },
    { id: 9, value: 9, label: 'Others', keyvalues: "OTHERS" },

  ];

  function findBySOL(keyvalues, Source) {
    const item = Source.find(item => item.keyvalues === keyvalues);
    return item ? item : null;
  }

  const defaultValue = {
    ticket: ticket,
    title: '',
    tag: '',
    typeofLead: '1',
    priority: '1',
    preferredCommunication: '1',
    start: formatDate(currentEvent?.start),
    end: (() => {
      const endDate = new Date(formatDate(currentEvent?.end)); // Convert end date to a Date object
      endDate.setDate(endDate.getDate() + 7); // Add 7 days
      return formatDate(endDate); // Return the formatted date
    })(),    // start: formatDate(selectEvent?.date),
    // duedate: formatDate(selectEvent?.due_date),
    score: '50',
    sourceofLead: '8',
    referred: '',


  };
  console.log("🚀 ~ defaultValue:", defaultValue)
  // console.log("🚀 ~initial  defaultValue.start:", defaultValue.start)
  // console.log("🚀 ~  edit defaultValue.date:", defaultValue.end)

  const defaultValue2 = {
    ticket: selectEvent?.ticket_number,
    title: selectEvent?.title,
    tag: selectEvent?.tag,
    typeofLead: findByvalue(selectEvent?.type, type)?.value,
    priority: selectEvent?.priority,
    preferredCommunication: findByprefComm(selectEvent?.preferred_communication, prefComm)?.value,
    start: formatDate(selectEvent?.date),
    end: formatDate(selectEvent?.due_date),
    score: selectEvent?.score,
    sourceofLead: findBySOL(selectEvent?.source, Source)?.value,
    referred: selectEvent?.referred_by,

  };
  console.log("🚀 ~ defaultValue2:", defaultValue2)
  const methods = useForm({
    resolver: yupResolver(EventSchema),
    // defaultValues: defaultValue,
    defaultValues: isEdit === true ? defaultValue2 : defaultValue,
  });


  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const dateError = values.start && values.end ? values.start > values.end : false;

  const onSubmit = handleSubmit(async (data) => {
    const eventData = {
      ticket_number: data?.ticket,
      tag: data?.tag,
      title: data?.title,
      priority: data?.priority,
      type: data?.typeofLead,
      preferred_communication: data?.preferredCommunication,
      date: formatDate(data?.start),
      due_date: formatDate(data?.end),
      source: data?.sourceofLead,
      score: data?.score,
      referred_by: data?.referred,
      // setSelectedContacts:payloadIds;

    };
    // console.log("🚀 ~ onSubmit ~ eventData.date:", eventData.date)
    console.log('🚀 ~ onSubmit ~ eventData:', eventData);

    try {
      const response = await axios.post(isEdit ? `${endpoints.editLead}/${selectEvent.id}?_method=PUT` : endpoints.getLead,
        eventData, {
        headers: {
          Authorization: token,
          currentCompanyId: 2620,
          currentProjectId: 1298,
          'Content-Type': 'application/json',
        },
      });
      console.log('Create success!', response.data);
      if (response?.data?.metadata.success) {
        if (selectedContacts.length > 0) {
          assignLeadToUsers(response?.data.payload.id);
        }
        enqueueSnackbar(response?.data?.metadata.message);
        reset();
        onClose();
        refetchTicket()
        getLeadsEvents(LeadURL);
      } else {
        console.log('erors happens');
      }

    } catch (error) {
      console.error(error);
    }
  });
  const onDelete = useCallback(async () => {
    setDeletedLeadLoading(true)
    try {
      const response = await axios.delete(`${baseURL}/company/leads/${selectEvent.id}`,
      {
      headers: {
        Authorization: token,
        currentCompanyId: 2620,
        currentProjectId: 1298,
      },
    });
    setDeletedLeadLoading(false)
    if (response?.data?.metadata.success) {
      enqueueSnackbar(response?.data?.metadata.message);
      reset();
      onClose();
      refetchTicket()
      getLeadsEvents(LeadURL);
    } else {
      console.log('erors happens');
    }
    } catch (error) {
    setDeletedLeadLoading(false)

      console.error(error);
    }
  }, [currentEvent?.id, enqueueSnackbar, onClose]);


  const handleAddRecipient = (newValue) => {
    setSelectedContacts(newValue);
    console.log('newValue', newValue);
  };

  const filterOptions = (options, { inputValue }) => {
    // Filter options based on user input (email)
    return options.filter((option) =>
      option.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const assignLeadToUsers = async (id) => {
    let arr = [];
    selectedContacts.map((user, ind) => (arr[ind] = user.id));
    const response = await axios.post(
      `${baseURL}/company/leads/${id}/assign-users`,
      { users: arr },
      {
        headers: {
          Authorization: token,
          currentCompanyId: 2620,
          currentProjectId: 1298,
          'Content-Type': 'application/json',
        },
      }
    );
    if (response?.data?.metadata.success) {
    } else {
      enqueueSnackbar(response?.data?.metadata.message, { variant: 'error' });
    }
  };

  return (
    <Stack p={2}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '6px' }}>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Ticket number <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFTextField disabled type="number" name="ticket" />
          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Title <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFTextField name="title" />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '6px' }}>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Type of Lead <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFSelect name="typeofLead" defaultValue={1}>
              {[
                { id: 1, value: 1, label: 'Proposal', keyvalues: "PROPOSAL" },
                { id: 2, value: 2, label: 'Follow Up', keyvalues: "FOLLOW_UP" },
                { id: 3, value: 3, label: 'Maintenance', keyvalues: "MAINTENANCE" },
              ].map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>

          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Tag <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFTextField name="tag" />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '6px' }}>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Priority <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFSelect name="priority" defaultValue={1}>
              {[
                { id: 1, value: 1, label: 'Low' },
                { id: 2, value: 2, label: 'Medium' },
                { id: 3, value: 3, label: 'High' },
              ].map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Preferred Communication <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFSelect name="preferredCommunication" defaultValue={1}>
              {[
                { id: 1, value: 1, label: 'Email' },
                { id: 2, value: 2, label: 'Text' },
                { id: 3, value: 3, label: 'Phone' },
                { id: 4, value: 4, label: 'Others' },
              ].map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '6px' }}>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Date of Lead<span style={{ color: 'red' }}>*</span>
            </label>
            <Controller
              name="start"
              control={control}
              render={({ field }) => (
                <MobileDatePicker
                  {...field}
                  value={new Date(field.value)}
                  onChange={(newValue) => {
                    if (newValue) {
                      field.onChange(fTimestamp(newValue));
                    }
                  }}
                  // label="Start date"
                  // format="dd/MM/yyyy hh:mm a"
                  format="yyyy/MM/dd"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Due Date<span style={{ color: 'red' }}>*</span>
            </label>
            <Controller
              name="end"
              control={control}
              render={({ field }) => (
                <MobileDatePicker
                  {...field}
                  value={new Date(field.value)}
                  onChange={(newValue) => {
                    if (newValue) {
                      field.onChange(fTimestamp(newValue));
                    }
                  }}
                  // label="Start date"
                  // format="dd/MM/yyyy hh:mm a"
                  format="yyyy/MM/dd"
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" style={{ marginBottom: '6px' }}>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Score <span style={{ color: 'red' }}>*</span>
            </label>
            <RHFTextField
              name="score"
              type="number"
              min={0}
              max={100}
            />

          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Source of Lead<span style={{ color: 'red' }}>*</span>
            </label>
            <RHFSelect name="sourceofLead" defaultValue={1}>
              {[
                { id: 1, value: 1, label: 'Google' },
                { id: 2, value: 2, label: 'Facebook' },
                { id: 3, value: 3, label: 'Instgram' },
                { id: 4, value: 4, label: 'Tiktok' },
                { id: 5, value: 5, label: 'Campaign' },
                { id: 6, value: 6, label: 'Advertisement' },
                { id: 7, value: 7, label: 'Referal' },
                { id: 8, value: 8, label: 'Email' },
                { id: 9, value: 9, label: 'Others' },
              ].map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </RHFSelect>{' '}
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              If Referred - Referred By<span style={{ color: 'red' }}>*</span>
            </label>
            <RHFTextField name="referred" />
          </Grid>
          <Grid item lg={6} xs={12}>
            <label style={{ fontSize: '12px' }}>
              Assign Users<span style={{ color: 'red' }}>*</span>
            </label>
            <Autocomplete
              multiple
              limitTags={3}
              popupIcon={null}
              disableCloseOnSelect
              value={selectedContacts}
              onChange={(event, newValue) => handleAddRecipient(newValue)}
              options={userList}
              defaultValue={selectedContacts}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={filterOptions}
              renderInput={(params) => <TextField {...params} placeholder="Assign Users" />}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  {console.log(option)}
                  <Box
                    sx={{
                      mr: 1,
                      width: 32,
                      height: 32,
                      overflow: 'hidden',
                      borderRadius: '50%',
                      position: 'relative',
                    }}
                  >
                    <Avatar alt={option.name} src={option.avatar} sx={{ width: 32, height: 32 }} />
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        top: 0,
                        left: 0,
                        width: 32,
                        height: 32,
                        opacity: selected ? 1 : 0,
                        position: 'absolute',
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        transition: (theme) =>
                          theme.transitions.create(['opacity'], {
                            easing: theme.transitions.easing.easeInOut,
                            duration: theme.transitions.duration.shorter,
                          }),
                      }}
                    >
                      {selected && <Iconify icon="eva:checkmark-fill" />}
                    </Stack>
                  </Box>
                  {option.name}
                </li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    key={option.id}
                    label={option.name}
                    avatar={<Avatar alt={option.name} src={option.avatar} />}
                    size="small"
                    variant="outlined"
                  />
                ))
              }
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} justifyContent="center" >
          <Grid item lg={6} xs={12}>
          

          </Grid>
          <Grid item lg={6} xs={12}>

          </Grid>
        </Grid> */}

        {/* <RHFTextField name="description" label="Description" multiline rows={1} /> */}

        {/* <RHFSwitch name="allDay" label="All day" /> */}

        {/* <Controller
          name="color"
          control={control}
          render={({ field }) => (
            <ColorPicker
              selected={field.value}
              onSelectColor={(color) => field.onChange(color)}
              colors={colorOptions}
            />
          )}
        />  */}

        <DialogActions>
          {isEdit && (
            <Tooltip title="Delete Lead">
              <IconButton disabled={deleteLeadLoading} onClick={onDelete}>
                <Iconify icon="solar:trash-bin-trash-bold" />
              </IconButton>
            </Tooltip>
          )}

          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            // onClick={CalendarForm}
            // onClick={handleSubmit}
            loading={isSubmitting}
          // disabled={dateError}
          >
            Save Changes
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Stack>
  );
}

CalendarForm.propTypes = {
  colorOptions: PropTypes.arrayOf(PropTypes.string),
  currentEvent: PropTypes.object,
  onClose: PropTypes.func,
};
