import PropTypes from 'prop-types';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 395;
const LEGEND_HEIGHT = 55;

const StyledChart = styled(Chart)(({ theme }) => ({
    height: CHART_HEIGHT,
    '& .apexcharts-canvas, .apexcharts-inner, svg, foreignObject': {
        height: `100% !important`,
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        borderTop: `dashed 1px ${theme.palette.divider}`,
        marginTop: '0px',
    },
    '& .apexcharts-series path': {
        fill: 'chartColors !important', // Ensure fill colors are applied with !important
    },
}));

// ----------------------------------------------------------------------

export default function OverallRisk({ title, subheader, chart, ...other }) {
    const theme = useTheme();

    const { series, options } = chart;

    // Extract values and colors from the series
    const chartSeries = series.map((i) => i.value);
    const chartColors = series.map((i) => i.color); // Extract color from each series object
    console.log("🚀 ~ OverallRisk ~ chartColors:", chartColors);

    const chartOptions = useChart({
        chart: {
            sparkline: {
                enabled: true,
            },
        },
        colors: chartColors.length ? chartColors : ['lightgreen', 'green', 'yellow', 'orange', 'red'], // Use extracted colors or fallback colors
        labels: series.map((i) => i.label),
        stroke: { colors: [theme.palette.background.paper] },
        legend: {
            offsetY: 0,
            floating: true,
            position: 'bottom',
            horizontalAlign: 'center',
        },
        tooltip: {
            fillSeriesColor: true,
            y: {
                formatter: (value) => fNumber(value),
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '70%',
                    labels: {
                        value: {
                            formatter: (value) => fNumber(value),
                        },
                        total: {
                            formatter: (w) => {
                                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                return fNumber(sum);
                            },
                        },
                    },
                },
            },
        },
        ...options,
    });

    return (
        <Card {...other} sx={{ boxShadow: '0px 0px 30px 0px #64748B1A' }}>
            <CardHeader title={title} subheader={subheader} sx={{ mb: 2 }} />
            <StyledChart
                dir="ltr"
                type="donut"
                series={chartSeries}
                options={chartOptions}
                height={290}
            />
        </Card>
    );
}

OverallRisk.propTypes = {
    chart: PropTypes.object,
    subheader: PropTypes.string,
    title: PropTypes.string,
};
