import PropTypes from 'prop-types';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// utils
import { fDate } from 'src/utils/format-time';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { DatePicker } from '@mui/x-date-pickers';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { margin } from '@mui/system';

// ----------------------------------------------------------------------

const VIEW_OPTIONS = [
  {
    value: 'dayGridMonth',
    label: 'Month',
    icon: 'mingcute:calendar-month-line',
  },
  { value: 'timeGridWeek', label: 'Week', icon: 'mingcute:calendar-week-line' },
  { value: 'timeGridDay', label: 'Day', icon: 'mingcute:calendar-day-line' },
  // {
  //   value: 'listWeek',
  //   label: 'Agenda',
  //   icon: 'fluent:calendar-agenda-24-regular',
  // },
];

// ----------------------------------------------------------------------

export default function CalendarToolbar({
  date,
  view,
  loading,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
  onOpenFilters,
}) {
  const smUp = useResponsive('up', 'sm');

  const popover = usePopover();

  const selectedItem = VIEW_OPTIONS.filter((item) => item.value === view)[0];
  const [error, seterror] = useState();

  return (
    <>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2.5, pr: 2, position: 'relative' }}
      >
        <Grid item xs={12} sm={3} md={4} lg={2} wrap="wrap">
          {smUp && (
            <Button
              sx={{
                width: "270px",
                border: "1px solid #eee",
                padding: "26px",
                justifyContent: "start"
              }}
              size="small"
              color="inherit"
              onClick={popover.onOpen}
              startIcon={<Iconify icon={selectedItem?.icon} />}
              endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ marginLeft: '130px' }} />}
            >
              {selectedItem?.label}
            </Button>
          )}
        </Grid>
        {/* Dates */}
        <Grid item xs={12} sm={6} md={3} lg={2} wrap="wrap">
          <div style={{ width: "100%", padding: "none" }}>
            <DatePicker
              label="Start date"
              // value={startDate}
              // onChange={handleStartChange}
              sx={{ width: "350px" }}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} wrap="wrap">
          <div style={{ width: "100%" }}>
            <DatePicker
              label="End date"
              // value={endDate}
              // onChange={handleEndChange}
              sx={{ width: "350px" }}
              slotProps={{ textField: { fullWidth: true } }}
            />
            {error && (
              <Typography variant="body2" color="error">
                End date must be later than start date
              </Typography>
            )}
          </div>
        </Grid>

        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onPrevDate}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Typography variant="h6">{fDate(date)}</Typography>

          <IconButton onClick={onNextDate}>
            <Iconify icon="eva:arrow-ios-forward-fill" />
          </IconButton>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Button size="small" color="error" variant="contained" onClick={onToday}>
            Today
          </Button>

          {/* <IconButton onClick={onOpenFilters}>
            <Iconify icon="ic:round-filter-list" />
          </IconButton> */}
        </Stack>

        {loading && (
          <LinearProgress
            color="inherit"
            sx={{
              height: 2,
              width: 1,
              position: 'absolute',
              bottom: 0,
              left: 0,
            }}
          />
        )}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        {VIEW_OPTIONS.map((viewOption) => (
          <MenuItem
            key={viewOption.value}
            selected={viewOption.value === view}
            onClick={() => {
              popover.onClose();
              onChangeView(viewOption.value);
            }}
          >
            <Iconify icon={viewOption.icon} />
            {viewOption.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}

CalendarToolbar.propTypes = {
  date: PropTypes.object,
  loading: PropTypes.bool,
  onChangeView: PropTypes.func,
  onNextDate: PropTypes.func,
  onOpenFilters: PropTypes.func,
  onPrevDate: PropTypes.func,
  onToday: PropTypes.func,
  view: PropTypes.oneOf(['dayGridMonth', 'timeGridWeek', 'timeGridDay', 'listWeek']),
};
