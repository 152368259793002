import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';
import { useSnackbar } from 'notistack';
import { endpoints } from 'src/utils/axios';
import { postRequest } from 'src/services';
import { handleErrorMessages } from './feching';
import { useParams } from 'react-router';
import Iconify from 'src/components/iconify';

const MonitorCreates = ({ open, onClose, data, reload }) => {
  const theme = useTheme();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  // Updated Yup schema to make review_frequency required
  const NewUserSchema = Yup.object().shape({
    risk_id: Yup.string().required('Risk ID is required'),
    monitoring_activity: Yup.string().required('Monitoring Activities are required'),
    review_frequency: Yup.string().required('Review Frequency is required'),  // <-- Required field
  });

  const defaultValues = useMemo(
    () => ({
      risk_id: data?.risk_id || '',
      monitoring_activity: data?.monitoring_activity || '',
      review_frequency: data?.review_frequency || '',
      last_review_date: data?.last_review_date ? new Date(data.last_review_date) : '',
      next_review_date: data?.next_review_date ? new Date(data.next_review_date) : '',
      monitoring_notes: data?.monitoring_notes || '',
      review_notes: data?.review_notes || '',
      status: data?.status || '', 
    }),
    [data]
  );


  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (open) {
      reset(defaultValues);
    }
  }, [data, open, reset, defaultValues]);

  const onSubmit = handleSubmit(async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data)
    console.log("Selected Status:", data.status); 

    const payload = {
      risk_id: data?.risk_id,
      monitoring_activity: data?.monitoring_activity,
      review_frequency: data?.review_frequency,
      last_review_date: new Date(data?.last_review_date),
      next_review_date: new Date(data?.next_review_date),
      monitoring_notes: data?.monitoring_notes,
      review_notes: data?.review_notes,
      status: data?.status?.toString(), 
    };
    try {
      const responseData = await postRequest(endpoints.risk_management.risk_register.identification.monitor_and_tracks(id), payload, false);

      if (responseData.metadata && responseData.metadata.success) {
        onClose();
        reload();
        reset();
        enqueueSnackbar({
          variant: 'success',
          message: responseData.metadata.message,
        });
      } else {
        handleErrorMessages(responseData.metadata.message);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            onClose(); 
          }
        }}
        fullWidth
        maxWidth="md"
        sx={{
          borderRadius: 'none',
          padding: '60px',
          fontSize: '13px',
          fontFamily: 'arial',
        }}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >

        <FormProvider methods={methods} onSubmit={onSubmit}>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
            <span 
              className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
              &nbsp;&nbsp;  Risk Monitoring and Trackings &nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <IconButton onClick={onClose}>
              <Iconify icon="mdi:close" />
            </IconButton>
          </DialogTitle>

          <>
            <DialogContent
              sx={{
                borderRadius: '1px',
              }}
            >
              <Box
                mt={2}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField name="risk_id" label="Risk ID" />
                <RHFTextField name="monitoring_activity" label="Monitoring Activities" />
                <RHFAutocomplete
                  sx={{ width: '100%' }}
                  name="review_frequency"
                  label="Review Frequency"  // Update the label as needed
                  options={['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']}  // Static options
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option}>
                        {option}
                      </li>
                    );
                  }}
                  error={!!methods.formState.errors.frequencey} 
                  helperText={methods.formState.errors.frequencey?.message} 
                />

                <Controller
                  name="last_review_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Last Review Date"
                      value={field.value || null} 
                      onChange={(newValue) => {
                        field.onChange(newValue || "");  
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />



              </Box>
              <Box
                mt={2}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <Controller
                  name="next_review_date"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <DatePicker
                      label="Next Review Date"
                      value={field.value || null}  // Handle empty values by passing null
                      onChange={(newValue) => {
                        field.onChange(newValue || "");  // Set an empty string if no date is selected
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
                <RHFTextField name="monitoring_notes" label="Monitoring Notes" />
                <RHFTextField name="review_notes" label="Review Notes" />

                <RHFAutocomplete
                  sx={{ width: '100%' }}
                  name="status"
                  label="Current Status"
                  value={watch('status')}  // Ensure the value is taken from form control
                  options={['Monitoring', 'Closed', 'Escalated', 'Under Review', 'Pending Analysis']}
                  getOptionLabel={(option) => option}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(_, newValue) => {
                    methods.setValue('status', newValue);  // Update the form value on change
                  }}
                  renderOption={(props, option) => (
                    <li {...props} key={option}>
                      {option}
                    </li>
                  )}
                />
              </Box>
            </DialogContent>
          </>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" className='dark-button's loading={isSubmitting}>
              {!data ? 'Create Monitoring ' : 'Update Monitoring '}
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog >
    </>
  );
};
export default MonitorCreates;