// src/components/content/RiskRegister.js
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, CardContent, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ConfirmDialog } from 'src/components/custom-dialog';
import Iconify from 'src/components/iconify';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { emptyRows, getComparator, TableEmptyRows, TableHeadCustom, TableNoData, TablePaginationCustom, TableSelectedAction, useTable } from 'src/components/table';
import { useBoolean } from 'src/hooks/use-boolean';
import { applyFilter } from 'src/layouts/_common/searchbar/utils';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { deleteRequest, getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import Header from '../header';
import RiskQuaterlyreviewCreate from './create';

const QuarterlyRiskReview = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({})
  const [actionVisible, setActionVisible] = useState(true); // Step 1: Create state for visibility
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [qauaterlyReviewId, setqauaterlyReviewID] = useState([]);
  const [areIconsVisible, setAreIconsVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  console.log("🚀 ~ QuarterlyRiskReview ~ tableData:", tableData)
  const handleToggleIcons = () => {
    setAreIconsVisible(!areIconsVisible);
  };

  const remove = useBoolean();
  const TABLE_HEAD = [
    { id: 'Sr.No', label: 'Sr.No' },
    { id: 'Review ID', label: 'Review ID' },
    { id: 'Review Period', label: 'Review Period' },
    { id: 'Date of Review', label: 'Date of Review' },
    { id: ' Risks Reviewed', label: ' Risks Reviewed' },
    { id: 'Review Summary', label: 'Review Summary' },
    { id: 'Recommendations', label: 'Recommendations' },
    // { id: 'Attended By', label: 'Attended By' },
    { id: 'Action', label: 'Action' },
  ];
  const table = useTable();
  const router = useRouter();
  const confirm = useBoolean();
  const create = useBoolean();

  const [filters, setFilters] = useState({
    name: '',
    role: [],
    status: 'all',
  });
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  }) || []; // Fallback to an empty array if undefined or null

  const dataInPage = dataFiltered?.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  const denseHeight = table.dense ? 52 : 72;
  const canReset = !isEqual({ name: '', role: [], status: 'all' }, filters);
  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length;



  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  useEffect(() => {
    getQuaterlyReview();
  }, []);


  const adjustPageAfterDeletion = useCallback(() => {
    const currentPageRowCount = dataFiltered?.slice(
      table.page * table.rowsPerPage,
      table.page * table.rowsPerPage + table.rowsPerPage
    ).length;

    if (currentPageRowCount === 0 && table.page > 0) {
      table.onChangePage(table.page - 1); // Go to the previous page
    } else if (dataFiltered?.length === 0) {
      table.onChangePage(0); // If no data remains, move to the first page
    }
  }, [dataFiltered?.length, table]);


  const handleCloseForm = () => {
    create.onFalse();
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form
  };

  const getQuaterlyReview = async () => {
    setLoading(true);
    const payload = await getRequest(endpoints.risk_management.quaterly_review.crud);
    console.log("🚀 ~ getQuaterlyReview========> ~ payload:", payload);
    setLoading(false);
    if (payload) {
      setTableData(payload?.data);
      setqauaterlyReviewID(payload?.next_quarterly_risk_id);
    } else {
      console.error("Expected an array, but received:", payload);
      setTableData([]); // Set as empty array if response is not an array
    }
  };


  const deleteCallBack = () => {
    confirm.onFalse();
    getQuaterlyReview()
  }
  const handleDeleteRows = async () => {
    await deleteRequest(`${endpoints.risk_management.quaterly_review.crud}/${selectedItem.id}`, deleteCallBack);
    adjustPageAfterDeletion(); // Adjust the page after deletion
    setSelectedItem({});
    table.onSelectAllRows(false, []); // Clear selected rows when closing the form

  }

  const handleBulkDelete = async () => {
    const Ids = table.selected;
    console.log("🚀 ~ handleBulkDelete ~ Ids:", Ids);
    if (Ids.length > 0) {
      try {
        const response = await postRequest(endpoints.risk_management.incident_reporting.bulk_delete, { ids: Ids });
        console.log("🚀 ~ handleBulkDelete ~ response:", response);
        remove.onFalse();
        setActionVisible(false);
        adjustPageAfterDeletion();
        setSelectedItem({});
        table.onSelectAllRows(false, []);
        getQuaterlyReview();
      } catch (error) {
        console.error('Error during bulk delete:', error);
      }
    } else {
      console.warn('No rows selected');
    }
  };

  console.log("🚀 ~ QuarterlyRiskReview ~ dataInPage:", dataInPage)
  return <>
    <Card
      sx={{ height: '100%', marginTop: "10px", overflow: "hidden" }}
    >      <CardContent>
        {loading ? (
          <Box height={700}>
            <LoadingScreen />
          </Box>
        ) : (
          <>
            <Header name="Quarterly Risk Review" />
            <Box
              sx={{
                display: "flex",
                paddingBottom: "20px",
                justifyContent: "flex-end",
              }}
            >

              <Button
                onClick={() => {
                  setSelectedItem({});
                  create.onTrue();
                }}
                className="dark-button"
                variant="contained"
                startIcon={<Iconify icon="mingcute:add-line" />}
              >
                Quarterly Risk Review
              </Button>
            </Box>

            <Card sx={{
              boxShadow: '0px 0px 30px 0px #64748B1A',
            }}>
              <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
                {actionVisible && (
                  <div style={{ marginBottom: table.selected.length > 0 ? '3rem' : '0' }}>
                    <TableSelectedAction
                      dense={table.dense}
                      numSelected={table.selected.length}
                      rowCount={tableData.length}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          setActionVisible(checked),
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                      action={
                        <Tooltip title="Delete">
                          <IconButton color="primary" onClick={remove.onTrue}>
                            <Iconify icon="solar:trash-bin-trash-bold" />
                          </IconButton>
                        </Tooltip>
                      }
                    />
                  </div>
                )}

                <Scrollbar >
                  <Table size={table.dense ? 'small' : 'large'}
                    className="tableehead"
                  >
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                      onSelectAllRows={(checked) =>
                        table.onSelectAllRows(
                          checked,
                          tableData.map((row) => row.id)
                        )
                      }
                    />
                    <TableBody>
                      {dataInPage.map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={table.selected.includes(row.id)}
                              onChange={() => table.onSelectRow(row.id)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {index + 1 + table.page * table.rowsPerPage}
                          </TableCell>
                          <TableCell align="left">{row.review_id}-QRRF</TableCell>
                          <TableCell align="left">{row.review_period}</TableCell>
                          <TableCell align="left">{row.review_date}</TableCell>
                          <TableCell align="left">
                            <Tooltip
                              title={
                                row?.identification_and_assessment?.length ? (
                                  <div style={{ whiteSpace: "pre-line", padding: "8px", backgroundColor: "#f9f9f9", borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                                    {row.identification_and_assessment.map((item) => (
                                      <Typography
                                        key={item.id}
                                        variant="body2"
                                        color="textPrimary"
                                        sx={{
                                          display: "block",
                                          fontWeight: 500,
                                          padding: "6px 8px",
                                          borderRadius: "4px",
                                          margin: "4px 0",
                                          border: "1px solid #e0e0e0",
                                        }}
                                      >
                                        {item.risk_id}
                                      </Typography>
                                    ))}
                                  </div>
                                ) : "No Risk"
                              }
                              aria-label="view"
                              sx={{ justifyContent: "center" }}
                            >
                              <span style={{ cursor: "pointer", color: "#1976d2", fontWeight: "bold", textDecoration: "underline" }}>
                                View
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="left">{row.review_summary}</TableCell>
                          <TableCell align="left">{row.recommendations}</TableCell>
                          {/* <TableCell align="left">{row.attended_by}</TableCell> */}
                          <TableCell align="left" sx={{ display: "flex" }}>
                            <IconButton onClick={handleToggleIcons}>
                              <Iconify icon="eva:more-vertical-fill" />
                            </IconButton>
                            {areIconsVisible && (
                              <>
                                <Tooltip title="Delete">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    confirm.onTrue();
                                  }}>
                                    <Iconify icon="solar:trash-bin-trash-bold" />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit">
                                  <IconButton onClick={() => {
                                    setSelectedItem(row);
                                    create.onTrue();
                                  }}>
                                    <Iconify icon="solar:pen-bold" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="View" onClick={() => navigate(`/risk-management/quarterly-risk-review/View/${row.id}`)}>
                                  <IconButton >
                                    <Iconify icon="solar:eye-bold" />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                      />
                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>
              <TablePaginationCustom
                count={dataFiltered?.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </>
        )}
      </CardContent>
    </Card >
    <RiskQuaterlyreviewCreate
      open={create.value}
      onClose={create.onFalse}
      currentItem={selectedItem}
      qauaterlyReviewId={qauaterlyReviewId}
      reload={() => getQuaterlyReview()}
      closeform={() => handleCloseForm()}
    />


    <ConfirmDialog
      open={confirm.value}
      onClose={confirm.onFalse}
      title="Delete"
      content="Are you want to delete selected item?"
      action={
        <Button
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleDeleteRows();
            confirm.onFalse();
          }}
        >
          Delete
        </Button>
      }
    />

    <ConfirmDialog
      open={remove.value}
      onClose={remove.onFalse}
      title="Delete"
      content={
        <>
          Are you sure want to delete these items?
        </>
      }
      action={
        <LoadingButton
          loading={deleteLoading}
          variant="contained"
          className='dark-button'
          onClick={() => {
            handleBulkDelete();
          }}
        >
          Delete
        </LoadingButton>
      }
    />

  </>;
};

export default QuarterlyRiskReview;
