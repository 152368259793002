import { Helmet } from 'react-helmet-async';
import Dashboard from './risk-dashboard';
import { useSearchParams } from 'src/routes/hooks';
import { useEffect } from 'react';
import DashboardHeader from 'src/components/risk-management/dashboard-Header';

// ----------------------------------------------------------------------

export default function RiskDashboard() {
  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
  const project = searchParams.get('project_id') || localStorage.getItem('projectId') || '';
  const company = searchParams.get('company_id') || localStorage.getItem('companyId') || '';
  useEffect(() => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('projectId', project);
    localStorage.setItem('companyId', company);
  }, [token]);
  return (
    <>
      <Helmet>
        <title>Risk Management</title>
      </Helmet>
      <DashboardHeader Title="Risk Dashboard"  />
      <Dashboard />

    </>
  );
}
