import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import EscalationTable from './escalation-table';
const RiskEscalationView = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [EscalationViewDetails, setEscalationViewDetails] = useState();  // New state for incident details

    useEffect(() => {
        getEscalationViewDetails();
    }, [])

    const getEscalationViewDetails = async () => {
        const data = await getRequest(endpoints.risk_management.risk_escalation.esclation_Show(id));
        console.log("🚀 ~ getEscalationViewDetails ~ data:", data)
        setEscalationViewDetails(data);

    }
    return (
        <EscalationTable data={EscalationViewDetails} />
    );
};
export default RiskEscalationView;