import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// Import the icon image
import icon from "../../assets/icons/attachments.jpeg";
// ----------------------------------------------------------------------
export default function FileAttachment({ file }) {
    return (
        <Stack
            flexShrink={0}
            component="span"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 'fit-content',
                height: 'inherit',
            }}
        >
            {file ? (
                <Box
                    component="img"
                    src={icon}
                    sx={{
                        marginLeft: '15px',
                        width: "45px",
                        height: "45px",
                        flexShrink: 0,
                    }}
                />
            ) : (
                <Box sx={{ marginLeft: '15px', color: 'text.secondary' }}>
                    No file
                </Box>
            )}
        </Stack>
    );
}
FileAttachment.propTypes = {
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Retained to check for attachment
    sx: PropTypes.object,
};