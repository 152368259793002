import { StyleSheet } from "@react-pdf/renderer";
const styles = StyleSheet.create({
    page: {
        fontWeight: 800,
        fontSize: 6,
        display: 'grid',
    },
    main: {
        marginTop: 15,
        marginBottom: 15
    },
    table: {

        flexDirection: 'column',
        justifyContent: "start",
        paddingHorizontal: 36,
    },

    tableRow: {
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: "start",
        gap: 10,

    },

    tableRow2: {
        flexDirection: 'row',
        marginTop: 5,
        justifyContent: "start",

    },
    tableCell: {
        flex: 1,
        marginTop: 5,
        wordBreak: 'keep-all',
        hyphens: 'none',

    },
    tableText: {
        fontSize: 10,
        textAlign: 'start',
        wordBreak: 'keep-all',  // Prevents word breaking
        hyphens: 'none',  // Disables hyphens
    },
    darkText: {
        fontSize: 8,
        textAlign: 'start',
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    identificationText: {
        fontSize: 8,
        fontWeight: 700,
        color: "#2E2D2D",
        textAlign: 'start',
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    lighttext: {
        fontSize: 10,
        fontWeight: 700,
        color: "#2E2D2D",
        textAlign: 'start',
        wordBreak: 'keep-all',
        hyphens: 'none',
    },

    textRegular: {
        fontWeight: 'normal',
        fontSize: 10,
    },
    textValue: {
        fontSize: 10,
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    watermark: {
        position: 'absolute',
        width: '80%',
        height: '65%',
        opacity: 0.7,
        top: '15%',
        left: '10%',
        zIndex: -1,
    },
    headerImage: {
        width: "100%",
        height: "auto",
    },
    footerImage: {
        width: "100%",
        height: "auto",
        position: "absolute",
        bottom: 0,
        left: 0,
    },
    logoContainer: {
        position: "absolute",
        top: 10,
        left: 20,
        flexDirection: "row",
        alignItems: "center",
    },
    logo: {
        width: 50,
        height: 50,
        flexShrink: 1,
    },
    companyDetails: {
        marginLeft: 10,
        color: "#ffffff",
        flexDirection: "column",
    },
    companyName: {
        fontSize: 12,
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: 1.2,
    },
    companyAddress: {
        fontSize: 8,
        marginTop: 2,
    },

    textBold: {
        fontSize: 10,
        color: "#2E2D2D",
        fontWeight: 800,
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    container: {
        gridColumn: '1 / -1',
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    container2: {
        width: "100%",
        paddingHorizontal: 36,
    },
    companyTitle: {
        fontSize: 16,
        fontWeight: 700,
        textAlign: "center",
        lineHeight: 0.2,
        textDecoration: "underline",
        marginBottom: "10px",
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    bar: {
        backgroundColor: '#8DD06C',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: 12,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        marginTop: 18,
        marginBottom: 10,
        width: '100%',
        fontWeight: 700,
    },
    containerd: {
        paddingHorizontal: 36,
        paddingTop: 0,
    },
    riskInfoContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between', // Ensure even distribution across the row
        marginVertical: 0, // Adds vertical spacing between rows
    },
    riskInfoContainer2: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between', // Ensure even distribution across the row
        marginVertical: 0, // Adds vertical spacing between rows
    },
    riskInfoColumn: {
        width: '32%', // Two columns per row inside the half width
        marginBottom: 10,
    },
    riskInfoText: {
        fontSize: 8,
        fontWeight: 700,
        color: "#2E2D2D",
        display: 'flex',
        flexDirection: 'row',
    },
    riskInfoTitle: {
        fontSize: 8,
        fontWeight: 'bold', // This makes the title bold
        color: "#2E2D2D",
    },
    risk_ass_title: {
        fontSize: 8,
        fontWeight: 'bold', // This makes the title bold
        color: "#2E2D2D",
    },
    riskmitigation_title: {
        fontSize: 8,
        fontWeight: 'bold', // This makes the title bold
        color: "#2E2D2D",
    },
    infoText: {
        fontSize: 8,
        color: "#2E2D2D",
    },
    relatedDocumentsSection: {
        width: '55%', // Half width for attachment section
        marginTop: 12,
        justifyContent: 'flex-start',
    },
    relatedDocumentsTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 10,
        color: "#2E2D2D",
    },
    relatedDocumentsTitle2: {
        fontSize: 16,
        fontWeight: '700',
        marginBottom: 10,
        color: "#262626",
    },
    documentRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 3,
        border: "2px solid #E2E1E5",
        backgroundColor: '#fff',
    },
    downloadButton: {
        backgroundColor: '#2A3142',
        color: '#fff',
        padding: '5px 13px',
        borderRadius: 2,
        fontSize: 10,
        textAlign: 'center',
        textDecoration: 'none',
    },
    // table
    tableContainer: {
        marginTop: 10,
        width: '100%',
        paddingHorizontal: 36,
    },
    tableRowHeader: {
        flexDirection: 'row',
        backgroundColor: '#8DD06C', // Green header background
        color: '#2E2D2D',
        textAlign: 'center',
        padding: 5,
    },

    tableColHeader: {
        width: '10%', // Adjust width to fit content
        fontSize: 8,
        fontWeight: 'bold',
        padding: 0,
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    tableCol: {
        width: '30%', // Adjust width to fit content
        fontSize: 7,
        padding: 5,
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderRightColor: '#d3d3d3',
        borderLeftColor: '#d3d3d3',
        textAlign: 'center',
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    // total css
    totalsContainer: {
        marginTop: 20,
        alignSelf: 'flex-end',
        marginRight: 50,
        width: '30%',
    },
    totalRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    totalLabel: {
        fontSize: 10,
        textAlign: 'right',
    },
    totalValue: {
        fontSize: 10,
        textAlign: 'right',
    },
    // signature
    signatureContainer: {
        marginTop: 20,
        alignSelf: 'flex-end',
        marginRight: 50,
        width: '40%',
        textAlign: 'right',
    },
    statement: {
        fontSize: 10,
        marginBottom: 5,
        fontWeight: 'bold',
        wordBreak: 'keep-all',
        hyphens: 'none',
    },
    signatureImage: {
        width: 70,  // Adjust the size of the signature image
        height: 30,
        marginBottom: 0,
    },
    projectManagerInfo: {
        fontSize: 10,
        fontWeight: 'bold',
    },
});
export default styles;