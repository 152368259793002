import { Box, Grid, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';

export default function DashboardHeader({ Title }) {

  return (
    <>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", gap: 1,  overflow: "hidden" }}>
            <Box sx={{ marginTop: "3px" }}>
              <Iconify icon="ion:home" />
            </Box>
            <Typography variant='h5'>{Title}</Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
