export const TABLE_HEAD = [
    { id: 'project_id', label: 'Project ID',  minWidth: 100 },
    { id: 'change_order', label: 'Change Order ID',  minWidth: 200 },
    { id: 'facility', label: 'Facility',  minWidth: 100 },
    { id: 'level', label: 'Level',  minWidth: 80 },
    { id: 'milestone', label: 'Mile Stone',  minWidth: 120 },
    { id: 'category', label: 'Category',  minWidth: 100 },
    { id: 'activity', label: 'Activity',  minWidth: 150 },
    { id: 'start_date', label: 'Start Date',  minWidth: 150 },
    { id: 'end_date', label: 'End Date',  minWidth: 150 },
    { id: 'duration', label: 'Duration',  minWidth: 150 },
    { id: 'trade', label: 'Trade',  minWidth: 150 },
    { id: 'responsible_party', label: 'Responsible Party',  minWidth: 200 },
    { id: 'original_budgeted_amount', label: 'Original Budgeted Amount (A)',  minWidth: 200 },
    { id: 'approved_change_orders', label: 'Approved Change Orders (B)',  minWidth: 180 },
    { id: 'revised_budget_amount', label: 'Revised Budget (A+B)',  minWidth: 160 },
    { id: 'original_subcontractor_cost', label: 'Original Subcontractor Cost',  minWidth: 190 },
    { id: 'approved_change_order_subcontractor', label: 'Approved Change Orders to Subcontractors',  minWidth: 200 },
    { id: 'purchase_order', label: 'Purchase Order',  minWidth: 150 },
    { id: 'material_requisition', label: 'Material Requisition',  minWidth: 200 },
    { id: 'committed_revised_subcontractor_cost', label: 'Committed / Revised  Cost',  minWidth: 220 },
    { id: 'non_committed_cost_to_date', label: 'Non Committed Cost to Date',  minWidth: 180 },
    { id: 'non_committed_estimate_cost_to_date', label: 'Non Committed Estimated Cost to Date',  minWidth: 200 },
    { id: 'estimated_final_cost', label: 'Estimated Final Cost',  minWidth: 180 },
    { id: 'variance_project_budgeted', label: 'Variance (Projected - Budgeted)',  minWidth: 180 },
    { id: 'performed_amount', label: 'Performed Amount',  minWidth: 200 },
    { id: 'performed_percentage', label: 'Performed (%)',  minWidth: 150 },
    { id: 'resources_used', label: 'Resources Used',  minWidth: 150 },
    { id: 'expenses', label: 'Expenses',  minWidth: 150 },
    { id: 'actual_cost', label: 'Actual Cost',  minWidth: 150 },
    { id: 'gross_profit', label: 'Gross Profit',  minWidth: 150 },
    { id: 'gross_profit_percentage', label: 'Gross Profit (%)',  minWidth: 150 },
    { id: 'remaining_budget', label: 'Remaining Budget',  minWidth: 180 },
    { id: 'retainage_percentage', label: 'Retainage (5%)',  minWidth: 150 },
    { id: 'requisition_number', label: 'Requisition Number',  minWidth: 200 },
    { id: 'invoice_number', label: 'Invoice Number',  minWidth: 150 },
    { id: 'payment_status', label: 'Payment Status',  minWidth: 150 },
    { id: 'risk_level', label: 'Risk Level',  minWidth: 150 },
    { id: 'quality_checks', label: 'Quality Checks',  minWidth: 150 },
    { id: 'compliance', label: 'Compliance',  minWidth: 150 },
    { id: 'contingency_used', label: 'Contingency Used',  minWidth: 180 },
    { id: 'notes', label: 'Notes',  minWidth: 150 },
];


export const settingsGrapghs = [
    { id: 'jobcostgraph', label: 'Job Cost Graph',  minWidth: 150 },
    { id: 'sedhuleperformance', label: 'Sedhule Performance',  minWidth: 150 },
    { id: 'costperformance', label: 'Cost Performance',  minWidth: 150 },

]