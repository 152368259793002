import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// locales
import { useLocales } from 'src/locales';
import { useRouter, useSearchParams } from 'src/routes/hooks';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import SvgColor from 'src/components/svg-color';
import Button from '@mui/material/Button';


// ----------------------------------------------------------------------

export default function LanguagePopover() {
  // const locales = useLocales();

  // const popover = usePopover();
  const calendarPopover = usePopover();
  const chatPopover = usePopover();
  const mailPopover = usePopover();

  const handleCalendarClick = (event) => {
    calendarPopover.onOpen(event);
  };

  const handleChatClick = (event) => {
    chatPopover.onOpen(event);
  };

  const handleMailClick = (event) => {
    mailPopover.onOpen(event);
  };

  const handleClosePopovers = () => {
    calendarPopover.onClose();
    chatPopover.onClose();
    mailPopover.onClose();
  };

  const router = useRouter();

  // const handleChangeLang = useCallback(
  //   (newLang) => {
  //     locales.onChangeLang(newLang);
  //     popover.onClose();
  //   },
  //   [locales, popover]
  // );

  return (
    <>
      {/* <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          // width: 40,
          // height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify icon={locales.currentLang.icon} sx={{ borderRadius: 0.65, width: 28 }} />

      </IconButton> */}

      <Button
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={handleCalendarClick}
        size="small"
        color="inherit"
        sx={{ mr: 1 }}
        // startIcon={<Iconify icon={selectedItem.icon} />}
        endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ ml: -0.5 }} />}

      >
        {/* {selectedItem.label} */}
        <SvgColor src={`/assets/icons/navbar/ic_calendar.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />

        Calendar
      </Button>


      <CustomPopover open={calendarPopover.open} onClose={handleClosePopovers} sx={{ width: 170 }}>
        {/* {locales.allLangs.map((option) => ( */}
        <MenuItem
          // key={option.value}
          // selected={option.value === locales.currentLang.value}
          // onClick={() => handleChangeLang(option.value)}
          onClick={() => router.push('/company-calendar')}
        >
          {/* <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} /> */}
          <SvgColor src={`/assets/icons/navbar/ic_calendar.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
          Company Calendar
        </MenuItem>
        <MenuItem
          onClick={() => router.push('/project-calendar')}>
          <SvgColor src={`/assets/icons/navbar/ic_calendar.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
          Project Calendar
        </MenuItem>
        {/* ))} */}
      </CustomPopover>


      {/* second */}

      <>

        <Button
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={handleChatClick}
          size="small"
          color="inherit"
          sx={{ mr: 1 }}
          // startIcon={<Iconify icon={selectedItem.icon} />}
          endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ ml: -0.5 }} />}
        >
          {/* {selectedItem.label} */}
          <SvgColor src={`/assets/icons/navbar/ic_chat.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />

          Chat
        </Button>


        <CustomPopover open={chatPopover.open} onClose={handleClosePopovers} sx={{ width: 170 }}>
          {/* {locales.allLangs.map((option) => ( */}
          <MenuItem
            // key={option.value}
            // selected={option.value === locales.currentLang.value}
            // onClick={() => handleChangeLang(option.value)}
            onClick={() => router.push('/company-chat-view')}
          >
            {/* <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} /> */}
            <SvgColor src={`/assets/icons/navbar/ic_chat.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
            Company Chat
          </MenuItem>
          <MenuItem
            onClick={() => router.push('/chat-view')}
          >
            <SvgColor src={`/assets/icons/navbar/ic_chat.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
            Project Chat
          </MenuItem>
          {/* ))} */}
        </CustomPopover>
      </>


      {/* third */}

      <>

        <Button
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          // onClick={mailPopover.onOpen}
          onClick={handleMailClick}
          size="small"
          color="inherit"
          // startIcon={<Iconify icon={selectedItem.icon} />}
          endIcon={<Iconify icon="eva:arrow-ios-downward-fill" sx={{ ml: -0.5 }} />}
        >
          {/* {selectedItem.label} */}
          <SvgColor src={`/assets/icons/navbar/ic_mail.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />

          Mail
        </Button>


        <CustomPopover open={mailPopover.open} onClose={handleClosePopovers} sx={{ width: 170 }}>
          {/* {locales.allLangs.map((option) => ( */}
          <MenuItem
            // key={option.value}
            // selected={option.value === locales.currentLang.value}
            // onClick={() => handleChangeLang(option.value)}
            onClick={() => router.push('/company-mail')}
          >
            {/* <Iconify icon={option.icon} sx={{ borderRadius: 0.65, width: 28 }} /> */}
            <SvgColor src={`/assets/icons/navbar/ic_mail.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
            Company Mail
          </MenuItem>
          <MenuItem
            onClick={() => router.push('/ezelog-mail')}
          >
            <SvgColor src={`/assets/icons/navbar/ic_mail.svg`} sx={{ width: 18, height: 18, mr: 0.5 }} />
            Project Mail
          </MenuItem>
          {/* ))} */}
        </CustomPopover>
      </>


    </>
  );
}


