import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'src/routes/hooks';
import RiskMitigationRequestTable from './risk-mitigation-request-view-table';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
const RiskMitigationRequestView = () => {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    console.log('myToken', token)

    const { id } = useParams();
    const [RiskMitigationRequestDetails, setRiskMitigationRequestDetails] = useState();  // New state for incident details

    useEffect(() => {
        getRiskMitigationRequest();
    }, [])

    const getRiskMitigationRequest = async () => {
        const data = await getRequest(endpoints.risk_management.mitigation_budget_request.mitigation_budget_request_Show(id));
        console.log("🚀 ~ getRiskMitigationRequest ~ data:", data)
        setRiskMitigationRequestDetails(data);

    }
    return (
        <RiskMitigationRequestTable data={RiskMitigationRequestDetails} />
    );
};
export default RiskMitigationRequestView;