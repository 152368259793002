import { Helmet } from 'react-helmet-async';
import DashboardHeader from 'src/components/accounts-management/dashboard-Header';

import Dashboard from './accounts-dashboard';

// ----------------------------------------------------------------------



export default function AccountsDashboard() {
 

  return (
    <>
      <Helmet>
        <title>Accounts</title>
      </Helmet>
      <DashboardHeader Title="Accounting Dashboard"  />
       <Dashboard/>
    </>
  );
}
