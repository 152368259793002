import { m } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hooks';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { baseURL } from 'src/config-global';
import axios from 'axios';
import { endpoints } from 'src/utils/axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Link, Tooltip } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon } from '@mui/x-date-pickers';
import { GridKeyboardArrowRight } from '@mui/x-data-grid';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: '/',
  },
  {
    label: 'Profile',
    linkTo: paths.dashboard.user.profile,
  },
  {
    label: 'Settings',
    linkTo: paths.dashboard.user.account,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user } = useMockedUser();

  const { logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const searchParams = useSearchParams();

  const [myProfile, setMyProfile] = useState({})
  const [chatDetails, setChatDetails] = useState({})

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleClickItem = (path) => {
    popover.onClose();
    router.push(path);
  };





  const getProfileInfo = () => {
    axios.get(`${baseURL}${endpoints.profile_info}`, {
      headers: {
        Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
        currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
        currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
      }
    }).then(res => {
      localStorage.getItem('userDetails', JSON.stringify(res.data.payload))
      localStorage.setItem('userId', res.data?.payload?.id)
      if (res.status === 200) {
        setMyProfile(res.data.payload)
      }
    }).catch(err => {
      console.log(err);
    })
  }


  const getChatDetails = () => {
    axios.get(`${baseURL}${endpoints.chat_details}`, {
      headers: {
        Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
        currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
        currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
      }
    }).then(res => {
      localStorage.getItem('chatDetails', JSON.stringify(res.data))
      if (res.status === 200) {
        setChatDetails(res.data)
      }
    }).catch(err => {
      console.log(err);
    })
  }


  useEffect(() => {
    getProfileInfo()
    getChatDetails()

  }, [])


  function extractPathFromUrl() {
    const url = window.location.href
    // const urlParts = url.split('/');
    // const lastPart = urlParts[urlParts.length - 1];
    // return lastPart;


    const baseUrl = url.split('://')[1].split('/')[1]; // Extract base URL
    // console.log("baseurl", baseUrl)
    let yug = baseUrl.split('?')
    return yug[0];

  }


  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          // marginRight: 20,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={myProfile?.avatar}
          alt={myProfile?.first_name}
          sx={{
            width: 36,
            height: 36,
            border: (theme) => `solid 2px ${theme.palette.background.default}`,
          }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 250, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {`${myProfile?.first_name} ${myProfile?.last_name}`}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {myProfile?.email}
          </Typography>
        </Box>

        {
          extractPathFromUrl() == 'ezelog-mail' ? (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Box sx={{ pt: 1, pl: 2, pr: 2, pb: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                  Project Name
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  <Tooltip title={chatDetails?.project?.name}> {chatDetails?.project?.name}</Tooltip>
                </Typography>
              </Box>
            </>
          )
            :
            null
        }


        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          <Link href='https://web.ezelogs.com/' target='_blank' style={{ textDecoration: 'none', color: '#8BC443' }}>
            <MenuItem sx={{ textDecoration: 'none' }}>
              Back To Dashboard <ArrowRightIcon ms="2" fontSize="small" />
            </MenuItem>
          </Link>
        </Stack>

        {/* this is original  code from Material UI */}
        {/* <Stack sx={{ p: 1 }}>
          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem> */}
      </CustomPopover >
    </>
  );
}
