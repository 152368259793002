/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import Calendar from '@fullcalendar/react'; // => request placed at the top
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
//
import { useState, useEffect, useCallback } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Container from '@mui/material/Container';
// import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
// utils
import { fTimestamp } from 'src/utils/format-time';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { CALENDAR_COLOR_OPTIONS } from 'src/_mock/_calendar';
// api
import { updateEvent, payload, eventsData, leadpayload, leadsEventsData } from 'src/api/calendar';
// components
// import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
//
import { useCalendar, useEvent } from '../hooks';
import { StyledCalendar } from '../styles';
import CalendarForm from '../CalendarForm';
import CalendarToolbar from '../calendar-toolbar';
import CalendarFilters from '../calendar-filters';
import CalendarFiltersResult from '../calendar-filters-result';
// import Image from 'src/components/image';
import { endpoints } from 'src/utils/axios';
import { token } from 'src/config-global';
import { Box, DialogActions, DialogContent, FormControl, Grid, MenuItem, Select } from '@mui/material';
// import { RHFSelect } from 'src/components/hook-form';
// import { display } from '@mui/system';
import { useSearchParams } from 'src/routes/hooks';
import Avtarpop from './avtar';
import { LoadingScreen } from 'src/components/loading-screen';

import renderEventContent from './renderEventContent';
import rednerplannedvsperformed from './renderPlannedvsperformed';
import renderperform from './rendefault';

// ----------------------------------------------------------------------

const defaultFilters = {
  colors: [],
  startDate: null,
  endDate: null,
};



// ----------------------------------------------------------------------
const baseUrl = `https://web.ezelogs.com/api`
const PlannedCostCodeURL = `${baseUrl}${endpoints.planned_cost_code}`;
const PerformedCostCodeURL = `${baseUrl}${endpoints.performed_cost_code}`;
const PlannedPerformedCostCodeURL = `${baseUrl}/project/calendar/planned-performed-activities`;



export default function ProjectCalendar() {
  const theme = useTheme();
  const searchParams = useSearchParams();
  const settings = useSettingsContext();
  const smUp = useResponsive('up', 'sm');

  const openFilters = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);
  const [events, setEvents] = useState([]);
  const [eventsLoading, setEventLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to hold the selected event data

  const [plannedCostCodeId, setPlannedCostCodeId] = useState(null); // State to hold the planned cost code id

  const [activeType, setActiveType] = useState('Planned'); // Set 'project' as the initial state

  const [activeTypes, setActiveTypes] = useState('CostCodes '); // Set 'project' as the initial state

  //Emplyee states avatar
  const [Employ, setEmploy] = useState([])

  const [trades, settrades] = useState([])

  const [Loding, setLoading] = useState(false)

  const [newData, setnewData] = useState()

  const [budgetedhours, setbudgetedhours] = useState();

  const [startdate, setstartdate] = useState() //event start date

  const [employeelength, setemployeelength] = useState()

  const renderEventContentWithEmployeelength = (eventInfo) => renderEventContent(eventInfo, employeelength);

  useEffect(() => {
    getPlannedCostCodeEvents(PlannedCostCodeURL)

  }, [])


  const formatDueDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString();
    return formattedDate;
  }
  const formatEndDate = (dateString) => {
    const dateObject = new Date(dateString);
    dateObject.setDate(dateObject.getDate() + 1); // Adding one day
    const formattedDate = dateObject.toISOString();
    return formattedDate;
  }

  const headers = {
    Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
    currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
    currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
  }



  const onTypeChange = (val) => {
    setActiveType(val);
    if (val == 'Planned') {
      setEvents([])
      getPlannedCostCodeEvents(PlannedCostCodeURL);
      console.log("Selected Item 1st dropdown:", val);
    }
    if (val == 'Performed') {
      setEvents([])
      getPerformedCostCodeEvents(PerformedCostCodeURL);
      console.log("Selected Item 2nd dropdown:", val);
    }
    if (val == 'PlannedvsPerformed') {
      setEvents([])
      getplannedperformedactivities(PlannedPerformedCostCodeURL)
      console.log("Selected Item 3rd dropdown:", val);

    }
  };


  const onClickDialog = (eventClickInfo) => {

    console.log("🚀 ~ onClickDialog ~ eventClickInfo:", eventClickInfo);

    const planned_cost_code_id = eventClickInfo.event.id;

    // console.log("🚀 ~ event id ~ id:", planned_cost_code_id);

    // getperformedcodesdetails(planned_cost_code_id)   // performed api

    getplannedostcodesdetails(planned_cost_code_id) // planned details api

    getemploytradedata(planned_cost_code_id) // emp trade data

    setPlannedCostCodeId(planned_cost_code_id);

    setSelectedEvent(eventClickInfo);

    setOpenDialog(true);

  };


  useEffect((planned_cost_code_id) => {
    getemploytradedata(planned_cost_code_id)

  }, [])

  // planned dropdwown api
  const getPlannedCostCodeEvents = (PlannedCostCodeURL) => {
    setEventLoading(true)
    fetch(PlannedCostCodeURL, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {

        const events = data?.payload.map((event) => ({
          ...event,
          // textColor: getRandomHexColor(),
          title: event.description,
          start: formatDueDate(event.start_date),
          end: formatEndDate(event.finish_date)

        }));
        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log(err)
        setEventLoading(false)

      })
  }

  // performed api
  const getPerformedCostCodeEvents = (PerformedCostCodeURL) => {
    setEventLoading(true)
    fetch(PerformedCostCodeURL, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((data) => {

        const events = data?.payload.map((event) => ({
          ...event,
          // textColor: getRandomHexColor(),
          title: event.description,
        }));

        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log(err)
        setEventLoading(false)

      })
  }

  // planned-performed-activities dropdoww api
  const getplannedperformedactivities = (PlannedPerformedCostCodeURL) => {
    setEventLoading(true)
    fetch(PlannedPerformedCostCodeURL, {
      method: 'GET',
      headers: headers,
    }).then(res => res.json())
      .then((res) => {

        const performance = res?.payload.plannedPerformed;
        // console.log("🚀 ~ .performance ~ performance:", performance)f
        // setcolorchng(performance)

        let alldates = [];
        performance.forEach(item => {
          item.performed.forEach(performedItem => {
            alldates.push(performedItem.date);
          });
        });
        // console.log("======= dates:", alldates);


        const events = res?.payload?.plannedPerformed?.map((event) => ({
          ...event,
          // textColor: getRandomHexColor(),
          title: event.description,
          performed: event.performed,
          start: formatDueDate(event.start_date),
          end: formatEndDate(event.finish_date),
          // backgroundColor: colorchng ? 'black' : null

        }));
        console.log("🚀=== planned-performed-events:", events)
        setEvents(events)
        setEventLoading(false)

      }).catch(err => {
        console.log(err)
        setEventLoading(false)

      })
  }




  //set wla data 
  // getemploytradedata API >>
  const getemploytradedata = (planned_cost_code_id) => {

    fetch(`${baseUrl}/project/calendar/planned-cost-code-assigned/${planned_cost_code_id}`, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      // get data from api
      .then(res => {
        console.log("🚀 ~ getemploytradedata ~ res:", res.payload)
        //store in array
        let arr = []
        arr = res?.payload.map((event) => ({
          employee_id: event.employee_id,
          project_trade_work_title_id: event.project_trade_work_title_id,
          hours: event.hours
        }));

        setnewData(arr)

        const employeelength = arr.map(item => item.employee_id);
        console.log("🚀 ~ employee length:", employeelength.length);
        setemployeelength(employeelength)
        console.log("🚀 ~ getemploytradedata ~ arr:", arr)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  console.log("🚀 ~ ProjectCalendar ~ newData:", newData)



  // planned cost codes details 
  const getplannedostcodesdetails = (planned_cost_code_id) => {
    setLoading(true)
    setEventLoading(true);
    fetch(`${baseUrl}/project/calendar/planned-cost-codes/${planned_cost_code_id}`, {
      method: 'GET',
      headers: headers,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then(res => {
        const payload = res?.payload;
        // console.log("🚀 ~ getplannedostcodesdetails ~ payload:", payload.budgeted_hours)

        //budgeted_hours
        const budgeted_hours = res?.payload.budgeted_hours
        setbudgetedhours(budgeted_hours)

        //start_date
        const start_date = res?.payload.start_date;
        console.log("🚀 ~ getplannedostcodesdetails ~ start_date:", start_date)
        setstartdate(start_date)

        const projectID = res?.payload.project_id;

        getEmployees(projectID)

        getTrade(projectID)

        setEventLoading(false);

        setSelectedEvent(payload)

        setLoading(false)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setEventLoading(false);
      });
  };


  // performed cost codes details 
  // const getperformedcodesdetails = () => {
  //   setLoading(true)
  //   setEventLoading(true);
  //   fetch(`${baseUrl}/project/calendar/performed-cost-codes`, {
  //     method: 'GET',
  //     headers: headers,
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
  //       }
  //       return response.json();
  //     })
  //     .then(res => {
  //       const payload = res?.payload;
  //       const projectID = res?.payload.project_id;
  //       getEmployees(projectID)
  //       getTrade(projectID)
  //       setEventLoading(false);
  //       setSelectedEvent(payload)
  //       setLoading(false)
  //     })
  //     .catch(error => {
  //       console.error('Error fetching data:', error);
  //       setEventLoading(false);
  //     });
  // };


  // Employee API
  const getEmployees = (projectID) => {
    fetch(`${baseUrl}/projects/${projectID}/employees`, {
      method: 'GET',
      headers: headers,
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        const myEmploy = data.payload.map(item => {
          return {
            id: item.id,
            label: `${item.first_name} ${item.last_name}` // Combining first and last name
          };
        });
        setEmploy(myEmploy)
      })

      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };


  // trade API >>
  const getTrade = (projectID) => {
    fetch(`${baseUrl}/trade-worker-titles?project_id=${projectID} `, {
      method: 'GET',
      headers: headers
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => {
        const mytrade = data.payload.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
        settrades(mytrade)

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const onTypeChange2 = (val) => {
    setActiveTypes(val);
    console.log("Selected Item in second dropdown:", val);

  }

  function getRandomHexColor() {
    // Generate random values for red, green, and blue components
    var red = Math.floor(Math.random() * 256);
    var green = Math.floor(Math.random() * 256);
    var blue = Math.floor(Math.random() * 256);

    // Convert decimal values to hexadecimal and ensure they have two digits
    var redHex = red.toString(16).padStart(2, '0');
    var greenHex = green.toString(16).padStart(2, '0');
    var blueHex = blue.toString(16).padStart(2, '0');

    // Construct the hex color string
    var hexColor = '#' + redHex + greenHex + blueHex;

    return hexColor;
  }


  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.getTime() > filters.endDate.getTime()
      : false;

  const {
    calendarRef,
    view,
    date,
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onChangeView,
    onSelectRange,
    onClickEvent,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    selectEventId,
    selectedRange,
    //
    onClickEventInFilters,
  } = useCalendar();




  const currentEvent = useEvent(events, selectEventId, selectedRange, openForm);

  useEffect(() => {
    onInitialView();
  }, [onInitialView]);

  const handleFilters = useCallback((name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const canReset = !!filters.colors.length || (!!filters.startDate && !!filters.endDate);

  const dataFiltered = applyFilter({
    inputData: events,
    filters,
    dateError,
  });


  const renderResults = (
    <CalendarFiltersResult
      filters={filters}
      onFilters={handleFilters}
      //
      canReset={canReset}
      onResetFilters={handleResetFilters}
      //
      results={dataFiltered.length}
      sx={{ mb: { xs: 3, md: 5 } }}
    />
  );

  // Function to handle closing the dialog
  const handleCloseDialog = () => {

    setOpenDialog(false);
  };



  const formatChanger = (currentDate) => {
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };

  const Constcodedetail = ({ label, value }) => (
    <>
      <Grid item xs={6} md={3} lg={3} display="flex" alignItems="center" justifyContent="Start">
        <b><p>{label}</p></b>
      </Grid>
      <Grid item xs={6} md={3} lg={3} display="flex" alignItems="center" justifyContent="center">
        <p>{value}</p>
      </Grid>
    </>
  );





  // const eventDidMount = (info) => {
  //   const eventStartDate = new Date(info.event.start);
  //   const eventEndDate = new Date(info.event.end);

  //   // Iterate through each day of the event and apply custom styles if it matches the target date
  //   for (let date = new Date(eventStartDate); date <= eventEndDate; date.setDate(date.getDate() + 1)) {
  //     const dateString = date.toISOString().split('T')[0];
  //     if (dateString === '2024-05-07') {
  //       const cell = document.querySelector(`[data-date='${dateString}']`);
  //       if (cell) {
  //         cell.classList.add('highlighted-event');
  //       }
  //     }
  //   }
  // }


  return (
    <>
      <Container style={{ paddingTop: 70 }} maxWidth={settings.themeStretch ? false : 'xl'}>
        {/* <Stack
          direction="row"
          alignItems="center"
          // justifyContent="center"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        > */}
        {/* <Image alt="grid" src="/assets/images/ezelogs-logo2.png" /> */}
        {/* <Typography variant="h4" textAlign={'center'} sx={{ flex: 1, marginRight: 22 }}>Project Calendar</Typography> */}

        {/* <Button
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
            onClick={onOpenForm}
          >
            New Event
          </Button> */}
        {/* </Stack> */}

        {canReset && renderResults}

        <Box marginBottom={2} >
          {/*
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={['Planned Cost Codes', 'Performed Cost Codes']}
            sx={{ width: '30%' }}
            value={slectedtype}
            onChange={(e, value) => onTypeChange(value)}
            renderInput={(params) => <TextField {...params} label="Select " />}
          /> */}

          <Select
            style={{ width: "250px" }}
            value={activeType}
            onChange={(event) => onTypeChange(event.target.value)}
          >
            <MenuItem value="Planned" disabled={activeType === 'Planned'}>Planned</MenuItem>
            <MenuItem value="Performed" disabled={activeType === 'Performed'}>Performed</MenuItem>
            <MenuItem value="PlannedvsPerformed" disabled={activeType === 'PlannedvsPerformed'}>Planned vs Performed</MenuItem>
          </Select>

          <Select
            style={{ width: "250px", marginLeft: "15px" }}
            value={activeTypes}
            onChange={(e) => onTypeChange2(e.target.value)}
          >
            <MenuItem value="CostCodes " disabled={activeTypes === 'CostCode'}>Cost Codes </MenuItem>
            <MenuItem value="Trade" disabled={activeTypes === 'Trade'}>Trade / Worker title</MenuItem>
            <MenuItem value="Material" disabled={activeTypes === 'Material'}>Material</MenuItem>
            <MenuItem value="Equipment" disabled={activeTypes === 'Equipment'}>Equipment</MenuItem>
            <MenuItem value="Subcontractor" disabled={activeTypes === 'Subcontractor'}>Subcontractor</MenuItem>
            <MenuItem value="Employee" disabled={activeTypes === 'Employee'}>Employee</MenuItem>
          </Select>

        </Box>

        <Card>
          <StyledCalendar>
            <CalendarToolbar
              date={date}
              view={view}
              loading={eventsLoading}
              onNextDate={onDateNext}
              onPrevDate={onDatePrev}
              onToday={onDateToday}
              onChangeView={onChangeView}
              onOpenFilters={openFilters.onTrue}
            />

            <Calendar
              weekends
              editable
              droppable
              selectable
              rerenderDelay={10}
              allDayMaintainDuration
              eventResizableFromStart
              ref={calendarRef}
              initialDate={date}
              initialView={view}
              dayMaxEventRows={2}
              eventDisplay="block"
              events={dataFiltered}
              eventContent={
                activeType == "Planned" ? renderEventContentWithEmployeelength
                  : activeType == "PlannedvsPerformed" ? rednerplannedvsperformed
                    : renderperform}
              headerToolbar={false}
              select={onSelectRange}
              eventClick={onClickDialog}
              height={smUp ? 720 : 'auto'}
              // eventDidMount={eventDidMount}
              eventDrop={(arg) => {
                onDropEvent(arg, updateEvent);
              }}
              eventResize={(arg) => {
                onResizeEvent(arg, updateEvent);
              }}
              plugins={[
                listPlugin,
                dayGridPlugin,
                timelinePlugin,
                timeGridPlugin,
                interactionPlugin,
              ]}

            />

          </StyledCalendar>
        </Card>
      </Container>
      {selectedEvent && (


        <>
          <Dialog open={openDialog} onClose={handleCloseDialog}
            fullWidth
            maxWidth="lg"
            sx={{
              borderRadius: "2px",
              padding: "60px",
              fontSize: "13px",
              fontFamily: "arial"

            }}
            transitionDuration={{
              enter: theme.transitions.duration.shortest,
              exit: theme.transitions.duration.shortest - 80,
            }}
          >
            <DialogTitle sx={{
              justifyContent: "space-evenly",
              boxSizing: "content-box",
            }}><span style={{ background: "#8BC443", padding: "7px", marginLeft: "-25px", color: "white ", fontWeight: "700" }} >
                &nbsp;&nbsp;   COST CODE DETAILS  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; </span>
              <br /><br />

            </DialogTitle>
            <>
              {
                Loding ?
                  (
                    <LoadingScreen />
                  ) : (

                    <DialogContent sx={{
                      borderRadius: "1px",
                      padding: "80px"
                    }} >
                      <Grid container spacing={5}>
                        <Constcodedetail label="Cost Code Activity" value={selectedEvent.code} />
                        <Constcodedetail label="Cost Code Activity Description" value={selectedEvent.description} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Unit" value={selectedEvent.unit} />
                        <Constcodedetail label="Quantity" value={selectedEvent.quantity} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Unit Price" value={selectedEvent.unit_price} />
                        <Constcodedetail label="Budgeted Amount" value={selectedEvent.budgeted_amount} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Budgeted Hrs" value={selectedEvent.budgeted_hours} />
                        <Constcodedetail label="Hrly Rate" value={selectedEvent.hourly_rate} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Planned Labor Cost" value={selectedEvent.planned_labor_cost} />
                        <Constcodedetail label="Planned Material Cost" value={selectedEvent.planned_material_cost} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Planned Equipment Cost" value={selectedEvent.planned_equipment_cost} />
                        <Constcodedetail label="Planned Subcontractor Cost" value={selectedEvent.planned_subcontractor_cost} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Mark  Ups" value={selectedEvent.mark_ups} />
                        <Constcodedetail label="Duration in days" value={selectedEvent.duration} />
                      </Grid>
                      <hr />
                      <Grid container spacing={5}>
                        <Constcodedetail label="Planned Start Date" value={formatChanger(new Date((selectedEvent.planned_start_date)))} />
                        <Constcodedetail label="Planned Fisnish Date" value={formatChanger(new Date((selectedEvent.planned_finish_date)))} />
                      </Grid>
                      <hr />

                      <br />
                      <br />
                      <h3>Assign Detail</h3>
                      <Avtarpop
                        Employ={Employ}
                        trades={trades}
                        planned_cost_code_id={plannedCostCodeId} // Pass the planned cost code id as a prop
                        newData={newData}
                        getemploytradedata={getemploytradedata}
                        budgetedhours={budgetedhours}
                      // startdate={startdate}
                      />
                    </DialogContent>

                  )
              }
            </>
            < DialogActions >
              <Button variant="contained" onClick={handleCloseDialog} sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                backgroundColor: "#FF5370",
                fontWeight: "400",
                borderRadius: "4px"
              }} >Close</Button>
            </DialogActions>
          </Dialog>
        </>
      )
      }


      {/* 

      <Dialog
        fullWidth
        // maxWidth="xs"
        // open={openForm}
        onClose={onCloseForm}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>
          {openForm && <> {currentEvent?.id ? 'Edit Event' : 'Project Details'}</>}
        </DialogTitle>

        <CalendarForm
          currentEvent={currentEvent}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={onCloseForm}
        />
      </Dialog> */}

      <CalendarFilters
        open={openFilters.value}
        onClose={openFilters.onFalse}
        //
        filters={filters}
        onFilters={handleFilters}
        //
        canReset={canReset}
        onResetFilters={handleResetFilters}
        //
        dateError={dateError}
        //
        events={events}
        colorOptions={CALENDAR_COLOR_OPTIONS}
        onClickEvent={onClickEventInFilters}
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, filters, dateError }) {
  const { colors, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index]);

  inputData = stabilizedThis.map((el) => el[0]);

  if (colors.length) {
    inputData = inputData.filter((event) => colors.includes(event.color));
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (event) =>
          fTimestamp(event.start) >= fTimestamp(startDate) &&
          fTimestamp(event.end) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
