
import { useSearchParams } from 'src/routes/hooks';
import JobCostingList from './job-costing-list';
import { useEffect } from 'react';
export default function Mainpage() {
    const searchParams = useSearchParams();
    const token = searchParams.get('token') || localStorage.getItem('authToken') || '';
    const project = searchParams.get('project_id') || localStorage.getItem('projectId') || '';
    const company = searchParams.get('company_id') || localStorage.getItem('companyId') || '';
    useEffect(() => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('projectId', project);
        localStorage.setItem('companyId', company);
    }, [token]);
    return (
        <>
                <JobCostingList />
        </>
    );
}



