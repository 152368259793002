import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import {
  getDropdownsId,
  handleErrorMessages,
} from '../../risk-register/feching';
import DropDownAddValue from 'src/components/drop-down-add-value/drop-down-add-value';

const RiskEscalationCreate = ({
  open,
  onClose,
  escalationOptions,
  reload,
  currentItem,
  escalationId,
  handleCloseForm,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [riskIds, setRiskIds] = useState([]);
  const [isFetchingRisks, setIsFetchingRisks] = useState(true);

  // Fetch risk IDs on component mount
  useEffect(() => {
    const fetchIdentification = async () => {
      try {
        const data = await getRequest(
          endpoints.risk_management.risk_register.identification.identification
        );
        setRiskIds(data || []);
      } catch (err) {
        console.error('Error fetching risk IDs:', err);
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to fetch risk IDs.',
        });
      } finally {
        setIsFetchingRisks(false);
      }
    };
    fetchIdentification();
  }, [enqueueSnackbar]);

  // Find the matched risk based on currentItem
  const matchedRisk = useMemo(() => {
    if (currentItem && riskIds.length > 0) {
      return (
        riskIds.find(
          (risk) => risk.id === currentItem?.identification_and_assessment?.id
        ) || { id: '', risk_id: 'None' }
      );
    }
    return { id: '', risk_id: 'None' };
  }, [currentItem, riskIds]);

  // Validation schema
  const NewUserSchema = Yup.object().shape({
    escalation_id: Yup.string().required('Escalation ID is required'),
    original_risk_id: Yup.object().shape({
      id: Yup.string().required('Original Risk ID is required'),
    }).required(),
    reason_for_escalation: Yup.string().required(
      'Reason for Escalation is required'
    ),
    action_required: Yup.string().required(
      'Action Required is required'
    ),
    escalation_date: Yup.date()
      .required('Escalation Date is required')
      .nullable(),
    escalation_level: Yup.string().required('Escalation Level is required'),
  });

  // Default form values
  const defaultValues = useMemo(
    () => ({
      escalation_id: currentItem?.escalation_id || escalationId || '',
      original_risk_id: matchedRisk || '',
      reason_for_escalation: currentItem?.reason || '',
      escalation_level: currentItem?.escalation_level?.name || '',
      action_required: currentItem?.action_required || '',
      escalation_date: currentItem?.date ? new Date(currentItem.date) : null,
    }),
    [currentItem, escalationId, matchedRisk]
  );

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur', // You can adjust the validation mode as needed
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
    getValues,
  } = methods;

  // Reset form when currentItem or riskIds change
  useEffect(() => {
    if (!isFetchingRisks) {
      reset(defaultValues);
    }
  }, [currentItem, defaultValues, reset, isFetchingRisks]);

  // Handle form submission
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const formData = new FormData();

    // Clean escalation_id by removing '-REF' suffix if present
    const cleanEscalationId = data.escalation_id.replace('-REF', '');
    formData.append('risk_id', data.original_risk_id.id);
    formData.append('escalation_id', cleanEscalationId);
    formData.append('reason', data.reason_for_escalation);
    formData.append('action_required', data.action_required);
    formData.append(
      'date',
      data.escalation_date ? data.escalation_date.toISOString().split('T')[0] : ''
    );

    // Append escalation_level_id if available
    const escalationLevelId = getDropdownsId(
      escalationOptions,
      data.escalation_level
    );
    if (escalationLevelId) formData.append('escalation_level_id', escalationLevelId);

    try {
      const endpoint = currentItem?.id
        ? `${endpoints.risk_management.risk_escalation.crud}/${currentItem.id}?_method=PUT`
        : endpoints.risk_management.risk_escalation.crud;

      const response = await postRequest(endpoint, formData, true);

      if (response.metadata?.success) {
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
        handleClose();
        reload();
      } else {
        handleErrorMessages(response.message);
      }
    } catch (err) {
      console.error('Submission error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
      setLoading(false);
    }
  });

  // Handle dialog close with form reset
  const handleClose = () => {
    reset(defaultValues);
    handleCloseForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      sx={{ padding: '60px', fontSize: '13px', fontFamily: 'Arial' }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span
            className="cardColor"
            style={{
              padding: '5px',
              marginLeft: '-25px',
              color: 'white',
              fontWeight: '700',
            }}
          >
            Risk Escalation Form
          </span>
          <IconButton onClick={handleClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            mt={2}
            display="grid"
            gap={2}
            gridTemplateColumns={{ xs: '1fr', sm: 'repeat(2, 1fr)' }}
          >
            <RHFTextField
              name="escalation_id"
              label="Escalation ID"
              disabled
              value={`${getValues('escalation_id') || ''}${currentItem ? '' : '-REF'
                }`}
            />
            <RHFAutocomplete
              name="original_risk_id"
              label="Original Risk ID"
              options={[{ id: '', risk_id: 'None' }, ...riskIds]}
              getOptionLabel={(option) => option.risk_id || 'None'}
              isOptionEqualToValue={(option, value) =>
                option.id === value?.id || value === ''
              }
              renderOption={(props, option) => (
                <li {...props} key={option.id || 'none'}>
                  {option.risk_id}
                </li>
              )}
              disabled={isFetchingRisks}
            />
            <RHFTextField
              name="reason_for_escalation"
              label="Reason for Escalation"
            />
            <DropDownAddValue
              name="escalation_level"
              label="Escalation Level"
              options={escalationOptions}
              reload={reload}
              url={endpoints.risk_management.risk_escalation.escalation_levels}
            />
            <RHFTextField name="action_required" label="Action Required" />
            <Controller
              name="escalation_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Escalation Date"
                  {...field}
                  inputFormat="yyyy-MM-dd"
                  disableFuture
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton
            className="dark-button"
            type="submit"
            variant="contained"
            loading={isSubmitting || loading}
          >
            {!currentItem.id ? 'Create Risk Escalation' : 'Update Risk Escalation'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default RiskEscalationCreate;
