import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    page: {
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: 8,
        color: "#333",
        paddingBottom: 50,
    },
    headerImage: {
        width: "100%",
        height: "auto",
    },
    footerImage: {
        width: "100%",
        height: "auto",
        position: "absolute",
        bottom: 0,
        left: 0,
    },
    watermark: {
        position: 'absolute',
        width: '80%',
        height: '85%',
        opacity: 0.7,
        top: '15%',
        left: '10%',
        zIndex: -1,
    },
    footerPageNumber: {
        position: "absolute",
        bottom: 35,
        color: "white",
        right: 95,
        fontSize: 10,
        padding: 5,
    },
    logoContainer: {
        position: "absolute",
        top: 10,
        left: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
    },
    logo: {
        width: "auto",
        maxWidth: 50,
        maxHeight: 50,
        flexShrink: 1,
    },
    companyDetails: {
        marginLeft: 10,
        color: "#ffffff",
        flexDirection: "column",
        fontFamily: "Montserrat",
    },
    companyName: {
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "left",
        lineHeight: 1.2,
    },
    companyAddress: {
        fontSize: 9,
        marginTop: 2,
    },
    tableRow: {
        width: "100%", // Adjust width as needed
        flexDirection: "row",
        justifyContent: "flex-start",
    },
    tableCell: {
        padding: 1,
        borderStyle: "solid",
        borderColor: "#8DD06C",
        borderWidth: 1,
        width: "100%",
        textAlign: "center",
        overflow: "hidden",
        fontSize: 8,
        maxHeight: 50,
        // Prevent word break or hyphenation
        wordBreak: 'normal',
        whiteSpace: 'nowrap',
    },
    tableHeader: {
        backgroundColor: '#8DD06C',
        color: '#FFFFFF',
        justifyContent: "flex-start",
        fontWeight: "bold",
        fontSize: 8,
        marginTop: "10px",
    },
    tableheadCell: {
        padding: 0,
        width: "100%", // Adjust width as needed
        textAlign: 'center',
        alignItems: "center",
        fontWeight: "800",
        borderStyle: "solid",
        borderColor: "#8bc443",
        borderWidth: 1,
        wordBreak: 'break-word', // Allow word break within the cell
    },
    headerTextLine: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 7, // Reduce font size if necessary
    },
});

export default styles;
