import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetUpper, calculateVariance } from "./Calculations/formulas";
export const getCsvData = (tableData = [], jobCostingLabels, isColumnVisible ,subtotals) => {



    
    return tableData?.map((row) => {
        function isValidNumber(value) {
            return !isNaN(value) && value !== Infinity && value !== -Infinity;
        }
        const categorydata = `${row.cost_code_category?.code} - ${row.cost_code_category?.description}`
        const filteredRow = {};
        const approvedChangeOrders = isValidNumber(row?.change_orders?.[0]?.budgeted_amount || 0)
            ? row?.change_orders?.[0]?.budgeted_amount || 0
            : '';
        const revisedBudget = isValidNumber(calculateRevisedBudgetUpper(row?.original_budgeted_amount, approvedChangeOrders))
            ? calculateRevisedBudgetUpper(row?.original_budgeted_amount, approvedChangeOrders)
            : '';
        const committedSubcontractorCost = isValidNumber(calculateCommittedSubcontractorCost(row, row?.change_orders?.[0]?.planned_subcontractor_cost))
            ? calculateCommittedSubcontractorCost(row, row?.change_orders?.[0]?.planned_subcontractor_cost)
            : '';
        const nonCommittedCostToDate = calculateNonCommittedCostToDate(revisedBudget, committedSubcontractorCost);
        const estimatedFinalCost = isValidNumber(calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            row.non_committed_estimate_cost_to_date || 0
        )) ? calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            row.non_committed_estimate_cost_to_date || 0
        ) : '';
        const variance = isValidNumber(calculateVariance(estimatedFinalCost, revisedBudget || 0))
            ? calculateVariance(estimatedFinalCost, revisedBudget || 0)
            : '';
        const performedPercentage = isValidNumber(calculatePerformedPercentage(row.performed_amount, revisedBudget || 0))
            ? calculatePerformedPercentage(row.performed_amount, revisedBudget || 0)
            : '';
        const actualCost = isValidNumber(calculateActualCost(row.resources_used, row.expenses))
            ? calculateActualCost(row.resources_used, row.expenses)
            : '';
        const grossProfit = isValidNumber(calculateGrossProfit(row.performed_amount, actualCost))
            ? calculateGrossProfit(row.performed_amount, actualCost)
            : '';
        const grossProfitPercentage = isValidNumber(calculateGrossProfitPercentage(grossProfit, revisedBudget || 0))
            ? calculateGrossProfitPercentage(grossProfit, revisedBudget || 0)
            : '';
        const remainingBudget = isValidNumber(calculateRemainingBudget(revisedBudget || 0, actualCost))
            ? calculateRemainingBudget(revisedBudget || 0, actualCost)
            : '';
        const retainage = isValidNumber(calculateRetainage(row.performed_amount))
            ? calculateRetainage(row.performed_amount)
            : '';
        const fields = {
            change_order: row?.change_orders?.map((item) => item.id).join(' '),
            facility: row?.facility,
            level: row?.level,
            milestone: row?.milestone,
            category: categorydata,
            activity: `${row?.cost_codes?.code} - ${row?.cost_codes?.description}`,
            start_date: row?.start_date,
            end_date: row?.end_date,
            duration: row?.cost_codes?.duration,
            trade: row?.trade,
            responsible_party: row?.responsible_party,
            original_budgeted_amount: row?.original_budgeted_amount,
            approved_change_orders: approvedChangeOrders,
            revised_budget_amount: revisedBudget,
            original_subcontractor_cost: row?.original_subcontractor_cost,
            approved_change_order_subcontractor: row?.change_orders?.[0]?.planned_subcontractor_cost,
            purchase_order: row?.purchase_order,
            material_requisition: row?.material_requisition,
            committed_revised_subcontractor_cost: committedSubcontractorCost,
            non_committed_cost_to_date: nonCommittedCostToDate,
            non_committed_estimate_cost_to_date: row?.non_committed_estimate_cost_to_date,
            estimated_final_cost: estimatedFinalCost,
            variance_project_budgeted: variance,
            performed_amount: row?.performed_amount,
            performed_percentage: performedPercentage,
            resources_used: row?.resources_used,
            expenses: row?.expenses,
            actual_cost: actualCost,
            gross_profit: grossProfit,
            gross_profit_percentage: grossProfitPercentage,
            remaining_budget: remainingBudget,
            retainage_percentage: retainage,
            requisition_number: row?.requisition_number,
            invoice_number: row?.invoice_number,
            payment_status: row?.payment_status,
            risk_level: row?.risk_level,
            quality_checks: row?.quality_checks,
            compliance: row?.compliance,
            contingency_used: row?.contingency_used,
            notes: row?.notes,
        };
        Object.keys(fields).forEach((key) => {
            if (isColumnVisible(key) && key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
    

    
};
