/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState, useCallback, useRef } from 'react';
import { baseURL, token } from 'src/config-global';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fTimestamp } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function useCalendar() {
  const calendarRef = useRef(null);

  const calendarEl = calendarRef.current;

  const smUp = useResponsive('up', 'sm');

  const [date, setDate] = useState(new Date());

  const [openForm, setOpenForm] = useState(false);

  const [payloadIds, setPayloadIds] = useState([]);

  const [selectEventId, setSelectEventId] = useState('');

  const [selectEvent, setSelectEvent] = useState({});


  const [selectedRange, setSelectedRange] = useState(null);
  // const [selectedContacts, setSelectedContacts] = useState([]);

  const [view, setView] = useState(smUp ? 'dayGridMonth' : 'listWeek');

  const onOpenForm = useCallback(() => {
    setOpenForm(true);
  }, []);

  const opner = (url) => {
    axios.get(url, {
      headers: {
        Authorization: token,
        currentCompanyId: 2620,
        currentProjectId: 1298,
      }
    }).then((response) => {
      const data = response.data
      console.log(data, "here we go")
      if (data.metadata) {
        if (data.metadata.success) {
          setSelectEvent(data.payload)
          setSelectEventId(data.payload.id);
          onOpenForm();
        }
      }
      console.log(data, "ya hy users ")
    }).catch(err => {
      console.log(err)

    })
  }
  const assignLeadToUsers = async (id) => {
    try {
      const response = await axios.get(
        `${baseURL}/company/leads/${id}/assign-users`,
        {
          headers: {
            Authorization: token,
            currentCompanyId: 2620,
            currentProjectId: 1298,
            'Content-Type': 'application/json',
          },
        }
      );
      if (response?.data?.metadata.success) {
        const payloadArray = []; // Initialize an empty array to store payload data
        response.data.payload.forEach(item => {
          // payloadArray.push(item);
          // payloadArray.push(item.id);
          payloadArray.push({ name: `${item.first_name}  ${item.last_name}`, id: item.id })
          const ids = payloadArray;
          setPayloadIds(ids);
        });

        console.log("assignLeadToUsers  Payload Array:", payloadArray); // Output the array with payload data
      } else {
        // Handle error case if needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error case if needed
    }
  };


  const onCloseForm = useCallback(() => {
    setOpenForm(false);
    setSelectedRange(null);
    setSelectEventId('');
    setSelectEvent({})
    setPayloadIds([])
  }, []);

  const onInitialView = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      const newView = smUp ? 'dayGridMonth' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [calendarEl, smUp]);

  const onChangeView = useCallback(
    (newView) => {
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();

        calendarApi.changeView(newView);
        setView(newView);
      }
    },
    [calendarEl]
  );

  const onDateToday = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  }, [calendarEl]);

  const onDatePrev = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, [calendarEl]);

  const onDateNext = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, [calendarEl]);

  const onSelectRange = useCallback(
    (arg) => {
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();

        calendarApi.unselect();
      }
      onOpenForm();
      setSelectedRange({
        start: fTimestamp(arg.start),
        end: fTimestamp(arg.end),
      });
    },
    [calendarEl, onOpenForm]
  );

  const onClickEvent = useCallback(
    (arg) => {
      const { event } = arg;
      const url = `${baseURL}/company/leads/${event.id}`
      opner(url);
      assignLeadToUsers(event.id)
      // setSelectedContacts(selectedContacts)
    },
    [onOpenForm]
  );

  const onResizeEvent = useCallback((arg, updateEvent) => {
    const { event } = arg;

    updateEvent({
      id: event.id,
      allDay: event.allDay,
      start: fTimestamp(event.start),
      end: fTimestamp(event.end),
    });
  }, []);

  const onDropEvent = useCallback((arg, updateEvent) => {
    const { event } = arg;

    updateEvent({
      id: event.id,
      allDay: event.allDay,
      start: fTimestamp(event.start),
      end: fTimestamp(event.end),
    });
  }, []);

  const onClickEventInFilters = useCallback(
    (eventId) => {
      if (eventId) {
        setSelectEventId(eventId);

      }
    },
    [onOpenForm]
  );

  return {
    calendarRef,
    //
    view,
    date,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onClickEvent,
    onChangeView,
    onSelectRange,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    selectEventId,
    selectEvent,
    selectedRange,
    //
    onClickEventInFilters,
    payloadIds
  };
}
