export const tablerowData = [
    {
        projectId: 'P12345',
        projectName: 'New Building',
        contractName: 'Main Contract',
        facility: 'Facility A',
        level: 'Level 1',
        milestone: 'Phase 1',
        category: 'Electrical',
        activity: 'Wiring',
        startDate: '2024-01-01',
        endDate: '2024-02-01',
        duration: '1 month',
        trade: 'Electrician',
        responsibleParty: 'ABC Contractors',
        originalBudget: 50000,
        approvedChangeOrders: 10000,
        revisedBudget: 60000,
        originalSubcontractorCost: 30000,
        approvedChangeOrdersSubcontractors: 5000,
        purchaseOrder: 4000,
        materialRequisition: 2000,
        committedSubcontractorCost: 41000,
        nonCommittedCost: 19000,
        nonCommittedEstimatedCost: 5000,
        estimatedFinalCost: 65000,
        variance: 5000,
        performedAmount: 30000,
        performedPercentage: '50%',
        resourcesUsed: 'Labor, Materials',
        expenses: 15000,
        actualCost: 45000,
        grossProfit: 15000,
        grossProfitPercentage: '25%',
        remainingBudget: 15000,
        retainage: 1500,
        billingSchedule: '30 days',
        paymentTerms: 'Net 30',
        requisitionNumber: 'REQ123',
        invoiceNumber: 'INV456',
        paymentStatus: 'Pending',
        riskLevel: 'Medium',
        qualityChecks: 'Passed',
        compliance: 'Yes',
        contingencyUsed: 3000,
        notes: 'Project on track'
    },
    {
        projectId: '9125488',
        projectName: 'New Building',
        contractName: 'Main Contract',
        facility: 'Facility A',
        level: 'Level 1',
        milestone: 'Phase 1',
        category: 'Electrical',
        activity: 'Wiring',
        startDate: '2024-01-01',
        endDate: '2024-02-01',
        duration: '1 month',
        trade: 'Electrician',
        responsibleParty: 'ABC Contractors',
        originalBudget: 50000,
        approvedChangeOrders: 10000,
        revisedBudget: 60000,
        originalSubcontractorCost: 30000,
        approvedChangeOrdersSubcontractors: 5000,
        purchaseOrder: 4000,
        materialRequisition: 2000,
        committedSubcontractorCost: 41000,
        nonCommittedCost: 19000,
        nonCommittedEstimatedCost: 5000,
        estimatedFinalCost: 65000,
        variance: 5000,
        performedAmount: 30000,
        performedPercentage: '50%',
        resourcesUsed: 'Labor, Materials',
        expenses: 15000,
        actualCost: 45000,
        grossProfit: 15000,
        grossProfitPercentage: '25%',
        remainingBudget: 15000,
        retainage: 1500,
        billingSchedule: '30 days',
        paymentTerms: 'Net 30',
        requisitionNumber: 'REQ123',
        invoiceNumber: 'INV456',
        paymentStatus: 'Pending',
        riskLevel: 'Medium',
        qualityChecks: 'Passed',
        compliance: 'Yes',
        contingencyUsed: 3000,
        notes: 'Project on track'
    },
    // Add more rows as needed
];
