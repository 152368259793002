import { useCallback, useEffect, useState } from 'react';
import { Button, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetUpper, calculateVariance } from './Calculations/formulas';
import Iconify from 'src/components/iconify';
import ChangeOrder from './change-order-details-modal';
import { useBoolean } from 'src/hooks/use-boolean';
import { LoadingButton } from '@mui/lab';
import { handleErrorMessages } from 'src/components/risk-management/risk-register/feching';

// ----------------------------------------------------------------------

export default function JobcostingGenerateTable({
    row,
    selected,
    onSelectRow,
    onDeleteRow,
    onConfirmDrop,
    visibleColumns
}) {
    const { enqueueSnackbar } = useSnackbar();

    const [rowData, setRowData] = useState(row);

    useEffect(() => {
        setRowData(row);
    }, [row]);
    const [committedSubcontractorCost, setCommittedSubcontractorCost] = useState(0);
    const [nonCommittedCostToDate, setNonCommittedCostToDate] = useState(0);
    const [estimatedFinalCost, setEstimatedFinalCost] = useState(0);
    const [variance, setVariance] = useState(0);
    const [performedPercentage, setPerformedPercentage] = useState(0);
    const [actualCost, setActualCost] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [grossProfitPercentage, setGrossProfitPercentage] = useState(0);
    const [remainingBudget, setRemainingBudget] = useState(0);
    const [retainage, setRetainage] = useState(0);
    const [revisedBudget, setRevisedBudget] = useState(0);
    const [approvedChangeOrders, setApprovedChangeOrders] = useState();
    const [plannedSubcontractorCost, setplannedSubcontractorCost] = useState();

    const confirm = useBoolean()

    useEffect(() => {
        const budgetedAmount = parseFloat(rowData?.change_orders?.[0]?.budgeted_amount);
        setApprovedChangeOrders(budgetedAmount);
        const plannedSubcontractorCost = parseFloat(rowData?.change_orders?.[0]?.planned_subcontractor_cost);
        setplannedSubcontractorCost(plannedSubcontractorCost);
    }, [rowData]);

    useEffect(() => {
        //14
        const calculatedRevisedBudget = calculateRevisedBudgetUpper(
            row.original_budgeted_amount,
            approvedChangeOrders
        );
        setRevisedBudget(calculatedRevisedBudget);

        //19
        const calculatedValue = calculateCommittedSubcontractorCost(row, plannedSubcontractorCost);
        setCommittedSubcontractorCost(calculatedValue);
        //20
        const calculatedNonCommittedCost = calculateNonCommittedCostToDate(calculatedRevisedBudget, committedSubcontractorCost);
        setNonCommittedCostToDate(calculatedNonCommittedCost);
        //22
        const calculatedEstimatedFinalCost = calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            parseFloat(row.non_committed_estimate_cost_to_date) || 0
        );
        setEstimatedFinalCost(calculatedEstimatedFinalCost);
        //23
        const calculatedVariance = calculateVariance(estimatedFinalCost, parseFloat(calculatedRevisedBudget) || 0);
        setVariance(calculatedVariance);
        //25
        const calculatedPerformedPercentage = calculatePerformedPercentage(
            row.performed_amount,
            parseFloat(calculatedRevisedBudget) || 0
        );
        setPerformedPercentage(calculatedPerformedPercentage);
        //28
        const calculatedActualCost = calculateActualCost(
            parseFloat(row.resources_used) || 0,
            parseFloat(row.expenses) || 0
        );
        setActualCost(calculatedActualCost);
        //29
        const calculatedGrossProfit = calculateGrossProfit(
            parseFloat(row.performed_amount) || 0,
            actualCost
        );
        setGrossProfit(calculatedGrossProfit);
        //30
        const calculatedGrossProfitPercentage = calculateGrossProfitPercentage(
            grossProfit,
            parseFloat(calculatedRevisedBudget) || 0
        );
        setGrossProfitPercentage(calculatedGrossProfitPercentage);
        //31
        const calculatedRemainingBudget = calculateRemainingBudget(
            parseFloat(calculatedRevisedBudget) || 0,
            actualCost
        );
        setRemainingBudget(calculatedRemainingBudget);
        //32
        const calculatedRetainage = calculateRetainage(row.performed_amount);
        setRetainage(calculatedRetainage);
    }, [
        row.original_subcontractor_cost,
        row.approved_change_orders_subcontractors,
        row.purchase_order,
        row.material_requisition,
        //20
        row.revised_budget,
        committedSubcontractorCost,
        //22
        row.non_committed_estimate_cost_to_date,
        //23
        estimatedFinalCost,
        //25
        row.performed_amount,
        //28
        row.resources_used,
        row.expenses,
        //29
        actualCost,
        //30
        grossProfit,
        //14
        row.original_budgeted_amount,
        row.approved_change_orders, 
    onConfirmDrop,
        approvedChangeOrders,
        plannedSubcontractorCost
    ]);

    const renderMainRow = () => (
      <TableRow
        hover
        selected={selected}
        key={rowData.id}
        style={{
          backgroundColor:
            rowData?.change_orders?.length > 0
              ? 'rgb(205 238 238)'
              : rowData.isMerged
              ? 'red'
              : 'transparent',
        }}
      >
        <TableCell>{rowData.project_id}</TableCell>
        <TableCell> {rowData?.change_orders?.map((item) => item.id).join(' ')}</TableCell>
        {visibleColumns.includes('facility') && <TableCell>{rowData.facility}</TableCell>}
        {visibleColumns.includes('level') && <TableCell>{rowData.level}</TableCell>}
        {visibleColumns.includes('milestone') && <TableCell>{rowData.milestone}</TableCell>}
        {visibleColumns.includes('category') && (
          <TableCell>
            {typeof rowData?.cost_code_category === 'object' && rowData.cost_code_category !== null
              ? `${rowData.cost_code_category?.code ?? ''} - ${
                  rowData.cost_code_category?.description ?? ''
                }`
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('activity') && (
          <TableCell>
            {typeof rowData?.cost_codes === 'object' && rowData.cost_codes !== null
              ? `${rowData.cost_codes?.code ?? ''} - ${rowData.cost_codes?.description ?? ''}`
              : ''}
          </TableCell>
        )}

        {visibleColumns.includes('start_date') && (
          <TableCell>
            {rowData.start_date
              ? new Date(rowData.start_date).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('end_date') && (
          <TableCell>
            {rowData.end_date
              ? new Date(rowData.end_date).toLocaleDateString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
                })
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('duration') && (
          <TableCell>{rowData.cost_codes?.duration ?? ''}</TableCell>
        )}
        {visibleColumns.includes('trade') && <TableCell>{rowData.trade ?? ''}</TableCell>}
        {visibleColumns.includes('responsible_party') && (
          <TableCell>{rowData.responsible_party ?? ''}</TableCell>
        )}
        {visibleColumns.includes('original_budgeted_amount') && (
          <TableCell>
            {rowData.original_budgeted_amount === 0
              ? '$0'
              : rowData.original_budgeted_amount
              ? `$${rowData.original_budgeted_amount}`
              : ''}
          </TableCell>
        )}

        {visibleColumns.includes('approved_change_orders') && (
          <TableCell>
            {approvedChangeOrders && !isNaN(approvedChangeOrders)
              ? `$${approvedChangeOrders.toFixed(2)}`
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('revised_budget_amount') && (
          <TableCell>${revisedBudget.toFixed(2)}</TableCell>
        )}
        {visibleColumns.includes('original_subcontractor_cost') && (
          <TableCell>${rowData.original_subcontractor_cost ?? ''}</TableCell>
        )}
        {visibleColumns.includes('approved_change_order_subcontractor') && (
          <TableCell>
            {plannedSubcontractorCost && !isNaN(plannedSubcontractorCost)
              ? `$${plannedSubcontractorCost.toFixed(2)}`
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('purchase_order') && (
          <TableCell>${rowData.purchase_order ?? ''}</TableCell>
        )}
        {visibleColumns.includes('material_requisition') && (
          <TableCell>${rowData.material_requisition ?? ''}</TableCell>
        )}
        {visibleColumns.includes('committed_revised_subcontractor_cost') && (
          <TableCell>
            {committedSubcontractorCost && !isNaN(committedSubcontractorCost)
              ? `$${committedSubcontractorCost.toFixed(2)}`
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('non_committed_cost_to_date') && (
          <TableCell>
            $
            {nonCommittedCostToDate && !isNaN(nonCommittedCostToDate)
              ? nonCommittedCostToDate.toFixed(2)
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('non_committed_estimate_cost_to_date') && (
          <TableCell>${rowData.non_committed_estimate_cost_to_date ?? ''}</TableCell>
        )}
        {visibleColumns.includes('estimated_final_cost') && (
          <TableCell>
            ${estimatedFinalCost && !isNaN(estimatedFinalCost) ? estimatedFinalCost.toFixed(2) : ''}
          </TableCell>
        )}
        {visibleColumns.includes('variance_project_budgeted') && (
          <TableCell>{variance && !isNaN(variance) ? `$${variance.toFixed(2)}` : ''}</TableCell>
        )}
        {visibleColumns.includes('performed_amount') && (
          <TableCell>{rowData.performed_amount ? `$${rowData.performed_amount}` : ''}</TableCell>
        )}
        {visibleColumns.includes('performed_percentage') && (
          <TableCell>{`$${performedPercentage.toFixed(2)}%`}</TableCell>
        )}
        {visibleColumns.includes('resources_used') && rowData.resources_used ? (
          <TableCell>${rowData.resources_used}</TableCell>
        ) : (
          <TableCell></TableCell>
        )}

        {visibleColumns.includes('expenses') && <TableCell>${rowData.expenses ?? ''}</TableCell>}
        {visibleColumns.includes('actual_cost') && (
          <TableCell>
            <TableCell>
              {actualCost && !isNaN(actualCost) ? `$${actualCost.toFixed(2)}` : ''}
            </TableCell>
          </TableCell>
        )}
        {visibleColumns.includes('gross_profit') && (
          <TableCell>
            <TableCell>
              {grossProfit && !isNaN(grossProfit) ? `$${grossProfit.toFixed(2)}` : ''}
            </TableCell>
          </TableCell>
        )}
        {visibleColumns.includes('gross_profit_percentage') && (
          <TableCell>
            {grossProfitPercentage && !isNaN(grossProfitPercentage)
              ? `${grossProfitPercentage.toFixed(2)}%`
              : ''}
          </TableCell>
        )}
        {visibleColumns.includes('remaining_budget') && (
          <TableCell>
            ${remainingBudget && !isNaN(remainingBudget) ? remainingBudget.toFixed(2) : ''}
          </TableCell>
        )}
        {visibleColumns.includes('retainage_percentage') && (
          <TableCell>${retainage.toFixed(2)}</TableCell>
        )}
        {visibleColumns.includes('requisition_number') && (
          <TableCell>{rowData.requisition_number}</TableCell>
        )}
        {visibleColumns.includes('invoice_number') && (
          <TableCell>{rowData.invoice_number}</TableCell>
        )}
        {visibleColumns.includes('payment_status') && (
          <TableCell>{rowData.payment_status || "pending"}</TableCell>
        )}
        {visibleColumns.includes('risk_level') && <TableCell>{rowData.risk_level || "Low"}</TableCell>}
        {visibleColumns.includes('quality_checks') && (
          <TableCell>{rowData.quality_checks || "passed"}</TableCell>
        )}
        {visibleColumns.includes('compliance') && <TableCell>{rowData.compliance || "Yes"}</TableCell>}
        {visibleColumns.includes('contingency_used') && (
          <TableCell>{rowData.contingency_used}</TableCell>
        )}
        {visibleColumns.includes('notes') && <TableCell>{rowData.notes}</TableCell>}
      </TableRow>
    );

    return (
        <>
            {renderMainRow()}
            <ChangeOrder
                open={confirm.value}
                onClose={confirm.onFalse}
                title="Change Orders"
                rowData={rowData}
             
            />
        </> );}