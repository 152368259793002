import React from 'react';
import { Page, Text, View, Document, Image, PDFDownloadLink, Font, pdf } from '@react-pdf/renderer';
import head from '../../../assets/pdf/headertop2.png';
import footer from '../../../assets/pdf/headertop.png';
import styles from './tablecss';
import { _descriptions } from './../../../_mock/assets';

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf', // Montserrat Regular
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2', // Montserrat Bold
      fontWeight: 'bold',
    },
  ],
});

const ROWS_PER_PAGE = 12;
const MIN_ROWS_PER_PAGE = 12;
const paginateData = (
  data = [],
  rowsPerPage = ROWS_PER_PAGE,
  minRowsPerPage = MIN_ROWS_PER_PAGE
) => {
  if (!Array.isArray(data)) {
    return [];
  }
  const pages = [];
  let page = [];
  for (let i = 0; i < data.length; i++) {
    page.push(data[i]);
    if (page.length === rowsPerPage || i === data.length - 1) {
      if (page.length < minRowsPerPage && i !== data.length - 1) {
        const carryOverRows = page.splice(0);
        if (pages.length > 0) {
          pages[pages.length - 1].push(...carryOverRows);
        } else {
          pages.push(carryOverRows);
        }
      } else {
        pages.push(page);
      }
      page = [];
    }
  }
  return pages;
};

// PDF Document
const MyDocument = ({
  tableData,
  jobCostinglabels,
  subtotals,
  projectname='',
  profile,
  startDate,
  endDate,
}) => {

  const pages = paginateData(tableData, ROWS_PER_PAGE, MIN_ROWS_PER_PAGE);
  const totalPages = pages.length;
  const visibleColumnNames = [
    ' Base/Change Order',
    'category',
    'activity',
    'expenses',
    'actual_cost',
    'original_budgeted_amount',
    'approved_change_orders',
    'revised_budget_amount',
    'committed_revised_subcontractor_cost',
    'non_committed_estimate_cost_to_date',
    'estimated_final_cost',
    'variance_project_budgeted',
    'performed_amount',
    'performed_percentage',
    'gross_profit',
    'remaining_budget',
  ];
  let visibleColumnCount = 0;

  const isColumnVisible = (columnKey) => {
    const label = jobCostinglabels.find((label) => label.key === columnKey);
    if (!label) {
      return false;
    }
    const isVisible = label ? !label.value : true;
    if (isVisible && visibleColumnCount < 16) {
      visibleColumnCount++;
      return true;
    }
    return false;
  };

  const formatLabel = (label) => {
    if (!label) return null;
    return label.split(' ').map((word, index) => (
      <Text key={index} style={styles.headerTextLine}>
        {word}
      </Text>
    ));
  };

  return (
    <Document>
      {pages.length === 0 ? (
        <Page size="A4" style={styles.page} orientation="landscape">
          <Image style={styles.headerImage} src={head} fixed />
          <View style={{ padding: '20px 20px', display: 'table', width: '100%' }}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              {visibleColumnNames.map((columnKey) => (
                <View key={columnKey} style={styles.tableHeaderCell}>
                  {formatLabel(
                    jobCostinglabels?.find((label) => label.key === columnKey)?.name || columnKey
                  )}
                </View>
              ))}
            </View>
            <View style={{ marginTop: 20 }}>
              <Text style={{ textAlign: 'center', fontSize: 14, color: '#999' }}>
                No Data Available.
              </Text>
            </View>
          </View>
          <Image style={styles.footerImage} src={footer} fixed />
          <Text style={styles.footerPageNumber}>01/01</Text>
        </Page>
      ) : (
        pages.map((pageData, pageIndex) => (
          <Page key={pageIndex} size="A4" style={styles.page} orientation="landscape">
            <Image style={styles.headerImage} src={head} fixed />
            <View style={styles.logoContainer} fixed>
              {/* <Image src={profile?.avatar} style={styles.logo} /> */}
              <View style={styles.companyDetails}>
                <Text style={styles.companyName}>{profile?.company_name || ''}</Text>
                <Text style={styles.companyAddress}>{`${profile?.city || ''}, ${
                  profile?.address || ''
                },${profile?.state.name || ''},${profile?.country.name || ''},${
                  profile?.zip || ''
                }`}</Text>
              </View>
            </View>
            <View
              style={[
                styles.tableRow2,
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'start',
                  paddingRight: '10px',
                },
              ]}
            >
              <Text style={styles.headings}>Project Name: </Text>
              <Text style={{ fontSize: '10px', marginRight: '55px' }}>
                {tableData[0]?.project_name}
              </Text>
              <Text style={styles.headings}>Contract Number: </Text>
              <Text style={{ fontSize: '10px', marginRight: '55px' }}>
                {tableData[0]?.contract_number}
              </Text>
              <Text style={styles.headings}>From: </Text>
              <Text style={{ fontSize: '10px', marginRight: '55px' }}>{startDate}</Text>
              <Text style={styles.headings}>To: </Text>
              <Text style={{ fontSize: '10px', marginRight: '55px' }}>{endDate}</Text>
            </View>

            <View style={styles.headermain}>
              <Text>
                <Text style={styles.date}>
                  <Text style={styles.headings}>JOB COSTING </Text>
                </Text>
              </Text>
            </View>

            <View style={styles.header}></View>
            <View style={{ padding: '0px 10px', display: 'table', width: '100%' }}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                {visibleColumnNames.map((columnKey) => (
                  <View key={columnKey} style={styles.tableHeaderCell}>
                    {formatLabel(
                      jobCostinglabels.find((label) => label.key === columnKey)?.name || columnKey
                    )}
                  </View>
                ))}
              </View>
              {pageData.map((row, rowIndex) => {
                visibleColumnCount = 0;
                const rowStyle = rowIndex % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd; // Alternate rows
                return (
                  <>
                    <View key={rowIndex} style={[styles.tableRow, rowStyle]}>
                      {console.log('row==', rowIndex, row)}

                      <Text style={styles.tableCell}>
                        {row?.change_orders[0]?.id ? `${row.change_orders[0].id}` : ''}
                      </Text>

                      {isColumnVisible('category') && (
                        <Text style={styles.tableCell}>
                          {row.cost_code_category?.code || ''}-
                          {row.cost_code_category?.description || ''}
                        </Text>
                      )}
                      {isColumnVisible('activity') && (
                        <Text style={styles.tableCell}>{row.cost_codes?.code || ''}</Text>
                      )}
                      {isColumnVisible('expenses') && (
                        <Text style={styles.tableCell}>
                          {row.expenses ? `$${row.expenses}` : ''}
                        </Text>
                      )}

                      {isColumnVisible('actual_cost') && (
                        <Text style={styles.tableCell}>
                          {row.actual_cost ? `$${row.actual_cost}` : ''}
                        </Text>
                      )}

                      {isColumnVisible('original_budgeted_amount') && (
                        <Text style={styles.tableCell}>
                          {row.original_budgeted_amount && !isNaN(row.original_budgeted_amount)
                            ? `$${row.original_budgeted_amount}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('approved_change_orders') && (
                        <Text style={styles.tableCell}>
                          {row?.change_orders[0]?.budgeted_amount &&
                          !isNaN(row.change_orders[0].budgeted_amount)
                            ? `$${row.change_orders[0].budgeted_amount}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('revised_budget_amount') && (
                        <Text style={styles.tableCell}>
                          {row.revised_budget_amount && !isNaN(row.revised_budget_amount)
                            ? `$${row.revised_budget_amount}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('committed_revised_subcontractor_cost') && (
                        <Text style={styles.tableCell}>
                          {row.committed_revised_subcontractor_cost &&
                          !isNaN(row.committed_revised_subcontractor_cost)
                            ? `$${row.committed_revised_subcontractor_cost}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('non_committed_cost_to_date') && (
                        <Text style={styles.tableCell}>
                          {row.non_committed_cost_to_date && !isNaN(row.non_committed_cost_to_date)
                            ? `$${row.non_committed_cost_to_date}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('estimated_final_cost') && (
                        <Text style={styles.tableCell}>
                          {row.estimated_final_cost && !isNaN(row.estimated_final_cost)
                            ? `$${row.estimated_final_cost}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('variance_project_budgeted') && (
                        <Text style={styles.tableCell}>
                          {row.variance_project_budgeted != null &&
                          !isNaN(row.variance_project_budgeted)
                            ? `$${row.variance_project_budgeted.toFixed(2)}`
                            : ''}
                        </Text>
                      )}

                      {isColumnVisible('performed_amount') && (
                        <Text style={styles.tableCell}>
                          {row.performed_amount && !isNaN(row.performed_amount)
                            ? `$${row.performed_amount}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('performed_percentage') && (
                        <Text style={styles.tableCell}>
                          {row.performed_percentage && !isNaN(row.performed_percentage)
                            ? `${row.performed_percentage.toFixed(2)}%`
                            : ''}
                        </Text>
                      )}

                      {isColumnVisible('gross_profit') && (
                        <Text style={styles.tableCell}>
                          {row.gross_profit && !isNaN(row.gross_profit)
                            ? `$${row.gross_profit.toFixed(2)}`
                            : ''}
                        </Text>
                      )}
                      {isColumnVisible('remaining_budget') && (
                        <Text style={styles.tableCell}>
                          {row.remaining_budget && !isNaN(row.remaining_budget)
                            ? `$${row.remaining_budget}`
                            : ''}
                        </Text>
                      )}
                    </View>
                  </>
                );
              })}
              <View style={[styles.tableRow, styles.tableSubtotalRow]}>
                <Text style={styles.tableCellbold}>BASE CONTRACT TOTAL</Text>
                <Text style={styles.tableCellbold}></Text>
                <Text style={styles.tableCellbold}></Text>

                <Text style={styles.tableCellbold}>{`$${subtotals.totalExpenses}`}</Text>

                <Text style={styles.tableCellbold}>{`$${subtotals.totalActualCost}`}</Text>

                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalOriginalBudgetedAmount}`}
                </Text>

                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalApprovedChangeOrders}`}
                </Text>
                <Text style={styles.tableCellbold}>{`$${subtotals.totalRevisedBudget}`}</Text>
                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalCommittedSubcontractorCost}`}
                </Text>
                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalNonCommittedCostToDate}`}
                </Text>
                <Text style={styles.tableCellbold}>{`$${subtotals.totalEstimatedFinalCost}`}</Text>
                <Text style={styles.tableCellbold}>{`$${subtotals.totalVariance}`}</Text>
                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalPerformedAmount}`}
                </Text>
                <Text style={styles.tableCellbold}>{`${subtotals.totalPerformedPercentage}%`}</Text>
                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalGrossProfit.toFixed(2)}`}
                </Text>
                <Text style={styles.tableCellbold}>
                  {`$${subtotals.totalRemainingBudget.toFixed(2)}`}
                </Text>
              </View>
            </View>
            <Image style={styles.footerImage} src={footer} fixed />
            <Text style={styles.footerPageNumber}>
              {String(pageIndex + 1).padStart(2, '0')}/{String(totalPages).padStart(2, '0')}
            </Text>
          </Page>
        ))
      )}
    </Document>
  );
};

export const generatePdfBlob = async ({
  tableData,
  jobCostinglabels,
  subtotals,
  projectname,
  profile,
  // startDate,
  // endDate,
}) => {
  const blob = await pdf(
    <MyDocument
      tableData={tableData}
      jobCostinglabels={jobCostinglabels}
      subtotals={subtotals}
      projectname={projectname}
      profile={profile}
      // startDate={startDate}
      // endDate={endDate}
    />
  ).toBlob();
  return blob;
};



// Main Component
const PdfTable = ({
  tableData,
  jobCostinglabels,
  subtotals,
  projectname,
  profile,
  startDate,
  endDate,
}) => (
  <div>
    <PDFDownloadLink
      document={
        <MyDocument
          projectname={projectname}
          profile={profile}
          tableData={tableData}
          jobCostinglabels={jobCostinglabels}
          subtotals={subtotals}
          startDate={startDate}
          endDate={endDate}
        />
      }
      fileName="job_costing.pdf"
      style={{
        padding: '10px 0px',
        textDecoration: 'none',
        color: '#212b36',
        textAlign: 'center',
      }}
    >
      {({ loading }) => (loading ? '...' : 'PDF')}
    </PDFDownloadLink>
  </div>
);

export default PdfTable;
