import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { Box, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import './style.css';
import RenderField from './render-field';
import { getRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { useParams } from 'react-router';
import CreateAssessment from './risk-assessment-create';
import { useBoolean } from 'src/hooks/use-boolean';

const RiskAssessment = ({ reload }) => {
  const { id } = useParams();
  const add = useBoolean();
  const [getLoading, setGetLoading] = useState(false);
  const [threatOpportunity, setThreatOpportunity] = useState([]);
  const [riskArea, setRiskArea] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    getRiskAssessment();
    getThreatOpportunity();
    getRiskArea();
  }, []);

  const getThreatOpportunity = async () => {
    const response = await getRequest(endpoints.risk_management.risk_register.identification.threat_opportunity);
    if (response) {
      setThreatOpportunity(response);
    }
  };
  const getRiskArea = async () => {
    const response = await getRequest(endpoints.risk_management.risk_register.identification.risk_area);
    if (response) {
      setRiskArea(response);
    }
  };
  const getRiskAssessment = async () => {
    setGetLoading(true);
    const response = await getRequest(endpoints.risk_management.risk_register.identification.risk_assessments(id));
    setGetLoading(false);
    if (response) {
      setData(response);
    }
  };
  const handleReload = () => {
    reload();
    getRiskAssessment();
  };

  return (
    <Card sx={{ marginTop: "20px" }}>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h4" class="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
            Risk Assessment
          </Typography>
        </Box>
        <Box sx={{ marginRight: '20px', marginTop: '22px' }}>
          <Tooltip title="Edit">
            <IconButton onClick={add.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box p={4} mt={4} display="grid" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={3}>
        <RenderField label="Qualitative Assessment" value={data?.qualitative_analysis || " "} />
        <RenderField label="Risk Rating" value={data?.risk_rating || " "} />
        <RenderField label="Threat or Opportunity" value={data?.threat_opportunity?.name || " "} />
      </Box>

      <Divider />

      <Box p={4} display="grid" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={3}>
        <RenderField label="Probability of Occurrence" value={data?.probability_of_occurrence || 0} />
        <RenderField label="Risk Area" value={data?.risk_area?.name || " "} />
        <RenderField label="Impact on Cost" value={data?.impact_on_cost || 0} />
      </Box>

      <Divider />

      <Box p={4} display="grid" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={3}>
        <RenderField label="Full Probable Loss" value={data?.full_probable_loss || " "} />
        <RenderField label="Expected Loss" value={data?.expected_loss || " "} />
        <RenderField label="Impact on Schedule" value={data?.impact_on_schedule || 0} />
      </Box>

      <Divider />



      <CreateAssessment
        data={data}
        threatOpportunity={threatOpportunity}
        riskArea={riskArea}
        reloadThreatOpportunity={getThreatOpportunity}
        reloadRiskArea={getRiskArea}
        open={add.value}
        onClose={add.onFalse}
        reload={handleReload}
      />
    </Card>
  );
};

export default RiskAssessment;
