import React from 'react';
import Iconify from 'src/components/iconify';
import { Box, Button, Card, Divider, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import RenderField from '../../risk-register/render-field';
const QuarterlyRiskTable = ({ data }) => {
    console.log("🚀 ~ QuarterlyRiskTable ~ data:", data)
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <Card>
            <Box p={3}>
                <Typography className=" Escalation-view-main-heading">Quarterly Risk Review View </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
                <Box sx={{ position: 'relative' }}>
                    <Typography variant="h4" className="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
                       Quarterly Risk Review View{' '}
                    </Typography>
                </Box>
                <Box>
                    <Button
                        className='dark-button'
                        sx={{ marginRight: '20px', marginTop: '22px' }}
                        onClick={goBack}
                        size="md"
                        variant="contained"
                        startIcon={<Iconify icon="ep:back" width={16} />}
                    >
                        Go Back
                    </Button>
                </Box>
            </Box>
            <Box
                p={4}
                mt={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField label="Review  ID" value={data?.review_id}/>
                <RenderField label=" Review Period" value={data?.review_period} />
                <RenderField label=" Date of Review" value={data?.review_date} />
            </Box>
            <Divider />
            <Box
                p={4}
                rowGap={3}
                columnGap={20}
                display="grid"
                gridTemplateColumns={{
                    xs: 'repeat(1, 1fr)',
                    sm: 'repeat(3, 1fr)',
                }}
            >
                <RenderField
                    label="Key Risks Reviewed"
                    value={
                        <Tooltip
                            title={
                                <div style={{ whiteSpace: "pre-line" }}>
                                    {data?.identification_and_assessment?.map((item) => (
                                        <Typography
                                            key={item.name}
                                            variant="body2"
                                            color="inherit"
                                            sx={{ display: "block", fontWeight: 500 }}
                                        >
                                            {item?.risk_id}
                                        </Typography>
                                    ))}
                                </div>
                            }
                            aria-label="view"
                            sx={{ justifyContent: "center" }}
                        >
                            <span style={{ cursor: "pointer", color: "#1976d2", fontWeight: "bold" }}>
                                View
                            </span>
                        </Tooltip>
                    }
                />
                <RenderField label="Review Summary" value={data?.review_summary} />
                <RenderField label="Recommendations" value={data?.attended_by} />
            </Box>
        
            <Divider />
        </Card>
    );
};
export default QuarterlyRiskTable;