import { useCallback, useEffect, useState } from 'react';
// @mui
import { MenuItem, Select, TextField } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useSnackbar } from 'notistack';
// icons
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { usePopover } from 'src/components/custom-popover';
import { calculateActualCost, calculateCommittedSubcontractorCost, calculateEstimatedFinalCost, calculateGrossProfit, calculateGrossProfitPercentage, calculateNonCommittedCostToDate, calculatePerformedPercentage, calculateRemainingBudget, calculateRetainage, calculateRevisedBudgetLower, calculateVariance } from './Calculations/formulas';
import { postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import { debounce } from 'lodash';
import { _descriptions } from './../../../_mock/assets';

// ----------------------------------------------------------------------

export default function JobcostingChangeorderTableRow({
    row,
    selected,
    onEditRow,
    onSelectRow,
    onDeleteRow,
    handleDragStart,
    refreshTableData,
    visibleColumns 
}) {
    const [loading, setLoading] = useState(false);
    const confirm = useBoolean();
    const edit = useBoolean();
    const { enqueueSnackbar } = useSnackbar();
    const quickEdit = useBoolean();
    const popover = usePopover();

    const [committedSubcontractorCost, setCommittedSubcontractorCost] = useState(0);
    const [nonCommittedCostToDate, setNonCommittedCostToDate] = useState(0);
    const [estimatedFinalCost, setEstimatedFinalCost] = useState(0);
    const [variance, setVariance] = useState(0);
    const [performedPercentage, setPerformedPercentage] = useState(0);
    const [actualCost, setActualCost] = useState(0);
    const [grossProfit, setGrossProfit] = useState(0);
    const [grossProfitPercentage, setGrossProfitPercentage] = useState(0);
    const [remainingBudget, setRemainingBudget] = useState(0);
    const [retainage, setRetainage] = useState(0);
    const [revisedBudget, setRevisedBudget] = useState(0);
    const [original_budgeted_amount, setoriginal_budgeted_amount] = useState(0);
    const [rowData, setRowData] = useState(row);


    useEffect(() => {
        //14
        const calculatedRevisedBudget = calculateRevisedBudgetLower(
            original_budgeted_amount,
            row.approved_change_orders
        );
        setRevisedBudget(calculatedRevisedBudget);
        //19
        //19
        const calculatedValue = calculateCommittedSubcontractorCost(row);
        setCommittedSubcontractorCost(calculatedValue);
        //20
        const calculatedNonCommittedCost = calculateNonCommittedCostToDate(row, committedSubcontractorCost);
        setNonCommittedCostToDate(calculatedNonCommittedCost);
        //22
        const calculatedEstimatedFinalCost = calculateEstimatedFinalCost(
            committedSubcontractorCost,
            nonCommittedCostToDate,
            parseFloat(row.non_committed_estimated_cost) || 0
        );
        setEstimatedFinalCost(calculatedEstimatedFinalCost);
        //23
        const calculatedVariance = calculateVariance(estimatedFinalCost, parseFloat(row.revised_budget) || 0);
        setVariance(calculatedVariance);
        //25
        const calculatedPerformedPercentage = calculatePerformedPercentage(
            row.performed_amount,
            parseFloat(row.revised_budget) || 0
        );
        setPerformedPercentage(calculatedPerformedPercentage);
        //28
        const calculatedActualCost = calculateActualCost(
            parseFloat(row.resources_used) || 0,
            parseFloat(row.expenses) || 0
        );
        setActualCost(calculatedActualCost);
        //29
        const calculatedGrossProfit = calculateGrossProfit(
            parseFloat(row.performed_amount) || 0,
            actualCost
        );
        setGrossProfit(calculatedGrossProfit);
        //30
        const calculatedGrossProfitPercentage = calculateGrossProfitPercentage(
            grossProfit,
            parseFloat(row.revised_budget) || 0
        );
        setGrossProfitPercentage(calculatedGrossProfitPercentage);
        //31
        const calculatedRemainingBudget = calculateRemainingBudget(
            parseFloat(row.revised_budget) || 0,
            actualCost
        );
        setRemainingBudget(calculatedRemainingBudget);
        //32
        const calculatedRetainage = calculateRetainage(row.performed_amount);
        setRetainage(calculatedRetainage);
    }, [
        row.original_subcontractor_cost,
        row.approved_change_order_subcontractor,
        row.purchase_order,
        row.material_requisition,
        //20
        row.revised_budget,
        committedSubcontractorCost,
        //22
        row.non_committed_estimated_cost,
        //23
        estimatedFinalCost,
        //25
        row.performed_amount,
        //28
        row.resources_used,
        row.expenses,
        //29
        actualCost,
        //30
        grossProfit,
    ]);

      const handleFieldChange = (field, value) => {
        const updatedRow = {
            ...rowData,
            [field]: value
        };
        setRowData(updatedRow);
        debouncedApiCall(field, value, updatedRow.id);
    };
    const apiCall = async (field, numericValue, id) => {
        // Prepare FormData with the updated field
        const formData = new FormData();
        formData.append(field, numericValue);
        formData.append('id', id);

        try {
            // API call on field change
            const response = await postRequest(
                `${endpoints.job_costing.update}/${id}`,
                formData,
                true
            );
            // console.log("🚀 ~ response ()()()()():", response);
            if (response.metadata && response.metadata.success) {
                enqueueSnackbar({
                    variant: 'success',
                    message: "Change Orders Updated Successfully",
                });
                refreshTableData && refreshTableData();
            } else {
                throw new Error(response.metadata.message);
            }
        } catch (error) {
            enqueueSnackbar({
                variant: 'error',
                message: error.message || 'Failed to save data.',
            });
        }
    };
    const debouncedApiCall = useCallback(debounce(apiCall, 2000), []);

    return (
      <>
        <TableRow hover selected={selected} key={rowData.id}>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {''}
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.change_order_id ?? ''}
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.project_id}
          </TableCell>
          {visibleColumns.includes('facility') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              <TextField
                value={rowData.facility || ''}
                onChange={(e) => handleFieldChange('facility', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}
          {visibleColumns.includes('level') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              <TextField
                value={rowData.level || ''}
                onChange={(e) => handleFieldChange('level', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}

          {visibleColumns.includes('milestone') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              <TextField
                value={rowData.milestone || ''}
                onChange={(e) => handleFieldChange('milestone', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}
          {visibleColumns.includes('category') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              {typeof rowData?.change_order_category === 'object' &&
              rowData.change_order_category !== null
                ? `${rowData.change_order_category?.code ?? ''} - ${
                    rowData.change_order_category?.description ?? ''
                  }`
                : ''}
            </TableCell>
          )}
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {typeof rowData?.change_order_activity === 'object' &&
            rowData.change_order_activity !== null
              ? `${rowData.change_order_activity?.code ?? ''} - ${
                  rowData.change_order_activity?.description ?? ''
                }`
              : ''}
          </TableCell>

          {visibleColumns.includes('start_date') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              {rowData.start_date
                ? new Date(rowData.start_date).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })
                : ''}
            </TableCell>
          )}

          {visibleColumns.includes('end_date') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              {rowData.end_date
                ? new Date(rowData.end_date).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric',
                  })
                : ''}
            </TableCell>
          )}

          {visibleColumns.includes('duration') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              {rowData.cost_codes?.duration ?? ''}
            </TableCell>
          )}

          {visibleColumns.includes('trade') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              <TextField
                value={rowData.trade || ''}
                onChange={(e) => handleFieldChange('trade', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}

          {visibleColumns.includes('responsible_party') && (
            <TableCell
              sx={{ minWidth: '100px' }}
              draggable
              onDragStart={(event) => handleDragStart(event, rowData)}
            >
              <TextField
                value={rowData.responsible_party || ''}
                onChange={(e) => handleFieldChange('responsible_party', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {original_budgeted_amount ? `$${original_budgeted_amount.toFixed(2)}` : ''}
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.approved_change_orders ? `$${rowData.approved_change_orders}` : ''}
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {`$${revisedBudget.toFixed(2)}`}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.original_subcontractor_cost ? `$${rowData.original_subcontractor_cost}` : ''}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.approved_change_order_subcontractor
              ? `$${rowData.approved_change_order_subcontractor}`
              : ''}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            <TextField
              value={rowData.purchase_order ? `$${rowData.purchase_order}` : ''}
              onChange={(e) => handleFieldChange('purchase_order', e.target.value.replace('$', ''))}
              variant="outlined"
              size="small"
            />
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            <TextField
              value={rowData.material_requisition ? rowData.material_requisition : ''}
              onChange={(e) =>
                handleFieldChange('material_requisition', e.target.value.replace(/[^0-9.-]+/g, ''))
              }
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: rowData.material_requisition ? '$' : null, // Display dollar sign if value exists
              }}
            />
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {committedSubcontractorCost ? `$${committedSubcontractorCost}` : ''}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {nonCommittedCostToDate ? `$${nonCommittedCostToDate}` : ''}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            <TextField
              value={
                rowData.non_committed_estimated_cost
                  ? `$${rowData.non_committed_estimated_cost}`
                  : ''
              }
              onChange={(e) =>
                handleFieldChange('non_committed_estimated_cost', e.target.value.replace('$', ''))
              }
              variant="outlined"
              size="small"
            />
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            ${estimatedFinalCost.toFixed(2)}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            ${variance.toFixed(2)}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {rowData.performed_amount ? `$${rowData.performed_amount}` : ''}
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {isNaN(performedPercentage) ? '0' : performedPercentage.toFixed(2)}%
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            <TextField
              value={rowData.resources_used ? rowData.resources_used : ''}
              onChange={(e) =>
                handleFieldChange('resources_used', e.target.value.replace(/[^0-9.-]+/g, ''))
              }
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: rowData.resources_used ? '$' : null, // Only show dollar sign if there is a value
              }}
            />
          </TableCell>

          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            <TextField
              value={rowData.expenses ? `$${rowData.expenses}` : ''}
              onChange={(e) => handleFieldChange('expenses', e.target.value.replace('$', ''))}
              variant="outlined"
              size="small"
            />
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            ${actualCost.toFixed(2)}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            ${grossProfit.toFixed(2)}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            {grossProfitPercentage.toFixed(2)}%
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            ${remainingBudget.toFixed(2)}
          </TableCell>
          <TableCell
            sx={{ minWidth: '100px' }}
            draggable
            onDragStart={(event) => handleDragStart(event, rowData)}
          >
            $ {retainage.toFixed(2)}
          </TableCell>
          {visibleColumns.includes('requisition_number') && (
            <TableCell>
              <TextField
                sx={{ minWidth: '100px' }}
                value={rowData.requisition_number ? rowData.requisition_number : ''}
                onChange={(e) =>
                  handleFieldChange('requisition_number', e.target.value.replace(/[^0-9.-]+/g, ''))
                }
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: rowData.requisition_number ? '$' : null, // Only show dollar sign if there is a value
                }}
              />
            </TableCell>
          )}
          {visibleColumns.includes('invoice_number') && (
            <TableCell>
              <TextField
                sx={{ minWidth: '100px' }}
                value={rowData.invoice_number || ''}
                onChange={(e) => handleFieldChange('invoice_number', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}
          {visibleColumns.includes('payment_status') && (
            <TableCell>
              <Select
                value={rowData.payment_status || 'pending'}
                onChange={(e) => handleFieldChange('payment_status', e.target.value)}
                variant="outlined"
                size="small"
                displayEmpty
              >
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="Reviewed">Reviewed</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
              </Select>
            </TableCell>
          )}
          {visibleColumns.includes('risk_level') && (
            <TableCell>
              <Select
                value={rowData.risk_level || 'Low'}
                onChange={(e) => handleFieldChange('risk_level', e.target.value)}
                variant="outlined"
                size="small"
                displayEmpty
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </TableCell>
          )}
          {visibleColumns.includes('quality_checks') && (
            <TableCell>
              <Select
                value={rowData.quality_checks || 'passed'}
                onChange={(e) => handleFieldChange('quality_checks', e.target.value)}
                variant="outlined"
                size="small"
                displayEmpty
              >
                <MenuItem value="passed">Passed</MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="failed">Failed</MenuItem>
              </Select>
            </TableCell>
          )}
          {visibleColumns.includes('compliance') && (
            <TableCell>
              <Select
                value={rowData.compliance || 'Yes'}
                onChange={(e) => handleFieldChange('compliance', e.target.value)}
                variant="outlined"
                size="small"
                displayEmpty
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </TableCell>
          )}
          {visibleColumns.includes('contingency_used') && (
            <TableCell>
              <TextField
                sx={{ minWidth: '100px' }}
                value={rowData.contingency_used ? rowData.contingency_used : ''}
                onChange={(e) =>
                  handleFieldChange('contingency_used', e.target.value.replace(/[^0-9.-]+/g, ''))
                }
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: rowData.contingency_used ? '$' : null, // Only show the dollar sign if there is a value
                }}
              />
            </TableCell>
          )}
          {visibleColumns.includes('notes') && (
            <TableCell>
              <TextField
                sx={{ minWidth: '100px' }}
                value={rowData.notes || ''}
                onChange={(e) => handleFieldChange('notes', e.target.value)}
                variant="outlined"
                size="small"
              />
            </TableCell>
          )}
        </TableRow>
      </>
    );
}