import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import RiskRegister from 'src/components/risk-management/risk-register/risk-register';
import RiskView from 'src/components/risk-management/risk-register/risk-view';
import ComprehensiveRiskInventory from 'src/components/risk-management/SideCards.js/comprehensive-risk-inventory/comprehensive-risk-inventory';
import IncidentReporting from 'src/components/risk-management/SideCards.js/incident-reporting/Incident-Reporting';
import IncidentView from 'src/components/risk-management/SideCards.js/incident-reporting/incident-view';
import QuarterlyRiskReview from 'src/components/risk-management/SideCards.js/quarterly-risk-review';
import QuaterlyReviewPage from 'src/components/risk-management/SideCards.js/quarterly-risk-review/quaterly-review-view-page';
import RiskActionLog from 'src/components/risk-management/SideCards.js/risk-action-log/risk-action-log';
import RiskChangeRequest from 'src/components/risk-management/SideCards.js/risk-change-request/risk-change-request';
import RiskChangeView from 'src/components/risk-management/SideCards.js/risk-change-request/risk-change-view';
import RiskEscalation from 'src/components/risk-management/SideCards.js/risk-escalation';
import RiskEscalationView from 'src/components/risk-management/SideCards.js/risk-escalation/risk-escalation-view';
import RiskLearningandInsightsJournal from 'src/components/risk-management/SideCards.js/risk-learning-and-insights-journal/risk-learning-and-insights-journal';
import RiskLearningInsightsView from 'src/components/risk-management/SideCards.js/risk-learning-and-insights-journal/risk-learning-view-page';
import RiskMitigationBudgetRequest from 'src/components/risk-management/SideCards.js/risk-mitigation-budget-request/risk-mitigation-budget-request';
import RiskMitigationRequestView from 'src/components/risk-management/SideCards.js/risk-mitigation-budget-request/risk-mitigation-budget-request-view';
import RiskMitigationEffectivenessSurvey from 'src/components/risk-management/SideCards.js/risk-mitigation-effectiveness-survey/risk-mitigation-effectiveness-survey';
import RiskMitigationView from 'src/components/risk-management/SideCards.js/risk-mitigation-effectiveness-survey/risk-mitigation-survey-view';
import RiskPrioritization from 'src/components/risk-management/SideCards.js/risk-prioritization/risk-prioritization';
import RiskPrioritizationView from 'src/components/risk-management/SideCards.js/risk-prioritization/risk-priotization-view';
import RiskResponseTeamAssignment from 'src/components/risk-management/SideCards.js/risk-response-team-assignment/risk-response-team-assignment';
import RiskTeamViewPage from 'src/components/risk-management/SideCards.js/risk-response-team-assignment/risk-team-view';
import RiskTrendAnalaysisView from 'src/components/risk-management/SideCards.js/risk-trends-and-analysis-report/risk-trend-analysis-view';
import RiskTrendsAndAnalysisReport from 'src/components/risk-management/SideCards.js/risk-trends-and-analysis-report/risk-trends-and-analysis-report';
import SettingsInformation from 'src/components/risk-management/SideCards.js/settings-Info';
import StackeholderView from 'src/components/risk-management/SideCards.js/stakeholder-feedback/stake-holder-view';
import StakeholderFeedback from 'src/components/risk-management/SideCards.js/stakeholder-feedback/stakeholder-feedback';
import MainDashboardLayout from 'src/layouts/dashboard/main-layout';
import RiskSideBarLayout from 'src/layouts/dashboard/risk-sideBar-layout';
import RiskDashboard from 'src/pages/dashboard/risk-managment';

const contentComponents = [
  { name: "Settings Information", route: "/settings-information", component: SettingsInformation },
  {
    name: "Risk Register", route: "/risk-register", component: RiskRegister, subRoutes: [
      { name: "View", route: "/view/:id", component: RiskView },
    ]
  },
  {
    name: "Incident Reporting", route: "/incident-reporting", component: IncidentReporting, subRoutes: [
      { name: "IncidentView", route: "/incidentView/:id", component: IncidentView },
    ]
  },
  {
    name: "Risk Change Request", route: "/risk-change-request", component: RiskChangeRequest, subRoutes: [
      { name: "RiskChangeView", route: "/view/:id", component: RiskChangeView },
    ]
  },
  {
    name: "Stakeholder Feedback", route: "/stakeholder-feedback", component: StakeholderFeedback
    , subRoutes: [
      { name: "Stake-holder", route: "/view/:id", component: StackeholderView },
    ]
  },
  {
    name: "Risk Prioritization", route: "/risk-prioritization", component: RiskPrioritization, subRoutes: [
      { name: "Risk-Prioritization-View", route: "/view/:id", component: RiskPrioritizationView } ]},
  { name: "Risk Escalation", route: "/risk-escalation", component: RiskEscalation, subRoutes: [
    { name: "Risk-Prioritization-View", route: "/view/:id", component: RiskEscalationView }] },
  { name: "Risk Response Team Assignment", route: "/risk-response-team-assignment", component: RiskResponseTeamAssignment ,
 subRoutes: [
   { name: "Risk-Response-Team-Assignment-View", route: "/view/:id", component: RiskTeamViewPage }]

  },
  { name: "Quarterly Risk Review", route: "/quarterly-risk-review", component: QuarterlyRiskReview ,
    subRoutes: [
      { name: "Quarterly-Risk-Review-View", route: "/view/:id", component: QuaterlyReviewPage }]
   },
  {
    name: "Risk Mitigation Effectiveness Survey", route: "/risk-mitigation-effectiveness-survey",
    component: RiskMitigationEffectivenessSurvey ,
    subRoutes: [
      { name: "Risk-Mitigation-Effectiveness-Survey-View", route: "/view/:id", component: RiskMitigationView }] 
  },
  { name: "Risk Trends and Analysis Report", route: "/risk-trends-and-analysis-report", component: RiskTrendsAndAnalysisReport ,
    subRoutes: [
      { name: "Risk-Trends-and-Analysis-View", route: "/view/:id", component: RiskTrendAnalaysisView }]
  },
  { name: "Comprehensive Risk Inventory", route: "/comprehensive-risk-inventory", component: ComprehensiveRiskInventory },
  { name: "Risk Mitigation Budget Request", route: "/risk-mitigation-budget-request", component: RiskMitigationBudgetRequest
    , subRoutes: [
      { name: "Risk-Mitigation-Budget-Request-View", route: "/view/:id", component: RiskMitigationRequestView }]
   },
  { name: "Risk Action Log", route: "/risk-action-log", component: RiskActionLog },
  { name: "Risk Learning and Insights Journal", route: "/risk-learning-and-insights-journal", component: RiskLearningandInsightsJournal
    , subRoutes: [
      { name: "Risk-Learning-and-Insights-Journal-View", route: "/view/:id", component: RiskLearningInsightsView }]
   },
];

export const riskManagementRoutes = [
  {
    path: '/risk-management',
    element: (
      <MainDashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </MainDashboardLayout>
    ),
    children: [
      { element: <RiskDashboard />, index: true },
      ...contentComponents.flatMap(({ route, component: Component, subRoutes }) => {
        const mainRoute = {
          path: route.slice(1),
          element: (
            <RiskSideBarLayout>
              <Component />
            </RiskSideBarLayout>
          ),
        };

        const subRouteElements = subRoutes ? subRoutes.map(({ route: subRoute, component: SubComponent }) => ({
          path: `${route.slice(1)}${subRoute}`,
          element: (
            <RiskSideBarLayout>
              <SubComponent />
            </RiskSideBarLayout>
          ),
        })) : [];
        return [mainRoute, ...subRouteElements];
      }),
    ],
  },
];