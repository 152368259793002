/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import MenuItem from '@mui/material/MenuItem';
// routes
import { useEffect, useState } from 'react';
import { Button, Grid, Select, TextField } from '@mui/material';
// import { namedQuery } from 'firebase/firestore';
import { useSearchParams } from 'src/routes/hooks';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// ----------------------------------------------------------------------
// Commented code -----------------------------------------------------------

export default function Avtarpop({ trades, Employ, planned_cost_code_id, newData, getemploytradedata, startdate, budgetedhours }) {
    // console.log("🚀 ~ Avtarpop ~ budgetedhours:", budgetedhours)

    useEffect(() => {
        setLabourCrew(false);
        setTimeout(() => {
            setLabourCrew(true);
        }, 300);
    }, []);
    console.log("🚀 ~ Avtarpop ~ startdate:", startdate)

    const searchParams = useSearchParams();

    const headers = {
        Authorization: `Bearer ${searchParams.get('token') || localStorage.getItem('authToken') || ''}`,
        currentCompanyId: searchParams.get('company_id') || localStorage.getItem('companyId'),
        currentProjectId: searchParams.get('project_id') || localStorage.getItem('projectId'),
        'Content-Type': 'application/json',
    }

    let arrary = [
        {
            employee_id: "",
            project_trade_work_title_id: "",
            hours: "",
        }
    ]

    useEffect(() => {
        setDropmap(newData?.length == 0 ? arrary : newData)
    }, [newData])

    const [Dropmap, setDropmap] = useState([
        {
            employee_id: "",
            project_trade_work_title_id: "",
            hours: "",
            // planned_cost_code_id: planned_cost_code_id
        }
    ]);
    console.log("🚀 ~ Avtarpop ~ Dropmap   :", Dropmap)
    //handleSave
    const handleSave = () => {

        setLaoding(true)
        let Arr = Dropmap.map(item => {
            return {
                employee_id: Number(item.employee_id),
                project_trade_work_title_id: Number(item.project_trade_work_title_id),
                hours: item.hours,
            };
        });
        console.log("🚀 ~ handleSave:", Arr);

        if (Dropmap !== null) {
            fetch("https://web.ezelogs.com/api/project/calendar/planned-cost-code-assigned", {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    assign_resource: Arr,
                    planned_cost_code_id
                }),
            })
                .then(res => res.json())
                .then((data) => {
                    console.log("🚀 ~ .then ~ data:", data);
                    enqueueSnackbar(data.payload);
                    getemploytradedata(planned_cost_code_id)
                    setLaoding(false)
                })
                .catch(err => {
                    console.log(err);
                });
        } else {
            console.log("Dropmap is null. Handle this case accordingly.");
        }
    };

    //cehckboxes
    const [assignIndividual, setAssignIndividual] = useState(true);
    const [assignCrew, setAssignCrew] = useState(false);
    //activetypes dropdowns
    const [activeType2, setActiveType2] = useState('Material');
    const [activeType3, setActiveType3] = useState('Equipment');
    const [activeType4, setActiveType4] = useState('Subcontractor');
    const [activeType5, setActiveType5] = useState('Select Crew');
    //set crew
    const [Crew, setCrew] = useState(false)
    // hide states
    const [isLabourCrew, setLabourCrew] = useState(true);
    const [isMaterial, setMaterial] = useState(false);
    const [isEquipment, setEquipment] = useState(false);
    const [isSub, setSub] = useState(false);
    const [counter, setCounter] = useState(1);
    const [formDataArray, setFormDataArray] = useState([]);
    // employe and trade state
    const [activeTypes1, setActiveTypes1] = useState([]);
    const [activeTypes, setActiveTypes] = useState([]);
    //hour state
    const [hoursArray, setHoursArray] = useState([""]);
    const [loading, setLaoding] = useState(false);

    // functions 
    //add 1
    const handleAddMore = () => {
        const newData = {};
        setCounter(counter + 1);
        setFormDataArray(prevDataArray => [...prevDataArray, newData]);

        setDropmap([...Dropmap, {
            employee_id: "",
            project_trade_work_title_id: "",
            hours: "",
        }]);
        setActiveTypes1([...activeTypes1, ""]);
        setActiveTypes([...activeTypes, ""]);
        setHoursArray([...hoursArray, ""]);
    };

    const handleDelete = (index) => {
        if (Dropmap.length > 1) {
            const remover = Dropmap.filter((item, i) => i != index)
            setDropmap(remover)
        }
    };

    //checkboxes
    const handleCheckboxChange0 = (event) => {
        setLabourCrew(event.target.checked);
    };
    // const handleCheckboxChange = (event) => {
    //     setMaterial(event.target.checked);
    // };
    // const handleCheckboxChange2 = (event) => {
    //     setEquipment(event.target.checked);
    // };
    // const handleCheckboxChange3 = (event) => {
    //     setSub(event.target.checked);
    // };

    //checkboxes
    const handleAssignIndividualChange = () => {
        setCrew(false) // crew case
        setAssignIndividual(true);
        setAssignCrew(false);
    };

    // const handleAssignCrewChange = () => {
    //     setCrew(true) // crew case
    //     setAssignCrew(true);
    //     setAssignIndividual(false);
    // };

    // hours deals
    const handleHoursChange = (index, value) => {
        const newHoursArray = [...hoursArray];
        newHoursArray[index] = value;
        setHoursArray(newHoursArray);

        const newDropmap = [...Dropmap];
        newDropmap[index].hours = value;
        const totalHours = newDropmap.reduce((sum, item) => sum + Number(item.hours), 0);
        if (totalHours > budgetedhours) {
            enqueueSnackbar("Total hours exceeds the budgeted hours! Do you want to add more ", { variant: 'error' });
        }
        setDropmap(newDropmap);
    };


    // employee selceted error
    const onTypeChange1 = (index, val) => {
        const updatedTypes = [...activeTypes1];
        updatedTypes[index] = val;
        setActiveTypes1(updatedTypes);

        const updatedDropmap = [...Dropmap];
        const existingIds = updatedDropmap.map(item => item.employee_id);

        if (existingIds.includes(val)) {
            enqueueSnackbar("Employee is already selected. Please select a different employee.", { variant: 'error' });
            return;
        }

        updatedDropmap[index] = {
            ...updatedDropmap[index],
            employee_id: val.toString()
        };
        setDropmap(updatedDropmap);
    };


    const onTypeChange = (index, val) => {
        const updatedTypes = [...activeTypes];
        updatedTypes[index] = val;
        setActiveTypes(updatedTypes);

        const updatedDropmap = [...Dropmap];
        updatedDropmap[index] = {
            ...updatedDropmap[index],
            project_trade_work_title_id: val.toString()
        };
        setDropmap(updatedDropmap);
    };

    // const onTypeChange2 = (val) => {
    //     setActiveType2(val);
    // };
    // const onTypeChange3 = (val) => {
    //     setActiveType3(val);
    // };
    // const onTypeChange4 = (val) => {
    //     setActiveType4(val);
    // };
    // crew case
    const onTypeChange5 = (val) => {
        setActiveType5(val);
    };


    console.log(Dropmap, "=========>")
    console.log(trades, "employ")
    // const formatChanger = (currentDate) => {
    //     const year = currentDate.getFullYear();
    //     const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    //     const day = String(currentDate.getDate()).padStart(2, "0");
    //     const formattedDate = `${year}-${month}-${day}`;
    //     return formattedDate;
    // };

    const parsedStartDate = new Date(startdate);

    return (
        <>
            <Grid container spacing={1} lg={12} alignItems="center" justifyContent="start">
                <Grid item xs={12} sm={4} md={3} lg={4} mr={1.5}>
                    <h2>Assign Individual <input type="checkbox" checked={assignIndividual} onChange={handleAssignIndividualChange} /> </h2>
                </Grid>
                {/* <Grid item xs={12} sm={4} md={3} lg={3}>
                    <h2>Assign Crew <input type="checkbox" checked={assignCrew} onChange={handleAssignCrewChange} /> </h2>
                </Grid> */}
            </Grid>
            <Grid spacing={1} alignItems="center" justifyContent="start" display="flex" >
                <Grid item xs={12} sm={4} md={3} lg={12}>
                    <h3>
                        Assign Labor <input type="checkbox" checked={isLabourCrew} onChange={handleCheckboxChange0} />
                    </h3>
                </Grid>
            </Grid>
            <br />
            <br />
            {Dropmap?.map((item, index) => (
                <Grid container spacing={2} alignItems="center" justifyContent="start" mt={0.5} key={index}>
                    {isLabourCrew && (
                        <>
                            {Crew ? (
                                <Grid item xs={12} sm={4} md={3} lg={3}>
                                    {/* <label>Crew *</label>
                                    <Select
                                        style={{ width: "100%" }}
                                        value={activeType5}
                                        onChange={(event) => onTypeChange5(event.target.value)}
                                    >
                                        <MenuItem value="Select Crew" disabled={activeType5 === 'Crew'}>Select Crew </MenuItem>
                                        <MenuItem value="none" disabled={activeType5 === 'none'}>none</MenuItem>
                                        <MenuItem value="Cr" disabled={activeType5 === 'Cr'}>Crew 1</MenuItem>
                                    </Select> */}
                                </Grid>
                            ) : (

                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <label>Employee *</label>
                                    <Select
                                        key={index}
                                        style={{ width: "100%" }}
                                        defaultValue={item.employee_id}
                                        onChange={(event) => onTypeChange1(index, event.target.value)}
                                    >
                                        {Employ.map((emp, empIndex) => (
                                            <MenuItem key={empIndex} value={emp.id}>
                                                {emp.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            )}

                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <label>Trade / Worker Title *</label>
                                <Select
                                    key={index}
                                    style={{ width: "100%" }}
                                    defaultValue={item.project_trade_work_title_id}
                                    onChange={(event) => onTypeChange(index, event.target.value)}
                                >
                                    {trades.map((option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} lg={2}>
                                <label>No of Hours*</label>
                                <TextField
                                    id={`outlined-basic-${index}`}
                                    type="number"
                                    variant="outlined"
                                    style={{ width: "180px" }}
                                    value={item.hours}
                                    onChange={(e) => handleHoursChange(index, e.target.value)} // update state on change
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={3} lg={2} justifyContent="end" >
                                <label>Date*</label>
                                <DatePicker
                                    // label="Date *"
                                    defaultValue={parsedStartDate}
                                    // onChange={handleStartChange}

                                    sx={{ width: "100%", marginLeft: "13px", }}
                                    slotProps={{
                                        textField: {
                                            fullWidth: true,
                                        },
                                    }}
                                />

                            </Grid>
                            <Grid item xs={12} sm={4} md={3} lg={4} alignItems="center" justifyContent="space-evenly" display="flex" mt={2.0} >
                                <Button variant="contained" onClick={handleAddMore} color="success" sx={{
                                    paddingLeft: "30px",
                                    paddingRight: "30px",
                                    fontWeight: "500",
                                    borderRadius: "4px",
                                }} >+ Add More</Button>

                                {Dropmap.length < 2 ? null : (
                                    <Button variant="contained" onClick={() => handleDelete(index)} sx={{
                                        paddingLeft: "30px",
                                        paddingRight: "30px",
                                        fontWeight: "500",
                                        backgroundColor: "#FF5370",
                                        borderRadius: "4px",
                                    }} >- Remove </Button>
                                )}
                            </Grid>
                        </>
                    )}
                </Grid>
            ))}
            <br />
            <Grid item xs={12} sm={4} md={3} lg={12} >
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    onClick={handleSave} color="success"
                    sx={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        fontWeight: "400",
                        borderRadius: "4px",
                        float: "right",
                        marginRight: "30px"
                    }} >Submit</LoadingButton>
            </Grid>

            <br />

            {/* <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid container spacing={1} alignItems="center" justifyContent="start" ml={24.0} >
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <h3>Assign Material <input type="checkbox" onChange={handleCheckboxChange} /> </h3>
                    </Grid>
                </Grid>
                {isMaterial && (
                    <>
                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <label>Material *</label>
                            <Select
                                style={{ width: "100%" }}
                                value={activeType2}
                                onChange={(event) => onTypeChange2(event.target.value)}
                            >
                                <MenuItem value="Material" disabled={activeType2 === 'Material'}>Select Material </MenuItem>
                                <MenuItem value="none" disabled={activeType2 === 'none'}>none</MenuItem>
                                <MenuItem value="Temporary" disabled={activeType2 === 'Temporary'}>Temporary Repairs</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item xs={12} sm={4} md={3} lg={3}>
                            <label>No of Hours* </label>
                            <TextField id="outlined-basic" type='number' variant="outlined" style={{ width: "250px" }} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3} lg={2}>

                        </Grid>
                    </>
                )}
            </Grid>
            <br />

            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid container spacing={1} alignItems="center" justifyContent="start" ml={24.0} >
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                        <h3>Assign Equipment  <input type="checkbox" onChange={handleCheckboxChange2} />  </h3>
                    </Grid>
                </Grid>
                {isEquipment && (<>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <label>Equipment *</label>
                        <Select
                            style={{ width: "100%" }}
                            value={activeType3}
                            onChange={(event) => onTypeChange3(event.target.value)}
                        >
                            <MenuItem value="Equipment" disabled={activeType3 === 'Equipment'}>Select Equipment </MenuItem>
                            <MenuItem value="none" disabled={activeType3 === 'none'}>none</MenuItem>
                            <MenuItem value="Equipment " disabled={activeType3 === 'Equipment '}>Equipment 1</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <label>No of Hours* </label>
                        <TextField id="outlined-basic" type='number' variant="outlined" style={{ width: "250px" }} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                    </Grid>
                </>)}
            </Grid>
            <br />

            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid container spacing={1} alignItems="center" justifyContent="start" ml={24.0} >
                    <Grid item xs={12} sm={4} md={3} lg={6}>
                        <h3>Assign Subcontractor  <input type="checkbox" onChange={handleCheckboxChange3} /> </h3>
                    </Grid>
                </Grid>
                {isSub && (<>
                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <label>Subcontractor *</label>
                        <Select
                            style={{ width: "100%" }}
                            value={activeType4}
                            onChange={(event) => onTypeChange4(event.target.value)}
                        >
                            <MenuItem value="Subcontractor" disabled={activeType4 === 'Subcontractor'}>Select Subcontractor </MenuItem>
                            <MenuItem value="none" disabled={activeType4 === 'none'}>none</MenuItem>
                            <MenuItem value="Temporary" disabled={activeType4 === 'Temporary'}>Subcontractor 1</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={3}>
                        <label>No of Hours* </label>
                        <TextField id="outlined-basic" type="number" variant="outlined" style={{ width: "250px" }} />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                    </Grid>

                </>)}
            </Grid>
            <br /> */}

        </>
    );
}
