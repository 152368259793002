import { useFormContext, Controller } from 'react-hook-form';
import {
    Box,
    OutlinedInput,
    Select,
    MenuItem,
    Checkbox,
    InputLabel,
    FormControl,
} from '@mui/material';

const MultipleRiskSelector = ({ name, label, options, onMemberSelect }) => {
    const { control, setValue } = useFormContext();

    const handleOwnerChange = (event, selectedIds) => {
        setValue(name, selectedIds);
        if (onMemberSelect) {
            onMemberSelect(selectedIds);
        }
    };

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>{label}</InputLabel>
                        <Select
                            {...field}
                            multiple
                            label={label}
                            input={<OutlinedInput label={label} />}
                            renderValue={(selected) => {
                                const selectedOptions = selected.map((id) => {
                                    const option = options.find((opt) => opt.id === id);
                                    return option ? option.risk_id : '';
                                });

                                if (selectedOptions.length > 2) {
                                    return `${selectedOptions.slice(0, 2).join(', ')}...`;
                                } else {
                                    return selectedOptions.join(', ');
                                }
                            }}
                            onChange={(event) => {
                                const selectedIds = event.target.value;
                                field.onChange(selectedIds);
                                handleOwnerChange(event, selectedIds);
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        maxWidth: 100,
                                        maxHeight: 300,
                                    },
                                },
                            }}
                        >
                            {options?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    <Checkbox
                                        disableRipple
                                        size="small"
                                        checked={field.value.includes(option.id)}
                                    />
                                    {option.risk_id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            />
        </Box>
    );
};

export default MultipleRiskSelector;
