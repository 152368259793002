// src/components/content/ComingSoon.js
import React from 'react';
import { Box, Card, Typography } from '@mui/material';

const SettingsInformation = () => {
  return (
    <Card
      sx={{ height: '100%', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '0px' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h3"
          component="h1"
          sx={{
            fontSize: '3rem',
            color: '#333',
          }}
        >
          Coming Soon
        </Typography>
      </Box>
    </Card>
  );
};

export default SettingsInformation;
