import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import { useResponsive } from 'src/hooks/use-responsive';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hooks';
import { NavSectionVertical } from 'src/components/nav-section';
import { NAV } from 'src/layouts/config-layout';
import { useRiskNavData } from './conifg-nav';
import DashboardSubHeader from '../risk-management/dashboard-subheader';
// ----------------------------------------------------------------------
export default function NavVertical({ openNav, onCloseNav }) {
    const { user } = useMockedUser();
    const pathname = usePathname();
    const lgUp = useResponsive('up', 'lg');
    const navData = useRiskNavData();

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <DashboardSubHeader  Title={"Risk Managemnt"} />
            <NavSectionVertical
                data={navData}
                config={{
                    currentRole: user?.role || 'admin',
                }}
            />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    );
    return (
        <Box
            component="nav"
            sx={{
                flexShrink: { lg: 0 },
                width: { lg: NAV.W_VERTICAL },
            }}
        >
            {/* <NavToggleButton /> */}
            {lgUp ? (
                <Stack
                    sx={{
                        height: 1,
                        position: 'fixed',
                        width: NAV.W_VERTICAL,
                        borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
                    }}
                >
                    {renderContent}
                </Stack>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    PaperProps={{
                        sx: {
                            width: NAV.W_VERTICAL,
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
NavVertical.propTypes = {
    onCloseNav: PropTypes.func,
    openNav: PropTypes.bool,
};