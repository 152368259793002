import React from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { useState } from 'react';
import FormProvider, { RHFAutocomplete, RHFTextField } from 'src/components/hook-form';

import { useForm, Controller } from 'react-hook-form';

import * as Yup from 'yup';
import { useMemo } from 'react';

import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import { Button } from '@mui/base';
import Iconify from 'src/components/iconify';

const CreateVendor = () => {
  const [isChecked, setIsChecked] = useState(false);

  const [is_Checked, setIs_Checked] = useState(false);

  const [isCheckedOne, setIsCheckedOne] = useState(false);

  const handleCheckboxChangeOne = (event) => {
    setIsCheckedOne(event.target.checked);
  };

  const handleToggle = (event) => {
    setIsCheckedOne(event.target.checked); // Update the state
  };

  const [riskIds, setRiskIds] = useState([]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckbox_Change = (event) => {
    setIs_Checked(event.target.checked);
  };

  const NewUserSchema = Yup.object().shape({
    incident_id: Yup.string().required('Incident Id is required'),
    incident_description: Yup.string().required('Incident Description is required'),
    date_and_time_of_incident: Yup.mixed().required('Date And Time Of Inciden is required'),
    impacted_areas: Yup.string().required('Impacted Areas is required'),
    immediate_actions_taken: Yup.string().required('Immediate Actions Taken is required'),
    incident_severity: Yup.string().required('Incident Severity is required'),
  });

  const defaultValues = useMemo(() => {
    const relatedRisk = { id: '', risk_id: 'None' };
    return {
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: relatedRisk,
    };
  }, [riskIds]);

  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // Reset form values when `currentItem` changes
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const values = watch();

  useEffect(() => {
    reset({
      incident_id: '',
      incident_description: '',
      date_and_time_of_incident: new Date(),
      impacted_areas: '',
      immediate_actions_taken: '',
      incident_severity: '',
      is_related: { id: '', risk_id: 'None' },
    });
  }, [reset]);

  const onSubmit = handleSubmit(async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
  });

  return (
    <>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <h2>Base Field</h2>
        <Box
          mt={2}
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Sr No <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="sr.no" label="Sr. No" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Type of Vendor <span style={{ color: 'red' }}>*</span>
            </h3>
            <select
              name="vendorType"
              defaultValue="vendor"
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '14px',
                color: '#333',
              }}
            >
              <option value="vendor">vendor 1</option>
              <option value="vendor">vendor 2</option>
              <option value="vendor">vendor 3</option>
              <option value="vendor">vendor 4</option>
            </select>
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              First Name <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="firstName" label="First Name" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Last Name <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="last" label="Last Name" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Title <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="title" label="Title" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Trade / Speciality <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="trade" label="Trade / Speciality" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Company Name <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="companyname" label="Company Name" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Address 1 <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="address 1" label="Address 1" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Address 2 <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="address 2" label="address 2" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              City <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="city" label="City" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              States <span style={{ color: 'red' }}>*</span>
            </h3>
            <select
              name="stateType"
              defaultValue=""
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '14px',
                color: '#333',
              }}
            >
              <option value="states"></option>
            </select>
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Zip Code <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="zipCode" label="Zip Code" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Country <span style={{ color: 'red' }}>*</span>
            </h3>
            <select
              name="countryType"
              defaultValue="United States"
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '14px',
                color: '#333',
              }}
            >
              <option value="United State"></option>
            </select>
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Counties Served <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="countiesserved" label="Counties Served" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Phone <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="phone" label="Phone" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Email <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="email" label="Email" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Fax <span style={{ color: 'red' }}>*</span>
            </h3>
            <RHFTextField name="fax" label="Fax" />
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Union / Non Union <span style={{ color: 'red' }}>*</span>
            </h3>
            <select
              name="unionType"
              defaultValue="United States"
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '14px',
                color: '#333',
              }}
            >
              <option value="United State">United State</option>
              <option value="United State">United State</option>
              <option value="United State">United State</option>
              <option value="United State">United State</option>
            </select>
          </div>

          <div>
            <h3
              style={{
                fontSize: '14px',
                fontWeight: 'normal',
                marginBottom: '8px',
                color: 'darkgrey',
              }}
            >
              Minority Contractor Type <span style={{ color: 'red' }}>*</span>
            </h3>
            <select
              name="unionType"
              defaultValue="Select Option"
              style={{
                width: '100%',
                height: '50px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '0 8px',
                fontSize: '14px',
                color: '#333',
              }}
            >
              <option value="Select Option">Select Option</option>
              <option value="Select Option">United States</option>
              <option value="Select Option">United States</option>
              <option value="Select Option">United States</option>
            </select>
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>
              <span style={{ color: 'darkgrey' }}>Signature</span>{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <div>
              <input
                type="file"
                className="form-control"
                required
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                  fontSize: '14px',
                  marginTop: '8px',
                  width: '500px',
                  height: '50px',
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: '10px' }}>
            <label>
              <span style={{ color: 'darkgrey' }}>Upload Signature</span>{' '}
              <span style={{ color: 'red' }}>*</span>
            </label>
            <div>
              <input
                type="file"
                className="form-control"
                required
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                  fontSize: '14px',
                  marginTop: '8px',
                  width: '500px',
                  height: '50px',
                }}
              />
            </div>
          </div>
        </Box>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            marginTop: '35px',
            maginLeft: '25px',
          }}
        >
          <input
            type="checkbox"
            style={{ width: '16px', height: '16px' }}
            onChange={handleCheckboxChange}
            checked={isChecked}
          />
          <span
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Additional Information
          </span>
        </div>

        {isChecked && (
          <div>
            <div>
              <Box
                mt={2}
                rowGap={2}
                columnGap={3}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Naics Code <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="naicscode" label="Naics Code" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Description <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="description" label="Description" />
                </div>
                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Classification <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="classification" label="Classification" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Specific Function <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="specificfunction" label="Specific Function" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Website <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="website" label="Website" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Social Media <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="socialmedia" label="Social Media" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Business Location <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="businesslocation" label="Business Location" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    No. of Years of Experiences <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="businesslocation" label="Business Location" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    License <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="license" label="License" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Installer Certificates <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="installercertificates" label="Installer Certificates" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Insurance <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="insurance" label="Insurance" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    DNB Number <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="dnbnumber" label="DNB Number" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Blockchain ID <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="blockchainid" label="Blockchain ID" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Agencies Worked with <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="agenciesworkedwith" label="Agencies Worked With" />
                </div>

                <div>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 'normal',
                      marginBottom: '8px',
                      color: 'darkgrey',
                    }}
                  >
                    Area of Experties <span style={{ color: 'red' }}>*</span>
                  </h3>
                  <RHFTextField name="areaofexperties" label="Area of Expertise" />
                </div>
              </Box>
            </div>

            <div style={{ marginTop: '40px' }}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                  }}
                >
                  <Switch checked={isCheckedOne} color="primary" onChange={handleToggle} />
                  <h4 style={{ margin: 0 }}>ADD EXPERIENCES</h4>
                </div>

                {isCheckedOne && (
                  <div style={{ marginTop: '10px' }}>
                    <div>
                      <Box
                        mt={2}
                        rowGap={2}
                        columnGap={3}
                        display="grid"
                        gridTemplateColumns={{
                          xs: 'repeat(1, 1fr)',
                          sm: 'repeat(2, 1fr)',
                        }}
                      >
                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Contract Number<span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="naicscode" label="Contract Number" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Name  of Contract <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="description" label="Name  of Contract" />
                        </div>
                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Name  of Agency <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="classification" label="Name  of Agency" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Name  of Person to Contact  <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="specificfunction" label="Name  of Person to Contact" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Title of person <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="website" label="Title of person" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Agency Phone Number <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="socialmedia" label="Agency Phone Number" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Agency Contact Email <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="businesslocation" label="Agency  Contact Email" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Value of Conctract <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="businesslocation" label="Value of Conctract" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Date of Completion <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="license" label="Date of Completion" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Performed As <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="installercertificates" label="Performed As" />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Scope of work  <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="insurance" label="Scope of work " />
                        </div>

                        <div>
                          <h3
                            style={{
                              fontSize: '14px',
                              fontWeight: 'normal',
                              marginBottom: '8px',
                              color: 'darkgrey',
                            }}
                          >
                            Sample of Work performance <span style={{ color: 'red' }}>*</span>
                          </h3>
                          <RHFTextField name="dnbnumber" label="Sample of Work performance" />
                        </div>
                     
                      </Box>
                    </div>         
                      
                             </div>
                )}
              </div>
            </div>
        
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginTop: '35px',
                maginLeft: '25px',
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                SHIPPING INFORMATION
              </span>
              <input
                type="checkbox"
                style={{ width: '16px', height: '16px' }}
                onChange={handleCheckbox_Change}
                checked={is_Checked}
              />
           
            </div>

            {is_Checked && (
              <div style={{ marginTop: '10px' }}>
                <div>
                  <Box
                    mt={2}
                    rowGap={2}
                    columnGap={3}
                    display="grid"
                    gridTemplateColumns={{
                      xs: 'repeat(1, 1fr)',
                      sm: 'repeat(2, 1fr)',
                    }}
                  >
                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                        Adress<span style={{ color: 'red' }}>*</span>
                      </h3>
                      <RHFTextField name="naicscode" label="Adress" />
                    </div>

                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                        Suit <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <RHFTextField name="description" label="Suit" />
                    </div>
                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                       Country <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <select
                        name="vendorType"
                        defaultValue="Country"
                        style={{
                          width: '100%',
                          height: '50px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '0 8px',
                          fontSize: '14px',
                          color: '#333',
                        }}
                      >
                        <option value="Country">Country 1</option>
                        <option value="Country">Country 2</option>
                        <option value="Country">Country 3</option>
                        <option value="Country">Country 4</option>
                      </select>
                    </div>
                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                       State <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <select
                        name="vendorType"
                        defaultValue="State"
                        style={{
                          width: '100%',
                          height: '50px',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          padding: '0 8px',
                          fontSize: '14px',
                          color: '#333',
                        }}
                      >
                        <option value="State">State 1</option>
                        <option value="State">State 2</option>
                        <option value="State">State 3</option>
                        <option value="State">State 4</option>
                      </select>
                    </div>

                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                        Country  <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <RHFTextField name="specificfunction" label="Country" />
                    </div>

                    <div>
                      <h3
                        style={{
                          fontSize: '14px',
                          fontWeight: 'normal',
                          marginBottom: '8px',
                          color: 'darkgrey',
                        }}
                      >
                        Zip Code <span style={{ color: 'red' }}>*</span>
                      </h3>
                      <RHFTextField name="website" label="Zip Code" />
                    </div>

         
               
                  </Box>
                </div>

              </div>
            )}
          </div>
        )}
      </FormProvider>
    </>
  );
};

export default CreateVendor;
