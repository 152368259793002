import React from 'react';
import {Grid, Typography, Button, Box } from '@mui/material';
import "./accounts-dashboard.css"

import { useNavigate } from 'react-router';

export default function HeadingCard() {
  const navigate=useNavigate()
  return (
   
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6"  className="heading1">Welcome Back !</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }} >
          <Button variant="contained" onClick={() => navigate('/accounts-management/risk-register')} className='accountsbutton' >
	      + Accounting  
          </Button>
            </Box>
          </Grid>
        </Grid>
    
  );
}
