import React from 'react';
import { Page, Text, View, Document, Image, PDFDownloadLink, Font } from '@react-pdf/renderer';
import head from "../../../assets/pdf/hedaerdyynamic.png";
import footer from "../../../assets/pdf/footer4x.png";
import watermark from "../../../assets/pdf/watermark.png";
import styles from './tablecss';
Font.register({
    family: 'Montserrat',
    fonts: [
        {
            src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf', // Montserrat Regular
        },
        {
            src: 'https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyzbi.woff2', // Montserrat Bold
            fontWeight: 'bold',
        },
    ],
});
const ROWS_PER_PAGE = 10; 
const MIN_ROWS_PER_PAGE = 7; 
const paginateData = (data, rowsPerPage, minRowsPerPage) => {
    const pages = [];
    let page = [];
    for (let i = 0; i < data.length; i++) {
        page.push(data[i]);
        if (page.length === rowsPerPage || i === data.length - 1) {
            if (page.length < minRowsPerPage && i !== data.length - 1) {
                const carryOverRows = page.splice(0);
                if (pages.length > 0) {
                    pages[pages.length - 1].push(...carryOverRows);
                } else {
                    pages.push(carryOverRows);
                }
            } else {
                pages.push(page);
            }
            page = [];
        }
    }
    return pages;
};

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
};

const MyDocument = ({ tableData, profile, projectname, riskLabels }) => {
    const pages = paginateData(tableData, ROWS_PER_PAGE, MIN_ROWS_PER_PAGE);
    const totalPages = pages.length;

    const visibleColumns = riskLabels
        .filter((label) => !label.value)
        .slice(0, 13);

    let visibleColumnCount = 0;
    const isColumnVisible = (columnKey) => {
        const label = riskLabels.find((label) => label.key === columnKey);
        const isVisible = label ? !label.value : true; 
        if (isVisible && visibleColumnCount < 18) {
            visibleColumnCount++;
            return true;
        }
        return false;
    };
    const formatLabel = (label) => {
        return label.split(' ').map((word, index) => (
            <Text key={index} style={styles.headerTextLine}>
                {word}
            </Text>
        ));
    };
    return (
        <Document>
            {pages?.length === 0 ? (
                <Page size="A4" style={styles.page} orientation="landscape">
                    <Image style={styles.headerImage} src={head} fixed />
                    <Image src={watermark} style={styles.watermark} fixed />
                    <View style={styles.logoContainer}>
                        <Image src={profile?.avatar} style={styles.logo} />
                        <View style={styles.companyDetails}>
                            <Text style={styles.companyName}>{profile?.company_name || ''}</Text>
                            <Text style={styles.companyAddress}>{`${profile?.city || ''}, ${profile?.address || ''},${profile?.state.name || ''},${profile?.country.name || ''},${profile?.zip || ''}`}</Text>
                        </View>
                    </View>
                    <View style={{ marginTop: 20, marginBottom: 10, marginRight: 40, paddingHorizontal: 30 }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{ fontSize: 10, fontWeight: 'bold', color: "#2E2D2D" }}>
                                Project Name: &nbsp;&nbsp; {projectname?.project.name}
                            </Text>
                            <Text style={{ fontSize: 10, fontWeight: 'bold', color: "#2E2D2D" }}>
                                Contract/Project Number: &nbsp;&nbsp; {projectname?.project.contract_number || ''}
                            </Text>
                        </View>
                    </View>
                    {/* Table Header */}
                    <View style={{ padding: "20px 20px", display: "table", width: "100%" }}>
                        <View style={[styles.tableRow, styles.tableHeader]}>
                            <Text style={styles.tableheadCell}>Risk ID</Text>
                            <Text style={styles.tableheadCell}>Risk Title</Text>
                            <Text style={styles.tableheadCell}>Project Stage</Text>
                            <Text style={styles.tableheadCell}>Risk Category</Text>
                            <Text style={styles.tableheadCell}>Description</Text>
                        </View>
                        {/* No Data Message */}
                        <View style={{ marginTop: 20 }}>
                            <Text style={{ textAlign: 'center', fontSize: 14, color: '#999' }}>No Data Available.</Text>
                        </View>
                    </View>
                    {/* Footer */}
                    <Image style={styles.footerImage} src={footer} fixed />
                    <Text style={styles.footerPageNumber}>01/01</Text>
                </Page>
            ) : (
                pages?.map((pageData, pageIndex) => (
                    <Page key={pageIndex} size="A4" style={styles.page} orientation="landscape">
                        <Image style={styles.headerImage} src={head} fixed />
                        <Image src={watermark} style={styles.watermark} fixed />
                        <View style={styles.logoContainer}>
                            <Image src={profile?.avatar} style={styles.logo} />
                            <View style={styles.companyDetails}>
                                <Text style={styles.companyName}>{profile?.company_name || ''}</Text>
                                <Text style={styles.companyAddress}>{`${profile?.city || ''}, ${profile?.address || ''},${profile?.state.name || ''},${profile?.country.name || ''},${profile?.zip || ''}`}</Text>
                            </View>
                        </View>
                        {pageIndex === 0 && (
                            <View style={{ marginTop: 20, marginBottom: 10, marginRight: 40, paddingHorizontal: 30 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={{ fontSize: 10, fontWeight: 'bold', color: "#2E2D2D" }}>
                                        Project Name: &nbsp;&nbsp; {projectname?.project.name}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: 'bold', color: "#2E2D2D" }}>
                                        Contract/Project Number: &nbsp;&nbsp; {projectname?.project.contract_number || ''}
                                    </Text>
                                </View>
                            </View>
                        )}
                        <View style={{ padding: "0px 30px", display: "table", width: "100%" }}>
                            {/* Table Header */}
                            <View style={[styles.tableRow, styles.tableHeader]}>
                                <Text style={styles.tableheadCell}>Risk ID</Text>
                                <Text style={styles.tableheadCell}>Risk Title</Text>
                                <Text style={styles.tableheadCell}>Project Stage</Text>
                                <Text style={styles.tableheadCell}>{`Risk\nCategory`}</Text>
                                <Text style={styles.tableheadCell}>Description</Text>
                                {visibleColumns.map((column) => (
                                    <View key={column.key} style={styles.tableheadCell}>
                                        {formatLabel(column.name)}
                                    </View>
                                ))}
                            </View>
                            {/* Conditionally render table rows */}
                            {pageData.map((row, rowIndex) => {
                                visibleColumnCount = 0;
                                return (
                                    <View key={rowIndex} style={styles.tableRow}>
                                        {console.log("row==", rowIndex, row)}
                                        {console.log("Pagedata====== ", pageIndex, pageData, rowIndex)}
                                        {isColumnVisible('risk_id') && <Text key={`risk_id`} style={styles.tableCell}>{row.risk_id}</Text>}
                                        {isColumnVisible('risk_title') && <Text style={styles.tableCell}>{row.title}</Text>}
                                        {isColumnVisible('project_stage') && <Text style={styles.tableCell}>{row.project_stage?.name}</Text>}
                                        {isColumnVisible('risk_category') && <Text style={styles.tableCell}>{row.risk_category?.name}</Text>}
                                        {isColumnVisible('description') && <Text style={styles.tableCell}>{row.description}</Text>}
                                        {isColumnVisible('base_case') && <Text style={styles.tableCell}>{row.base_case}</Text>}
                                        {isColumnVisible('exposure') && <Text style={styles.tableCell}>{row.exposure}</Text>}
                                        {isColumnVisible('date_identified') && <Text style={styles.tableCell}>{formatDate(row.date_identified)}</Text>}
                                        {isColumnVisible('identified_by') && <Text style={styles.tableCell}>{row.identified_by}</Text>}
                                        {isColumnVisible('risk_owner') && <Text style={styles.tableCell}>{row.risk_owner?.name}</Text>}
                                        {isColumnVisible('attachment') && <Text style={styles.tableCell}>{row?.file?.name}</Text>}
                                        {isColumnVisible('qualitative_analysis') && <Text style={styles.tableCell}>{row?.risk_assessment?.qualitative_analysis}</Text>}
                                        {isColumnVisible('threat_or_opportunity') && <Text style={styles.tableCell}>{row?.risk_assessment?.threat_opportunity?.name}</Text>}
                                        {isColumnVisible('risk_area') && <Text style={styles.tableCell}>{row?.risk_assessment?.risk_area?.name}</Text>}
                                        {isColumnVisible('probability_of_occurrence') && <Text style={styles.tableCell}>{row?.risk_assessment?.probability_of_occurrence}</Text>}
                                        {isColumnVisible('impact_on_cost') && <Text style={styles.tableCell}>{row?.risk_assessment?.impact_on_cost}</Text>}
                                        {isColumnVisible('impact_on_schedule') && <Text style={styles.tableCell}>{row?.risk_assessment?.impact_on_schedule}</Text>}
                                        {isColumnVisible('risk_rating') && <Text style={styles.tableCell}>{row?.risk_assessment?.risk_rating}</Text>}
                                        {isColumnVisible('expected_loss') && <Text style={styles.tableCell}>{row?.risk_assessment?.expected_loss}</Text>}
                                        {isColumnVisible('full_probable_loss') && <Text style={styles.tableCell}>{row?.risk_assessment?.full_probable_loss}</Text>}
                                        {isColumnVisible('severity_rating') && <Text style={styles.tableCell}>{row?.risk_analysis?.severity_rating?.score}</Text>}
                                        {isColumnVisible('impact_level_pre_mitigation') && <Text style={styles.tableCell}>{row?.risk_analysis?.impact_level_pre_mitigation}</Text>}
                                        {isColumnVisible('likelihood_pre_mitigation') && <Text style={styles.tableCell}>{row?.risk_analysis?.likelihood_pre_mitigation}</Text>}
                                        {isColumnVisible('severity_pre_mitigation_updated') && <Text style={styles.tableCell}>{row?.risk_analysis?.severity_pre_mitigation}</Text>}
                                        {isColumnVisible('priority') && <Text style={styles.tableCell}>{row?.risk_analysis?.priority}</Text>}
                                        {isColumnVisible('severity_post_mitigation') && <Text style={styles.tableCell}>{row?.risk_analysis?.severity_post_mitigation}</Text>}
                                        {isColumnVisible('likelihood_post_mitigation') && <Text style={styles.tableCell}>{row?.risk_analysis?.likelihood_post_mitigation}</Text>}
                                        {isColumnVisible('related_task_ids') && <Text style={styles.tableCell}>{row?.risk_analysis?.related_task_ids}</Text>}
                                        {isColumnVisible('affected_areas') && <Text style={styles.tableCell}>{row?.risk_analysis?.affected_areas}</Text>}
                                        {isColumnVisible('risk_triggers') && <Text style={styles.tableCell}>{row?.risk_analysis?.risk_triggers}</Text>}
                                        {isColumnVisible('mitigation_strategy') && <Text style={styles.tableCell}>{row?.mitigation_plan?.mitigation_strategy}</Text>}
                                        {isColumnVisible('assigned_to') && <Text style={styles.tableCell}>{row?.mitigation_plan?.assigned_user?.name}</Text>}
                                        {isColumnVisible('budget_approval_status') && (
                                            <Text style={styles.tableCell}>
                                                {row?.mitigation_plan?.budget_approval_status === 1 ? 'YES' : 'NO'}
                                            </Text>
                                        )}
                                        {isColumnVisible('total_budgeted_amount') && <Text style={styles.tableCell}>{row?.mitigation_plan?.total_budgeted_amount}</Text>}
                                        {isColumnVisible('total_amount_spent') && <Text style={styles.tableCell}>{row?.mitigation_plan?.total_amount_spent}</Text>}
                                        {isColumnVisible('total_balance') && <Text style={styles.tableCell}>{row?.mitigation_plan?.total_balance}</Text>}
                                        {isColumnVisible('status') && <Text style={styles.tableCell}>{row?.mitigation_plan?.status}</Text>}
                                        {isColumnVisible('start_date') && <Text style={styles.tableCell}>{formatDate(row?.mitigation_plan?.start_date)}</Text>}
                                        {isColumnVisible('end_date') && <Text style={styles.tableCell}>{formatDate(row?.mitigation_plan?.end_date)}</Text>}
                                        {isColumnVisible('effectiveness_rating') && <Text style={styles.tableCell}>{row?.mitigation_plan?.effectiveness_rating}</Text>}
                                        {isColumnVisible('meeting_notes') && <Text style={styles.tableCell}>{row?.mitigation_plan?.meeting_notes}</Text>}
                                        {isColumnVisible('monitoring_activities') && <Text style={styles.tableCell}>{row?.monitor_and_track?.monitoring_activity}</Text>}
                                        {isColumnVisible('review_frequency') && <Text style={styles.tableCell}>{row?.monitor_and_track?.review_frequency}</Text>}
                                        {isColumnVisible('last_review_date') && <Text style={styles.tableCell}>{formatDate(row?.monitor_and_track?.last_review_date)}</Text>}
                                        {isColumnVisible('next_review_date') && <Text style={styles.tableCell}>{formatDate(row?.monitor_and_track?.next_review_date)}</Text>}
                                        {isColumnVisible('monitoring_notes') && <Text style={styles.tableCell}>{row?.monitor_and_track?.monitoring_notes}</Text>}
                                        {isColumnVisible('review_notes') && <Text style={styles.tableCell}>{row?.monitor_and_track?.review_notes}</Text>}
                                        {isColumnVisible('current_status') && <Text style={styles.tableCell}>{row?.monitor_and_track?.status}</Text>}

                                    </View>
                                );
                            })}
                        </View>
                        {/* Footer */}
                        <Image style={styles.footerImage} src={footer} fixed />
                        <Text style={styles.footerPageNumber}>
                            {String(pageIndex + 1).padStart(2, '0')}/{String(totalPages).padStart(2, '0')}
                        </Text>
                    </Page>
                ))
            )}
        </Document>
    );
};
const PdfTable = ({ tableData, profile, projectname, riskLabels }) => (
    <div>
        <PDFDownloadLink
            document={<MyDocument tableData={tableData} profile={profile} projectname={projectname} riskLabels={riskLabels} />}
            fileName="risk_register_complete.pdf"
            style={{
                padding: '10px 0px',
                textDecoration: 'none',
                color: '#212b36',
                textAlign: 'center',
            }}
        >
            {({ loading }) => (loading ? '...' : 'PDF')}
        </PDFDownloadLink>
    </div>
);
export default PdfTable;