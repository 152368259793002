import React from 'react';
import { Grid } from '@mui/material';
import RisksByStage from './riskbystage';
import RiskTrendChart from 'src/components/risk-management/BarChart/trend-chart';

export default function BarChartStageGrapgh({data}) {
  return (
    <Grid container spacing={2} pt={6}>
      <Grid
        item
        sm={12}
        md={6}
        lg={6}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <RisksByStage
          data={data}
          title="Risk By Stage"
          sx={{ flexGrow: 1 }} 
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <RiskTrendChart
          data={data}
          title="Risk Trend"
          sx={{ flexGrow: 1 }} 
        />
      </Grid>
    </Grid>
  );
}