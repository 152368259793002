// exportCsvData.js

export const getCsvData = (tableData={}, riskLabels, isColumnVisible) => {
    return tableData?.map((row) => {
        const filteredRow = {};
        const fields = {
            risk_id: row?.risk_id,
            risk_title: row?.title,
            project_stage: row?.project_stage?.name,
            risk_category: row?.risk_category?.name,
            description: row?.description,
            base_case: row?.base_case,
            exposure: row?.exposure,
            date_identified: row?.date_identified,
            identified_by: row?.identified_by,
            risk_owner: row?.risk_owner?.name,
            attachment: row?.file?.name,
            qualitative_analysis: row?.risk_assessment?.qualitative_analysis,
            threat_or_opportunity: row?.risk_assessment?.threat_opportunity?.name,
            risk_area: row?.risk_assessment?.risk_area?.name,
            probability_of_occurrence: row?.risk_assessment?.probability_of_occurrence,
            impact_on_cost: row?.risk_assessment?.impact_on_cost,
            impact_on_schedule: row?.risk_assessment?.impact_on_schedule,
            risk_rating: row?.risk_assessment?.risk_rating,
            expected_loss: row?.risk_assessment?.expected_loss,
            full_probable_loss: row?.risk_assessment?.full_probable_loss,
            severity_rating: row?.risk_analysis?.severity_rating?.score,
            impact_level_pre_mitigation: row?.risk_analysis?.impact_level_pre_mitigation,
            likelihood_pre_mitigation: row?.risk_analysis?.likelihood_pre_mitigation,
            severity_pre_mitigation_updated: row?.risk_analysis?.severity_pre_mitigation,
            priority: row?.risk_analysis?.priority,
            severity_post_mitigation: row?.risk_analysis?.severity_post_mitigation,
            likelihood_post_mitigation: row?.risk_analysis?.likelihood_post_mitigation,
            related_task_ids: row?.risk_analysis?.related_task_ids,
            affected_areas: row?.risk_analysis?.affected_areas,
            risk_triggers: row?.risk_analysis?.risk_triggers,
            assigned_to: row?.mitigation_plan?.assigned_user?.name,
            budget_approval_status: row?.mitigation_plan?.budget_approval_status ? "Yes" : "No",
            total_budgeted_amount: row?.mitigation_plan?.total_budgeted_amount,
            total_amount_spent: row?.mitigation_plan?.total_amount_spent,
            total_balance: row?.mitigation_plan?.total_balance,
            start_date: row?.mitigation_plan?.start_date,
            end_date: row?.mitigation_plan?.end_date,
            status: row?.mitigation_plan?.status,
            effectiveness_rating: row?.mitigation_plan?.effectiveness_rating,
            meeting_notes: row?.mitigation_plan?.meeting_notes,
            review_frequency: row?.monitor_and_track?.review_frequency,
            last_review_date: row?.monitor_and_track?.last_review_date,
            next_review_date: row?.monitor_and_track?.next_review_date,
            monitoring_notes: row?.monitor_and_track?.monitoring_notes,
            review_notes: row?.monitor_and_track?.review_notes,
            monitor_and_track_status: row?.monitor_and_track?.status,
            current_status: row?.monitor_and_track?.status
        };

        Object.keys(fields).forEach((key) => {
            if (isColumnVisible(key) && key !== 'actions') {
                filteredRow[key] = fields[key];
            }
        });
        return filteredRow;
    });
};
