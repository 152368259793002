import { Box, Card, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify';
import RenderField from './render-field';
import './style.css';

import { useBoolean } from 'src/hooks/use-boolean';
import MonitorCreates from './risk-monitor-create';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

const MonitoringTracking = ({ data, reload }) => {
  console.log("🚀 ~ MonitoringTracking ~ data:", data)
  const add = useBoolean()
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Card sx={{ marginTop: "20px", marginBottom: "20px" }}>
      <Box display="flex" justifyContent="space-between">
        <Box sx={{ position: 'relative' }}>
          <Typography variant="h4" class="pull-left mt-2 ribbon ribbon-bookmark ribbon-success">
            Monitoring and Tracking
          </Typography>
        </Box>
        <Box
          sx={{ marginRight: '20px', marginTop: '22px' }}
        >
          <Tooltip title="Edit">
            <IconButton onClick={add.onTrue}>
              <Iconify icon="solar:pen-bold" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box
        p={4}
        mt={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Risk ID" value={data?.risk_id} />
        <RenderField label="Monitoring Activities" value={data?.monitoring_activity} />
        <RenderField label="Review Frequencey" value={data?.review_frequency} />

      </Box>
      <Divider />
      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Last Review Date" value={formatDate(data?.last_review_date)} />
        <RenderField label="Next Review Date" value={formatDate(data?.next_review_date)} />
        <RenderField label="Monitoring Notes" value={data?.monitoring_notes} />

      </Box>
      <Divider />

      <Box
        p={4}
        rowGap={3}
        columnGap={20}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(3, 1fr)',
        }}
      >
        <RenderField label="Review Notes" value={data?.review_notes} />
        <RenderField label="Current Status" value={data?.status} />
      </Box>

      <MonitorCreates
        data={data}
        open={add.value}
        onClose={add.onFalse}
        reload={reload}
      />
    </Card>
  );
};
export default MonitoringTracking;