import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { useSnackbar } from 'src/components/snackbar';
import { DatePicker } from '@mui/x-date-pickers';
import FormProvider, {
  RHFAutocomplete,
  RHFTextField,
} from 'src/components/hook-form';
import { getRequest, postRequest } from 'src/services';
import { endpoints } from 'src/utils/axios';
import {
  handleErrorMessages,
} from '../../risk-register/feching';
import RiskprojectUserAdd from 'src/components/risk-project-user-drop/project-user-drop';
import MultipleRiskSelector from './multiple-risk-selector';
import Iconify from 'src/components/iconify';

const RiskQuaterlyreviewCreate = ({
  open,
  onClose,
  reload,
  currentItem,
  qauaterlyReviewId,
  handleCloseForm,
  riskOwnersOptions,
  reloadRiskOwnersOptions,
  closeform,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [riskIds, setRiskIds] = useState([]);
  const [isFetchingRisks, setIsFetchingRisks] = useState(true);
  const [selectedRiskOwnerId, setSelectedRiskOwnerId] = useState(null);

  // Fetch risk IDs on component mount
  useEffect(() => {
    const fetchIdentification = async () => {
      try {
        const data = await getRequest(
          endpoints.risk_management.risk_register.identification.identification
        );
        setRiskIds(data || []);
      } catch (err) {
        console.error('Error fetching risk IDs:', err);
        enqueueSnackbar({
          variant: 'error',
          message: 'Failed to fetch risk IDs.',
        });
      } finally {
        setIsFetchingRisks(false);
      }
    };
    fetchIdentification();
  }, [enqueueSnackbar]);

  const statusOptions = [
    { label: 'Q1', value: 'Q1' },
    { label: 'Q2', value: 'Q2' },
    { label: 'Q3', value: 'Q3' },
    { label: 'Q4', value: 'Q4' },
  ];

  const defaultValues = useMemo(
    () => ({
      review_id: currentItem?.review_id || qauaterlyReviewId || '',
      review_period: currentItem
        ? statusOptions.find((option) => option.value === currentItem.review_period) || ''
        : '',
      review_date: currentItem?.review_date ? new Date(currentItem.review_date) : null,
      risk_ids: currentItem?.risk_ids || [],
      review_summary: currentItem?.review_summary || '',
      recommendations: currentItem?.recommendations || '',
      attended_by: currentItem?.attended_by || '',
    }),
    [currentItem, qauaterlyReviewId]
  );

  const NewUserSchema = Yup.object().shape({
    review_id: Yup.string().required('Review ID is required'),
    // review_period: Yup.string().required('Review Period is required'),
    review_date: Yup.mixed().required('Date of Review is required'),
    risk_ids: Yup.array().min(1, 'Risks Reviewed is required'),
    review_summary: Yup.string().required('Review Summary is required'),
    recommendations: Yup.string().required('Recommendations are required'),
    // attended_by: Yup.string().required('Attended By is required'),
  });



  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
    mode: 'onBlur',
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = methods;

  useEffect(() => {
    if (!isFetchingRisks) {
      reset(defaultValues);
    }
  }, [currentItem, defaultValues, reset, isFetchingRisks]);

  const onMemberIdsChange = useCallback(
    (memberIds) => {
      setValue('risk_ids', memberIds);
    },
    [setValue]
  );

  // Handle form submission
  const onSubmit = handleSubmit(async (data) => {
    console.log('🚀 ~ onSubmit ~ data:', data);
    const formData = new FormData();
    const cleanReviewId = data.review_id.replace('-QRRF', '');
    formData.append('review_id', cleanReviewId);
    formData.append('review_period', data.review_period?.value || '');
    formData.append(
      'review_date',
      data.review_date ? data.review_date.toISOString().split('T')[0] : ''
    );
    if (Array.isArray(data.risk_ids)) {
      data.risk_ids.forEach((id) => {
        formData.append('risk_ids[]', id);
      });
    }
    formData.append('review_summary', data.review_summary);
    formData.append('recommendations', data.recommendations);
    formData.append('attended_by', selectedRiskOwnerId);

    try {
      const endpoint = currentItem?.id
        ? `${endpoints.risk_management.quaterly_review.crud}/${currentItem.id}?_method=PUT`
        : endpoints.risk_management.quaterly_review.crud;
      const response = await postRequest(endpoint, formData, true);
      if (response.metadata?.success) {
        enqueueSnackbar({
          variant: 'success',
          message: response.metadata.message,
        });
        handleClose();
        reload();
      } else {
        handleErrorMessages(response.message);
      }
    } catch (err) {
      console.error('Submission error:', err);
      handleErrorMessages(err.message || 'Submission failed.');
    } finally {
    }
  });

  const handleClose = () => {
    reset(defaultValues);
    closeform();
    onClose();
  };

  const handleOwnerChange = useCallback((riskOwnerId) => {
    setSelectedRiskOwnerId(riskOwnerId);
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{
        borderRadius: 'none',
        padding: '60px',
        fontSize: '13px',
        fontFamily: 'arial',
      }}
      transitionDuration={{
        enter: theme.transitions.duration.shortest,
        exit: theme.transitions.duration.shortest - 80,
      }}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'content-box' }}>
          <span
            className='cardColor'
            style={{ padding: '7px', marginLeft: '-25px', color: 'white', fontWeight: '700' }}>
            &nbsp;&nbsp; Quarterly Risk Review Form &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <IconButton onClick={onClose}>
            <Iconify icon="mdi:close" />
          </IconButton>
        </DialogTitle>
      
        <DialogContent sx={{ borderRadius: '1px' }}>
          <Box
            mt={2}
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="review_id"
              label="Review ID"
              disabled
              value={`${getValues('review_id') || ''}${currentItem ? '' : '-QRRF'}`}
            />
            <RHFAutocomplete
              name="review_period"
              label="Review Period"
              options={statusOptions}
              getOptionLabel={(option) => option.label || ""}
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              defaultValue={null}
            />

            <Controller
              name="review_date"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DatePicker
                  label="Date of Review"
                  value={field.value}
                  onChange={(newValue) => {
                    field.onChange(newValue);
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />
            <MultipleRiskSelector
              label="Risks Reviewed"
              name="risk_ids"
              options={Array.isArray(riskIds) ? riskIds : []} 
              onMemberSelect={onMemberIdsChange}
              value={getValues('risk_ids')}
            />
            <RHFTextField name="review_summary" label="Review Summary" />
            <RHFTextField name="recommendations" label="Recommendations" />
            <RiskprojectUserAdd
              name="attended_by"
              label="Attended By"
              options={riskOwnersOptions}
              reload={() => reloadRiskOwnersOptions()}
              url={endpoints.risk_management.risk_register.identification.risk_owners}
              onRiskOwnerChange={handleOwnerChange}
              onChange={(value) => setValue('attended_by', value)}
              value={getValues('attended_by')}
              error={!!errors.attended_by}
              helperText={errors.attended_by?.message}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            className="dark-button"
            loading={isSubmitting}
          >
            {!currentItem ? 'Create Review' : 'Save Changes'}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};
export default RiskQuaterlyreviewCreate;