import React from 'react';
import { Grid } from '@mui/material';
import OverallRisk from './overall-riskscirlce';
import RiskFilterTable from './risk-filter-table';

export default function ListfilterTable({data}) {
  console.log("🚀 ~ ListfilterTable ~ data:", data);
  return (
    <Grid container spacing={2} pt={6}>
      <Grid item xs={12} lg={8} container style={{ display: 'flex' }}>
        <Grid item xs={12} style={{ flexGrow: 1 }}>
          <RiskFilterTable
            title="Top Risks"
            tabledata={data}
            tableLabels={[
              { id: 'id', label: 'ID' },
              { id: 'description', label: 'Risk Description' },
              { id: 'dateOfIssue', label: 'Date of Issue' },
            ]}
          />          
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4} container style={{ display: 'flex' }}>
        <Grid item xs={12} style={{ flexGrow: 1 }}>
          <OverallRisk
            title="Overall Risk By Severity Rating "
            chart={{
              series: [
                { label: 'Very Low', value: data?.over_all_risk?.very_low || 0 },
                { label: 'Low', value: data?.over_all_risk?.low || 0 },
                { label: 'Medium', value: data?.over_all_risk?.medium || 0},
                { label: 'High', value: data?.over_all_risk?.high || 0 },
                { label: 'Very High', value: data?.over_all_risk?.very_high || 0 },
              ],
            }}
          />

        </Grid>
      </Grid>
    </Grid>
  );
}