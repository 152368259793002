import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import {
  IconButton,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
} from '@mui/material';
import Iconify from 'src/components/iconify';
import { RHFAutocomplete, RHFTextField } from '../hook-form';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../hook-form/form-provider';
import { useSearchParams } from 'src/routes/hooks';
import { handleErrorMessages } from '../risk-management/risk-register/feching';
import { baseURL } from 'src/config-global';

const DropDownAddValue = ({ name, label, options = [], url = '', reload  }) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = useSearchParams();
  const token = searchParams.get('token') || localStorage.getItem('authToken') || '';

  // Validation schema for the modal form
  const NewUserSchema = Yup.object().shape({
    label: Yup.string().required(`${label} is required`),
  });

  // Default form values
  const defaultValues = useMemo(() => ({
    label: '',
  }), []);

  // Hook form setup with validation schema
  const methods = useForm({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = methods;

  // Modal open and close handlers
  const handleOpen = (e) => {
    e.stopPropagation(); // Prevent the parent form from submitting
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    reset(); // Reset form when modal is closed
  };

  // Custom form validation
  const validateForm = () => {
    const formData = watch();
    if (!formData.label) {
      enqueueSnackbar(`${label} is required`, { variant: 'error' });
      return false;
    }
    return true;
  };

  // Save handler for the modal form
  const handleSave = async () => {
    if (!validateForm()) return; // Validate before submitting

    setLoading(true);
    try {
      const response = await fetch(`${baseURL}${url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name: watch('label') }), // Form data submission
      });

      const result = await response.json();
      setLoading(false);

      if (result.metadata?.success) {
        reload(); 
        reset();
        handleClose(); 
        enqueueSnackbar(result.metadata.message, { variant: 'success' });
      } else {
        handleErrorMessages(result.metadata.message);
        enqueueSnackbar(result.metadata.message, { variant: 'error' });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Oops something went wrong', { variant: 'error' });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'start' }}>
        <RHFAutocomplete
          sx={{ width: '100%' }}
          name={name}
          label={label} // Use plain label here
          options={options.map((option) => option.name)}
          getOptionLabel={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          renderOption={(props, option) => {
            const { name, id } = options.find((item) => item.name === option) || {};
            return (
              <li {...props} key={id}>
                <Iconify width={28} sx={{ mr: 1 }} />
                {name}
              </li>
            );
          }}
        />
        <IconButton
          onClick={handleOpen}
          className='cardColor'
          sx={{
            margin: '5px 10px',
            
            color: '#FFF',
            fontSize: 25,
            padding: '7px',
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: '#7AB13A',
            },
            boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Iconify icon="mingcute:add-line" />
        </IconButton>
      </Box>

      {/* Modal with Error Handling */}
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <FormProvider methods={methods}>
          <DialogTitle>Add New {label}</DialogTitle>
          <Divider />
          <DialogContent>
            <Box
              mt={2}
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns="repeat(1, 1fr)"
            >
              <RHFTextField
                name="label"
                label={`${label} *`} // Asterisk for required field in modal
                error={!!errors.label} // Show error if it exists
                helperText={errors.label?.message} // Error message for validation
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              className='dark-button'
              disabled={loading || isSubmitting}
              loading={loading || isSubmitting}
              variant="contained"
              onClick={handleSubmit(handleSave)} // Custom save handler
            >
              Save
            </LoadingButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default DropDownAddValue;
